<template>
    <div class="pt-4 jobsheet">
		<div class="row">
			<div class="col-md-12">
                <h3>{{type | uppercase}} CHECKLIST - {{id}}</h3>
                <div class="card shadow">
                    <div class="card-body">
                        <div class="table-responsive" v-if="checklist">
                            <table class="mb-1 table table-striped">
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(data, key) in checklist" :key="key">
                                        <td>{{FormatKey(key)}}</td>
                                        <td>{{data}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 class="mb-0" v-else>NO DATA</h5>
                    </div>
                </div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    data: () => ({
        type: null,
        id: null,
        checklist: null
    }),
    mounted() {
        this.id = this.$route.params.id;
        this.type = this.$route.params.type;
        this.GetData();
    },
    methods: {
        GetData() {
            switch (this.type) {
                case 'prework':
                    this.GetChecklistData('prework')
                    break;
                case 'roadtest':
                    this.GetChecklistData('roadtest')
                    break;
                case 'workshop':
                    this.GetChecklistData('workshop')
                    break;
                case 'photos':
                    this.GetChecklistData('photos')
                    break;
                case 'postwork':
                    this.GetChecklistData('postwork')
                    break;
                case 'frt':
                    this.GetChecklistData('frt')
                    break;
                default:
                    break;
            }
        },
        GetChecklistData(type) {
            axios.post(api_url + '/api/checklist/data', {
                type: type,
                job_id: this.id
            }).then(resp => {
                if(resp.data) {
                    this.checklist = resp.data;
                }
            });
        },
        FormatKey(value) {
            value = value.replace(/_/g, ' ');
            var splits = value.split(' ');
            splits.forEach((element,indx) => {
                splits[indx] = _.capitalize(element);
            });
            return _.join(splits, ' ');
        },
    },
    filters: {
        uppercase(val) {
            return _.upperCase(val)
        }
    }
}
</script>

<style scoped>
nav {
    display: none !important
}
.container-fluid.pt-5 {
    padding-top: 10px !important
}
.pt-4.jobsheet {
    padding-top: 20px !important
}
</style>