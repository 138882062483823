<template>
    <div class="px-4 py-2 border-t border-gray-300">
        <div v-if="job">
            <div class="grid grid-cols-2 gap-x-3 gap-y-6 py-3">
                <div class="" @click="select_checklist('Pre-Check')">
                    <img class="rounded mr-1 h-8" :src="meetGreetImg"  alt="PreWorkChecklist"/>
                    <span class="text-md font-bold flex-1">Pre Work</span>
                    <ul v-if="job.pre_work_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.pre_work_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Locker Location:
                            <span class="font-bold">{{job.pre_work_check.locking_wheel_nut_location}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.pre_work_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="" @click="select_checklist('Road-Test')">
                    <img class="rounded mr-1 h-8" :src="roadTestImg"  alt="RoadTestChecklist"/>
                    <span class="text-md font-bold flex-1">Road Test</span>
                    <ul v-if="job.road_test_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.road_test_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Fault Scan:
                            <span class="font-bold">{{job.road_test_check.fault_scan}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.road_test_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="" @click="select_checklist('Workshop-Check')">
                    <img class="rounded mr-1 h-8" :src="workshopImg"  alt="PreWorkChecklist"/>
                    <span class="text-md font-bold flex-1">Workshop</span>
                    <ul v-if="job.workshop_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.workshop_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.workshop_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="" @click="select_checklist('Extra-Media')">
                    <img class="rounded mr-1 h-8" :src="photosVideosImg"  alt="RoadTestChecklist"/>
                    <span class="text-md font-bold flex-1">Photos & Videos</span>
                    <ul v-if="job.extra_media_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.extra_media_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.extra_media_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="" @click="select_checklist('Post-Work')">
                    <img class="rounded mr-1 h-8" :src="postWorkImg"  alt="PreWorkChecklist"/>
                    <span class="text-md font-bold flex-1">Post Work</span>
                    <ul v-if="job.post_work_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.post_work_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.post_work_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="" @click="select_checklist('Final-Road-Test')">
                    <img class="rounded mr-1 h-8" :src="finalRoadTestImg"  alt="RoadTestChecklist"/>
                    <span class="text-md font-bold flex-1">Final Road Test</span>
                    <ul v-if="job.final_road_test_check">
                        <li class="text-xs font-semibold">
                            Checked by:
                            <span class="font-bold">{{job.final_road_test_check.check_completed_by}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Fault Scan:
                            <span class="font-bold">{{job.final_road_test_check.fault_scan}}</span>
                        </li>
                        <li class="text-xs font-semibold">
                            Completed at:
                            <span class="font-bold">{{ConvertDate(job.final_road_test_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>
            </div>

            <!--<div class="row">
                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbwR8pbEfKcOoAq3VbzF5jMtWcJwZEU3y11L9abMn-MWss8wWm8/exec?job_id='+job.id" target="_blank" v-if="!job.pre_work_check">
                        <div class="mb-2">
                            <img class="rounded mr-1 h-8" :src="meetGreetImg"  alt="PreWorkChecklist"/> <span class="text-md font-bold flex-1">Pre-Check List</span>
                        </div>
                    </a>

                    <div class="mb-2" @click="select_checklist('Pre-Check')" v-else>
                        <img class="rounded mr-1 h-8" :src="meetGreetImg" alt="PreWorkChecklist"/> <span class="text-md font-bold flex-1">Pre-Check List</span>
                    </div>

                    <ul v-if="job.pre_work_check">
                        <li>
                            Checked by:
                            <span>{{job.pre_work_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Locker Location:
                            <span>{{job.pre_work_check.locking_wheel_nut_location}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.pre_work_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbyOBN_ovprU3nqz2gKzx_dEL2hLLEbHL_MuVHXlAvIIC_H2xQU/exec?job_id='+job.id" target="_blank" v-if="!job.road_test_check">
                        <div class="flex items-center mb-2 flex-wrap">
                            <img class="rounded mr-1 h-8" :src="roadTestImg" alt="RoadTest"/> <span class="text-md font-bold">Road Test</span>
                        </div>
                    </a>
                    <div class="flex items-center mb-2 flex-wrap" @click="select_checklist('Road-Test')" v-else>
                        <img class="rounded mr-1 h-8" :src="roadTestImg" alt="RoadTest"/> <span class="text-md font-bold">Road Test</span>
                    </div>
                    <ul v-if="job.road_test_check">
                        <li>
                            Checked by:
                            <span>{{job.road_test_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Fault Scan:
                            <span>{{job.road_test_check.fault_scan}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.road_test_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbwxmNfGkdretgtinnFcmicLwqCYv2Ye6GpNKUxANymnnjguAWBw/exec?job_id='+job.id" target="_blank" v-if="!job.workshop_check">
                        <div class="flex items-center mb-2">
                            <img class="rounded mr-1 h-10" :src="workshopImg" alt="Workshop"/> <span class="text-md font-bold">Workshop</span>
                        </div>
                    </a>
                    <div class="flex items-center mb-2" @click="select_checklist('Workshop-Check')" v-else>
                        <img class="rounded mr-1 h-10" :src="workshopImg" alt="Workshop"/> <span class="text-md font-bold">Workshop</span>
                    </div>
                    <ul v-if="job.workshop_check">
                        <li>
                            Checked by:
                            <span>{{job.workshop_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.workshop_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbyKeZ3PBQazCkBUscmJMYd0xOZM3K8Z328NhyV42-kSHH5Kp9bF/exec?job_id='+job.id" target="_blank" v-if="!job.extra_media_check">
                        <div class="flex items-center mb-2">
                            <img class="rounded mr-1 h-10" :src="photosVideosImg"  alt="ExtraPhotos"/> <span class="text-md font-bold">Extra Photos &amp; Videos</span>
                        </div>
                    </a>
                    <div class="flex items-center mb-2" @click="select_checklist('Extra-Media')" v-else>
                        <img class="rounded mr-1 h-10" :src="photosVideosImg" alt="ExtraPhotos"/> <span class="text-md font-bold">Extra Photos &amp; Videos</span>
                    </div>
                    <ul v-if="job.extra_media_check">
                        <li>
                            Checked by:
                            <span>{{job.extra_media_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.extra_media_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbw36in8kfQfE3qH4zTo508bcBJCji-mQXPUGgTqN28RUQhJruzS/exec?job_id='+job.id" target="_blank" v-if="!job.post_work_check">
                        <div class="flex items-center mb-2">
                            <img class="rounded mr-1 h-10" :src="postWorkImg" alt="PostWork"/> <span class="text-md font-bold">Post-Work Check</span>
                        </div>
                    </a>
                    <div class="flex items-center mb-2" @click="select_checklist('Post-Work')" v-else>
                        <img class="rounded mr-1 h-10" :src="postWorkImg" alt="PostWork"/> <span class="text-md font-bold">Post-Work Check</span>
                    </div>
                    <ul v-if="job.post_work_check">
                        <li>
                            Checked by:
                            <span>{{job.post_work_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.post_work_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>

                <div class="col-md-6">
                    <a :href="'https://script.google.com/a/darksidedevelopments.co.uk/macros/s/AKfycbx-HSem9eeKa9vjbF-pxZiZCHDS5sQFgyGM17Nq6qGRuX6QpvvM/exec?job_id='+job.id" target="_blank" v-if="!job.final_road_test_check">
                        <div class="flex items-center mb-2">
                            <img class="rounded mr-1 h-10" :src="finalRoadTestImg" alt="FinalRoadTest"/> <span class="text-md font-bold">Final Road Test</span>
                        </div>
                    </a>
                    <div class="flex items-center mb-2" @click="select_checklist('Final-Road-Test')" v-else>
                        <img class="rounded mr-1 h-10" :src="finalRoadTestImg" alt="FinalRoadTest"/> <span class="text-md font-bold">Final Road Test</span>
                    </div>
                    <ul v-if="job.final_road_test_check">
                        <li>
                            Checked by:
                            <span>{{job.final_road_test_check.check_completed_by}}</span>
                        </li>
                        <li>
                            Fault Scan:
                            <span>{{job.final_road_test_check.fault_scan}}</span>
                        </li>
                        <li>
                            Completed at:
                            <span>{{ConvertDate(job.final_road_test_check.timestamp)}}</span>
                        </li>
                    </ul>
                    <p v-else class="text-danger">Incomplete</p>
                </div>
            </div>-->
        </div>
    </div>

    <a-modal class="p-0" width="50%" v-model:visible="modalOpen" :title="selectedChecklist.title + ' Checklist'" v-if="job && selectedChecklist">
        <div class="overflow-x-auto">
            <table class="checklist-table w-full table-striped">
                <thead>
                    <tr>
                        <td class="px-2 py-2 border-r border-b border-gray-200 font-semibold">Key</td>
                        <td class="px-2 py-2 border-b border-gray-200 font-semibold">Value</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in selectedChecklist" :key="key">
                        <td class="px-2 py-1 font-semibold border-r border-b border-gray-200">{{tidy(key)}}</td>
                        <td class="px-2 py-1 border-b border-gray-200">{{value}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </a-modal>
</template>

<script>
import moment from 'moment';
import MeetGreetImg from '@/assets/images/checklists/Meet and Greet.png';
import RoadTestImg from '@/assets/images/checklists/Road Test.png';
import WorkshopImg from '@/assets/images/checklists/Workshop Check.png';
import PhotosVideosImg from '@/assets/images/checklists/Add Photos - Videos.png';
import PostWorkImg from '@/assets/images/checklists/Post Work Check.png';
import FinalRoadTestImg from '@/assets/images/checklists/Final Road Test.png';
export default {
    props: ['job'],
    filters: {
        date(val) {
            return moment(val).format('DD-MM-YYYY HH:mm');
        }
    },
    data: () => ({
        selectedChecklist: null,
        modalOpen: false,
        meetGreetImg: MeetGreetImg,
        roadTestImg: RoadTestImg,
        workshopImg: WorkshopImg,
        photosVideosImg: PhotosVideosImg,
        postWorkImg: PostWorkImg,
        finalRoadTestImg: FinalRoadTestImg
    }),
    methods: {
        select_checklist(checklist) {
            if (checklist === 'Pre-Check') {
                if(this.job.pre_work_check) {
                    this.selectedChecklist = this.job.pre_work_check
                    this.selectedChecklist.title = "Pre Work";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }
            if (checklist === 'Road-Test') {
                if(this.job.road_test_check) {
                    this.selectedChecklist = this.job.road_test_check
                    this.selectedChecklist.title = "Road Test";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }

            if (checklist === 'Workshop-Check') {
                if(this.job.workshop_check) {
                    this.selectedChecklist = this.job.workshop_check
                    this.selectedChecklist.title = "Workshop Check";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }

            if (checklist === 'Post-Work') {
                if(this.job.post_work_check) {
                    this.selectedChecklist = this.job.post_work_check
                    this.selectedChecklist.title = "Post Work Check";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }

            if (checklist === 'Final-Road-Test') {
                if(this.job.final_road_test_check) {
                    this.selectedChecklist = this.job.final_road_test_check
                    this.selectedChecklist.title = "Final Road Test";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }

            if (checklist === 'Extra-Media') {
                if(this.job.extra_media_check) {
                    this.selectedChecklist = this.job.extra_media_check
                    this.selectedChecklist.title = "Extra Photos/Videos";
                    this.modalOpen = true;
                } else {
                    window.open('https://checklists.darkside-developments.com/', '_blank')
                }
            }
        },
        tidy(val) {
            if(val) {
                return val.replace(/_/g, ' ');
            } else {
                return val;
            }
        }
    }
}
</script>

<style>
.ant-modal.p-0 .ant-modal-body {
    padding: 0;
}
.checklist-table td {
    white-space: nowrap;
}
</style>
