<template>
    <div>
        <div class="pt-14 pb-4 container-fluid">
            <h5 class="text-xl py-4 font-semibold">Check Swiftec Progress <span v-if="$route.params.id">for job {{ $route.params.id }}</span></h5>
            <div class="pb-3">
                <div class="p-3 bg-green-200 border border-solid border-green-400 rounded" v-if="currentJob">
                    <ul>
                        <li><b>Job:</b> <span>{{ currentJob.folder }}</span></li>
                        <li><b>Status:</b> <span>{{ currentJob.state }}</span></li>
                        <li><b>Started:</b> <span>{{ currentJob.created_at }}</span></li>
                        <li><b>Finished:</b> <span>{{ currentJob.updated_at }}</span></li>
                        <li><b>ECU:</b> <span>{{ currentJob.ecu }}</span></li>
                        <li><b>Filename:</b> <span>{{ currentJob.filename }}</span></li>
                        <li><b>Codes:</b> <span>{{ currentJob.codes }}</span></li>
                    </ul>
                </div>
                <a-button type="primary" class="mt-2" @click="checkswiftecStatus($event)">Check</a-button>

                <div class="row mt-4">
                    <div class="col-md-12">
                        <h6 class="text-lg mb-2">SWIFTEC VM PREVIEW
                            <a-button size="small" @click="getScreenshot">Refresh</a-button>
                        </h6>
                        <img class="img-fluid" :src="screenshot" v-if="screenshot" alt="Screenshot">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'underscore';

_.mixin({
    capitalize: function (val) {
        return val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
    }
});
export default {
    data: () => ({
        all_jobs: [],
        job_id: "",
        folder: "",
        file: "",
        name: "",
        phone: "",
        reg: "",
        ecu_type: "",
        make: "",
        model: "",
        tool: "",
        engine: "",
        bhp: "",
        year: "",
        vin: "",
        transmission: "",
        swiftecProgressMsg: "",
        hasRouteParam: false,
        currentJob: null,
        screenshot: "",
    }),
    mounted() {
        if (this.$route.params && this.$route.params.id === undefined) {
            axios.get(api_url + "/v2/jobs/swiftec/status").then((resp) => {
                this.currentJob = resp.data;
                this.getScreenshot();
            });
        } else {
            this.GetJobById(this.$route.params.id);
            this.getScreenshot();
        }
    },
    methods: {
        GetJobById(id) {
            axios.get(api_url + '/v2/jobs/' + id + '/swiftec').then((resp) => {
                const data = resp.data;
                this.name = data.customer.firstname + " " + data.customer.lastname;
                this.phone = data.customer.phone.phonenumber;
                this.reg = data.vehicle.VRVrm;
                this.ecu_type = data.vehicle.ecu_type;
                this.make = data.vehicle.VRMake;
                this.model = data.vehicle.VRModel;
                this.engine = data.vehicle.EngineCapacity;
                this.bhp = data.vehicle.hp;
                this.year = data.vehicle.YearOfManufacture;
                this.vin = data.vehicle.VRVin;
                this.transmission = data.vehicle.gearboxType;
                if (resp.data.tool !== null) {
                    this.tool = resp.data.tool.Tool;
                } else {
                    this.tool = "";
                }
                this.folder = resp.data.tuning_folder;
                this.checkswiftecProgress();
            })
        },
        GetAllJobs() {
            axios.get(api_url + '/v2/jobs/all').then((resp) => {
                this.all_jobs = resp.data;
            })
        },
        FileChanged(e) {
            const files = e.target.files; // FileList object
            this.file = files[0].name;
        },
        utfEncode(s) {
            if (s !== null && s !== "" && s !== undefined) {
                s = s.replace(/Â£/g, '£');
                s = s.replace(/Â\s/g, '');
                s = s.replace(/Ã©/g, 'é');
                s = s.replace(/&quot;/g, '"');
                return s
            } else {
                return s;
            }
        },
        SubmitForm() {
            console.log("Form Should Submit")
            const make = this.make.replace(/\s/g, "-");
            const model = this.model.replace(/\s/g, "-");
            const transmission = this.transmission.replace(/\s/g, "-");
            if (
                this.file === "" ||
                this.folder === "" ||
                this.name === "" ||
                this.phone === "" ||
                this.reg === "" ||
                this.ecu_type === "" ||
                this.tool === "" ||
                this.make === "" ||
                this.model === "" ||
                this.engine === "" ||
                this.bhp === "" ||
                this.year === "" ||
                this.vin === "" ||
                this.transmission === ""
            ) {
                return;
            }
            const json = {
                "tuning_file": this.file.trim(),
                "folder": this.folder.trim(),
                "name": this.name.trim(),
                "phone": this.phone,
                "reg": this.reg.trim(),
                "ecu_type": this.ecu_type.trim(),
                "tool": this.tool.trim(),
                "make": make.trim(),
                "model": model.trim(),
                "engine_capacity": this.engine,
                "bhp": this.bhp,
                "year": this.year,
                "vin": this.vin.trim(),
                "gearbox": transmission.trim()
            };
            axios.post('https://api.darkside-developments.com/api/jobs/swiftec', json).then((resp) => {
                if (resp.data.success) {
                    location.replace('/swiftec/status');
                } else {
                    alert(resp.data.reason)
                }
            })
        },
        checkswiftecProgress() {
            axios.post(api_url + "/v2/jobs/swiftec/progress", {folder: this.folder.trim()}).then(resp => {
                if (resp.data !== null) {
                    this.currentJob = resp.data;//this.swiftecProgressMsg = resp.data.state;
                }
            });
        },
        checkswiftecStatus(e) {
            e.target.disabled = true;
            axios.get(api_url + "/v2/jobs/swiftec/status").then((resp) => {
                this.currentJob = resp.data;
                e.target.disabled = false;
            })
        },
        getScreenshot() {
            axios(api_url + '/v2/jobs/swiftec/screenshot', {
                method: 'GET',
                mode: 'no-cors',
            }).then((response) => {
                this.screenshot = 'data:image/png;base64,' + response.data;
            }).catch(() => {
            });
            /*setTimeout(() => {
              this.getScreenshot()
            }, 10000);*/

        },
        hexToBase64(str) {
            return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
        }
    }
}
</script>
<style scoped>
input.required,
select.required {
    border: 1px solid red;
}

ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

ul li span {
    margin-left: 10px;
}
</style>
