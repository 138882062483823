<template>
    <i :class="classes" :style="style"></i>
</template>

<script>
export default {
    props: {
        regular: {
            type: Boolean,
            default: false,
        },
        solid: {
            type: Boolean,
            default: false,
        },
        light: {
            type: Boolean,
            default: false,
        },
        thin: {
            type: Boolean,
            default: false,
        },
        duotone: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: "#1890ff"
        },
        size: {
            type: String,
            default: null,
            validator: (value) => ['2xs', 'xs', 'sm', 'lg', 'xl', '2xl', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].indexOf(value) > -1    
        },
        primaryColor: {
            type: String,
            default: "rebeccapurple"
        },
        secondaryColor: {
            type: String,
            default: "gold"
        }
    },
    setup(props) {
        var classlist = [];
        if(props.duotone) {
            classlist.push("fa-duotone")
        } else {
            var find = classlist.findIndex(el => el == "fa-duotone");
            if(find > -1) {
                classlist.splice(find, 1);
            }
        }
        
        if(props.solid) {
            classlist.push("fa-solid")
        } else {
            var find = classlist.findIndex(el => el == "fa-solid");
            if(find > -1) {
                classlist.splice(find, 1);
            }
        }
        
        if(props.thin) {
            classlist.push("fa-thin")
        } else {
            var find = classlist.findIndex(el => el == "fa-thin");
            if(find > -1) {
                classlist.splice(find, 1);
            }
        }
        
        if(props.regular) {
            classlist.push("fa-regular")
        } else {
            var find = classlist.findIndex(el => el == "fa-regular");
            if(find > -1) {
                classlist.splice(find, 1);
            }
        }
        
        if(props.light) {
            classlist.push("fa-light")
        } else {
            var find = classlist.findIndex(el => el == "fa-light");
            if(find > -1) {
                classlist.splice(find, 1);
            }
        }

        classlist.push(props.icon)
        var classes = classlist.join(' ');
        var style = "--fa-primary-color: "+props.primaryColor+";--fa-secondary-color: "+props.secondaryColor+";"
        return {
            classes,
            style
        }
    },
    data() {
        return {
            classlist: []
        }
    },
    computed: {
        isDuotone() {
            if(this.duotone) {
                this.classlist.push("fa-duotone")
            } else {
                var find = this.classlist.findIndex(el => el == "fa-duotone");
                if(find > -1) {
                    this.classlist.splice(find, 1);
                }
            }
        },
        setIcon() {
            this.classlist.push(this.icon)
        }
    }
}
</script>

<style>
.svg-inline--fa  path{
    stroke: #1890ff;
    stroke-width: 30px;
    fill: #E6F7FE;
}
</style>