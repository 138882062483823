<template>
    <div class="booking mt-16">
        <div class="container mt-5 pb-3">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h2 class="text-3xl">Quick Book</h2>
                    <h6 class="text-base">{{today}}</h6>
                    <h6 class="text-base" v-if="customer">{{customer.firstname}} {{customer.lastname}} (<span v-if="customer.email">{{customer.email.email}}</span> - <span v-if="customer.phone">{{customer.phone.phonenumber}}</span>)</h6>
                    <h6 class="text-sm text-uppercase" v-if="vehicle">{{vehicle.VRMake}} {{vehicle.VRModel}} ({{vehicle.VRVrm}} - {{vehicle.VRVin}})</h6>
                </div>
            </div>
        </div>
    </div>

    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="maincard border-0">
                    <div class="p-3">
                        <div>
                            <div class="flex">
                                <label>Dropping Date and Time*</label>
                                <a-button type="primary" class="ml-auto" size="small" @click="defaultDrop">Default Start Date / Time</a-button>
                            </div>
                            <div class="flex gap-3 mt-1">
                                <flat-pickr v-model="dropoff_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                <flat-pickr v-model="dropoff_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="flex">
                                <label>Start Date and Time*</label>
                                <a-button type="primary" class="ml-auto" size="small" @click="defaultStart">Default Start Date / Time</a-button>
                            </div>
                            <div class="flex gap-3 mt-1">
                                <flat-pickr v-model="start_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                <flat-pickr v-model="start_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="flex">
                                <label>Finish Date and Time*</label>
                                <a-button type="primary" class="ml-auto" size="small" @click="defaultFinish">Default Finish Date / Time</a-button>
                            </div>
                            <div class="flex gap-3 mt-1">
                                <flat-pickr v-model="finish_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                <flat-pickr v-model="finish_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div class="flex">
                                <label>Collection Date and Time*</label>
                                <a-button type="primary" class="ml-auto" size="small" @click="defaultPick">Default Collection Date / Time</a-button>
                            </div>
                            <div class="flex gap-3 mt-1">
                                <flat-pickr v-model="collect_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                <flat-pickr v-model="collect_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                            </div>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Booking Notes</label>
                            <text-editor v-model="notes" class-name="main-notes" class="w-full"></text-editor>
                        </div>

                      <div class="mt-3">
                        <div class="form-group fs-sm">
                          <a-checkbox v-model:checked="is_warranty">Is Warranty Job?</a-checkbox>
                        </div>
                      </div>

                        <div class="flex gap-3 mt-3">
                            <div class="w-full text-center">
                                <a-button type="primary" @click="submit" :disabled="shouldDisable" :loading="loading">Complete Booking</a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import TextEditor from '../TextEditor.vue';
import axios from "axios";
import {useAuthStore} from "@/store/authStore";

export default {
    components: {
        TextEditor
    },
    data() {
        return {
            customer_id: null,
            booked_in_by: null,
            booked_in_reg: null,
            today: moment().tz("Europe/London").format('MMMM Do YYYY, h:mm:ss a'),
            now: moment().tz("Europe/London").format('YYYY-MM-DD'),
            errors: [],
            dropoffDatePicker: null,
            dropoffTimePicker: null,
            startDatePicker: null,
            startTimePicker: null,
            endDatePicker: null,
            endTimePicker: null,
            pickupDatePicker: null,
            pickupTimePicker: null,
            pickerConfigDate: {
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                altInput: true,
                enableTime: false,
                defaultDate: null
            },
            pickerConfigTime: {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
            },
            dropoff_date: null,
            dropoff_time: null,
            start_date: null,
            start_time: null,
            collect_date: null,
            collect_time: null,
            finish_date: null,
            finish_time: null,
            notes: "",
            customer: null,
            vehicle: null,
            loading: false,
            is_warranty: false,
        }
    },
    mounted() {
        this.customer_id = this.$route.query.name;
        this.booked_in_by = useAuthStore().user.nicename;
        this.booked_in_reg = this.$route.query.reg;
        this.getVehicleDetails();
        this.getCustomerDetails();
    },
    computed: {
        shouldDisable() {
            return this.customer === null ||
                this.vehicle === null ||
                this.dropoff_date === null ||
                this.dropoff_time === null ||
                this.start_date === null ||
                this.start_time === null ||
                this.collect_date === null ||
                this.collect_time === null ||
                this.finish_date === null ||
                this.finish_time === null;
        }
    },
    methods: {
        defaultDrop(){
            if(this.dropoff_date === "" || this.dropoff_date == null) {
                this.dropoff_date = moment().tz("Europe/London").format('YYYY-MM-DD');
                this.dropoff_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                if(moment(this.dropoff_date).format('dddd') === 'Saturday') {
                    this.dropoff_time = moment('09:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                } else {
                    this.dropoff_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                }
            }
        },
        defaultStart(){
            this.start_date = this.dropoff_date || moment().tz("Europe/London").format('YYYY-MM-DD');
            if(this.dropoff_time) {
                this.start_time = moment(this.dropoff_time, 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.start_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            }
        },
        defaultFinish(){
            if(this.start_date === "") {
                this.finish_date = this.start_date || moment().tz("Europe/London").format('YYYY-MM-DD');
                this.finish_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.finish_date = this.start_date || moment().tz("Europe/London").format('YYYY-MM-DD');
                if(moment(this.start_date).tz("Europe/London").format('dddd') === 'Saturday') {
                    this.finish_time = moment('15:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                } else {
                    this.finish_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                }
            }
        },
        defaultPick(){
            this.collect_date = this.finish_date || moment().tz("Europe/London").format('YYYY-MM-DD');
            if(this.finish_time) {
                this.collect_time = moment(this.finish_time, 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.collect_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            }
        },
        getVehicleDetails(){
            let p_reg = this.booked_in_reg;
            if(p_reg !== undefined) {
                p_reg = p_reg.replace(' ', '')
                axios.post('https://api.darkside-developments.com/api/crm/vehicle/lookup?ecp=true&id=' + p_reg).then((resp) => {
                    axios.get(api_url + '/v2/jobs/vehicle/'+p_reg).then((response) => {
                        const d = response.data;
                        this.vehicle = d;
                        console.log(d)
                    }).catch((error) => {
                        console.log(error);
                    });
                });
            }
        },
        getCustomerDetails(){
            const p_cust_id = this.customer_id;
            if(p_cust_id !== undefined) {
                // Make a request for a customer with a given ID
                axios.get(api_url + '/v2/jobs/customer/' + p_cust_id).then((response) => {
                    this.customer = response.data;
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        submit() {
            this.loading = true;
            const booking = {
                customer_id: this.customer_id,
                vehicle_id: this.vehicle.ID,
                booked_in_by: this.booked_in_by,
                notes: this.notes,
                dropoff_date: this.dropoff_date,
                dropoff_time: this.dropoff_time,
                collect_date: this.collect_date,
                collect_time: this.collect_time,
                start_date: this.start_date,
                start_time: this.start_time,
                finish_date: this.finish_date,
                finish_time: this.finish_time,
                is_warranty: this.is_warranty
            }
            axios.post(api_url + '/v2/jobs/booking/quick', {
                booking: booking
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Job booked in.');
                    this.loading = false;
                    this.$router.push({ name: 'Jobsheet', params: { id: resp.data.data.job_id } })
                } else {
                    this.loading = false;
                    this.$message.error('Something went wrong: '+ resp.data.data);
                }
            }).catch(err => {
                console.log(err)
                this.loading = false;
                this.$message.error('Something went wrong');
            })
        }
    }
}
</script>
