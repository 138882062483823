import axios from "axios";
import Cookies from "js-cookie";
import {useAuthStore} from "../../store/authStore";
if (window.location.hostname === "localhost") {
	window.api_url = "https://darkside_api.test";//"http://localhost:8000";
} else {
	window.api_url = "https://api.darkside-developments.com";
}
const Auth_URL = api_url + "/api/auth";

Date.prototype.addHours = function(h) {
	this.setHours(this.getHours() + h);
	return this;
};

class AuthService {
	login(payload) {
		return new Promise((resolve, reject) => {
			const { loginSuccessfull } = useAuthStore();
			//super.post(window.api_url + "/auth", payload)
			//	.then(async (response: UserLoggedResponse) => {
			//		await loginSuccessfull(response.data)
			//		resolve(response);
			//	})
			//	.catch((error) => {
			//		reject(error);
			//	});
			axios.post(Auth_URL, {
				username: user.username,
				password: user.password,
			}).then((response) => {
				if (response.data.success) {
					let access_token = response.data.data.user.token;
					axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
					//const expires = new Date().addHours(12).toUTCString();
					if (window.location.hostname === "localhost") {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "localhost" });
					} else {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "darkside-developments.com" });
					}
				}
				return response.data;
			});
		});
	}

	/*login(user) {
		return axios
			.post(Auth_URL, {
				username: user.username,
				password: user.password,
			})
			.then((response) => {
				if (response.data.success) {
					let access_token = response.data.data.user.token;
					axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
					//const expires = new Date().addHours(12).toUTCString();
					if (window.location.hostname === "localhost") {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "localhost" });
					} else {
						Cookies.set("user", JSON.stringify(response.data.data.user), { expires: new Date().addHours(12), domain: "darkside-developments.com" });
					}
				}
				return response.data;
			});
	}*/

	logout() {
		//localStorage.removeItem("user");
		Cookies.remove("user");
	}

	register(user) {
		return axios.post(Auth_URL + "signup", {
			username: user.username,
			email: user.email,
			password: user.password,
		});
	}
}

export default new AuthService();
