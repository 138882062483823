<template>
    <div class="pt-4 pb-4 container-fluid">
        <div class="row mb-3" v-if="job">
            <div class="col-md-12">
                <h3 class="text-3xl">Job ID: <router-link class="text-blue-500" :to="'/jobsheet/'+job_id" target="_blank">{{job_id}}</router-link></h3>
            </div>
        </div>
        <div class="row mb-3" v-if="job && job.times && job.times.length > 0">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">All Times <span class="ml-auto">Total: {{totalTimeSpent()}}</span></div>
                    <div class="overflow-x-auto">
                        <table class="w-full table-striped">
                            <thead>
                                <tr>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">ID</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Task Owner</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Start Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Pause Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">User</th>
                                    <th class="px-3 py-2 border-b border-gray-200 bg-gray-50">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="time in job.times" :key="time.id">
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{time.id}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(job.owner)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.start_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.pause_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(time.user)}}</td>
                                    <td class="px-3 py-2 border-b border-gray-200">{{timeDiff(time.start_time, time.pause_time)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="workshop && workshop.times && workshop.times.length > 0">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">Workshop <span class="ml-auto">Total: {{totalWorkshop()}}</span></div>
                    <div class="overflow-x-auto">
                        <table class="w-full table-striped">
                            <thead>
                                <tr>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">ID</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Task Owner</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Start Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Pause Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">User</th>
                                    <th class="px-3 py-2 border-b border-gray-200 bg-gray-50">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody v-if="workshop">
                                <tr v-for="time in workshop.times" :key="time.id">
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{time.id}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(job.owner)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.start_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.pause_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(time.user)}}</td>
                                    <td class="px-3 py-2 border-b border-gray-200">{{timeDiff(time.start_time, time.pause_time)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="tuning && tuning.times && tuning.times.length > 0">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">Tuning <span class="ml-auto">Total: {{totalTuning()}}</span></div>
                    <div class="overflow-x-auto">
                        <table class="w-full table-striped">
                            <thead>
                                <tr>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">ID</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Task Owner</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Start Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Pause Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">User</th>
                                    <th class="px-3 py-2 border-b border-gray-200 bg-gray-50">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody v-if="tuning">
                                <tr v-for="time in tuning.times" :key="time.id">
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{time.id}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(job.owner)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.start_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.pause_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(time.user)}}</td>
                                    <td class="px-3 py-2 border-b border-gray-200">{{timeDiff(time.start_time, time.pause_time)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="fabrication && fabrication.times && fabrication.times.length > 0">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">Fabrication <span class="ml-auto">Total: {{totalFabrication()}}</span></div>
                    <div class="overflow-x-auto">
                        <table class="w-full table-striped">
                            <thead>
                                <tr>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">ID</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Task Owner</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Start Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Pause Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">User</th>
                                    <th class="px-3 py-2 border-b border-gray-200 bg-gray-50">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody v-if="fabrication">
                                <tr v-for="time in fabrication.times" :key="time.id">
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{time.id}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(job.owner)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.start_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.pause_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(time.user)}}</td>
                                    <td class="px-3 py-2 border-b border-gray-200">{{timeDiff(time.start_time, time.pause_time)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-3" v-if="bodyshop && bodyshop.times && bodyshop.times.length > 0">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">Bodyshop <span class="ml-auto">Total: {{totalBodyshop()}}</span></div>
                    <div class="overflow-x-auto">
                        <table class="w-full table-striped">
                            <thead>
                                <tr>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">ID</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Task Owner</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Start Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">Pause Time</th>
                                    <th class="px-3 py-2 border-r border-b border-gray-200 bg-gray-50">User</th>
                                    <th class="px-3 py-2 border-b border-gray-200 bg-gray-50">Time Taken</th>
                                </tr>
                            </thead>
                            <tbody v-if="bodyshop">
                                <tr v-for="time in bodyshop.times" :key="time.id">
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{time.id}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(job.owner)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.start_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{formatTime(time.pause_time)}}</td>
                                    <td class="px-3 py-2 border-r border-b border-gray-200">{{getUsername(time.user)}}</td>
                                    <td class="px-3 py-2 border-b border-gray-200">{{timeDiff(time.start_time, time.pause_time)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
export default {
    data(){
        return {
            job_id: null,
            job: null,
            workshop: null,
            tuning: null,
            fabrication: null,
            bodyshop: null,
            users: []
        }
    },
    mounted(){
        this.job_id = this.$route.params.id;
        console.log('get times...')
        axios.get(api_url + '/v2/jobs/'+this.job_id+'/times').then((resp) => {
            this.job = resp.data.all
            this.workshop = resp.data.workshop
            this.tuning = resp.data.tuning
            this.fabrication = resp.data.fabrication
            this.bodyshop = resp.data.bodyshop
            this.users = resp.data.users

            this.job.times = this.job.times.reverse();
            this.workshop.times = this.workshop.times.reverse();
            this.tuning.times = this.tuning.times.reverse();
            this.fabrication.times = this.fabrication.times.reverse();
            this.bodyshop.times = this.bodyshop.times.reverse();
        }).catch((err) => {
            console.log(err)
            console.log('failed')
        });
    },
    methods: {
        timeDiff(start,pause) {
            var a = moment(pause);
            var b = moment(start);
            var diff = a.diff(b);
            var time = this.msToTime(diff);//moment(diff).format('HH:mm:ss') // 1
            if(time === "NaN:NaN:NaN") {
                return "In Progress";
            }
            return time;
        },
        totalTimeSpent() {
            if(this.job){
                var count = 0;
                this.job.times.forEach(element => {
                    var start = Date.parse(element.start_time);
                    var pause = Date.parse(element.pause_time);
                    count += (pause - start);
                });
                return this.msToTime(count);
            } else {
                return 0;
            }
        },
        totalWorkshop() {
            if(this.workshop){
                var count = 0;
                this.workshop.times.forEach(element => {
                    var start = Date.parse(element.start_time);
                    var pause = Date.parse(element.pause_time);
                    count += (pause - start);
                });
                return this.msToTime(count);
            } else {
                return 0;
            }
        },
        totalTuning() {
            if(this.tuning){
                var count = 0;
                this.tuning.times.forEach(element => {
                    var start = Date.parse(element.start_time);
                    var pause = Date.parse(element.pause_time);
                    count += (pause - start);
                });
                return this.msToTime(count);
            } else {
                return 0;
            }
        },
        totalFabrication() {
            if(this.fabrication){
                var count = 0;
                this.fabrication.times.forEach(element => {
                    var start = Date.parse(element.start_time);
                    var pause = Date.parse(element.pause_time);
                    count += (pause - start);
                });
                return this.msToTime(count);
            } else {
                return 0;
            }
        },
        totalBodyshop() {
            if(this.bodyshop){
                var count = 0;
                this.bodyshop.times.forEach(element => {
                    var start = Date.parse(element.start_time);
                    var pause = Date.parse(element.pause_time);
                    count += (pause - start);
                });
                return this.msToTime(count);
            } else {
                return 0;
            }
        },
        msToTime(duration) {
            /*var milliseconds = parseInt((duration % 1000) / 100),
                seconds = Math.floor((duration / 1000) % 60),
                minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            hours = (hours < 10) ? "0" + hours : hours;
            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;

            return hours + ":" + minutes + ":" + seconds;*/

            var milliseconds = parseInt(duration);
            var hours = Math.floor(milliseconds / 3600000);
            var minutes = Math.floor((milliseconds - (hours * 3600000)) / 60000);
            var seconds = parseInt((milliseconds - (hours * 3600000) - (minutes * 60000)) / 1000);

            if(hours < 10) {hours = "0"+hours;}
            if(minutes < 10) {minutes = "0"+minutes;}
            if(seconds < 10) {seconds = "0"+seconds;}
            return hours + ":" + minutes + ":" + seconds;
        },
        formatTime(time) {
            if(time) {
                return moment(time).format("DD-MM-YYYY HH:mm:ss")
            } else {
                return time;
            }
        },
        getUsername(id) {
            var name = null;
            this.users.find((e) => {
                if(e.id == id){
                    name = e.nicename;
                }
            });
            return name;
        }
    }
}
</script>

<style>

</style>
