<template>
    <editor-content :editor="editor" :class="className" :id="idName" :ref="idName"/>
</template>

<script>
import {Editor, EditorContent} from "@tiptap/vue-3";
import StarterKit from '@tiptap/starter-kit'
//import * as Y from 'yjs'
//import {WebsocketProvider} from 'y-websocket'
import _ from 'underscore';
export default {
    props: ['modelValue', 'className', 'idName', 'readonly', 'jobId'],
    components: {
        EditorContent
    },
    data(){
        return {
            editor: null,
            val: "",
            linkUrl: null,
            linkMenuIsActive: false,
            provider: null,
        }
    },
    mounted() {
        //const ydoc = new Y.Doc()
        //this.provider = new WebsocketProvider('ws://localhost:1234', 'Job-'+this.jobId, ydoc)
        //this.val = this.escapeHtml(this.modelValue);
        this.editor = new Editor({
            content: this.modelValue,
            extensions: [
                StarterKit.configure({
                    history: false,
                }), 
                /*Collaboration.configure({
                    document: ydoc,
                }),
                CollaborationCursor.configure({
                    provider: this.provider,
                    user: {
                        name: this.$store.state.auth.user.nicename,
                        color: '#f783ac',
                    },
                }),*/
            ],
            onUpdate: (e) => {
                let html = this.editor.getHTML();
                if(html !== "" && html !== null) {
                    html = html.replace(/<a\s+href=/gi, '<a target="_blank" href=');
                }
                this.$emit('update:modelValue', html)
            },
            onBlur: (e) => {
                this.$emit('blur', this.editor.getHTML())
            },
            spellcheck: true
        });
        if(this.readonly) {
            this.editor.setOptions({editable: false});
        }

        if(this.editor.getHTML() !== this.escapeHtml(this.modelValue)) {
            //this.editor.commands.setContent(this.escapeHtml(this.modelValue))
        }
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(event, command, url) {
            event.stopPropagation();
            command(
                { 
                    href: url,
                    target: '_blank'
                }
            )
            this.hideLinkMenu();
            this.editor.focus();
        },
        unescape: function(val) {
            if(val) {
                const el = document.createElement('div');
                return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                    el.innerHTML = enc;
                    return el.innerText
                });
            }
        },
        wrapURL(val, new_window) {
            if(val !== '') {
                let text = this.unescape(val);
                text = text.replace(/\<br\>/g, '<br/>');
                //text = text.replace(/\<p\>\<br\/\>/g, '<p>');
                text = text.replace(/\<br \/\>/g, '<br/>');
                text = text.replace(/&(?!#?[a-z0-9]+;)/g, '&amp;');
                text = "<div>"+text+"</div>"
                const parser = new DOMParser();
                const xmlString = text;
                const doc1 = parser.parseFromString(xmlString, "application/xml");
                return doc1.documentElement.innerHTML;
            } else {
                return val;
            }
        },
        replace(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                return this.wrapURL(s);
            } else {
                return this.wrapURL(s);
            }
        },
        escapeHtml(text) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return this.replace(_.unescape(textArea.value));
        },
    },
    beforeUnmount() {
        this.editor.destroy()
        //this.provider.destroy()
    },
    filters: {
        unescape: function(val) {
            return unescape(val)
        }
    },
    watch: {
        value (val) {
            /*if (this.editor && val !== this.value) {
                this.editor.setContent(val, true)
            }*/
        }
    }
}
</script>

<style>

</style>