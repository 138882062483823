if (window.location.hostname === "localhost") {
    window.api_url = "https://darkside_api.test";//"http://localhost:8000";
} else {
    window.api_url = "https://api.darkside-developments.com";
}

import { createApp } from "vue";
import { createPinia } from 'pinia'
import axios from 'axios'
window.axios = axios;
import Cookies from "js-cookie";
import Antd from 'ant-design-vue';
import flatPickr from "vue-flatpickr-component";
import store from "./store/index";
import routes from "./routes";
import App from "./App.vue";
import draggable from "vuedraggable";
import VueProgressBar from "./libs/ProgressBar/index"//"@aacassandra/vue3-progressbar";
//import Vue3Progress from "vue3-progress";
import vue2Dropzone from "vue2-dropzone";
import moment from 'moment';
import VTooltipPlugin from "v-tooltip";
import CollapseTransition from "@ivanv/vue-collapse-transition/src/CollapseTransition.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import 'select2/dist/css/select2.min.css';
import 'flatpickr/dist/flatpickr.css';
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "ant-design-vue/dist/antd.css";
import "v-tooltip/dist/v-tooltip.css";
import "./assets/style.scss";
window.Cookies = Cookies;
window.api_url = import.meta.env.VITE_APP_SERVER_URL.toString();
const parse = JSON.parse(window.Cookies.get("user") || "null");
if(parse) {
    window.token = parse.token;
} else {
    window.token = null;
}
const pinia = createPinia()
const router = routes;
const options = {
	position: "fixed",
	height: "3px",
	color: "rgb(217, 48, 26)",
	failedColor: "#874b4b",
	thickness: "3px",
	transition: {
		speed: "3s",
		opacity: "0.6s",
		termination: 0,
	},
	autoRevert: true,
	location: "top",
};

const app = createApp(App);
if (window.location.hostname !== "localhost") {
	Sentry.init({
		app,
		dsn: "https://7f7c175d228342b096645a5b8fa7d261@sentry.darksidedev.net/10",
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ["localhost", "jobs.darkside-developments.com", /^\//],
			}),
		],
		tracesSampleRate: 0.3,
	});
}
app.config.productionTip = false;
app.provide("$http", axios);
app.use(pinia);
app.use(store);
app.use(router);
app.use(Antd);
app.use(VueProgressBar, options);
app.use(flatPickr);
app.use(VTooltipPlugin);
app.component("draggable", draggable);
app.component("vueDropzone", vue2Dropzone);
app.component("select2", import("./components/extras/Select2.vue") );
app.component("skeleton", import("./components/extras/Skeleton.vue"));
app.component("timer-count", import("./components/extras/Timer.vue"));
app.component("bodyshop-table", import("./components/jobsheet/tables/BodyshopParts.vue"));
app.component("old-parts-table", import("./components/jobsheet/tables/OldParts.vue"));
app.component("collapse-transition", CollapseTransition);
app.directive("click-outside", {
	bind: function(el, binding, vNode) {
		if (typeof binding.value !== "function") {
			const compName = vNode.context.name;
			let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
			if (compName) {
				warn += `Found in component '${compName}'`;
			}

			console.warn(warn);
		}
		const bubble = binding.modifiers.bubble;
		const handler = (e) => {
			if (bubble || (!el.contains(e.target) && el !== e.target)) {
				binding.value(e);
			}
		};
		el.__vueClickOutside__ = handler;
		document.addEventListener("click", handler);
	},

	unbind: function(el, binding) {
		document.removeEventListener("click", el.__vueClickOutside__);
		el.__vueClickOutside__ = null;
	},
});
app.mixin({
    methods: {
        ConvertDate(val) {
            return moment(val).format("DD-MM-YYYY HH:mm");
        },
        ConvertPrice(val) {
            let new1;
			if (isNaN(val) || val === 0 || val === null) {
				val = 0;
				new1 = val.toLocaleString("en-GB", { style: "currency", currency: "GBP" });
				if (!new1.includes("£")) {
					new1 = "£" + new1;
				}
				return new1;
			} else {
				new1 = parseFloat(val).toLocaleString("en-GB", {style: "currency", currency: "GBP"});
				if (!new1.includes("£")) {
					new1 = "£" + new1;
				}
				return new1;
			}
        }
    }
})
app.mount("#app");
