<template>
<span>
    <a-dropdown :trigger="['click']" v-model:visible="visible">
        <span class="a-dropdown-link" @click="e => e.preventDefault()">
            <FilterOutlined class="ml-2" type="filter" theme="twoTone"/>
        </span>
        <template #overlay>
            <a-menu>
                <a-menu-item key="0">
                    <a-button @click='CheckAll()' size="small">Check All</a-button>
                    <a-button @click='UncheckAll()' size="small">Uncheck All</a-button>
                </a-menu-item>
                <a-menu-item key="1">
                    <a-button @click='Reload()' size="small" block>Reload</a-button>
                </a-menu-item>
                <a-menu-item key="2"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_payment">Awaiting Payment</a-checkbox></a-menu-item>
                <a-menu-item key="3"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_vehicle">Awaiting Vehicle</a-checkbox></a-menu-item>
                <a-menu-item key="4"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_technician">Awaiting Technician</a-checkbox></a-menu-item>
                <a-menu-item key="5"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_road_test">Awaiting Road Test</a-checkbox></a-menu-item>
                <a-menu-item key="6"><a-checkbox class="m-0" v-model:checked="checkboxes.vehicle_not_started">Vehicle Present Not Started</a-checkbox></a-menu-item>
                <a-menu-item key="7"><a-checkbox class="m-0" v-model:checked="checkboxes.road_test">Road Test</a-checkbox></a-menu-item>
                <a-menu-item key="8"><a-checkbox class="m-0" v-model:checked="checkboxes.final_road_test_complete">Final Road Test Complete</a-checkbox></a-menu-item>
                <a-menu-item key="9"><a-checkbox class="m-0" v-model:checked="checkboxes.started_workshop">Started - Workshop</a-checkbox></a-menu-item>
                <a-menu-item key="10"><a-checkbox class="m-0" v-model:checked="checkboxes.started_bodyshop">Started - Bodyshop</a-checkbox></a-menu-item>
                <a-menu-item key="11"><a-checkbox class="m-0" v-model:checked="checkboxes.started_tuning">Started - Tuning</a-checkbox></a-menu-item>
                <a-menu-item key="12"><a-checkbox class="m-0" v-model:checked="checkboxes.started_fabrication">Started - Fabrication</a-checkbox></a-menu-item>
                <a-menu-item key="13"><a-checkbox class="m-0" v-model:checked="checkboxes.final_checks">Final Checks</a-checkbox></a-menu-item>
                <a-menu-item key="14"><a-checkbox class="m-0" v-model:checked="checkboxes.complete">Complete</a-checkbox></a-menu-item>
                <a-menu-item key="15"><a-checkbox class="m-0" v-model:checked="checkboxes.cancelled">Cancelled</a-checkbox></a-menu-item>
                <a-menu-item key="16"><a-checkbox class="m-0" v-model:checked="checkboxes.customer_notified">Customer Notified</a-checkbox></a-menu-item>
                <a-menu-item key="17"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_collection">Awaiting Collection</a-checkbox></a-menu-item>
                <a-menu-item key="18"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_invoice">Awaiting Invoice</a-checkbox></a-menu-item>
                <a-menu-item key="19"><a-checkbox class="m-0" v-model:checked="checkboxes.collected_paid">Collected / Paid</a-checkbox></a-menu-item>
                <a-menu-item key="20"><a-checkbox class="m-0" v-model:checked="checkboxes.rebooking">Rebooking</a-checkbox></a-menu-item>
                <a-menu-item key="21"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_parts">Awaiting Parts</a-checkbox></a-menu-item>
                <a-menu-item key="22"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_authority">Awaiting Authority</a-checkbox></a-menu-item>
                <a-menu-item key="23"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_authority">Awaiting Authority - Insurance</a-checkbox></a-menu-item>
                <a-menu-item key="23"><a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_vehicle_file_read">File Read - Awaiting Vehicle</a-checkbox></a-menu-item>
                <a-menu-item key="23"><a-checkbox class="m-0" v-model:checked="checkboxes.paid_awaiting_collection">Paid - Awaiting Collection</a-checkbox></a-menu-item>
            </a-menu>
        </template>
    </a-dropdown>
</span>
</template>

<script>
import {
    FilterOutlined
} from '@ant-design/icons-vue';
export default {
    components: {
        FilterOutlined
    },
    data() {
        return {
            visible: false,
            checkboxes: {
                awaiting_payment: true,
                awaiting_vehicle: true,
                awaiting_vehicle_file_read: true,
                awaiting_technician: true,
                awaiting_road_test: true,
                bodyshop: true,
                vehicle_not_started: true,
                road_test: true,
                final_road_test_complete: true,
                started_workshop: true,
                started_tuning: true,
                started_fabrication: true,
                started_bodyshop: true,
                final_checks: true,
                complete: true,
                cancelled: false,
                customer_notified: true,
                awaiting_collection: true,
                awaiting_invoice: true,
                collected_paid: false,
                rebooking: false,
                awaiting_parts: true,
                awaiting_authority: true,
                awaiting_authority_insurance: true,
                paid_awaiting_collection: true,
            },
        }
    },
    emits: ['changed'],
    methods: {
        CheckAll() {
            this.checkboxes.awaiting_payment = true;
            this.checkboxes.awaiting_vehicle = true;
            this.checkboxes.awaiting_vehicle_file_read = true;
            this.checkboxes.awaiting_technician = true;
            this.checkboxes.awaiting_road_test = true;
            this.checkboxes.vehicle_not_started = true;
            this.checkboxes.road_test = true;
            this.checkboxes.started_workshop = true;
            this.checkboxes.started_tuning = true;
            this.checkboxes.started_fabrication = true;
            this.checkboxes.started_bodyshop = true;
            this.checkboxes.awaiting_invoice = true;
            this.checkboxes.final_checks = true;
            this.checkboxes.customer_notified = true;
            this.checkboxes.awaiting_collection = true;
            this.checkboxes.final_road_test_complete = true;
            this.checkboxes.complete = true;
            this.checkboxes.cancelled = true;
            this.checkboxes.collected_paid = true;
            this.checkboxes.rebooking = true;
            this.checkboxes.awaiting_parts = true;
            this.checkboxes.awaiting_authority = true;
            this.checkboxes.awaiting_authority_insurance = true;
            this.checkboxes.paid_awaiting_collection = true;
        },
        UncheckAll() {
            this.checkboxes.awaiting_payment = false;
            this.checkboxes.awaiting_vehicle = false;
            this.checkboxes.awaiting_vehicle_file_read = false;
            this.checkboxes.awaiting_technician = false;
            this.checkboxes.awaiting_road_test = false;
            this.checkboxes.vehicle_not_started = false;
            this.checkboxes.road_test = false;
            this.checkboxes.started_workshop = false;
            this.checkboxes.started_tuning = false;
            this.checkboxes.started_fabrication = false;
            this.checkboxes.started_bodyshop = false;
            this.checkboxes.awaiting_invoice = false;
            this.checkboxes.final_checks = false;
            this.checkboxes.customer_notified = false;
            this.checkboxes.awaiting_collection = false;
            this.checkboxes.final_road_test_complete = false;
            this.checkboxes.complete = false;
            this.checkboxes.cancelled = false;
            this.checkboxes.collected_paid = false;
            this.checkboxes.rebooking = false;
            this.checkboxes.awaiting_parts = false;
            this.checkboxes.awaiting_authority = false;
            this.checkboxes.awaiting_authority_insurance = false;
            this.checkboxes.paid_awaiting_collection = false;
        },
        Reload() {
            var statusList = [];
            if (this.checkboxes.awaiting_payment == true) {
                statusList.push('Awaiting Payment')
            }
            if (this.checkboxes.awaiting_vehicle == true) {
                statusList.push('Awaiting Vehicle')
            }
            if (this.checkboxes.awaiting_vehicle_file_read == true) {
                statusList.push('File Read - Awaiting Vehicle')
            }
            if (this.checkboxes.bodyshop == true) {
                statusList.push('Bodyshop')
            }
            if (this.checkboxes.awaiting_technician == true) {
                statusList.push('Awaiting Technician')
            }
            if (this.checkboxes.awaiting_road_test == true) {
                statusList.push('Awaiting Road Test')
            }
            if (this.checkboxes.vehicle_not_started == true) {
                statusList.push('Vehicle Present Not Started')
            }
            if (this.checkboxes.road_test == true) {
                statusList.push('Road Test')
            }
            if (this.checkboxes.started_workshop == true) {
                statusList.push('Started - Workshop')
            }
            if (this.checkboxes.started_tuning == true) {
                statusList.push('Started - Tuning')
            }
            if (this.checkboxes.started_fabrication == true) {
                statusList.push('Started - Fabrication')
            }
            if (this.checkboxes.started_bodyshop == true) {
                statusList.push('Started - Bodyshop')
            }
            if (this.checkboxes.awaiting_invoice == true) {
                statusList.push('Awaiting Invoice')
            }
            if (this.checkboxes.final_checks == true) {
                statusList.push('Final Checks')
            }
            if (this.checkboxes.customer_notified == true) {
                statusList.push('Customer Notified')
            }
            if (this.checkboxes.awaiting_collection == true) {
                statusList.push('Awaiting Collection')
            }
            if (this.checkboxes.final_road_test_complete == true) {
                statusList.push('Final Road Test Complete')
            }
            if (this.checkboxes.complete == true) {
                statusList.push('Complete')
            }
            if (this.checkboxes.cancelled == true) {
                statusList.push('Cancelled')
            }
            if (this.checkboxes.collected_paid == true) {
                statusList.push('Collected / Paid')
            }
            if (this.checkboxes.rebooking == true) {
                statusList.push('Rebooking')
            }
            if (this.checkboxes.awaiting_parts == true) {
                statusList.push('Awaiting Parts')
            }
            if (this.checkboxes.awaiting_authority == true) {
                statusList.push('Awaiting Authority')
            }
            if (this.checkboxes.awaiting_authority_insurance == true) {
                statusList.push('Awaiting Authority - Insurance')
            }
            if (this.checkboxes.paid_awaiting_collection == true) {
                statusList.push('Paid - Awaiting Collection')
            }
            this.visible = false;
            setTimeout(() => {
                this.$emit('changed', statusList)
            }, 500);
        }
    }
}
</script>

<style>
.ant-dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}
</style>
