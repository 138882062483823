<template>
    <!--<div class="maincard audit customer" >
        <div class="maincard-header">Audit
            <a-button size="small" type="primary" class="ml-auto refresh" @click="refreshAuditLog($event)" :loading="refreshingAudit">Refresh</a-button>
        </div>
        <div class="h-full">
            <div class="overflow-scroll">
                <div class="px-3" v-if="!audit">
                    <div class="mb-2" v-for="i in 10" :key="i">
                        <skeleton type="text" height="20px"/>
                        <skeleton height="8px" width="60%" />
                        <hr>
                    </div>
                </div>

                <div v-else class="overflow-auto" >
                    <div v-for="log in audit" v-bind:key="log.id" class="px-4 py-3 border-b border-solid border-grey-400">
                        <h6 class="font-bold" style="font-size:1rem">{{log.reason}}</h6>
                        <ul>
                            <li><b>{{log.user}}</b> - {{ConvertDate(log.date)}}</li>
                            <li><b>Price:</b> £{{log.price}} <span v-if="log.price_ex_vat">(£{{log.price_ex_vat}} ex vat)</span></li>
                            <li v-if="log.location"><b>Location:</b> {{log.location}}</li>
                            <li v-if="log.old_price"><b>Old Cost:</b> {{ConvertPrice(log.old_price)}}</li>
                            <li v-if="log.old_labour"><b>Old Labour:</b> {{log.old_labour}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div v-if="job" class="overflow-auto">
        <div v-for="log in audit" v-bind:key="log.id" class="px-4 py-3 border-b border-solid border-grey-400">
            <h6 class="font-bold" style="font-size:1rem">{{log.reason}}</h6>
            <ul>
                <li><b>{{log.user}}</b> - {{ConvertDate(log.date)}}</li>
                <li><b>Price:</b> £{{log.price}} <span v-if="log.price_ex_vat">(£{{log.price_ex_vat}} ex vat)</span></li>
                <li v-if="log.location"><b>Location:</b> {{log.location}}</li>
                <li v-if="log.old_price"><b>Old Cost:</b> {{ConvertPrice(log.old_price)}}</li>
                <li v-if="log.old_labour"><b>Old Labour:</b> {{log.old_labour}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['job'],
    data(){
        return {
            audit: null,
            refreshingAudit: false
        }
    },
    mounted(){
        if(this.job) {
            this.getAuditLog();
        }
    },
    methods : {
        getAuditLog(){
            var id = this.job.id;
            axios.get(api_url + '/v2/jobs/'+id+'/audit').then((resp) => {
                this.audit = resp.data;
                setTimeout(() => {
                    this.getAuditLog();
                }, 300000);
            }).catch((err) => {
                this.$message.error(err);
            })
        },
        refreshAuditLog(e){
            this.refreshingAudit = true;
            var id = this.job.id;
            axios.get(api_url + '/v2/jobs/'+id+'/audit').then((resp) => {
                this.audit = resp.data;
                this.refreshingAudit = false;
                this.$message.success("Audit Log Refreshed");
            }).catch((err) => {
                this.$message.error(err);
                this.refreshingAudit = false;
            })
        },
        updateAuditLog(){
            const id = this.job.id;
            axios.get(api_url + '/v2/jobs/'+id+'/audit').then((resp) => {
                this.audit = resp.data;
            }).catch((err) => {
                this.$message.error(err);
            })
        },
    },
    watch: {
        job(val) {
            if(val) {
                this.getAuditLog();
            }
        }
    }
}
</script>

<style>
.refresh.btn {
    position: absolute;
    right: 20px;
}
</style>
