<template>
    <div class="bg-white rounded border border-gray-200 p-3 h-full" v-if="job">
        <h3 class="font-bold mb-3">CUSTOMER</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-3">
            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-user" class="mr-2 w-4"/><span class="opacity-60">Name:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.customer" class="mr-1">{{job.customer.firstname}}</span>
                    <span v-if="job.customer">{{job.customer.lastname}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-briefcase" class="mr-2 w-4"/><span class="opacity-60">Company:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.customer && job.customer.company !== null && job.customer.company !== 'null'">{{job.customer.company}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-map-pin" class="mr-2 w-4"/><span class="opacity-60">Address:</span></span>
                <div class="font-semibold ml-4" v-if="job.customer.address && job.customer.address.length > 0">
                    <div v-if="job.customer.address[0].address1">{{job.customer.address[0].address1}}</div>
                    <div v-if="job.customer.address[0].address2">{{job.customer.address[0].address2}}</div>
                    <div v-if="job.customer.address[0].address3 && job.customer.address[0].address3 !== job.customer.address[0].county">{{job.customer.address[0].address3}}</div>
                    <div v-if="job.customer.address[0].address4 && job.customer.address[0].address4 !== job.customer.address[0].county">{{job.customer.address[0].address4}}</div>
                    <div v-if="job.customer.address[0].county">{{job.customer.address[0].county}}</div>
                    <div v-if="job.customer.address[0].country">{{job.customer.address[0].country}}</div>
                    <div v-if="job.customer.address[0].postcode">{{job.customer.address[0].postcode}}</div>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-phone" class="mr-2 w-4"/><span class="opacity-60">Numbers:</span></span>
                <ul class="phoneList font-semibold ml-4" v-if="job.customer">
                    <li v-for="num in job.customer.phones" v-bind:key="num.id">
                        <span v-if="num.primary === 1" class="text-success">
                            <input v-model="num.phonenumber" @change="updateNum(num.id,$event)" v-if="!viewOnly" />
                            <span v-else>{{num.phonenumber}}</span>
                        </span>
                        <span v-else>
                            <input v-model="num.phonenumber" @change="updateNum(num.id, $event)" v-if="!viewOnly" />
                            <span v-else>{{num.phonenumber}}</span>
                        </span>
                    </li>
                </ul>

                <span class="block font-semibold mt-3"><fa-icon solid icon="fa-at" class="mr-2 w-4"/><span class="opacity-60">Emails:</span></span>
                <ul class="emailList font-semibold ml-4" v-if="job.customer">
                    <li v-for="email in job.customer.emails" v-bind:key="email.id">
                        <span v-if="email.primary === 1" class="text-success">
                            <input v-model="email.email" @change="updateEmail(email.id, $event)" v-if="!viewOnly" />
                            <span v-else>{{email.email}}</span>
                        </span>
                        <span v-else>
                            <input v-model="email.email" @change="updateEmail(email.id, $event)" v-if="!viewOnly" />
                            <span v-else>{{email.email}}</span>
                        </span>
                    </li>
                </ul>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-megaphone" class="mr-2 w-4"/><span class="opacity-60">Contact Method:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.customer && job.customer.customer_contact_pref">{{job.customer.customer_contact_pref.method}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-hotel" class="mr-2 w-4"/><span class="opacity-60">Activity:</span></span>
                <div class=" ml-4">
                    <a-select v-model:value="job.customer_activity" class="w-full" @change="updateActivity($event)" v-if="!viewOnly" :class="{'priority': job.customer_activity === 'Waiting in Reception' || job.customer_activity === 'Staying in Hotel'}">
                        <a-select-option v-for="act in job.contact_actions" v-bind:key="act.id" :value="act.activity">{{act.activity}}</a-select-option>
                    </a-select>
                    <span v-else>{{job.customer_activity}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold">
                    <fa-icon solid icon="fa-sterling-sign" class="mr-2 w-4"/>
                    <span class="opacity-60">Deposit Reference:</span>
                </span>
                <div class=" ml-4">
                    <span>{{job.depref}}</span>
                </div>
            </div>
        </div>

        <div class="maincard" v-if="1==0">
            <div class="maincard-header">Customer</div>
            <div class="p-2">
                <div class="row" v-if="job">
                    <div class="col-md-12">
                        <table class="mb-0 table table-borderless">
                            <tbody>
                                <tr>
                                    <td class="font-bold">Name:</td>
                                    <td>
                                        <span v-if="job.customer" class="mr-1">{{job.customer.firstname}}</span>
                                        <span v-if="job.customer">{{job.customer.lastname}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Company:</td>
                                    <td>
                                        <span v-if="job.customer && job.customer.company !== null && job.customer.company !== 'null'">{{job.customer.company}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Address:</td>
                                    <td>
                                        <div v-if="job.customer.address && job.customer.address.length > 0">
                                            <div v-if="job.customer.address[0].address1">{{job.customer.address[0].address1}}</div>
                                            <div v-if="job.customer.address[0].address2">{{job.customer.address[0].address2}}</div>
                                            <div v-if="job.customer.address[0].address3 && job.customer.address[0].address3 !== job.customer.address[0].county">{{job.customer.address[0].address3}}</div>
                                            <div v-if="job.customer.address[0].address4 && job.customer.address[0].address4 !== job.customer.address[0].county">{{job.customer.address[0].address4}}</div>
                                            <div v-if="job.customer.address[0].county">{{job.customer.address[0].county}}</div>
                                            <div v-if="job.customer.address[0].country">{{job.customer.address[0].country}}</div>
                                            <div v-if="job.customer.address[0].postcode">{{job.customer.address[0].postcode}}</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Number(s):</td>
                                    <td>
                                        <ul class="phoneList" v-if="job.customer">
                                            <li v-for="num in job.customer.phones" v-bind:key="num.id">
                                                <span v-if="num.primary === 1" class="text-success">
                                                    <input v-model="num.phonenumber" @change="updateNum(num.id,$event)" v-if="!viewOnly" />
                                                    <span v-else>{{num.phonenumber}}</span>
                                                </span>
                                                <span v-else>
                                                    <input v-model="num.phonenumber" @change="updateNum(num.id, $event)" v-if="!viewOnly" />
                                                    <span v-else>{{num.phonenumber}}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Email(s):</td>
                                    <td>
                                        <ul class="emailList" v-if="job.customer">
                                            <li v-for="email in job.customer.emails" v-bind:key="email.id">
                                                <span v-if="email.primary === 1" class="text-success">
                                                    <input v-model="email.email" @change="updateEmail(email.id, $event)" v-if="!viewOnly" />
                                                    <span v-else>{{email.email}}</span>
                                                </span>
                                                <span v-else>
                                                    <input v-model="email.email" @change="updateEmail(email.id, $event)" v-if="!viewOnly" />
                                                    <span v-else>{{email.email}}</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Contact Method:</td>
                                    <td>
                                        <span v-if="job.customer && job.customer.customer_contact_pref">{{job.customer.customer_contact_pref.method}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Booked in by:</td>
                                    <td>{{job.booked_in_by}}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Picked by:</td>
                                    <td>{{job.picked_by}}</td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Activity:</td>
                                    <td>
                                        <a-select v-model:value="job.customer_activity" class="w-full" @change="updateActivity($event)" v-if="!viewOnly">
                                            <a-select-option v-for="act in job.contact_actions" v-bind:key="act.id" :value="act.activity">{{act.activity}}</a-select-option>
                                        </a-select>
                                        <span v-else>{{job.customer_activity}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-bold">Work Type:</td>
                                    <td>
                                        <div class="flex gap-2">
                                            <span v-if="job.workshop === true" class="rounded px-2 py-1 text-xs font-bold w-auto bg-blue-400 text-white">Workshop</span>
                                            <span v-if="job.tuning === true" class="rounded px-2 py-1 text-xs font-bold w-auto bg-gray-500 text-white">Tuning</span>
                                            <span v-if="job.fabrication === true" class="rounded px-2 py-1 text-xs font-bold w-auto bg-gray-800 text-white">Fabrication</span>
                                            <span v-if="job.vvt === true" class="rounded px-2 py-1 text-xs font-bold w-auto bg-yellow-400">VVT</span>
                                            <span v-if="job.bodyshop === true" class="rounded px-2 py-1 text-xs font-bold w-auto bg-yellow-400">Bodyshop</span>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td class="font-bold">Calendar:</td>
                                    <td>
                                        <div v-for="(cal, index) in calendars" :key="index">
                                            <a :href="cal[0].googleEvent.htmlLink" target="_blank">{{index}}</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="mt-5 flex gap-2" v-if="!viewOnly">
                            <a-button type="primary" size="small" v-if="job.workshop === false" @click="addType('workshop')" :loading="addingWorkshop">Add Workshop</a-button>
                            <a-button type="primary" size="small" v-if="job.tuning === false" @click="addType('tuning')" :loading="addingTuning">Add Tuning</a-button>
                            <a-button type="primary" size="small" v-if="job.fabrication === false" @click="addType('fabrication')" :loading="addingFabrication">Add Fabrication</a-button>
                            <a-button type="primary" size="small" v-if="job.bodyshop === false" @click="addType('bodyshop')" :loading="addingBodyshop">Add Bodyshop</a-button>
                        </div>
                        <div class="mt-2 flex gap-2 flex-wrap" v-if="!viewOnly">
                            <a-button danger size="small" v-if="job.workshop === true" @click="removeType('workshop')" :loading="removingWorkshop">Remove Workshop</a-button>
                            <a-button danger size="small" v-if="job.tuning === true" @click="removeType('tuning')" :loading="removingTuning">Remove Tuning</a-button>
                            <a-button danger size="small" v-if="job.fabrication === true" @click="removeType('fabrication')" :loading="removingFabrication">Remove Fabrication</a-button>
                            <a-button danger size="small" v-if="job.bodyshop === true" @click="removeType('bodyshop')" :loading="removingBodyshop">Remove Bodyshop</a-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useAuthStore} from "@/store/authStore";
import FaIcon from "@/components/extras/Icon.vue";
export default {
    props: {
        job: {
            type: Object
        },
        viewOnly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FaIcon
    },
    data() {
        return {
            addingWorkshop: false,
            addingTuning: false,
            addingFabrication: false,
            addingBodyshop: false,
            removingWorkshop: false,
            removingTuning: false,
            removingFabrication: false,
            removingBodyshop: false,
            calendars: []
        }
    },
    mounted() {
        const intrvl = setInterval(() => {
            if (this.job) {
                clearInterval(intrvl)
                this.getCalendars()
            }
        }, 1000);
    },
    methods: {
        getCalendars() {
            axios.get(api_url + '/v2/jobs/' + this.job.id + '/calendars').then((resp) => {
                this.calendars = resp.data
            }).catch(err => console.log(err));
        },
        updateNum(id, e) {
            axios.post(api_url + '/v2/jobs/' + this.job.id + '/update/customer/number', {
                num_id: id,
                number: e.target.value,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Customer Number Updated')
                } else {
                    this.$message.error('Customer Number Failed')
                }
            }).catch(() => {
                this.$message.error('Customer Number Failed')
            })
        },
        updateEmail(id, e) {
            axios.post(api_url + '/v2/jobs/' + this.job.id + '/update/customer/email', {
                email_id: id,
                email: e.target.value
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Customer Email Updated')
                } else {
                    this.$message.error('Customer Email Failed')
                }
            }).catch(() => {
                this.$message.error('Customer Email Failed')
            })
        },
        updateActivity(value) {
            const id = this.job.id;
            axios.post(api_url + '/v2/jobs/' + id + '/update/customer_activity', {
                value: value
            }).then(resp => {
                if (resp.data.success) {
                    this.$message.success('Customer Activity Updated.');
                } else {
                    this.$message.error('Customer Activity Failed.');
                }
            }).catch(() => {
                this.$message.error('Customer Activity Failed.');
            });
        },
        addType(type) {
            this.toggleAddingLoading(type, true);
            const conf = confirm('Are you sure you want to add ' + type + ' to this job?');
            if (conf) {
                axios.post(api_url + '/v2/jobs/' + this.job.id + '/add/type', {
                    type: type
                }).then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Added work type, Reloading page...');
                        location.reload();
                    } else {
                        this.$message.error('Failed to add work type.');
                    }
                    this.toggleAddingLoading(type, false);
                }).catch(() => {
                    this.$message.error('Failed to add work type.');
                    this.toggleAddingLoading(type, false);
                });
            } else {
                this.toggleAddingLoading(type, false);
            }
        },
        removeType(type) {
            this.toggleLoading(type, true);
            const conf = confirm('Are you sure you want to remove ' + type + ' from this job?');
            if (conf) {
                axios.post(api_url + '/v2/jobs/' + this.job.id + '/remove/type', {
                    type: type
                }).then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Removed work type, Reloading page...');
                        location.reload();
                    } else {
                        this.$message.error('Failed to remove work type.');
                    }
                    this.toggleLoading(type, false);
                }).catch(() => {
                    this.$message.error('Failed to remove work type.');
                    this.toggleLoading(type, false);
                });
            } else {
                this.toggleLoading(type, false);
            }
        },
        toggleLoading(type, status) {
            if (type === "workshop") {
                this.removingWorkshop = status
            }
            if (type === "tuning") {
                this.removingTuning = status
            }
            if (type === "fabrication") {
                this.removingFabrication = status
            }
            if (type === "bodyshop") {
                this.removingBodyshop = status
            }
        },
        toggleAddingLoading(type, status) {
            if (type === "workshop") {
                this.addingWorkshop = status
            }
            if (type === "tuning") {
                this.addingTuning = status
            }
            if (type === "fabrication") {
                this.addingFabrication = status
            }
            if (type === "bodyshop") {
                this.addingBodyshop = status
            }
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
    }
}
</script>

<style>
.ant-select.priority .ant-select-selection-item {
    font-weight: bold;
    color: red;
}
</style>
