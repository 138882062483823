<template>
    <div class="pt-20 pb-4 container-fluid">
        <div class="card-container">
            <a-tabs type="line" class="overview-tabs" ref="overviewTabs" v-model:activeKey="currentTab" @change="tabChanged">
                <a-tab-pane key="customers">
                    <template #tab>Customers
                        <status-overview-dropdown v-show="currentTab === 'customers'" @changed="customerStatusesChanged"></status-overview-dropdown>
                    </template>

                    <div class="bg-white border border-gray-200 rounded w-full mb-2 pl-5 pr-5 relative">
                        <SearchOutlined class="absolute left-3 text-lg opacity-60" style="top:-1px"/>
                        <input class="w-full py-1 pr-2 focus:outline-none" placeholder="Search Customer Jobs..." @input="searchExternal" v-model="externalSearchInput"/>
                        <CloseCircleOutlined class="absolute right-3 text-lg opacity-60 cursor-pointer" @click="clearSearchExternal" style="top:-1px" v-if="externalSearchInput.length > 0"/>
                    </div>
                    <a-table :columns="headers" :data-source="jobDataFiltered" :rowKey="record => record.id" :rowClassName="record => rowColour(record)" :customRow="customRow" :pagination="pagination" :loading="overviewTableLoading" ref="extTable">

                        <template #customerActivity="{ text, record }">
                            <a-select v-model:value="record.customer_activity" size="small" :options="activityTypes" style="width:100%" @change="updateCustomerActivity($event, record.id)"></a-select>
                        </template>

                        <template #pickStatus="{ text }">
                            <div class="pick-status" :style="{backgroundColor: statusBackgroundColour(text)}">{{text}}</div>
                        </template>

                        <template #status="{ text, record }">
                            <a-select v-model:value="record.status" size="small" :options="statusTypes" style="width:100%" @change="updateStatus($event, record.id)"></a-select>
                        </template>

                        <template #owner="{ text, record }">
                            <a-select v-model:value="record.owner" size="small" :options="userList" style="width:100%" @change="updateOwner($event, record.id)"></a-select>
                        </template>

                        <template #tuningOwner="{ text, record }">
                            <a-select v-model:value="record.default_tuning_owner" size="small" :options="userList" style="width:100%" @change="updateTuner($event, record.id)"></a-select>
                        </template>

                        <template #task="{ text, record }">
                            <span v-if="text">
                                <CheckCircleOutlined type="check-circle" theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                            <span v-else class="cursor-pointer" @click="createTask(record.id)">
                                <CloseCircleOutlined theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                        </template>

                        <template #diary="{ text }">
                            <div v-if="text.ws" class='workshop rounded px-2 py-1 text-xxs font-bold w-full bg-blue-400 text-black text-center mb-1'>Workshop</div>
                            <div v-if="text.tun" class='tuning rounded px-2 py-1 text-xxs font-bold w-full bg-gray-500 text-white text-center mb-1'>Tuning</div>
                            <div v-if="text.fab" class='fabrication rounded px-2 py-1 text-xxs font-bold w-full bg-gray-800 text-white text-center mb-1'>Fabrication</div>
                            <div v-if="text.bodyshop" class='bodyshop rounded px-2 py-1 text-xxs font-bold w-full bg-yellow-400 text-black text-center mb-1'>Bodyshop</div>
                        </template>

                        <template #progress="{ text }">
                            <template v-for="(working, index) in text" :key="index">
                                <span class="block">{{working.user}} - {{working.time}}</span>
                            </template>
                        </template>
                    </a-table>
                </a-tab-pane>

                <a-tab-pane key="internal">
                    <template #tab>Internal
                        <status-overview-dropdown v-show="currentTab === 'internal'" @changed="internalStatusesChanged"></status-overview-dropdown>
                    </template>

                    <div class="bg-white border border-gray-200 w-full rounded mb-2 pl-5 pr-5 relative">
                        <SearchOutlined class="absolute left-3 text-lg opacity-60" style="top:-1px"/>
                        <input class="w-full py-1 pr-2 focus:outline-none" placeholder="Search Internal Jobs..." @input="searchInternal" v-model="internalSearchInput"/>
                        <CloseCircleOutlined class="absolute right-3 text-lg opacity-60 cursor-pointer" @click="clearSearchInternal" type="close-circle" style="top:-1px" v-if="internalSearchInput.length > 0"/>
                    </div>

                    <a-table :columns="dsdHeaders" :data-source="internalJobDataFiltered" :rowKey="record => record.id" :rowClassName="record => rowColour(record)" :customRow="customRow" :pagination="pagination" :loading="internalOverviewTableLoading" ref="intTable">
                        <template #pickStatus="{text}">
                            <div class="pick-status" :style="{backgroundColor: statusBackgroundColour(text)}">{{text}}</div>
                        </template>

                        <template #status="{record}">
                            <a-select v-model:value="record.status" size="small" :options="statusTypes" style="width:100%" @change="updateStatus($event, record.id)"></a-select>
                        </template>

                        <template #owner="{ text, record }">
                            <a-select v-model:value="record.owner" size="small" :options="userList" style="width:100%" @change="updateOwner($event, record.id)"></a-select>
                        </template>

                        <template #tuningOwner="{ text, record }">
                            <a-select v-model:value="record.default_tuning_owner" size="small" :options="userList" style="width:100%" @change="updateTuner($event, record.id)"></a-select>
                        </template>

                        <template #task="{text}">
                            <span v-if="text">
                                <CheckCircleOutlined theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                            <span v-else class="cursor-pointer" @click="createTask(record.id)">
                                <CloseCircleOutlined theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                        </template>

                        <template #diary="{text}">
                            <div v-if="text.ws" class='workshop rounded px-2 py-1 text-xxs font-bold w-full bg-blue-400 text-black text-center mb-1'>Workshop</div>
                            <div v-if="text.tun" class='tuning rounded px-2 py-1 text-xxs font-bold w-full bg-gray-500 text-white text-center mb-1'>Tuning</div>
                            <div v-if="text.fab" class='fabrication rounded px-2 py-1 text-xxs font-bold w-full bg-gray-800 text-white text-center mb-1'>Fabrication</div>
                            <div v-if="text.bodyshop" class='bodyshop rounded px-2 py-1 text-xxs font-bold w-full bg-yellow-400 text-black text-center mb-1'>Bodyshop</div>
                        </template>

                        <template #progress="{text}">
                            <template v-for="(working, index) in text" :key="index">
                                <span class="block">{{working.user}} - {{working.time}}</span>
                            </template>
                        </template>
                    </a-table>
                </a-tab-pane>

                <a-tab-pane key="bodyshop">
                    <template #tab>Bodyshop
                        <status-overview-dropdown v-show="currentTab === 'bodyshop'" @changed="bodyshopStatusesChanged"></status-overview-dropdown>
                    </template>

                    <div class="bg-white border border-gray-200 w-full rounded mb-2 pl-5 pr-5 relative">
                        <SearchOutlined class="absolute left-3 text-lg opacity-60" style="top:-1px"/>
                        <input class="w-full py-1 pr-2 focus:outline-none" placeholder="Search Bodyshop Jobs..." @input="searchBodyshop" v-model="bodyshopSearchInput"/>
                        <CloseCircleOutlined class="absolute right-3 text-lg opacity-60 cursor-pointer" @click="clearSearchBodyshop" style="top:-1px" v-if="bodyshopSearchInput.length > 0"/>
                    </div>
                    <a-table :columns="headers" :data-source="bodyshopJobDataFiltered" :rowKey="record => record.id" :rowClassName="record => rowColour(record)" :customRow="customRow" :pagination="pagination" :loading="bodyshopOverviewTableLoading" ref="extTable">

                        <template #customerActivity="{ text, record }">
                            <a-select v-model:value="record.customer_activity" size="small" :options="activityTypes" style="width:100%" @change="updateCustomerActivity($event, record.id)"></a-select>
                        </template>

                        <template #pickStatus="{ text }">
                            <div class="pick-status" :style="{backgroundColor: statusBackgroundColour(text)}">{{text}}</div>
                        </template>

                        <template #status="{ text, record }">
                            <a-select v-model:value="record.status" size="small" :options="statusTypes" style="width:100%" @change="updateStatus($event, record.id)"></a-select>
                        </template>

                        <template #owner="{ text, record }">
                            <a-select v-model:value="record.owner" size="small" :options="userList" style="width:100%" @change="updateOwner($event, record.id)"></a-select>
                        </template>

                        <template #tuningOwner="{ text, record }">
                            <a-select v-model:value="record.default_tuning_owner" size="small" :options="userList" style="width:100%" @change="updateTuner($event, record.id)"></a-select>
                        </template>

                        <template #task="{ text, record }">
                            <span v-if="text">
                                <CheckCircleOutlined type="check-circle" theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                            <span v-else class="cursor-pointer" @click="createTask(record.id)">
                                <CloseCircleOutlined theme="twoTone" :style="{ fontSize: '21px'}" /> </span>
                        </template>

                        <template #diary="{ text }">
                            <div v-if="text.ws" class='workshop rounded px-2 py-1 text-xxs font-bold w-full bg-blue-400 text-black text-center mb-1'>Workshop</div>
                            <div v-if="text.tun" class='tuning rounded px-2 py-1 text-xxs font-bold w-full bg-gray-500 text-white text-center mb-1'>Tuning</div>
                            <div v-if="text.fab" class='fabrication rounded px-2 py-1 text-xxs font-bold w-full bg-gray-800 text-white text-center mb-1'>Fabrication</div>
                            <div v-if="text.bodyshop" class='bodyshop rounded px-2 py-1 text-xxs font-bold w-full bg-yellow-400 text-black text-center mb-1'>Bodyshop</div>
                        </template>

                        <template #progress="{ text }">
                            <template v-for="(working, index) in text" :key="index">
                                <span class="block">{{working.user}} - {{working.time}}</span>
                            </template>
                        </template>
                    </a-table>
                </a-tab-pane>

                <a-tab-pane key="quotes">
                    <template #tab>Quotes</template>

                    <a-table :columns="quoteHeaders" :data-source="quotesJobDataFiltered" :rowKey="record => record.id" :rowClassName="record => rowColour(record)" :customRow="customRowQuote" :pagination="pagination" :loading="quotesOverviewTableLoading" ref="extTable">
                        <template #vehicleMakeModel="{ text, record }">
                            {{text}}
                        </template>

                        <template #vehicleReg="{ text, record }">
                            {{text}}
                        </template>

                        <template #partsTotal="{ text, record }">
                            {{ConvertPrice(text)}}
                        </template>

                        <template #labourTotal="{ text, record }">
                            {{ConvertPrice(text)}}
                        </template>

                        <template #action="{ text, record }">
                            <a-button size="small" danger @click="deleteQuote(record)">Delete</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>

                <a-tab-pane key="lateVehicles">
                    <template #tab>Delayed Vehicles</template>

                    <a-table :rowKey="record => record.id" :columns="delayedVehicleHeaders" :customRow="customRow" :dataSource="delayedVehicleData" :pagination="pagination" :loading="delayedVehiclesTableLoading" ref="extTable">
                        <template #action="{ text, record }">
                            <a-button size="medium" warning @click="viewDelayedInfo(record.id)">View Delayed Info</a-button>
                        </template>
                    </a-table>

                    <a-modal v-model:visible="delayedModalShow" width="80%" :footer="null">
                        <h1 class="text-center text-xl">Reported delays:</h1>
                        <br>
                        <a-table bordered :data-source="delayedModalData" :columns="delayedModalColumns" :rowKey="record => record.id" >
                        </a-table>
                    </a-modal>

                </a-tab-pane>
                <!-- Commented out as per request -->
                <!--<template #tabBarExtraContent>
                    <div class="ml-auto mt-2" id="noj">
                        <ul class="flex border border-solid border-gray-200 rounded overflow-y-hidden overflow-x-auto">
                            <li class="h-7 bg-white border-l border-solid border-gray-200 flex items-center text-xs px-2 whitespace-nowrap" v-for="usersNOJ in nonJobUsers" :key="usersNOJ" v-show="usersNOJ !== 'NA'">{{usersNOJ}}</li>
                        </ul>
                    </div>
                </template>-->
            </a-tabs>
        </div>
    </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {CheckCircleOutlined, CloseCircleOutlined, SearchOutlined} from '@ant-design/icons-vue';
import moment from 'moment';
import {useAuthStore} from "@/store/authStore";
import statusOverviewDropdown from "@/components/extras/StatusOverviewDropdown.vue";
import axios from 'axios';
import { ssrContextKey } from 'vue';
export default {
    name: 'Overview',
    props: ['startDSDTable'],
    data() {
        return {
            currentTab: "customers",
            snackbar: false,
            toastText: "",
            token: Cookies.get('token') || "",
            showDSDTable: this.startDSDTable,
            checkboxes: {
                awaiting_payment: true,
                awaiting_vehicle: true,
                awaiting_vehicle_file_read: true,
                awaiting_technician: true,
                awaiting_road_test: true,
                bodyshop: true,
                vehicle_not_started: true,
                road_test: true,
                final_road_test_complete: true,
                started_workshop: true,
                started_tuning: true,
                started_fabrication: true,
                started_bodyshop: true,
                final_checks: true,
                complete: true,
                cancelled: false,
                customer_notified: true,
                awaiting_collection: true,
                awaiting_invoice: true,
                collected_paid: false,
                rebooking: false,
                awaiting_parts: true,
                awaiting_authority: true,
                paid_awaiting_collection: true,
            },
            statusList: [],
            internalStatusList: [],
            users: [],
            jobsTable: null,
            dsdTable: null,
            usersNotOnJob: [],
            workingUsers: [],
            userList: [],
            statusTypes: [
                {
                    value: 'Awaiting Authority',
                    label: 'Awaiting Authority'
                },
                {
                    value: 'Awaiting Collection',
                    label: 'Awaiting Collection',
                },
                {
                    value: 'Awaiting Invoice',
                    label: 'Awaiting Invoice',
                },
                {
                    value: 'Awaiting Parts',
                    label: 'Awaiting Parts',
                },
                {
                    value: 'Awaiting Payment',
                    label: 'Awaiting Payment',
                },
                {
                    value: 'Awaiting Road Test',
                    label: 'Awaiting Road Test',
                },
                {
                    value: 'Awaiting Technician',
                    label: 'Awaiting Technician',
                },
                {
                    value: 'Awaiting Vehicle',
                    label: 'Awaiting Vehicle',
                },
                {
                    value: 'File Read - Awaiting Vehicle',
                    label: 'File Read - Awaiting Vehicle',
                },
                {
                    value: 'Cancelled',
                    label: 'Cancelled',
                },
                {
                    value: 'Collected / Paid',
                    label: 'Collected / Paid',
                },
                {
                    value: 'Complete',
                    label: 'Complete',
                },
                {
                    value: 'Customer Notified',
                    label: 'Customer Notified',
                },
                {
                    value: 'Final Checks',
                    label: 'Final Checks',
                },
                {
                    value: 'Final Road Test Complete',
                    label: 'Final Road Test Complete',
                },
                {
                    value: 'Rebooking',
                    label: 'Rebooking',
                },
                {
                    value: 'Road Test',
                    label: 'Road Test',
                },
                {
                    value: 'Started - Fabrication',
                    label: 'Started - Fabrication',
                },
                {
                    value: 'Started - Tuning',
                    label: 'Started - Tuning',
                },
                {
                    value: 'Started - Workshop',
                    label: 'Started - Workshop',
                },
                {
                    value: 'Started - Bodyshop',
                    label: 'Started - Bodyshop',
                },
                {
                    value: 'Vehicle Present Not Started',
                    label: 'Vehicle Present Not Started',
                }
            ],
            activityTypes: [
                {
                    value: 'Leaving - Collect same day',
                    label: 'Leaving - Collect same day',
                },
                {
                    value: 'Leaving - Collect when done',
                    label: 'Leaving - Collect when done',
                },
                {
                    value: 'Waiting in Reception',
                    label: 'Waiting in Reception'
                },
                {
                    value: 'Staying in Hotel',
                    label: 'Staying in Hotel',
                }
            ],
            externalSearchInput: "",
            internalSearchInput: "",
            bodyshopSearchInput: "",
            headers: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.name.length - b.name.length,
                },
                {
                    title: 'Reg',
                    dataIndex: 'reg',
                    align: 'center',
                    width: "100px",
                    sorter: (a, b) => a.reg.length - b.reg.length,
                },
                {
                    title: 'Vehicle',
                    dataIndex: 'vr_model',
                    align: 'center',
                    width: "180px",
                    sorter: (a, b) => a.vr_model.length - b.vr_model.length,
                },
                {
                    title: 'Due',
                    dataIndex: 'drop_off_time',
                    align: 'center',
                    width: "150px",
                    sorter: (a, b) => new Date(a.drop_off_time) - new Date(b.drop_off_time),
                    customRender: (val) => {
                        return moment(val.text).format('DD-MM-YYYY HH:mm')
                    }
                },
                {
                    title: 'Customer Activity',
                    dataIndex: 'customer_activity',
                    align: 'center',
                    width: "200px",
                    slots: {
                        customRender: 'customerActivity'
                    },
                    sorter: (a, b) => a.customer_activity.length - b.customer_activity.length,
                },
                {
                    title: 'Collection',
                    dataIndex: 'pick_up_time',
                    align: 'center',
                    width: "150px",
                    sorter: (a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time),
                    customRender: (val) => {
                        return moment(val.text).format('DD-MM-YYYY HH:mm')
                    }
                },
                {
                    title: 'Order Status',
                    dataIndex: 'order_status_name',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'pickStatus'
                    },
                    sorter: (a, b) => a.order_status_name.length - b.order_status_name.length,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'status'
                    },
                    sorter: (a, b) => a.status.length - b.status.length,
                },
                {
                    title: 'Diary',
                    dataIndex: 'diary',
                    align: 'center',
                    slots: {
                        customRender: 'diary'
                    },
                },
                {
                    title: 'Owner',
                    dataIndex: 'owner',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'owner'
                    },
                    sorter: (a, b) => a.owner - b.owner,
                },
                {
                    title: 'Tuning Owner',
                    dataIndex: 'default_tuning_owner',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'tuningOwner'
                    },
                    sorter: (a, b) => a.default_tuning_owner - b.default_tuning_owner,
                },
                {
                    title: 'Task',
                    dataIndex: 'has_task',
                    align: 'center',
                    slots: {
                        customRender: 'task'
                    },
                    sorter: (a, b) => a.has_task - b.has_task,
                },
                {
                    title: 'Labour',
                    dataIndex: 'labour_total',
                    align: 'center',
                    customRender: (val) => {
                        const formatter = new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        });
                        return formatter.format(val.text);
                    },
                    sorter: (a, b) => a.labour_total - b.labour_total,
                },
                {
                    title: 'Parts',
                    dataIndex: 'parts_total',
                    align: 'center',
                    customRender: (val) => {
                        const formatter = new Intl.NumberFormat('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        });
                        return formatter.format(val.text);
                    },
                    sorter: (a, b) => a.parts_total - b.parts_total,
                },
                {
                    title: 'In Progress',
                    dataIndex: 'working_on_job',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'progress'
                    },
                }
            ],
            dsdHeaders: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.name.length - b.name.length,
                },
                {
                    title: 'Reg',
                    dataIndex: 'reg',
                    align: 'center',
                    width: "100px",
                    sorter: (a, b) => a.reg.length - b.reg.length,
                },
                {
                    title: 'Vehicle',
                    dataIndex: 'vr_model',
                    align: 'center',
                    width: "180px",
                    sorter: (a, b) => a.vr_model.length - b.vr_model.length,
                },
                {
                    title: 'Due',
                    dataIndex: 'drop_off_time',
                    align: 'center',
                    width: "150px",
                    sorter: (a, b) => new Date(a.drop_off_time) - new Date(b.drop_off_time),
                },
                {
                    title: 'Order Status',
                    dataIndex: 'order_status_name',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'pickStatus'
                    },
                    sorter: (a, b) => a.order_status_name.length - b.order_status_name.length,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'status'
                    },
                    sorter: (a, b) => a.status.length - b.status.length,
                },
                {
                    title: 'Diary',
                    dataIndex: 'diary',
                    align: 'center',
                    slots: {
                        customRender: 'diary'
                    },
                },
                {
                    title: 'Owner',
                    dataIndex: 'owner',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'owner'
                    },
                    sorter: (a, b) => a.owner - b.owner,
                },
                {
                    title: 'Tuning Owner',
                    dataIndex: 'default_tuning_owner',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'tuningOwner'
                    },
                    sorter: (a, b) => a.default_tuning_owner - b.default_tuning_owner,
                },
                {
                    title: 'Task',
                    dataIndex: 'has_task',
                    align: 'center',
                    slots: {
                        customRender: 'task'
                    },
                    sorter: (a, b) => a.has_task - b.has_task,
                },
                {
                    title: 'In Progress',
                    dataIndex: 'working_on_job',
                    align: 'center',
                    width: "150px",
                    slots: {
                        customRender: 'progress'
                    },
                }
            ],
            quoteHeaders: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: 'Name',
                    dataIndex: 'customer_name',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.name.length - b.name.length,
                },
                {
                    title: 'Reg',
                    dataIndex: 'customer_vehicle_reg',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'vehicleReg'
                    }
                },
                {
                    title: 'Make/Model',
                    dataIndex: 'customer_vehicle_make_model',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'vehicleMakeModel'
                    }
                },
                {
                    title: 'Parts Total',
                    dataIndex: 'parts_total',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'partsTotal'
                    }
                },
                {
                    title: 'Labour Total',
                    dataIndex: 'labour_total',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'labourTotal'
                    }
                },
                {
                    title: '',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'action'
                    }
                },
            ],
            delayedVehicleHeaders: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: 'Name',
                    dataIndex: 'customer_fullname',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.customer_fullname?.length - b.customer_fullname?.length,
                },
                {
                    title: 'Reg',
                    dataIndex: 'VRVrm',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.VRVrm?.length - b.VRVrm?.length,
                },
                {
                    title: 'Vehicle',
                    dataIndex: 'vehicle_model',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => a.vehicle_model?.length - b.vehicle_model?.length,
                },
                {
                    title: 'Due Date',
                    dataIndex: 'pick_up_time',
                    align: 'center',
                    width: "130px",
                    sorter: (a, b) => new Date(a.pick_up_time) - new Date(b.pick_up_time),
                    filters: [
                        {
                        text: 'Today',
                        value: 'today',
                        },
                        {
                        text: 'This Week',
                        value: 'this_week',
                        },
                        {
                        text: 'Past 2 Weeks',
                        value: 'past_2_weeks',
                        },
                        {
                        text: 'Past 3 Weeks',
                        value: 'past_3_weeks',
                        },
                        {
                        text: 'This Month',
                        value: 'this_month',
                        },
                        {
                        text: 'Past 2 Months',
                        value: 'past_2_months',
                        },
                        {
                        text: 'Past 3 Months',
                        value: 'past_3_months',
                        },
                    ],
                    onFilter: (value, record) => {
                        const currentDate = new Date();
                        const pickUpTime = new Date(record.pick_up_time);

                        switch (value) {
                        case 'today':
                            return (
                            pickUpTime.getFullYear() === currentDate.getFullYear() &&
                            pickUpTime.getMonth() === currentDate.getMonth() &&
                            pickUpTime.getDate() === currentDate.getDate()
                            );
                        case 'this_week':
                            const weekStart = new Date();
                            weekStart.setDate(weekStart.getDate() - weekStart.getDay());
                            weekStart.setHours(0, 0, 0, 0);

                            const weekEnd = new Date(weekStart);
                            weekEnd.setDate(weekStart.getDate() + 6);

                            return pickUpTime >= weekStart && pickUpTime <= weekEnd;
                        case 'past_2_weeks':
                            const past2WeeksStart = new Date();
                            past2WeeksStart.setDate(past2WeeksStart.getDate() - 14);
                            past2WeeksStart.setHours(0, 0, 0, 0);

                            const past2WeeksEnd = new Date(past2WeeksStart);
                            past2WeeksEnd.setDate(past2WeeksStart.getDate() + 13);

                            return pickUpTime >= past2WeeksStart && pickUpTime <= past2WeeksEnd;
                        case 'past_3_weeks':
                            const past3WeeksStart = new Date();
                            past3WeeksStart.setDate(past3WeeksStart.getDate() - 21);
                            past3WeeksStart.setHours(0, 0, 0, 0);

                            const past3WeeksEnd = new Date(past3WeeksStart);
                            past3WeeksEnd.setDate(past3WeeksStart.getDate() + 20);

                            return pickUpTime >= past3WeeksStart && pickUpTime <= past3WeeksEnd;
                        case 'this_month':
                            return (
                            pickUpTime.getFullYear() === currentDate.getFullYear() &&
                            pickUpTime.getMonth() === currentDate.getMonth()
                            );
                        case 'past_2_months':
                            const past2MonthsStart = new Date();
                            past2MonthsStart.setMonth(past2MonthsStart.getMonth() - 2);
                            past2MonthsStart.setDate(1);
                            past2MonthsStart.setHours(0, 0, 0, 0);

                            const past2MonthsEnd = new Date(past2MonthsStart);
                            past2MonthsEnd.setMonth(past2MonthsEnd.getMonth() + 1);
                            past2MonthsEnd.setDate(0);

                            return pickUpTime >= past2MonthsStart && pickUpTime <= past2MonthsEnd;
                        case 'past_3_months':
                            const past3MonthsStart = new Date();
                            past3MonthsStart.setMonth(past3MonthsStart.getMonth() - 3);
                            past3MonthsStart.setDate(1);
                            past3MonthsStart.setHours(0, 0, 0, 0);

                            const past3MonthsEnd = new Date(past3MonthsStart);
                            past3MonthsEnd.setMonth(past3MonthsEnd.getMonth() + 2);
                            past3MonthsEnd.setDate(0);

                            return pickUpTime >= past3MonthsStart && pickUpTime <= past3MonthsEnd;
                        default:
                            return true;
                        }
                    },
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    align: 'center',
                    width: "180px",
                    sorter: (a, b) => a.status.length - b.status.length,
                },
                {
                    title: 'Booked in by',
                    dataIndex: 'booked_in_by',
                    align: 'center',
                    width: "180px",
                    sorter: (a, b) => a.booked_in_by?.length - b.booked_in_by?.length,
                },
                {
                    title: '',
                    align: 'center',
                    width: "180px",
                    slots: {
                        customRender: 'action'
                    }
                },
            ],
            delayedModalColumns: [
                {
                    title: 'Reason',
                    dataIndex: 'reason',
                    align: 'center',
                    width: "180px",
                },
                {
                    title: 'Customer Informed',
                    dataIndex: 'customer_informed',
                    align: 'center',
                    width: "180px",
                },
                {
                    title: 'Added by',
                    dataIndex: 'added_by_name',
                    align: 'center',
                    width: "180px",
                },
                {
                    title: 'Created on',
                    dataIndex: 'created_at',
                    align: 'center',
                    width: "180px",
                },
            ],
            jobData: [],
            jobDataFiltered: [],
            internalJobData: [],
            internalJobDataFiltered: [],
            bodyshopJobData: [],
            bodyshopJobDataFiltered: [],
            quotesJobData: [],
            quotesJobDataFiltered: [],
            delayedVehicleData: [],
            overviewTableLoading: false,
            internalOverviewTableLoading: false,
            bodyshopOverviewTableLoading: false,
            quotesOverviewTableLoading: false,
            delayedVehiclesTableLoading: false,
            flatPickrConf: {
                position: 'auto',
                enableTime: true,
                time_24hr: true,
                altFormat: 'd-m-Y H:i:S',
                altInput: true,
                dateFormat: 'Y-m-d H:i:S',
            },
            pagination: {
                defaultPageSize: 30,
                disabled: false
            },
            delayedModalData: [],
            delayedModalShow: false
        }
    },
    components: {
        flatPickr,
        CloseCircleOutlined,
        CheckCircleOutlined,
        SearchOutlined,
        statusOverviewDropdown
    },
    mounted() {
        let hash = location.hash;
        if(hash) {
            hash = hash.replace('#', '');
            this.currentTab = hash;
        }
        if (this.user) {
            this.getStaff();
        }
    },
    computed: {
        nonJobUsers() {
            const _users = this.users.filter(x => x.department === 1 || x.department === 2 || x.department === 4);
            const _names = [];
            _users.forEach(element => {
                _names.push(element.nicename);
            });
            return _names.filter((el) => !this.workingUsers.includes(el));
        },
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
        overviewSearch() {
            if (this.search && this.search.length > 3) {
                return this.search;
            }
        },
    },
    methods: {
        viewDelayedInfo(id){
            axios.get(api_url + '/v2/jobs/delay/get/' + id).then((resp) => {
                this.delayedModalData = resp.data;
                this.delayedModalShow = true;
            });
        },
        getStaff() {
            if (this.users.length === 0) {
                try {
                    axios.get(api_url + '/v2/staff').then(resp => {
                        this.users = resp.data;
                        this.users.forEach(element => {
                            this.userList.push({
                                label: element.nicename,
                                value: element.id
                            });
                        });
                        this.getJobs();
                        this.getInternalJobs();
                        this.getBodyshopJobs();
                        this.getQuotedJobs();
                        this.getdelayedVehicleData();
                    }).catch(() => {

                    });
                } catch (error) {
                    console.log(error)
                }
            }
        },
        getJobs(statuslist) {
            this.overviewTableLoading = true;
            axios.post(api_url + '/v2/jobs/overview/external', {
                status: statuslist
            }).then(resp => {
                if (resp) {
                    this.jobData = resp.data.data;
                    this.jobDataFiltered = resp.data.data;
                    this.overviewTableLoading = false;
                    const onTask = resp.data.meta.extra.on_task;
                    onTask.forEach(time => {
                        const staff = JSON.parse(localStorage.getItem('staff'));
                        if(staff) {
                            const usernme = staff.find(el => el.id === time);
                            if (usernme) {
                                this.workingUsers.push(usernme.nicename);
                            }
                        }
                    });
                } else {
                    this.$message.error('Something went wrong grabbing customer jobs.');
                }
            });
        },
        getInternalJobs(statuslist) {
            this.internalOverviewTableLoading = true;
            axios.post(api_url + '/v2/jobs/overview/internal', {
                status: statuslist
            }).then(resp => {
                if (resp) {
                    this.internalJobData = resp.data.data;
                    this.internalJobDataFiltered = resp.data.data;
                    this.internalOverviewTableLoading = false;
                } else {
                    this.$message.error('Something went wrong grabbing internal jobs.');
                }
            });
        },
        getBodyshopJobs(statuslist) {
            this.overviewTableLoading = true;
            axios.post(api_url + '/v2/jobs/overview/bodyshop', {
                status: statuslist
            }).then(resp => {
                if (resp) {
                    this.bodyshopJobData = resp.data.data;
                    this.bodyshopJobDataFiltered = resp.data.data;
                    this.bodyshopOverviewTableLoading = false;
                } else {
                    this.$message.error('Something went wrong grabbing bodyshop jobs.');
                }
            });
        },
        getQuotedJobs(statuslist) {
            this.overviewTableLoading = true;
            axios.post(api_url + '/v2/jobs/overview/quotes').then(resp => {
                if (resp) {
                    this.quotesJobData = resp.data.data;
                    this.quotesJobDataFiltered = resp.data.data;
                    this.quotesOverviewTableLoading = false;
                } else {
                    this.$message.error('Something went wrong grabbing quoted jobs.');
                }
            });
        },
        getdelayedVehicleData(){
            this.delayedVehiclesTableLoading = true;
            axios.post(api_url + '/v2/jobs/overview/delayedVehicles').then(resp => {
                if (resp) {
                    this.delayedVehicleData = resp.data;
                    this.delayedVehiclesTableLoading = false;
                } else {
                    this.$message.error('Something went wrong grabbing delayed vehicle jobs.');
                }
            });
        },
        searchExternal(e) {
            let i;
            const val = e.target.value.toLowerCase();
            const ds = this.jobData;
            const dsf = this.jobData;
            const newArr = [];
            for (i = 0; i < ds.length; i++) {
                const id = dsf[i].id || "";
                const name = dsf[i].name || "";
                const reg = dsf[i].reg || "";
                const veh = dsf[i].vr_model || "";
                dsf[i].show = name.toLowerCase().includes(val) || reg.toLowerCase().includes(val) || veh.toLowerCase().includes(val) || id.toString().includes(val);
            }

            for (i = 0; i < dsf.length; i++) {
                if(dsf[i].show === true) {
                    newArr.push(dsf[i]);
                }
            }
            this.jobDataFiltered = newArr
        },
        searchInternal(e) {
            let i;
            const val = e.target.value.toLowerCase();
            const ds = this.internalJobData;
            const dsf = this.internalJobData;
            const newArr = [];
            for (i = 0; i < ds.length; i++) {
                const id = dsf[i].id || "";
                const name = dsf[i].name || "";
                const reg = dsf[i].reg || "";
                const veh = dsf[i].vr_model || "";
                dsf[i].show = name.toLowerCase().includes(val) || reg.toLowerCase().includes(val) || veh.toLowerCase().includes(val) || id.toString().includes(val);
            }

            for (i = 0; i < dsf.length; i++) {
                if(dsf[i].show === true) {
                    newArr.push(dsf[i]);
                }
            }
            this.internalJobDataFiltered = newArr
        },
        searchBodyshop(e) {
            let i;
            const val = e.target.value.toLowerCase();
            const ds = this.bodyshopJobData;
            const dsf = this.bodyshopJobData;
            const newArr = [];
            for (i = 0; i < ds.length; i++) {
                const id = dsf[i].id || "";
                const name = dsf[i].name || "";
                const reg = dsf[i].reg || "";
                const veh = dsf[i].vr_model || "";
                dsf[i].show = name.toLowerCase().includes(val) || reg.toLowerCase().includes(val) || veh.toLowerCase().includes(val) || id.toString().includes(val);
            }

            for (i = 0; i < dsf.length; i++) {
                if(dsf[i].show === true) {
                    newArr.push(dsf[i]);
                }
            }
            this.bodyshopJobDataFiltered = newArr
        },
        clearSearchExternal() {
            this.externalSearchInput = "";
            this.jobDataFiltered = this.jobData;
        },
        clearSearchInternal() {
            this.internalSearchInput = "";
            this.internalJobDataFiltered = this.internalJobData;
        },
        clearSearchBodyshop() {
            this.bodyshopSearchInput = "";
            this.bodyshopJobDataFiltered = this.bodyshopJobData;
        },
        customRow(record) {
            return {
                onClick: () => {
                    console.log(record)
                },
                onDblclick: () => {
                    this.openJobsheet(record.id)
                },
            };
        },
        customRowQuote(record) {
            return {
                onClick: () => {
                },
                onDblclick: () => {
                    this.openQuote(record.id)
                },
            };
        },
        rowColour(record) {
            let cssClass = this.rowBackgroundColour(record.status);
            if(record.is_insurance === 1 || record.is_insurance === true) {
                cssClass = cssClass + " insurance-job";
            }

            if(record.is_warranty_work === 1 || record.is_warranty_work === true) {
                cssClass = cssClass + " warranty-job";
            }
            return cssClass;
        },
        customerStatusesChanged(values) {
            this.getJobs(values)
        },
        internalStatusesChanged(values) {
            this.getInternalJobs(values)
        },
        bodyshopStatusesChanged(values) {
            this.getBodyshopJobs(values)
        },
        rowBackgroundColour(cellData) {
            let color = "";
            let text = "";
            if (cellData === "Awaiting Vehicle") {
                color = "#00a909";
                text = "#000"
                return "awaiting-payment-vehicle";
            }
            if (cellData === "Awaiting Payment") {
                color = "#00a909";
                text = "#000"
                return "awaiting-payment-vehicle";
            }

            if (cellData === "File Read - Awaiting Vehicle") {
                color = "#ED1C24";
                text = "#fff"
                return "file-read"
            }

            if (cellData === "Awaiting Road Test") {
                color = "#a6ce55";
                text = "#000"
                return "awaiting-road-test";
            }

            if (cellData === "Awaiting Collection") {
                color = "#02ce93";
                text = "#000"
                return "awaiting-collection-invoice";
            }
            if (cellData === "Awaiting Invoice") {
                color = "#02ce93";
                text = "#000"
                return "awaiting-collection-invoice";
            }

            if (cellData === "Vehicle Present Not Started") {
                color = "#076604";
                text = "#fff"
                return "present-not-started"
            }
            if (cellData === "Road Test") {
                color = "#00919b";
                text = "#fff"
                return "road-test";
            }
            if (cellData === "Final Checks") {
                color = "#6ece11";
                text = "#000"
                return "final-checks"
            }
            if (cellData === "Customer Notified") {
                color = "#988cff";
                text = "#fff";
                return "customer-notified"
            }

            if (cellData === "Complete") {
                color = "#8b47f6";
                text = "#fff"
                return "complete-collected-rebooking";
            }
            if (cellData === "Collected / Paid") {
                color = "#8b47f6";
                text = "#fff"
                return "complete-collected-rebooking";
            }
            if (cellData === "Rebooking") {
                color = "#8b47f6";
                text = "#fff"
                return "complete-collected-rebooking";
            }

            if (cellData === "Cancelled") {
                color = "#000";
                text = "#fff"
                return "cancelled";
            }

            if (cellData === "Started - Tuning") {
                color = "#045f66";
                text = "#fff"
                return "started-awaiting";
            }
            if (cellData === "Started - Fabrication") {
                color = "#045f66";
                text = "#fff"
                return "started-awaiting";
            }
            if (cellData === "Started - Workshop") {
                color = "#045f66";
                text = "#fff"
                return "started-awaiting";
            }
            if (cellData === "Started - Bodyshop") {
                color = "#045f66";
                text = "#fff"
                return "started-awaiting";
            }
            if (cellData === 'Awaiting Technician') {
                color = "#045f66";
                text = "#fff"
                return "started-awaiting";
            }
            return color;
        },
        statusBackgroundColour(cellData) {
            let status = '';
            let colour;
            if (cellData === 2 || cellData === "Not Picked") {
                status = "Not Picked";
                colour = "#ff6666";
            }
            if (cellData === 0 || cellData === "Not Started") {
                status = "Not Started";
                colour = "#ff6666";
            }
            if (cellData === 5 || cellData === "Picked") {
                status = "Picked";
                colour = "#78ff66";
            }
            if (cellData === 6 || cellData === "Picked & Processed") {
                status = "Picked & Processed";
                colour = "#78ff66";
            }
            if (cellData === 7 || cellData === "Picked & Deleted") {
                status = "Picked & Deleted";
                colour = "#78ff66";
            }
            if (cellData === 3 || cellData === "Partially Picked/Ordered") {
                status = "Partially Picked/Ordered";
                colour = "#ffb266";
            }
            if (cellData === 4 || cellData === "Partially Picked") {
                status = "Partially Picked";
                colour = "#ffb266";
            }
            if (cellData === 1 || cellData === "Partially Ordered") {
                status = "Partially Ordered";
                colour = "#ffb266";
            }
            return colour;
        },
        createTask(id) {
            axios.post(api_url + '/v2/jobs/' + id + '/task/create').then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Task Created.');
                    location.reload();
                } else {
                    this.$message.error('Task Failed.');
                }
            }).catch(() => {
                this.$message.error('Task Failed.');
            });
        },
        updateCustomerActivity(e, id) {
            axios.post(api_url + '/v2/jobs/' + id + '/update/customer_activity', {
                id: id,
                value: e
            }).then(resp => {
                if(resp.data.success) {
                    this.$message.success('Customer Activity Updated.');
                } else {
                    this.$message.error('Customer Activity Failed.');
                }
            }).catch(() => {
                this.$message.error('Customer Activity Failed.');
            });
        },
        updateStatus(e, id) {
            axios.post(api_url + '/v2/jobs/' + id + '/update/status', {
                value: e
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Status Updated.');
                } else {
                    if(resp.data.message.status === "Job not processed on Linnworks or Website!") {
                        alert('Job not processed on Linnworks or Website!')
                    }
                    this.$message.error('Status Failed.');
                }
            }).catch(() => {
                this.$message.error('Status Failed.');
            });
        },
        updateOwner(e, id) {
            axios.post(api_url + '/v2/jobs/' + id + '/update/owner', {
                value: e
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Owner Updated.');
                } else {
                    this.$message.error('Owner Failed.');
                }
            }).catch(() => {
                this.$message.error('Owner Failed.');
            });
        },
        updateTuner(e, id) {
            axios.post(api_url + '/v2/jobs/' + id + '/update/tuner', {
                value: e
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Tuner Updated.');
                } else {
                    this.$message.error('Tuner Failed.');
                }
            }).catch(() => {
                this.$message.error('Tuner Failed.');
            });
        },
        openJobsheet(id) {
            console.log(id)
            let routeData = this.$router.resolve({
                name: 'Jobsheet',
                params: {
                    id: id
                }
            });
            window.open(routeData.href, '_blank');
        },
        openQuote(id) {
            console.log(id)
            let routeData = this.$router.resolve({
                name: 'JobsheetQuote',
                params: {
                    id: id
                }
            });
            window.open(routeData.href, '_blank');
        },
        tabChanged(e) {
            if(e !== 'customers') {
                location.hash.replace('#','');
                location.hash = e;
            } else {
                location.hash = ""
            }
        },
        deleteQuote(record) {
            const conf = confirm("Are you sure you want to delete this quote?");
            if(conf) {
                axios.delete(api_url + '/api/quote/' + record.id).then(resp => {
                    console.log(resp)
                    this.$message.success("Deleted quote")
                    this.getQuotedJobs();
                }).catch(err => {
                    console.log(err)
                    this.$message.error(err)
                });
            }
        }
    },
}
</script>

<style lang="scss" scoped>
table#overview_table,
table#darkside_table {
    font-size: 12px;
}

.tablerow {
    td {
        text-align: center;
        font-size: 12px !important;
    }

    .form-control.input {
        color: #fff !important;
    }

    &.dark {
        .form-control.input {
            color: #000 !important;
        }
    }
}

.mw-100 {
    min-width: 100px !important;
}

.flatpickr-input~.input {
    color: #fff;
}

.v-snack--top {
    top: 60px;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}
</style>


<style lang="scss">
.ant-tabs-nav-container {
    .ant-tabs-tab {
        .a-dropdown-link {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.ant-tabs-nav .ant-tabs-tab {
    border-bottom: 0 !important;
    margin: 0;
    position: relative;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap .ant-tabs-tab {
    margin-right: 0;
}

.ant-tabs-tab-active.ant-tabs-tab {
    &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 5px;
        background: white;
    }
}

.ant-table-body {
    overflow-x: auto;
}

.ant-table-body .ant-table-thead>tr>th {
    background: darken(#fafafa, 2%);

    .ant-table-header-column {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 12px;
        font-weight: 700;
        opacity: 0.8;
    }
}

.ant-table-tbody {
    background: white
}

.ant-table-row {
    &.awaiting-payment-vehicle {
        background: #00a909;
        color: #fff;
      &:hover {
        background: #009f10;
      }
    }

    &.file-read {
        background: #ED1C24;
        color: #fff;
      &:hover {
        background: #c70e15;
      }
    }

    &.awaiting-road-test {
        background: #a6ce55;
        color: #fff;
      &:hover {
        background: #a0cb50;
      }
    }

    &.awaiting-collection-invoice {
        background: #02ce93;
        color: #fff;
      &:hover {
        background: #05b784;
      }
    }

    &.present-not-started {
        background: #076604;
        color: #fff;
      &:hover {
        background: #056503;
      }
    }

    &.road-test {
        background: #00919b;
        color: #fff;
      &:hover {
        background: #008e98;
      }
    }

    &.final-checks {
        background: #6ece11;
        color: #fff;
      &:hover {
        background: #6ccb10;
      }
    }

    &.customer-notified {
        background: #988cff;
        color: #fff;
      &:hover {
        background: #9387fa;
      }
    }

    &.complete-collected-rebooking {
        background: #8b47f6;
        color: #fff;
      &:hover {
        background: #8645ee;
      }
    }

    &.cancelled {
        background: #000;
        color: #fff;
      &:hover {
        background: #0e0e0e;
      }
    }

    &.started-awaiting {
        background: #045f66;
        color: #fff;
      &:hover {
        background: #03585e;
      }
    }
}

.ant-table-tbody tr.ant-table-row td {
    border-color: rgba(0, 0, 0, 0.12);
    font-size: 12px;
  font-weight: 600;
    position: relative;
    cursor: pointer;
}

.ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-thead>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td,
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: inherit !important;
}

.pick-status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ant-table-tbody>tr>td {
    padding: 10px !important;

    .ant-select {
        font-size: 12px;
    }
}

@media (max-width: 992px) {
    #noj {
        display: none;
    }
}

.ant-table-row.insurance-job td:first-of-type {
    position: relative;
    border-left: 20px solid rgba(0,0,0,0.8) !important;
    &::after {
        content: "Insurance";
        position: absolute;
        left: -31px;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        color: #fff;
        transform: rotate(-90deg);
        font-size: 10px;
    }
}
.ant-table-row.warranty-job {
    background: yellow;
  &:hover {
    background: #ecec00;
  }
}
.ant-table-row.warranty-job td{
    color: black !important;
}
.ant-table-row.warranty-job td:first-of-type {
    position: relative;
    border-left: 20px solid rgba(0,0,0,0.8) !important;
    &::after {
        content: "Warranty";
        position: absolute;
        left: -31px;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        color: #fff;
        transform: rotate(-90deg);
        font-size: 10px;
    }
}
</style>
