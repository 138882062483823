<template>
    <div class="booking">
        <div class="container mt-5 pb-3">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h2 class="text-3xl">Booking <small>Stage 2/4</small></h2>
                    <h6 class="text-base">{{today}}</h6>
                    <a-button class="flex items-center justify-center mx-auto mt-2" @click="saveJson">Save To File</a-button>

                    <div v-if="errors.length" class="bg-red-300 border border-solid border-red-400 rounded w-full error-list mt-3 p-3">
                        <strong>Please correct the following error(s):</strong>
                        <ul class="list">
                            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pb-5">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="maincard border-0">
                        <div class="p-3">
                            <a-button type="primary" class="flex mx-auto" size="small" @click="defaultToStock()">Default parts to Stock</a-button>

                            <div class="mt-3">
                                <label class="block mb-1">ECU Number*</label>
                                <a-input size="small" type="text" placeholder="ECU Number" v-model:value="ecu_num" required/>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <a-checkbox v-model:checked="prev_tuned" class="w-full">Previously Tuned?</a-checkbox>
                                <div v-if="prev_tuned" class="w-full">
                                    <label class="block">Previously tuned by...</label>
                                    <custom-select class="w-full" v-model="tuned_by" :options="parts.tuned_by" vparam="value" lparam="label"></custom-select>
                                </div>
                            </div>

                            <div class="flex gap-3 mt-3 justify-center" v-show="prev_tuned && tuned_by !== 'Select One' && tuned_by !== 'Stock' && tuned_by !== '' && tuned_by !== null">
                                <div class="w-full">
                                    <label class="block mb-1">Claimed Power</label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Claimed Power" v-model:value="claimed_power"/>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Claimed Torque <small>Ft/Lbs</small></label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Claimed Torque" v-model:value="claimed_torque"/>
                                </div>
                            </div>

                            <div class="flex gap-3 mt-3 justify-center">
                                <div class="w-full">
                                    <label class="block mb-1">Current Power</label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Current Power" v-model:value="current_power"/>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Current Torque <small>Ft/Lbs</small></label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Current Torque" v-model:value="current_torque"/>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Turbocharger*</label>
                                    <custom-select class="w-full" v-model="turbocharger" :options="parts.turbo" vparam="value" lparam="label"></custom-select>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Injectors*</label>
                                    <custom-select class="w-full" v-model="injectors" :options="parts.injectors" vparam="value" lparam="label"></custom-select>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Pump*</label>
                                    <custom-select class="w-full" v-model="pump" :options="parts.pump" vparam="value" lparam="label"></custom-select>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Map Sensor*</label>
                                    <custom-select class="w-full" v-model="map_sensor" :options="parts.mapsensor" vparam="value" lparam="label"></custom-select>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Intercooler*</label>
                                    <custom-select class="w-full" v-model="intercooler" :options="parts.intercooler" vparam="value" lparam="label"></custom-select>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Pipework*</label>
                                    <custom-select class="w-full" v-model="pipework" :options="parts.pipework" vparam="value" lparam="label"></custom-select>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Camshaft*</label>
                                    <custom-select class="w-full" v-model="camshaft" :options="parts.camshaft" vparam="value" lparam="label"></custom-select>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Cylinder Head*</label>
                                    <custom-select class="w-full" v-model="cylinder_head" :options="parts.cyl_head" vparam="value" lparam="label"></custom-select>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Clutch &amp; Flywheel*</label>
                                    <custom-select class="w-full" v-model="clutch_flywheel" :options="parts.clutch" vparam="value" lparam="label"></custom-select>
                                </div>
                                <div class="w-full">
                                    <label class="block mb-1">Smoke Preference*</label>
                                    <custom-select class="w-full" v-model="smoke_level" :options="smokeOptions" vparam="value" lparam="value"></custom-select>
                                </div>
                            </div>


                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Other Mods</label>
                                    <a-input size="small" type="text" placeholder="Other Mods" v-model:value="other_mods"/>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Other Gearbox Mods</label>
                                    <a-input size="small" type="text" placeholder="Other Gearbox Mods" v-model:value="gearbox_mods"/>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Expected Power</label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Expected Power" v-model:value="expected_power"/>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Expected Peak Torque</label>
                                    <a-input-number controls-position="right" size="small" class="w-full" placeholder="Expected Peak Torque" v-model:value="expected_peak_torque"/>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-50">
                                    <label class="block mb-1">MAF</label>
                                    <custom-select class="w-full" v-model:value="clutch_flywheel" :options="[{id: 1, value: 'Stock'}, {id: 2, value: 'Upgraded'}]" vparam="value" lparam="value"></custom-select>
                                </div>
                            </div>

                            <!-- RADIO BUTTONS -->
                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Power Tune</label>
                                    <a-radio-group v-model:value="power_tune">
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Yes">Yes</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Gearbox Tune</label>
                                    <a-radio-group v-model:value="gearbox_tune">
                                        <a-radio value="NA">NA</a-radio>
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Yes">Yes</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">MAF</label>
                                    <a-radio-group v-model:value="maf">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">EGR Hardware</label>
                                    <a-radio-group v-model:value="egr_hardware">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Deleted">Deleted</a-radio>
                                        <a-radio value="Blanked">Blanked</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">EGR Software</label>
                                    <a-radio-group v-model:value="egr_software">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Deleted">Deleted</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">ASV</label>
                                    <a-radio-group v-model:value="asv">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="NA">NA</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">CAT</label>
                                    <a-radio-group v-model:value="cat">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="Hollow">Hollow</a-radio>
                                        <a-radio value="NA">NA</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">DPF</label>
                                    <a-radio-group v-model:value="dpf">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="NA">NA</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">DPF Sensors</label>
                                    <a-radio-group v-model:value="dpf_sensors">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="NA">NA</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Swirl Flaps</label>
                                    <a-radio-group v-model:value="swirl_flaps">
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="NA">NA</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Hardcut</label>
                                    <a-radio-group v-model:value="hardcut">
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="If Possible">If Possible</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Launch</label>
                                    <a-radio-group v-model:value="launch">
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                        <a-radio value="If Possible">If Possible</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Hot Start Fix</label>
                                    <a-radio-group v-model:value="hot_start_fix">
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Yes">Yes</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Adblue</label>
                                    <a-radio-group v-model:value="adblue">
                                        <a-radio value="NA">NA</a-radio>
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Left Foot Braking</label>
                                    <a-radio-group v-model:value="left_foot_braking">
                                        <a-radio value="NA">NA</a-radio>
                                        <a-radio value="No">No</a-radio>
                                        <a-radio value="Yes">Yes</a-radio>
                                    </a-radio-group>
                                </div>

                                <div class="w-full">
                                    <label class="block mb-1">Stop/Start</label>
                                    <a-radio-group v-model:value="stopStart">
                                        <a-radio value="NA">NA</a-radio>
                                        <a-radio value="On">On</a-radio>
                                        <a-radio value="Off">Off</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>

                            <div class="mt-3 flex gap-3">
                                <div class="w-full">
                                    <label class="block mb-1">Track Use?</label>
                                    <a-radio-group v-model:value="track_use">
                                        <a-radio :value="false">No</a-radio>
                                        <a-radio :value="true">Yes</a-radio>
                                    </a-radio-group>
                                </div>
                            </div>
                            <!-- /RADIO BUTTONS -->

                            <div class="row mt-2 justify-content-center">
                                <div class="col-md-3">
                                    <a-button class="w-full" @click="changeStep(1, 'back')">Go back to stage 1</a-button>
                                </div>
                                <div class="col-md-3">
                                    <a-button type="primary" class="w-full" @click="changeStep(3, 'next')">Next Stage</a-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import {useBookingStore} from "@/store/bookingStore";
import customSelect from '@/components/extras/Select.vue';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    components: {
        customSelect
    },
    data(){
        return {
            today: moment().format('MMMM Do YYYY, h:mm:ss a'),
            now: moment().format('YYYY-MM-DD'),
            mainErrors: {
                name: "",
                number: "",
                email: "",
                cpref: "",
                vehicle: "",
                engineCode: "",
                gearboxType: "",
                drivetrain: "",
                diary: ""
            },
            errors: [],
            smokeOptions: [
                {id: 1, value: "No Preference"},
                {id: 2, value: "Smoke Free"},
                {id: 3, value: "Light Haze"},
                {id: 4, value: "Mild-Smoke"},
                {id: 5, value: "Heavy Smoke"},
                {id: 6, value: "Confirm With Customer"}
            ]
        }
    },
    mounted() {
        if(this.tuned_by !== "Stock" && this.tuned_by !== "Select One..." && this.tuned_by !== "Select One" && this.tuned_by !== null) {
            this.prev_tuned = true;
        }
    },
    validations: {
        ecu_num: {
            required,
            minLength: minLength(1)
        },
        turbocharger: {
            required
        },
        injectors: {
            required
        },
        pump: {
            required
        },
        map_sensor: {
            required
        },
        intercooler: {
            required
        },
        pipework: {
            required
        },
        camshaft: {
            required
        },
        cylinder_head: {
            required
        },
        clutch_flywheel: {
            required
        },
        smoke_level: {
            required
        },
    },
    computed: {
        customer_id: {
            get() {
                return useBookingStore().customer_id
            },
        },
        booked_in_by: {
            get() {
                return useBookingStore().booked_in_by
            },
        },
        booked_in_reg: {
            get() {
                return useBookingStore().booked_in_reg
            },
        },
        vin: {
            get() {
                return useBookingStore().vin
            },
        },
        parts: {
            get() {
                return useBookingStore().parts
            },
            set(value) {
                useBookingStore().setParts(value)
            }
        },
        ecu_num: {
            get() {
                return useBookingStore().ecu_num
            },
            set(value) {
                useBookingStore().setECUNum(value)
            }
        },
        prev_tuned: {
            get() {
                return useBookingStore().prev_tuned
            },
            set(value) {
                useBookingStore().setPrevTuned(value)
            }
        },
        tuned_by: {
            get() {
                return useBookingStore().tuned_by
            },
            set(value) {
                useBookingStore().setTunedBy(value)
            }
        },
        tuned_by_other: {
            get() {
                return useBookingStore().tuned_by_other
            },
            set(value) {
                useBookingStore().setTunedByOther(value)
            }
        },
        claimed_power: {
            get() {
                return useBookingStore().claimed_power
            },
            set(value) {
                useBookingStore().setClaimedPower(value)
            }
        },
        claimed_torque: {
            get() {
                return useBookingStore().claimed_torque
            },
            set(value) {
                useBookingStore().setClaimedTorque(value)
            }
        },
        bhp: {
            get() {
                return useBookingStore().bhp
            },
            set(value) {
                useBookingStore().setBHP(value)
            }
        },
        torque: {
            get() {
                return useBookingStore().torque
            },
            set(value) {
                useBookingStore().setTorque(value)
            }
        },
        turbocharger: {
            get() {
                return useBookingStore().turbocharger
            },
            set(value) {
                useBookingStore().setTurbocharger(value)
            }
        },
        turbocharger_other: {
            get() {
                return useBookingStore().turbocharger_other
            },
            set(value) {
                useBookingStore().setTurbochargerOther(value)
            }
        },
        injectors: {
            get() {
                return useBookingStore().injectors
            },
            set(value) {
                useBookingStore().setInjectors(value)
            }
        },
        injectors_other: {
            get() {
                return useBookingStore().injectors_other
            },
            set(value) {
                useBookingStore().setInjectorsOther(value)
            }
        },
        pump: {
            get() {
                return useBookingStore().pump
            },
            set(value) {
                useBookingStore().setPump(value)
            }
        },
        pump_other: {
            get() {
                return useBookingStore().pump_other
            },
            set(value) {
                useBookingStore().setPumpOther(value)
            }
        },
        map_sensor: {
            get() {
                return useBookingStore().map_sensor
            },
            set(value) {
                useBookingStore().setMapSensor(value)
            }
        },
        map_sensor_other: {
            get() {
                return useBookingStore().map_sensor_other
            },
            set(value) {
                useBookingStore().setMapSensorOther(value)
            }
        },
        maf_stock: {
            get() {
                return useBookingStore().maf_stock
            },
            set(value) {
                useBookingStore().setMafStock(value)
            }
        },
        intercooler: {
            get() {
                return useBookingStore().intercooler
            },
            set(value) {
                useBookingStore().setIntercooler(value)
            }
        },
        intercooler_other: {
            get() {
                return useBookingStore().intercooler_other
            },
            set(value) {
                useBookingStore().setIntercoolerOther(value)
            }
        },
        pipework: {
            get() {
                return useBookingStore().pipework
            },
            set(value) {
                useBookingStore().setPipework(value)
            }
        },
        pipework_other: {
            get() {
                return useBookingStore().pipework_other
            },
            set(value) {
                useBookingStore().setPipeworkOther(value)
            }
        },
        camshaft: {
            get() {
                return useBookingStore().camshaft
            },
            set(value) {
                useBookingStore().setCamshaft(value)
            }
        },
        camshaft_other: {
            get() {
                return useBookingStore().camshaft_other
            },
            set(value) {
                useBookingStore().setCamshaftOther(value)
            }
        },
        cylinder_head: {
            get() {
                return useBookingStore().cylinder_head
            },
            set(value) {
                useBookingStore().setCylHead(value)
            }
        },
        cylinder_head_other: {
            get() {
                return useBookingStore().cylinder_head_other
            },
            set(value) {
                useBookingStore().setCylHeadOther(value)
            }
        },
        clutch_flywheel: {
            get() {
                return useBookingStore().clutch_flywheel
            },
            set(value) {
                useBookingStore().setClutchFlywheel(value)
            }
        },
        clutch_flywheel_other: {
            get() {
                return useBookingStore().clutch_flywheel_other
            },
            set(value) {
                useBookingStore().setClutchFlywheelOther(value)
            }
        },
        smoke_level: {
            get() {
                return useBookingStore().smoke_level
            },
            set(value) {
                useBookingStore().setSmokeLevel(value)
            }
        },
        other_mods: {
            get() {
                return useBookingStore().other_mods
            },
            set(value) {
                useBookingStore().setOtherMods(value)
            }
        },
        gearbox_mods: {
            get() {
                return useBookingStore().gearbox_mods
            },
            set(value) {
                useBookingStore().setGearboxMods(value)
            }
        },
        expected_power: {
            get() {
                return useBookingStore().expected_power
            },
            set(value) {
                useBookingStore().setExpectedPower(value)
            }
        },
        expected_peak_torque: {
            get() {
                return useBookingStore().expected_peak_torque
            },
            set(value) {
                useBookingStore().setExpectedPeakTorque(value)
            }
        },
        power_tune: {
            get() {
                return useBookingStore().power_tune
            },
            set(value) {
                useBookingStore().setPowerTune(value)
            }
        },
        gearbox_tune: {
            get() {
                return useBookingStore().gearbox_tune
            },
            set(value) {
                useBookingStore().setGearboxTune(value)
            }
        },
        maf: {
            get() {
                return useBookingStore().maf
            },
            set(value) {
                useBookingStore().setMaf(value)
            }
        },
        egr_hardware: {
            get() {
                return useBookingStore().egr_hardware
            },
            set(value) {
                useBookingStore().setEGRHardware(value)
            }
        },
        egr_software: {
            get() {
                return useBookingStore().egr_software
            },
            set(value) {
                useBookingStore().setEGRSoftware(value)
            }
        },
        asv: {
            get() {
                return useBookingStore().asv
            },
            set(value) {
                useBookingStore().setASV(value)
            }
        },
        cat: {
            get() {
                return useBookingStore().cat
            },
            set(value) {
                useBookingStore().setCat(value)
            }
        },
        dpf: {
            get() {
                return useBookingStore().dpf
            },
            set(value) {
                useBookingStore().setDPF(value)
            }
        },
        dpf_sensors: {
            get() {
                return useBookingStore().dpf_sensors
            },
            set(value) {
                useBookingStore().setDPFSensors(value)
            }
        },
        swirl_flaps: {
            get() {
                return useBookingStore().swirl_flaps
            },
            set(value) {
                useBookingStore().setSwirlFlaps(value)
            }
        },
        hardcut: {
            get() {
                return useBookingStore().hardcut
            },
            set(value) {
                useBookingStore().setHardcut(value)
            }
        },
        launch: {
            get() {
                return useBookingStore().launch
            },
            set(value) {
                useBookingStore().setLaunch(value)
            }
        },
        hot_start_fix: {
            get() {
                return useBookingStore().hot_start_fix
            },
            set(value) {
                useBookingStore().setHotStartFix(value)
            }
        },
        adblue: {
            get() {
                return useBookingStore().adblue
            },
            set(value) {
                useBookingStore().setAdblue(value)
            }
        },
        left_foot_braking: {
            get() {
                return useBookingStore().left_foot_braking
            },
            set(value) {
                useBookingStore().setLeftFootBraking(value)
            }
        },
        track_use: {
            get() {
                return useBookingStore().track_use
            },
            set(value) {
                useBookingStore().setTrackUse(value)
            }
        },
        stopStart: {
            get() {
                return useBookingStore().stopStart
            },
            set(value) {
                useBookingStore().setStopStart(value)
            }
        },
        current_power: {
            get() {
              return useBookingStore().current_power
            },
            set(value) {
              useBookingStore().setCurrentPower(value)
            }
        },
        current_torque: {
            get() {
              return useBookingStore().current_torque
            },
            set(value) {
              useBookingStore().setCurrentTorque(value)
            }
        },
    },
    methods: {
        defaultToStock(){
            this.tuned_by = "Stock";
            this.turbocharger = "Stock";
            this.injectors = "Stock";
            this.pump = "Stock";
            this.map_sensor = "Stock";
            this.intercooler = "Stock";
            this.pipework = "Stock";
            this.camshaft = "Stock";
            this.cylinder_head = "Stock";
            this.clutch_flywheel = "Stock";
            this.maf = "On";
        },
        changeStep(step, direction){
            if(step === 3) {
                this.errors = [];
                if(this.v$.$invalid) {
                    if(this.v$.ecu_num.$invalid) {this.errors.push('ECU Number is required.')}
                    if(this.v$.turbocharger.$invalid) {this.errors.push('Turbocharger is required.')}
                    if(this.v$.injectors.$invalid) {this.errors.push('Injectors is required.')}
                    if(this.v$.pump.$invalid) {this.errors.push('Pump is required.')}
                    if(this.v$.map_sensor.$invalid) {this.errors.push('Map Sensor is required.')}
                    if(this.v$.intercooler.$invalid) {this.errors.push('Intercooler is required.')}
                    if(this.v$.pipework.$invalid) {this.errors.push('Pipework is required.')}
                    if(this.v$.camshaft.$invalid) {this.errors.push('Camshaft is required.')}
                    if(this.v$.cylinder_head.$invalid) {this.errors.push('Cylinder Head is required.')}
                    if(this.v$.clutch_flywheel.$invalid) {this.errors.push('Clutch & Flywheel is required.')}
                    if(this.v$.smoke_level.$invalid) {this.errors.push('Smoke Level is required.')}
                    this.scrollToTop();
                } else {
                    if(this.tuned_by === 'Select One') {
                        this.tuned_by = null;
                    }
                    this.$parent.step = 3;
                    this.scrollToTop();
                }
            } else {
                this.$parent.step = 1;
                this.scrollToTop();
            }
        },
        scrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        },
        saveJson() {
            const json = useBookingStore()
            delete json.parts;
            const a = document.createElement("a");
            const file = new Blob([JSON.stringify(json, undefined, 4)], {type: 'text/plain'});
            a.href = URL.createObjectURL(file);
            a.download = this.booked_in_reg +'_booking.json';
            a.click();
        },
    }
}
</script>

<style>

</style>
