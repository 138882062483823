<template>
    <div :class="{'in-iframe': isIframe}">
        <div v-click-outside-app="clickedOutside">
            <nav class="fixed top-0 left-0 w-full bg-white h-14 flex items-center px-4 z-20 shadow"
                 v-if="user && !$route.fullPath.includes('login')">
                <a href="/"><img class="h-8" src="./assets/images/logo.png" alt="Darkside Developments"></a>

                <ul class="items-center gap-4 ml-4 hidden md:flex">
                    <li>
                        <router-link to="/" class="transition-all duration-150 text-gray-400 hover:text-gray-800">
                            Overview
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/consumables" class="transition-all duration-150 text-gray-400 hover:text-gray-800">
                            Consumables
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/swiftec" class="transition-all duration-150 text-gray-400 hover:text-gray-800">
                            Swiftec
                        </router-link>
                    </li>
                    <li>
                        <a href="https://darkside-developments.com/ws_parts_ordered/" target="_blank" class="transition-all duration-150 text-gray-400 hover:text-gray-800">
                            WS Parts Ordered
                        </a>
                    </li>
                </ul>

                <div class="ml-auto hidden md:flex items-center gap-4">
                    <a-button @click="newQuote">New Quote</a-button>

                    <a-dropdown :trigger="['click']">
                        <div class="flex items-center ml-auto w-20 cursor-pointer transition-all duration-150 text-gray-400 hover:text-gray-800">
                            <span>{{ user.nicename }}</span>
                            <a-avatar size="small" class="ml-auto" :src="user.image_path"/>
                        </div>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="0">
                                    <a @click="reloadStaff">Reload Staff</a>
                                </a-menu-item>
                                <a-menu-divider />
                                <a-menu-item key="1" @click="logout">Logout</a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>

                <div class="ml-auto block md:hidden">
                    <span @click="navOpen = !navOpen">
                        <i class="fa fa-bars text-white text-xl" aria-hidden="true"></i>
                    </span>
                </div>
            </nav>
            <transition name="slide">
                <nav class="fixed w-full border-t border-solid border-black top-14 bg-dark-main px-4 z-20"
                     v-if="navOpen">
                    <ul class="items-center gap-4">
                        <li @click="navOpen=false">
                            <router-link to="/" class="block py-2 text-gray-400 hover:text-white">Overview</router-link>
                        </li>
                        <li @click="navOpen=false">
                            <router-link to="/internal" class="block py-2 text-gray-400 hover:text-white">
                                Internal Overview
                            </router-link>
                        </li>
                        <li @click="navOpen=false">
                            <router-link to="/bodyshop" class="block py-2 text-gray-400 hover:text-white">
                                Bodyshop Overview
                            </router-link>
                        </li>
                        <li @click="navOpen=false">
                            <router-link to="/consumables" class="block py-2 text-gray-400 hover:text-white">
                                Consumables
                            </router-link>
                        </li>
                        <li @click="navOpen=false">
                            <router-link to="/swiftec" class="block py-2 text-gray-400 hover:text-white">
                                Swiftec
                            </router-link>
                        </li>
                    </ul>
                    <div class="block mt-3 pb-2">
                        <div class="flex items-center w-20 cursor-pointer text-white" @click="logout">
                            Logout
                        </div>
                    </div>
                </nav>
            </transition>
        </div>
        <div :class="{'login': !user && $route.name !== 'Checklist'}">
            <router-view></router-view>
        </div>
        <vue-progress-bar></vue-progress-bar>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import {useAuthStore} from "@/store/authStore";
export default {
    name: 'app',
    data() {
        return {
            navOpen: false,
            isIframe: false,
            window: {
                width: 0,
                height: 0
            }
        }
    },
    directives: {
        "click-outside-app": {
            bind: function (el, binding) {
                const ourClickEventHandler = event => {
                    if (!el.contains(event.target) && el !== event.target) {
                        binding.value(event);
                    }
                };
                el.__vueClickEventHandler__ = ourClickEventHandler;
                document.addEventListener("click", ourClickEventHandler);
            },
            unbind: function (el) {
                document.removeEventListener("click", el.__vueClickEventHandler__);
            }
        }
    },
    created() {
        const hasStaff = localStorage.getItem('staff');
        if (location.pathname !== "/login" && !hasStaff) {
            try {
                axios.get(api_url + '/v2/staff', {
                    headers: {
                        Authorization: 'Bearer ' + useAuthStore().token
                    }
                }).then((resp) => {
                    localStorage.setItem('staff', JSON.stringify(resp.data))
                }).catch(() => {
                });
            } catch (error) {
                console.log(error)
            }
        }
        axios.interceptors.request.use(
            (config) => {
                this.$Progress.start();
                return config;
            }, (error) => {
                this.$Progress.finish();
                return Promise.reject(error);
            }
        );

        axios.interceptors.response.use(
            (response) => {
                this.$Progress.finish();
                return response
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                    this.$Progress.finish();
                    //this.$store.dispatch('auth/logout');
                    useAuthStore().logout();
                    location.reload();
                    console.log(error)
                } else {
                    return Promise.reject(error);
                }
            }
        );
    },
    mounted() {
        if (window.location !== window.parent.location) {
            this.isIframe = true
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user;
            } else {
                return null
            }
        },
    },
    methods: {
        logout() {
            //if (window.location.hostname === "localhost") {
            //    Cookies.remove('user', null, 'localhost');
            //    Cookies.remove('user', null, '.darkside-developments.com');
            //} else {
            //    Cookies.remove('user', null, '.darkside-developments.com');
            //}
            useAuthStore().logout();
        },
        reloadStaff() {
            axios.get(api_url + '/v2/staff').then((resp) => {
                localStorage.setItem('staff', JSON.stringify(resp.data))
            }).catch(() => {
                alert("Failed to get staff");
            });
        },
        GetBodyshopSheet() {
            axios.get(api_url + '/v2/jobs/bodyshop/consumables/jobsheet').then((resp) => {
                if (parseInt(resp.data)) {
                    let routeData = this.$router.resolve({
                        name: 'Jobsheet',
                        params: {
                            id: resp.data
                        }
                    });
                    window.open(routeData.href, '_blank');
                }
            })
        },
        clickedOutside() {
            if (this.navOpen) {
                this.navOpen = false
            }
        },
        newQuote() {
            const conf = confirm("Are you sure you want to create a new quote jobcard?");
            if(conf) {
                axios.post(api_url + '/v2/jobs/booking/quote').then((resp) => {
                    if(resp.data.success) {
                        const newId = resp.data.data.job_id;
                        if (parseInt(newId)) {
                            let routeData = this.$router.resolve({
                                name: 'JobsheetQuote',
                                params: {
                                    id: newId
                                }
                            });
                            window.open(routeData.href, '_blank');
                        }
                    } else {
                        alert('Something went wrong.');
                    }
                })
            }
        }
    },
    destroyed() {
        //window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style>
.ant-table-thead {
    position: sticky;
    top: 0px;
}
.parts_table thead{
    position: sticky;
    top: 0px;
}
</style>
