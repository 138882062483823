import { ApiService } from "@/services/api/ApiService";
//import type { UserLoginRequest } from "@/application/contracts/core/users/UserLoginRequest";
//import type { UserLoggedResponse } from "@/application/contracts/core/users/UserLoggedResponse";
//import type { UserVerifyRequest } from "@/application/contracts/core/users/UserVerifyRequest";
//import type {IQueryService} from "./IQueryService";

export class QueryService extends ApiService {
    constructor() {
        super("Query");
    }
    getData(url, method = "GET", data = null) {
        return new Promise((resolve, reject) => {
            if(method === "GET") {
                super.get(url)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else if(method === "POST") {
                super.post(url, data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }

            /*this.$Progress.start();
            this.$axios({
                method: method,
                url: url,//+ "?asdfasdfsadf",
                data: data,
                headers: {
                    "Authorization": "Bearer " + this.$authToken,
                }
            }).then((resp) => {
                this.$Progress.finish();
                resolve(resp);
            }).catch((err) => {
                this.$Progress.fail();
                reject(err);
            });*/
        });
    }

    postData(url, data = null, headers = {}) {
        return new Promise((resolve, reject) => {
            super.post(url, data, headers)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    putData(url, data = null, headers = {}) {
        return new Promise((resolve, reject) => {
            super.put(url, null, data, headers)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    deleteData(url, data = null, headers = {}) {
        return new Promise((resolve, reject) => {
            super.delete(url, data, headers)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /*rowUpdated(url: string, method = "POST", data = null) {
        return new Promise((resolve, reject) => {
            this.$Progress.start();
            this.$axios({
                method: method,
                url: url,
                data: data,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$Progress.finish();
                    this.$message.success("Updated");
                    resolve(resp);
                } else {
                    this.$Progress.fail();
                    this.$message.error("Something Went Wrong");
                    reject(resp);
                }
            }).catch((err) => {
                this.$Progress.fail();
                this.$message.error("Something Went Wrong");
                reject(err);
            });
        });
    }
    rowInserted(url: string, method = "POST", data = null) {
        return new Promise((resolve, reject) => {
            this.$Progress.start();
            this.$axios({
                method: method,
                url: url,
                data: data,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$Progress.finish();
                    this.$message.success("Updated");
                    resolve(resp);
                } else {
                    this.$Progress.fail();
                    this.$message.error("Something Went Wrong");
                    reject(resp);
                }
            }).catch((err) => {
                this.$Progress.fail();
                this.$message.error("Something Went Wrong");
                reject(err);
            });
        });
    }
    rowRemoved(url: string, method = "POST", data = null) {
        return new Promise((resolve, reject) => {
            this.$Progress.start();
            this.$axios({
                method: method,
                url: url,
                data: data,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$Progress.finish();
                    this.$message.success("Deleted");
                    resolve(resp);
                } else {
                    this.$Progress.fail();
                    this.$message.error("Something Went Wrong");
                    reject(resp);
                }
            }).catch((err) => {
                this.$Progress.fail();
                this.$message.error("Something Went Wrong");
                reject(err);
            });
        });
    }*/
}
