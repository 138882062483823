<template>
    <div class="h-screen">
        <div class="container h-full">
            <div class="row justify-center items-center pt-3 h-full">
                <div class="col-md-5">
                    <div class="text-center mb-3 flex justify-center">
                        <img src="/assets/images/logo.png" height="50px"/>
                    </div>
                    <div class="maincard h-auto" id="login-card">
                        <div class="p-3">
                            <form class="container">
                                <div class="mb-3">
                                    <label for="username" class="mb-1 block">Username</label>
                                    <a-input id="username" type="text" name="username" placeholder="Username..." required auto-focus v-model:value="user.username"/>
                                </div>

                                <div class="mb-3">
                                    <label for="password" class="mb-1 block">Password</label>
                                    <a-input id="password" type="password" name="password" required autocomplete="current-password" placeholder="Password..." v-model:value="user.password"/>
                                </div>

                                <a-checkbox v-model:checked="user.remember">Remember Me</a-checkbox>

                                <a-button id="login-btn" class="btn-dark mt-3 mb-3 w-full" :loading="loading" @click="handleSubmit($event)">Login</a-button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import services from "@/services";
export default {
    data() {
        return {
            //user: new User('', '', false),
            user: {
                username: "",
                password: "",
                remember: true
            },
            loading: false,
            message: '',
        };
    },
    mounted() {
    },
    methods: {
        handleSubmit() {
            this.loading = true;
            services.authentication.login(this.user).catch((error) => {
                if(error.message) {
                    this.message = error.message;
                } else {
                    console.log(error)
                }
            }).finally(() => {
                this.loading = false;
            });
        },
        login(e) {
            e.preventDefault();
            this.loading = true;
            if (this.user.username && this.user.password) {
                this.$store.dispatch('auth/login', this.user).then(() => {
                        try {
                            axios.get(api_url + '/v2/staff').then((resp) => {
                                localStorage.setItem('staff', JSON.stringify(resp.data))
                            }).catch(() => {
                            });
                        } catch (error) {
                            console.log(error)
                        }
                        var redir = localStorage.getItem('redirect');
                        if (redir) {
                            localStorage.removeItem('redirect');
                            this.$router.push(redir);
                        } else {
                            this.$router.push('/');
                        }
                    },
                    error => {
                        this.loading = false;
                        this.message = (error.response && error.response.data) || error.message || error.toString();
                    });
            }
        },
    },
};
</script>

<style></style>
