<template>
    <div v-if="job">
        <div class="bg-white rounded border border-gray-200">
            <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200">
                <div class="flex items-center w-full">
                    <a-checkbox v-model:checked="showSelect" class="mr-2 mt-0 text-white" label="Invoice Check" v-if="type !== 'unauthed'"></a-checkbox>
                    <span class="font-semibold">{{c_type}}</span>
                    <span class="ml-1" v-if="c_type === 'Extra'">(Hidden From Tasklist)</span>
                    <span class="ml-2 pointer-cursor" @click="table_collapsed = !table_collapsed">
                        <span v-if="table_collapsed"><i class="fa fa-caret-down"></i></span>
                        <span v-else><i class="fa fa-caret-up"></i></span>
                    </span>

                    <span class="ml-auto" v-if="job && job.times">
                        <i class="fa fa-clock mr-2"></i>
                        <span v-if="job.times[type+'_time'] == null">--</span>
                        <span v-else>{{job.times[type+'_time'].time}}</span>
                    </span>
                </div>
            </div>

            <collapse-transition :duration="600">
                <div v-show="!table_collapsed" class="relative">
                    <div class="loading-container" v-if="loading">
                        <a-spin :indicator="indicator"/>
                    </div>
                    <div class="overflow-x-auto">
                        <table class="w-full parts_table">
                            <thead>
                                <tr>
                                    <th class="part_table_header" v-for="(header, index) in table_headers" :key="index" @click="sortTable(header)" :class="{'cursor-pointer': header.sortable}">
                                        <span class="flex items-center justify-center gap-2">
                                            {{header.title}}

                                            <span class="icon" :class="{'isReverse' : sortDirectionAsc}" v-if="sortBy === header.key">
                                                <i class=" fas fa-angle-up text-success"></i>
                                            </span>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <draggable v-model="job[type+'_parts']" tag="tbody" handle=".handle" ghost-class="ghost" class="parts_table" @end="onDragUpdate(type+'_parts')" ref="tbody" item-key="id">
                                <template #item="{element: part}">
                                    <tr :key="part.id" :id="'tr-'+part.id" :style="{backgroundColor: isComplete(part)}">
                                        <td>
                                            <a-checkbox v-model:checked="part.authed" v-if="type === 'unauthed'"></a-checkbox>
                                            <template v-else>
                                                <div class="handle" v-if="sortBy === ''"><i class="fa fa-th"></i></div>
                                            </template>
                                        </td>

                                        <td v-if="showSelect">
                                            <a-checkbox v-model:checked="part.bc_checkbox" @change="setBcCheckbox(part)" color="#fff"></a-checkbox>
                                        </td>

                                        <td class="part-title" contenteditable="true" v-if="part.part_name !== ''" @blur="updatePartTitle($event, part)" :class="{'selected': part.bc_checkbox && showSelect}">
                                            <template v-if="part.part_name !== ''">{{utfEncode(part.part_name)}}</template>
                                            <template v-else>{{part.name}}</template>
                                        </td>

                                        <td v-if="job.project_car === false" :class="{'bordered': parseFloat(part.cost_per_part.toString().replace('£','').replace(',','')) === 0}" class="price-field" contenteditable="true" @blur="focusOut(type ,part.id,'cost_per_part', $event)">{{part.cost_per_part}}</td>

                                        <td class="price-field" v-if="job.project_car === false" :class="{'bordered': parseFloat(part.purchase_price.toString().replace('£','').replace(',','')) === 0}" contenteditable="true" @blur="focusOut(type ,part.id,'purchase_price', $event)">
                                            {{part.purchase_price}}
                                        </td>

                                        <td class="price-field" v-if="job.project_car === true && costIsZero(part.purchase_price)" :class="{'bordered': parseFloat(part.cost_per_part.toString().replace('£','').replace(',','')) === 0}" contenteditable="true" @blur="focusOut(type ,part.id,'purchase_price', $event)">
                                            {{part.cost_per_part}}
                                        </td>

                                        <td class="price-field" v-if="job.project_car === true && !costIsZero(part.purchase_price)" :class="{'bordered': parseFloat(part.purchase_price.toString().replace('£','').replace(',','')) === 0}" contenteditable="true" @blur="focusOut(type ,part.id,'purchase_price', $event)">
                                            {{part.purchase_price}}
                                        </td>

                                        <td contenteditable="true" @blur="updateField($event, type, 'part_no', part)">{{part.part_no}}</td>
                                        <td contenteditable="true" @blur="updatePartQuantity($event, part)">{{part.quantity}}</td>
                                        <td>
                                            <template v-if="job.project_car === false">{{ConvertPrice(part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity)}}</template>
                                            <template v-else>{{ConvertPrice(part.purchase_price.toString().replace('£','').replace(',','') * part.quantity)}}</template>
                                        </td>
                                        <td contenteditable="true" @blur="updatePartLabourHours($event, part)">{{part.labour_hours}}</td>
                                        <td v-if="showSelect">
                                            <a-checkbox v-model:checked="part.bc_labour_checkbox" @change="setBCLabourCheckbox(part)" color="#fff"></a-checkbox>
                                        </td>
                                        <td :class="{'selected': part.bc_labour_checkbox && showSelect}">{{ConvertPrice(part.labour_hours * lab_rate(part.type))}}</td>
                                        <td>
                                            <template v-if="job.project_car === false">
                                                {{ConvertPrice(((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity) + (part.labour_hours*lab_rate(part.type))))}}
                                            </template>
                                            <template v-else>
                                                {{ConvertPrice(((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity) + (part.labour_hours*lab_rate(part.type))))}}
                                            </template>
                                        </td>
                                        <td v-if="!quote" class="text-center">
                                            <a-checkbox v-model:checked="part.complete" @change="updatePartCompletion($event, part)"></a-checkbox>
                                        </td>
                                        <td>
                                            <text-editor :ref="part.id+'-notes-ref'" v-model="part.note" :id-name="part.id + '-notes'" class-name="part-notes" @blur="updatePartNotes($event, part)"></text-editor>
                                        </td>
                                        <td>
                                            <a-button size="small" danger @click="deletePart(type, part)" :loading="deletingPart === part.id"><i class="fa fa-trash"></i></a-button>
                                        </td>
                                        <td v-if="!quote">
                                            <custom-select class="w-24" v-model="part.owner" @change="updatePartOwner($event, part)" :options="staff" vparam="id" lparam="nicename" :show-arrow="false"></custom-select>
                                        </td>
                                        <td v-if="!quote" class="partStatus" :class="{'notPicked': part.picked === 'N', 'picked': part.picked === 'Y', 'ordered': part.picked === 'O'}" @click="updatePartPickStatus($event, part)">
                                            <template v-if="part.picked === 'Y'">Picked</template>
                                            <template v-if="part.picked === 'O'">Ordered</template>
                                            <template v-if="part.picked === 'N'">Not Picked</template>
                                        </td>
                                        <td class="binrack" contenteditable="true" @blur="updatePartBinrack($event, part)">{{part.binrack}}</td>
                                        <td :class="{'in-stock': part.stock_level && part.stock_level.Available > 0}">
                                            <template v-if="part.stock_level">{{part.stock_level.Available}}</template>
                                            <template v-else>--</template>
                                        </td>
                                        <td v-if="type !== 'unauthed' && !job.project_car && !quote" class="text-center">
                                            <a-checkbox v-model:checked="part.unauth_checkbox"></a-checkbox>
                                        </td>
                                        <td>
                                            <custom-select class="w-24" v-model="part.type" @change="updatePartType(part)" :options="partTypeOptions" vparam="id" lparam="name" :show-arrow="false"></custom-select>
                                        </td>
                                        <td v-if="type !== 'unauthed'">
                                            <a-button size="small" type="primary" v-if="part.binrack === 'COMPOSITE' || part.binrack === 'Parent ID'" @click="getCompositionStock(part.id, part.part_no, $event)">Composition Stock</a-button>
                                        </td>

                                        <td>
                                            <a-button size="small" type="primary" @click="swapPart(part, $event)" v-if="part.part_no">Wrong Pick/Part</a-button>
                                        </td>
                                    </tr>
                                </template>
                            </draggable>
                        </table>
                    </div>
                    <div class="px-4 pt-2" v-if="type === 'unauthed'">
                        <div class="flex items-center gap-6">
                            <span class="font-bold">Total Cost (Excl VAT): <span class="font-semibold">{{totalUnauthedRetail()}}</span></span>
                            <span class="font-bold">Total Labour (Excl VAT): <span class="font-semibold">{{totalUnauthedLabour()}}</span></span>
                            <span class="font-bold">Total inc Labour (Excl VAT): <span class="font-semibold">{{totalUnauthedIncLabour()}}</span></span>
                            <a-button size="small" type="primary" @click="authoriseSelected($event)" v-if="job && job.unauthed_parts.length > 0" :loading="authorizingParts">Authorise Selected</a-button>
                        </div>
                    </div>
                </div>
            </collapse-transition>

            <div class="px-3 pt-2 pb-3 rounded-b">
                <div class="row mb-1">
                    <div class="col-md-12 pb-0">
                        <a-select :value="partSelectVal" ref="part_select" show-search label-in-value placeholder="Search By Item Name" class="w-full" :filter-option="false" :not-found-content="fetching ? undefined : null" @search="fetchParts" @focus="parts = []" @select="selectPart" :loading="adding">
                            <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                            <a-select-option v-for="d in parts" :key="d.value" :value="d.value">{{ d.text }}</a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 pt-0 pb-0 flex items-center">
                        <a-input-search v-model:value="taskVal" class="w-full" @blur="addNewTask($event, c_type)" v-on:keyup.enter="blurField($event)" placeholder="Add task" :loading="addingTask" />
                    </div>
                </div>
            </div>
        </div>

        <a-modal v-model:visible="compStockModal" width="80%" :footer="null" @cancel="modalClosed">
            <div class="">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Picked</th>
                            <th>Ordered</th>
                            <th>SKU</th>
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Stock</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="stock in current_comp_stock" :key="stock.LinkedStockItemId">
                            <td><a-checkbox v-model:checked="stock.picked" @change="updateCompPickedStatus(stock.id, stock.picked)"/></td>
                            <td><a-checkbox v-model:checked="stock.ordered" @change="updateCompOrderedStatus(stock.id, stock.ordered)"/></td>
                            <td>{{stock.sku}}</td>
                            <td>{{stock.title}}</td>
                            <td>{{stock.quantity}}</td>
                            <td>{{stock.stock}}</td>
                            <td>{{stock.binrack}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </a-modal>


        <a-modal v-model:visible="swapPartModal" width="80%" :footer="null" @cancel="swapPartModalClosed">
            <div class="">
                <h3 class="text-lg font-bold">Swap Part</h3>
                <p>Swapping a part will change the current part to the newly selected part whilst keeping the labour hours and quantity.</p>
                <ul v-if="swapPartData" class="mt-2 mb-2">
                    <li><b>Part No:</b> {{swapPartData.part_no}}</li>
                    <li><b>Part Name:</b> {{swapPartData.part_name}}</li>
                    <li><b>Labour Hours:</b>  {{swapPartData.labour_hours}}</li>
                </ul>
                <a-select :value="partSelectVal" ref="part_select" show-search label-in-value placeholder="Search By Item Name" class="w-full" :filter-option="false" :not-found-content="fetching ? undefined : null" @search="fetchParts" @focus="parts = []" @select="selectPartToSwap" :loading="adding" :disabled="adding">
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="d in parts" :key="d.value" :value="d.value">{{ d.text }}</a-select-option>
                </a-select>
            </div>
        </a-modal>
    </div>
</template>

<script>
import {useAuthStore} from "@/store/authStore";

let urlStart = '/api/job/'
import _ from 'lodash';
import TextEditor from '../../extras/TextEditor.vue'
import { h } from 'vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import customSelect from '../../extras/Select.vue'
export default {
    props: ['job', 'type', 'free_labour', 'labourRates', 'loading', 'quote'],
    setup() {
        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '32px',
            },
            spin: true,
        });
        return {
            indicator,
        };
    },
    mounted() {
        if(this.quote) {
            urlStart = '/api/quote/';
        }
        this.tuning_labour_rate = this.labourRates.tuning;
        this.workshop_labour_rate = this.labourRates.workshop;
        this.fab_labour_rate = this.labourRates.fabrication;
        this.bodyshop_labour_rate = this.labourRates.bodyshop;

        this.main_tuning_labour_rate = this.labourRates.tuning;
        this.main_workshop_labour_rate = this.labourRates.workshop;
        this.main_fab_labour_rate = this.labourRates.fabrication;
        this.main_bodyshop_labour_rate = this.labourRates.bodyshop;

        if(this.job.is_insurance) {

        } else {
            if(this.job[this.type+"_parts"] !== undefined && this.job[this.type+"_parts"].length === 0) {
                this.table_collapsed = true;
            }
        }

        //this.job.project_car = true;
    },
    components: {
        TextEditor,
        customSelect
    },
    data: () => ({
        showSelect: false,
        table_collapsed: false,
        tuning_labour_rate: 0,
        workshop_labour_rate: 0,
        fab_labour_rate: 0,
        bodyshop_labour_rate: 0,
        main_tuning_labour_rate: 0,
        main_workshop_labour_rate: 0,
        main_fab_labour_rate: 0,
        main_bodyshop_labour_rate: 0,
        staff: JSON.parse(localStorage.getItem('staff')),
        fetching: false,
        adding: false,
        addingTask: false,
        taskVal: '',
        parts: [],
        partSelectVal: null,
        deletingPart: false,
        compStockModal: false,
        swapPartModal: false,
        authorizingParts: false,
        current_comp_stock: [],
        partTypeOptions: [
            {
                id: "Workshop",
                name: "Workshop"
            },
            {
                id: "Fabrication",
                name: "Fabrication"
            },
            {
                id: "Tuning",
                name: "Tuning"
            },
            {
                id: "Bodyshop",
                name: "Bodyshop"
            },
            {
                id: "Extra",
                name: "Extra"
            }
        ],
        rowColor: "#fff",
        sortBy: "",
        sortDirectionAsc: true,
        swapPartData: null
    }),
    methods: {
        lab_rate(partType) {
            if (partType === "Workshop" || partType === "WorkshopEnd") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            } else if (partType === "Tuning") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.tuning_labour_rate;
                }
            } else if (partType === "Fabrication") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.fab_labour_rate
                }
            } else if (partType === "Bodyshop") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.bodyshop_labour_rate
                }
            } else {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            }

            /*if (this.type === "workshop" || this.type === "workshop_end") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            } else if (this.type === "tuning") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.tuning_labour_rate;
                }
            } else if (this.type === "fabrication") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.fab_labour_rate
                }
            } else if (this.type === "bodyshop") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.bodyshop_labour_rate
                }
            } else {
                return 0;
            }*/
        },
        fetchParts: _.debounce(function (val) {
            if (val.length >= 3) {
                this.parts = [];
                this.fetching = true;
                axios.get(api_url + '/v2/jobs/search/parts?term=' + val).then((resp) => {
                    this.parts = resp.data.results.map(part => ({
                        text: part.text,
                        value: part.id,
                    }));
                    this.fetching = false;
                }).catch(err => {
                    console.log(err)
                })
            }
        }, 1000),
        selectPart(value) {
            if(value.label !== 'undefined' && value.label !== undefined) {
                const val = value.label.children;
                this.partSelectVal = value
                this.addNewItem(val, this.c_type)
            }
        },

        selectPartToSwap(value) {
            const type = this.swapPartData.type;
            if (this.job.processed_to_lw === true) {
                alert("Job already processed to Linnworks!")
                return;
            }
            let val = value.label.children;
            let sku;
            if(val.includes(" - S -") || val.includes(" - M -") || val.includes(" - L -") || val.includes(" - XL -")) {
                val = val.split(" - ").slice(0,2).join(" - ");
                sku = val;
            } else {
                sku = val.substr(0, val.indexOf(' -'));
            }
            //const sku = val;
            let allow = true;
            let partType = null;
            if(type === "Workshop") {partType = "workshop_parts"}
            else if(type === "WorkshopEnd") {partType = "workshop_end_parts"}
            else if(type === "Tuning") {partType = "tuning_parts"}
            else if(type === "Fabrication") {partType = "fabrication_parts"}
            else if(type === "Bodyshop") {partType = "bodyshop_parts"}
            else if(type === "Unauthed") {partType = "unauthed_parts"}
            this.job[partType].forEach(element => {
                try {
                    if ((element.part_no && element.part_no !== "") && element.part_no.trim() === sku.trim()) {
                        alert("The item with the part number " + sku + " already exists on this job, update the quantity instead.");
                        allow = false;
                    }
                } catch (error) {
                    console.log(error)
                }
            });

            if (allow) {
                this.adding = true;
                axios.post(api_url + urlStart + this.job.id + '/swap/part', {
                    id: this.job.id,
                    value: sku,
                    type: type,
                    swap: true,
                    old_id: this.swapPartData.id
                }).then((resp) => {
                    if(resp.data.success) {
                        this.swapPartModal = false;
                        this.job[partType] = resp.data.data.parts;
                        this.job[partType].forEach((part, key) => {
                            part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                part.estimate_fixed = "Fixed";
                            }
                            this.job[partType][key].complete = Boolean(part.complete)
                            if (part.quantity === 0) {
                                part.quantity = 1;
                            }
                        });
                        this.table_collapsed = false;
                        //if (resp.data.data.msg !== '0.00' && resp.data.data.msg !== 0) {
                        //    alert(sku + " has a labour value of " + resp.data.data.msg + " please check this does not affect other items");
                        //}
                        this.$emit('update-audit');

                        this.adding = false;
                        const part = this.job[partType].find(el => el.id === this.swapPartData.id);
                        if(part) {
                            const index = this.job[partType].indexOf(part);
                            if (index !== -1) this.job[partType].splice(index, 1);
                        }
                        this.partSelectVal = null;
                        this.swapPartData = null;
                        this.parts = [];
                    } else {
                        this.$message.error('Failed to add task.');
                    }
                })
                .catch((error) => {
                    this.adding = false;
                    this.$message.error('Failed to add item - ' + error);
                })
            } else {
                this.adding = false;
                this.partSelectVal = null;
                this.swapPartData = null;
            }
        },
        costIsZero(cost) {
            return parseFloat(cost.toString().replace('£', '').replace(',', '')) === 0;
        },
        onDragUpdate(type) {
            const parts = [];
            this.job[type].forEach(part => {
                parts.push({
                    "id": part.id
                })
            });
            axios.post(api_url + urlStart + this.job.id + '/update/part/order', {
                parts: parts
            }).then(() => {
                this.$message.success('Updated parts sorting.');
            }).catch(() => {
                this.$message.error('Failed to update part sorting.');
            });
        },
        isComplete(part) {
            if (part.pendin_complete === true) {
                part.complete = true;
                return '#99ffc0';
            } else if (part.complete === true) {
                part.pendin_complete = true;
                return '#99ffc0';
            } else {
                return '#f9aaaa';
            }
        },
        utfEncode(s) {
            if (s !== null && s !== "" && s !== undefined) {
                s = s.replace(/Â£/g, '£');
                s = s.replace(/Â\s/g, '');
                s = s.replace(/Ã©/g, 'é');
                s = s.replace(/&quot;/g, '"');
                return s
            } else {
                return s;
            }
        },
        updatePartTitle(e, part) {
            // part.part_name = e.target.innerText;
            axios.post(api_url + urlStart + this.job.id + '/update/part/title', {
                part_id: part.id,
                value: e.target.innerText
            }).then(response => {
                if (response.data.success) {
                    this.$message.success('Updated parts title.');
                } else {
                    this.$message.error('Failed to update part title.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part title.');
            })
        },
        updatePartLabourHours(e, part) {
            part.labour_hours = e.target.textContent;
            axios.post(api_url + urlStart + this.job.id + '/update/part/labour-hours', {
                part_id: part.id,
                value: e.target.textContent,
            }).then(resp => {
                if(resp.data.success) {
                    this.$message.success('Updated parts labour hours.');
                } else {
                    this.$message.error('Failed to update part labour hours.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part labour hours.');
            })
        },
        updatePartBinrack(e, part) {
            part.binrack = e.target.textContent;
            axios.post(api_url + urlStart + this.job.id + '/update/part/binrack', {
                part_id: part.id,
                value: e.target.textContent
            }).then(resp => {
                if(resp.data.success) {
                    this.$message.success('Updated part location.');
                } else {
                    this.$message.error('Failed to update part location.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part location.');
            })
        },
        async updatePartPickStatus(e, part) {
            const value = e.target.textContent;
            let canContinue = false;
            if(value === 'Ordered' && part.part_no) {
                await axios.get(api_url + "/v2/inventory/stock?sku="+part.part_no).then(resp => {
                    if(resp.data.Levels) {
                        console.log(resp.data.Levels.Available)
                        if(resp.data.Levels.Available > -1) {
                            canContinue = true;
                        } else {
                            const conf = confirm("'Ordered' parts cannot be marked as 'Picked' unless there is Available stock / sufficient stock. Would you like to switch back to 'Not Picked'?")
                            //alert("'Ordered' parts cannot be marked as 'Picked' unless there is Available stock / sufficient stock");
                            canContinue = false;
                            if(conf) {
                                part.picked = "N";
                                axios.post(api_url + urlStart + this.job.id + '/update/part/picked', {
                                    part_id: part.id,
                                    value: "N",
                                }).then(resp => {
                                    if (resp.data.success) {
                                        this.$message.success('Updated parts pick status.');
                                    } else {
                                        this.$message.error('Failed to update part pick status.');
                                    }
                                }).catch(() => {
                                    this.$message.error('Failed to update part pick status.');
                                })
                            }
                        }
                    } else {
                        canContinue = true;
                    }
                }).catch(err => {
                    console.log(err)
                    alert("An error occured when getting for stock levels, speak to Matt");
                    canContinue = false;
                });
            } else {
                canContinue = true;
            }

            if(canContinue) {
                if (value === 'Not Picked') {
                    part.picked = 'Y';
                } else if (value === 'Picked') {
                    part.picked = 'O';
                } else if (value === 'Ordered') {
                    part.picked = 'N';
                }
                axios.post(api_url + urlStart + this.job.id + '/update/part/picked', {
                    part_id: part.id,
                    value: part.picked,
                }).then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Updated parts pick status.');
                    } else {
                        this.$message.error('Failed to update part pick status.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update part pick status.');
                })
            }
        },
        updatePartNotes(e, part) {
            part.note = e.event.target.innerHTML;
            axios.post(api_url + urlStart + this.job.id + '/update/part/note', {
                part_id: part.id,
                value: part.note,
            }).then(resp => {
                if(resp.data.success) {
                    this.$message.success('Updated part note.');
                } else {
                    this.$message.error('Failed to update part note.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part note.');
            });
        },
        updatePartCompletion(e, part) {
            part.complete = e.target.checked;
            part.pendin_complete = e.target.checked;
            axios.post(api_url + urlStart + this.job.id + '/update/part/completion', {
                part_id: part.id,
                value: part.complete,
            }).then(resp => {
                if(resp.data.success) {
                    if (part.complete === true) {
                        part.picked = 'Y';
                    }
                    this.$message.success('Updated part completion.');
                } else {
                    this.$message.error('Failed to update part completion.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part completion.');
            })
        },
        updateField(e, type, attr, part) {
            let found = null;
            let canUpdate = false;
            let model = null;
            if(type === 'workshop') {model = "workshop_parts"}
            if(type === 'tuning') {model = "tuning_parts"}
            if(type === 'fabrication') {model = "fabrication_parts"}
            if(type === 'bodyshop') {model = "bodyshop_parts"}
            if(type === 'workshop_end') {model = "workshop_end_parts"}
            if(this.job[model]) {
                found = this.job[model].findIndex(el => el.id === part.id);
            } else {
                found = -1
            }
            if (found > -1) {
                if (this.job[model][found][attr] === e.target.textContent) {
                    return
                } else {
                    if (this.job[model][found][attr] == null && e.target.textContent === "") {
                        return;
                    }
                    canUpdate = true;
                }
            }

            if (canUpdate) {
                axios.post(api_url + urlStart + this.job.id + '/update/part/sku', {
                    part_id: part.id,
                    value: e.target.textContent,
                }).then(resp => {
                    if(resp.data.success) {
                        this.$message.success('Updated SKU.');
                    } else {
                        this.$message.error('Failed to update SKU.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update SKU.');
                })
            }
        },
        updatePartQuantity(e, part) {
            part.quantity = e.target.textContent;
            if (part.quantity > 0) {
                part.picked = 'N';
                axios.post(api_url + urlStart + this.job.id + '/update/part/quantity', {
                    part_id: part.id,
                    value: part.quantity,
                }).then(resp => {
                    if(resp.data.success) {
                        this.$message.success('Updated part quantity.');
                        if(!this.quote) {
                            axios.post(api_url + urlStart + this.job.id + '/update/part/picked', {
                                part_id: part.id,
                                value: part.picked,
                            }).then(response => {
                                if (response.data.success) {
                                    this.$message.success('Updated parts pick status.');
                                } else {
                                    this.$message.error('Failed to update part pick status.');
                                }
                            }).catch(() => {
                                this.$message.error('Failed to update part pick status.');
                            });
                        }
                    } else {
                        this.$message.error('Failed to update part quantity.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update part quantity.');
                })
            }
        },
        updatePartOwner(e, part) {
            axios.post(api_url + urlStart + this.job.id + '/update/part/owner', {
                part_id: part.id,
                value: e,
            }).then(resp => {
                if(resp.data.success) {
                    this.$message.success('Updated part owner.');
                } else {
                    this.$message.error('Failed to update part owner.');
                }
            }).catch(() => {
                this.$message.error('Failed to update part owner.');
            })
        },
        updatePartType(part) {
            const value = part.type;
            const id = part.id;
            axios.post(api_url + urlStart + this.job.id + '/update/part/type', {
                part_id: id,
                value: value,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Updated part type, reloading...');
                    location.reload();
                } else {
                    this.$message.error('Failed to Update part type');
                }
            }).catch((error) => {
                this.$message.error('Failed to Update part type ' + error);
            });
        },
        updateCompPickedStatus(id, picked) {
            axios.post(api_url + urlStart + this.job.id + '/composition/stock/picked', {
                part_id: id,
                status: picked,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Updated pick status');
                } else {
                    this.$message.error('Failed to Update pick status');
                }
            }).catch((error) => {
                this.$message.error('Failed to Update pick status ' + error);
            });
        },
        updateCompOrderedStatus(id, ordered) {
            axios.post(api_url + urlStart + this.job.id + '/composition/stock/ordered', {
                part_id: id,
                status: ordered,
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Updated ordered status');
                } else {
                    this.$message.error('Failed to Update ordered status');
                }
            }).catch((error) => {
                this.$message.error('Failed to Update ordered status ' + error);
            });
        },
        addNewItem(val, type, swap = false, old_id = null) {
            if (this.job.processed_to_lw === true) {
                alert("Job already processed to Linnworks!")
                return;
            }
            let sku;
            if(val.includes(" - S -") || val.includes(" - M -") || val.includes(" - L -") || val.includes(" - XL -")) {
                //let sku = val.substr(0, val.indexOf(' -'));
                val = val.split(" - ").slice(0,2).join(" - ");
                sku = val;
            } else {
                sku = val.substr(0, val.indexOf(' -'));
            }
            //const sku = val;
            let allow = true;
            let partType = null;
            if(type === "Workshop") {partType = "workshop_parts"}
            else if(type === "WorkshopEnd" || type === "Workshop End") {partType = "workshop_end_parts"}
            else if(type === "Tuning") {partType = "tuning_parts"}
            else if(type === "Fabrication") {partType = "fabrication_parts"}
            else if(type === "Bodyshop") {partType = "bodyshop_parts"}
            else if(type === "Unauthed") {partType = "unauthed_parts"}
            this.job[partType].forEach(async element => {
                try {
                    if ((element.part_no && element.part_no !== "") && element.part_no.trim() === sku.trim()) {
                        allow = false;
                        await alert("The item with the part number " + sku + " already exists on this job, update the quantity instead.");
                        if(element.id) {
                            const el = document.getElementById('tr-'+element.id);
                            const y = el.getBoundingClientRect().top + window.pageYOffset + -200;
                            window.scrollTo({top: y, behavior: 'smooth'});
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            });

            if (allow) {
                this.adding = true;
                axios.post(api_url + urlStart + this.job.id + '/add/part', {
                        id: this.job.id,
                        value: sku,
                        type: type,
                        swap: swap,
                        old_id: old_id
                    }).then((resp) => {
                        if(resp.data.success) {
                            this.job[partType] = resp.data.data.parts;
                            this.job[partType].forEach((part, key) => {
                                part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP'
                                });
                                part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP'
                                });
                                if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                    part.estimate_fixed = "Fixed";
                                }
                                this.job[partType][key].complete = Boolean(part.complete)
                                if (part.quantity === 0) {
                                    part.quantity = 1;
                                }
                            });
                            this.table_collapsed = false;
                            if (resp.data.data.msg !== '0.00' && resp.data.data.msg !== 0) {
                                alert(sku + " has a labour value of " + resp.data.data.msg + " please check this does not affect other items");
                            }
                            this.$emit('update-audit');
                            this.adding = false;
                            this.partSelectVal = undefined
                        } else {
                            this.$message.error('Failed to add task.');
                        }
                    })
                    .catch((error) => {
                        this.adding = false;
                        this.$message.error('Failed to add item - ' + error);
                    })
            } else {
                this.adding = false;
                this.partSelectVal = undefined
            }
        },
        addNewTask(event, type) {
            if (event.target.value !== '') {
                if (this.job.processed_to_lw === true) {
                    alert("Job already processed to Linnworks!")
                    return;
                }
                let partType = null;
                if(type === "Workshop") {partType = "workshop_parts"}
                else if(type === "WorkshopEnd") {partType = "workshop_end_parts"}
                else if(type === "Tuning") {partType = "tuning_parts"}
                else if(type === "Fabrication") {partType = "fabrication_parts"}
                else if(type === "Bodyshop") {partType = "bodyshop_parts"}
                else if(type === "Unauthed") {partType = "unauthed_parts"}
                else if(type === "Extra") {partType = "extra_parts"}

                if(type === "Workshop End") {
                    type = "WorkshopEnd"
                }
                this.addingTask = true;
                const value = event.target.value;
                axios.post(api_url + urlStart + this.job.id + '/add/task', {
                    value: value,
                    type: type
                }).then((resp) => {
                    if(resp.data.success) {
                        this.job[partType] = resp.data.data;
                        this.job[partType].forEach((part, key) => {
                            part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                part.estimate_fixed = "Fixed";
                            }
                            this.job[partType][key].complete = Boolean(part.complete)
                            if (part.quantity === 0) {
                                part.quantity = 1;
                            }
                        });
                        this.table_collapsed = false;

                        this.taskVal = null
                        this.addingTask = false;
                        this.$message.success('Added Task');
                        this.$emit('update-audit');
                    } else {
                        this.$message.error('Failed to add task.');
                    }
                }).catch((error) => {
                    this.addingTask = false;
                    this.$message.error('Failed to add task - ' + error + ' - try again.');
                });
            }
        },
        deletePart(type, part) {
            this.deletingPart = part.id;
            let sku = part.part_no;
            let lin_serv_item = false;
            if (part.part_no === "") {
                sku = part.part_name;
                lin_serv_item = true;
            }
            if(part.labour_hours > 0) {
                confirm("This part has " + part.labour_hours + " labour hours. If re-adding part, make sure to fill in correct labour hours");
            }

            axios.post(api_url + urlStart + this.job.id + '/update/part/delete', {
                part_id: part.id,
                sku: sku,
            }).then(response => {
                if (response.data.success) {
                    this.$message.success('Part Deleted.');
                    let model = null;
                    if(type === "workshop") {model = "workshop_parts"}
                    if(type === "workshop_end") {model = "workshop_end_parts"}
                    if(type === "tuning") {model = "tuning_parts"}
                    if(type === "fabrication") {model = "fabrication_parts"}
                    if(type === "bodyshop") {model = "bodyshop_parts"}
                    if(type === "unauthed") {model = "unauthed_parts"}

                    const index = this.job[model].indexOf(part);
                    if (index !== -1) this.job[model].splice(index, 1);
                    this.$emit('update-audit');
                } else {
                    this.$message.error('Failed to delete part.');
                }
                this.deletingPart = false;
            }).catch(() => {
                this.deletingPart = false;
                this.$message.error('Failed to delete part.');
            })
        },
        focusOut(type, key, model, e) {
            let part = null;
            let table = null;
            if(type === "workshop") {table = 'workshop_parts';}
            if(type === "workshop_end") {table = 'workshop_end_parts';}
            if(type === "fabrication") {table = 'fabrication_parts';}
            if(type === "tuning") {table = 'tuning_parts';}
            if(type === "unauthed") {table = 'unauthed_parts';}
            if(type === "bodyshop") {table = 'bodyshop_parts';}
            const prt = this.job[table].find(el => el.id === key);
            prt[model] = e.target.textContent;
            part = prt;

            if (model === "cost_per_part") {
                axios.post(api_url + urlStart + this.job.id + '/update/part/cost', {
                    part_id: part.id,
                    value: part.cost_per_part.toString().replace('£', '').replace(',', '')
                }).then(resp => {
                    if(resp.data.success) {
                        this.$message.success('Updated parts cost.');
                    } else {
                        this.$message.error('Failed to update part cost.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update part cost.');
                });
            }

            if (model === "purchase_price") {
                axios.post(api_url + urlStart + this.job.id + '/update/part/retail', {
                    part_id: part.id,
                    value: part.purchase_price.toString().replace('£', '').replace(',', '')
                }).then(resp => {
                    if(resp.data.success) {
                        this.$message.success('Updated parts retail.');
                    } else {
                        this.$message.error('Failed to update part retail.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update part retail.');
                });
            }
        },
        focusIn(type, key, model) {
            if (type === "workshop") {
                this.job.workshop_parts[key][model] = this.job.workshop_parts[key][model].toString().replace('£', '');
            }

            if (type === "fabrication") {
                this.job.fabrication_parts[key][model] = this.job.fabrication_parts[key][model].toString().replace('£', '');
            }

            if (type === "tuning") {
                this.job.tuning_parts[key][model] = this.job.tuning_parts[key][model].toString().replace('£', '');
            }

            if (type === "workshop_end") {
                this.job.workshop_end_parts[key][model] = this.job.workshop_end_parts[key][model].toString().replace('£', '');
            }
        },
        blurField(event) {
            event.target.blur();
        },
        getCompositionStock(id, sku, e) {
            e.target.innerText = "Getting Stock...";
            e.target.disabled = true;
            axios.post(api_url + urlStart + this.job.id + '/composition/stock', {
                sku: sku,
                part_id: id
            }).then((resp) => {
                this.current_comp_stock = resp.data.data;
                this.compStockModal = true;
                e.target.innerText = "Composition Stock";
                e.target.disabled = false;
            })
        },
        modalClosed() {
            this.current_comp_stock = [];
            this.compStockModal = false;
        },
        swapPartModalClosed() {
            this.swapPartData = null;
            this.swapPartModal = false;
        },
        swapPart(part) {
            this.swapPartData = part;
            this.swapPartModal = true;
        },
        setBcCheckbox(part) {
            axios.post(api_url + urlStart + this.job.id + '/update/part/bc-check', {
                part_id: part.id,
                checked: part.bc_checkbox
            }).then(() => {
                //this.$message.success('Part Saved!.', { icon: 'check' });
            }).catch(() => {
                this.$message.error('Failed to update part.');
            });
        },
        setBCLabourCheckbox(part) {
            axios.post(api_url + urlStart + this.job.id + '/update/part/bc-labour-check', {
                part_id: part.id,
                checked: part.bc_labour_checkbox
            }).then(() => {
                //this.$message.success('Part Saved!.', { icon: 'check' });
            }).catch(() => {
                this.$message.error('Failed to update part.');
            });
        },
        authoriseSelected() {
            const ready_to_auth = [];
            this.job.unauthed_parts.forEach(part => {
                if (part.authed === true) {
                    ready_to_auth.push(part);
                }
            });
            if (ready_to_auth.length > 0) {
                this.authorizingParts = true;
                axios.post(api_url + urlStart + this.job.id + '/update/unauthed', {
                    parts: ready_to_auth
                }).then(resp => {
                    if(resp.data.success) {
                        this.$message.success('Updated unauthed parts.');
                        this.$emit('reload');
                    } else {
                        this.$message.error('Failed to update unauthed parts.');
                    }
                    this.authorizingParts = false;
                }).catch(() => {
                    this.$message.error('Failed to update unauthed parts.');
                    this.authorizingParts = false;
                });
            }
        },
        totalUnauthedCost() {
            let cost = 0;
            this.job.unauthed_parts.forEach(part => {
                cost += parseFloat(part.purchase_price.toString().replace('£', '').replace(',', '')) * part.quantity
            });
            return "£" + cost.toFixed(2);
        },
        totalUnauthedRetail() {
            let cost = 0;
            this.job.unauthed_parts.forEach(part => {
                cost += parseFloat(part.cost_per_part.toString().replace('£', '').replace(',', '')) * part.quantity
            });
            return "£" + cost.toFixed(2);
        },
        totalUnauthedIncLabour() {
            const retail = parseFloat(this.totalUnauthedRetail().toString().replace('£', '').replace(',', ''));
            const labour = parseFloat(this.totalUnauthedLabour().toString().replace('£', '').replace(',', ''));
            return "£" + (retail + labour).toFixed(2);
        },
        totalUnauthedLabour() {
            let cost = 0;
            this.job.unauthed_parts.forEach(part => {
                let price = part.labour_hours * this.workshop_labour_rate;
                cost += price
            });
            return "£" + cost.toFixed(2);
        },
        sortTable(e) {
            if(e.sortable) {
                let cancelSort = false;
                if (this.sortBy === e.key) {
                    if(!this.sortDirectionAsc) {
                        cancelSort = true;
                    } else {
                        this.sortDirectionAsc = !this.sortDirectionAsc;
                    }
                } else {
                    this.sortBy = e.key;
                    this.sortDirectionAsc = true;
                }

                if(!cancelSort) {
                    const vm = this;
                    this.job[this.type + '_parts'] = this.job[this.type + '_parts'].sort(function (a, b) {
                      let sortA = a[vm.sortBy];
                      let sortB = b[vm.sortBy];
                      if(typeof sortA === "string") {
                        sortA = sortA.toLowerCase()
                      }
                      if(typeof sortB === "string") {
                        sortB = sortB.toLowerCase()
                      }

                      return vm.sortDirectionAsc ? (sortA === null) - (sortB === null) || +(sortA > sortB) || -(sortA < sortB) : (sortA === null) - (sortB === null) || -(sortA > sortB) || +(sortA < sortB);
                    });

                    /*if(this.sortBy === "binrack") {
                      this.job[this.type + '_parts'] = this.job[this.type + '_parts'].sort(function (a, b) {
                        const sortA = a[vm.sortBy];
                        const sortB = b[vm.sortBy];
                        return vm.sortDirectionAsc ? (sortA === null) - (sortB === null) || +(sortA > sortB) || -(sortA < sortB) : (sortA === null) - (sortB === null) || -(sortA > sortB) || +(sortA < sortB);
                      });
                    } else {
                      this.job[this.type + '_parts'] = this.job[this.type + '_parts'].sort(function (a, b) {
                        if (typeof a[vm.sortBy] === "boolean") {
                          const compare = a[vm.sortBy] - b[vm.sortBy];
                          return vm.sortDirectionAsc ? compare : -compare;
                        }


                        if (typeof a[vm.sortBy] === "string") {
                          const compare = (a[vm.sortBy] > b[vm.sortBy]) ? 1 : ((b[vm.sortBy] > a[vm.sortBy]) ? -1 : 0)
                          return vm.sortDirectionAsc ? compare : -compare;
                        }
                      });
                    }*/
                } else {
                    this.sortBy = "";
                    this.sortDirectionAsc = true;
                    this.$emit('reload-parts');
                }
            }
        },
    },
    computed: {
        table_headers() {
            if(this.quote) {
                return [
                    {
                        title: "Reorder",
                        key: "reorder",
                        dataIndex: "reorder",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Task",
                        key: "part_name",
                        dataIndex: "part_name",
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: "Retail",
                        key: "cost_per_part",
                        dataIndex: "cost_per_part",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Cost",
                        key: "purchase_price",
                        dataIndex: "purchase_price",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Part No",
                        key: "part_no",
                        dataIndex: "part_no",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Qty",
                        key: "quantity",
                        dataIndex: "quantity",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        key: "total_with_qty",
                        dataIndex: "total_with_qty",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Labour",
                        key: "labour_hours",
                        dataIndex: "labour_hours",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total Labour",
                        key: "total_labour",
                        dataIndex: "total_labour",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        key: "total_cost",
                        dataIndex: "total_cost",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Notes",
                        key: "note",
                        dataIndex: "note",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Remove",
                        key: "remove",
                        dataIndex: "remove",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Location",
                        key: "binrack",
                        dataIndex: "binrack",
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: "In Stock",
                        key: "stocks",
                        dataIndex: "stocks",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Type",
                        key: "type",
                        dataIndex: "type",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Comp Stock",
                        key: "comp_stock",
                        dataIndex: "comp_stock",
                        align: 'center',
                        sortable: false
                    },
                ]
            }

            let partCheck = {
                title: "Part Check",
                align: 'center',
                key: "part_check",
                dataIndex: "part_check",
            }
            let labourCheck = {
                title: "Labour Check",
                align: 'center',
                key: "labour_check",
                dataIndex: "labour_check",
            }
            if (this.type === 'unauthed') {
                let unauthedHeaders = [
                    {
                        title: "Auth",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Task",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Retail",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Cost",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Part No",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Qty",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Labour",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total Labour",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Completed",
                        align: 'center',
                        sortable: false,
                        dataField: 'pendin_complete'
                    },
                    {
                        title: "Notes",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Remove",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Owner",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Status",
                        align: 'center',
                        sortable: false,
                        width: '60px'
                    },
                    {
                        title: "Location",
                        align: 'center',
                        sortable: true
                    },
                    {
                        title: "In Stock",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Type",
                        align: 'center',
                        sortable: false
                    },
                ]
                if(this.job.project_car === true) {
                    unauthedHeaders.splice(2, 1);
                }
                return unauthedHeaders;
            }
            let headers =  [
                {
                    title: "Reorder",
                    key: "reorder",
                    dataIndex: "reorder",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Task",
                    key: "part_name",
                    dataIndex: "part_name",
                    align: 'center',
                    sortable: true
                },
                {
                    title: "Retail",
                    key: "cost_per_part",
                    dataIndex: "cost_per_part",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Cost",
                    key: "purchase_price",
                    dataIndex: "purchase_price",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Part No",
                    key: "part_no",
                    dataIndex: "part_no",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Qty",
                    key: "quantity",
                    dataIndex: "quantity",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Total",
                    key: "total_with_qty",
                    dataIndex: "total_with_qty",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Labour",
                    key: "labour_hours",
                    dataIndex: "labour_hours",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Total Labour",
                    key: "total_labour",
                    dataIndex: "total_labour",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Total",
                    key: "total_cost",
                    dataIndex: "total_cost",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Completed",
                    key: "complete",
                    dataIndex: "complete",
                    align: 'center',
                    sortable: true
                },
                {
                    title: "Notes",
                    key: "note",
                    dataIndex: "note",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Remove",
                    key: "remove",
                    dataIndex: "remove",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Owner",
                    key: "owner",
                    dataIndex: "owner",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Status",
                    key: "picked",
                    dataIndex: "picked",
                    align: 'center',
                    width: '60px',
                    sortable: true
                },
                {
                    title: "Location",
                    key: "binrack",
                    dataIndex: "binrack",
                    align: 'center',
                    sortable: true
                },
                {
                    title: "In Stock",
                    key: "stocks",
                    dataIndex: "stocks",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Unauth",
                    key: "unauth_checkbox",
                    dataIndex: "unauth_checkbox",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Type",
                    key: "type",
                    dataIndex: "type",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Comp Stock",
                    key: "comp_stock",
                    dataIndex: "comp_stock",
                    align: 'center',
                    sortable: false
                },
                {
                    title: "Swap Part",
                    key: "swap_part",
                    dataIndex: "swap_part",
                    align: 'center',
                    sortable: false
                }
            ]
            if(this.showSelect && !this.job.project_car) {
                headers.splice(1, 0, partCheck);
                headers.splice(9, 0, labourCheck);
            }

            if (this.job.project_car === true) {
                headers.splice(2, 1);
                headers.splice(16, 1);
                if(this.showSelect) {
                    headers.splice(1, 0, partCheck);
                    headers.splice(8, 0, labourCheck);
                }
            }
            return headers;

            /*if (this.type === 'unauthed') {
                return [
                    {
                        title: "Auth",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Task",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Retail",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Cost",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Part No",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Qty",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Labour",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total Labour",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Total",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Completed",
                        align: 'center',
                        sortable: false,
                        dataField: 'pendin_complete'
                    },
                    {
                        title: "Notes",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Remove",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Owner",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Status",
                        align: 'center',
                        sortable: false,
                        width: '60px'
                    },
                    {
                        title: "Location",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "In Stock",
                        align: 'center',
                        sortable: false
                    },
                    {
                        title: "Type",
                        align: 'center',
                        sortable: false
                    },
                ]
            } else {
                if (this.job.project_car === false) {
                    if (this.showSelect) {
                        return [
                            {
                                title: "Reorder",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Part Check",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Task",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Retail",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Cost",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Part No",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Qty",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Labour",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Labour Check",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total Labour",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Completed",
                                align: 'center',
                                sortable: false,
                                dataField: 'pendin_complete'
                            },
                            {
                                title: "Notes",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Remove",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Owner",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Status",
                                align: 'center',
                                sortable: false,
                                width: '60px'
                            },
                            {
                                title: "Location",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "In Stock",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Unauth",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Type",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Comp Stock",
                                align: 'center',
                                sortable: false
                            }
                        ]
                    }
                    return [
                        {
                            title: "Reorder",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Task",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Retail",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Cost",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Part No",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Qty",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Total",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Labour",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Total Labour",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Total",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Completed",
                            align: 'center',
                            sortable: false,
                            dataField: 'pendin_complete'
                        },
                        {
                            title: "Notes",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Remove",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Owner",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Status",
                            align: 'center',
                            sortable: false,
                            width: '60px'
                        },
                        {
                            title: "Location",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "In Stock",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Unauth",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Type",
                            align: 'center',
                            sortable: false
                        },
                        {
                            title: "Comp Stock",
                            align: 'center',
                            sortable: false
                        }
                    ]
                } else {
                    if (this.showSelect) {
                        return [
                            {
                                title: "Reorder",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Part Check",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Task",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Cost",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Part No",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Qty",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Labour",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Labour Check",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total Labour",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Total",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Completed",
                                align: 'center',
                                sortable: false,
                                dataField: 'pendin_complete'
                            },
                            {
                                title: "Notes",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Remove",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Owner",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Status",
                                align: 'center',
                                sortable: false,
                                width: '60px'
                            },
                            {
                                title: "Location",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "In Stock",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Unauth",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Type",
                                align: 'center',
                                sortable: false
                            },
                            {
                                title: "Comp Stock",
                                align: 'center',
                                sortable: false
                            }
                        ]
                    }
                    return [
                        {
                            title: "Reorder",
                            align: 'center',
                            sortable: false,
                            key: "reorder",
                            slots: {
                                customRender: 'reorder',
                            }
                        },
                        {
                            title: "Task",
                            align: 'center',
                            sortable: false,
                            key: "task",
                            slots: {
                                customRender: 'task',
                            }
                        },
                        {
                            title: "Cost",
                            align: 'center',
                            sortable: false,
                            key: "cost",
                            slots: {
                                customRender: 'cost',
                            }
                        },
                        {
                            title: "Part No",
                            align: 'center',
                            sortable: false,
                            key: "part_no",
                            slots: {
                                customRender: 'part_no',
                            }
                        },
                        {
                            title: "Qty",
                            align: 'center',
                            sortable: false,
                            key: "quantity",
                            slots: {
                                customRender: 'quantity',
                            }
                        },
                        {
                            title: "Total",
                            align: 'center',
                            sortable: false,
                            key: "total_cost",
                            slots: {
                                customRender: 'total_cost',
                            }
                        },
                        {
                            title: "Labour",
                            align: 'center',
                            sortable: false,
                            key: "labour",
                            slots: {
                                customRender: 'labour',
                            }
                        },
                        {
                            title: "Total Labour",
                            align: 'center',
                            sortable: false,
                            key: "total_labour",
                            slots: {
                                customRender: 'total_labour',
                            }
                        },
                        {
                            title: "Total",
                            align: 'center',
                            sortable: false,
                            key: "total_cost_with_labour",
                            slots: {
                                customRender: 'total_cost_with_labour',
                            }
                        },
                        {
                            title: "Completed",
                            align: 'center',
                            sortable: false,
                            dataField: 'pendin_complete',
                            key: "completed",
                            slots: {
                                customRender: 'completed',
                            }
                        },
                        {
                            title: "Notes",
                            align: 'center',
                            sortable: false,
                            key: "notes",
                            slots: {
                                customRender: 'notes',
                            }
                        },
                        {
                            title: "Remove",
                            align: 'center',
                            sortable: false,
                            key: "remove",
                            slots: {
                                customRender: 'remove',
                            }
                        },
                        {
                            title: "Owner",
                            align: 'center',
                            sortable: false,
                            key: "owner",
                            slots: {
                                customRender: 'owner',
                            }
                        },
                        {
                            title: "Status",
                            align: 'center',
                            sortable: false,
                            width: '60px',
                            key: "status",
                            slots: {
                                customRender: 'status',
                            }
                        },
                        {
                            title: "Location",
                            align: 'center',
                            sortable: false,
                            key: "location",
                            slots: {
                                customRender: 'location',
                            }
                        },
                        {
                            title: "In Stock",
                            align: 'center',
                            sortable: false,
                            key: "in_stock",
                            slots: {
                                customRender: 'in_stock',
                            }
                        },
                        {
                            title: "Unauth",
                            align: 'center',
                            sortable: false,
                            key: "unauth",
                            slots: {
                                customRender: 'unauth',
                            }
                        },
                        {
                            title: "Type",
                            align: 'center',
                            sortable: false,
                            key: "type",
                            slots: {
                                customRender: 'type',
                            }
                        },
                        {
                            title: "Comp Stock",
                            align: 'center',
                            sortable: false,
                            key: "comp_stock",
                            slots: {
                                customRender: 'comp_stock',
                            }
                        }
                    ]
                }
            }*/
        },
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
        estimate_fixed() {
            if (this.job.estimate_fixed !== "Fixed" && this.job.estimate_fixed !== "Estimate") {
                return "Fixed";
            } else {
                return this.job.estimate_fixed;
            }
        },
        c_type() {
            return _.startCase(_.camelCase(this.type));//_.capitalize(_.startCase(this.type));
        },
    },
    watch: {
        job(val) {
            if (val) {
                if (val[this.type + '_parts'].length === 0) {
                    this.table_collapsed = true;
                }
            }
        },
        free_labour(val) {
            if (val) {
                this.tuning_labour_rate = 0
                this.workshop_labour_rate = 0
                this.fab_labour_rate = 0
                this.bodyshop_labour_rate = 0
            } else {
                this.tuning_labour_rate = this.main_tuning_labour_rate;
                this.workshop_labour_rate = this.main_workshop_labour_rate;
                this.fab_labour_rate = this.main_fab_labour_rate;
                this.bodyshop_labour_rate = this.main_bodyshop_labour_rate;
            }
            this.$forceUpdate();
        },
        labourRates(val) {
            this.tuning_labour_rate = val.tuning;
            this.workshop_labour_rate = this.job.job_detail.lab_rate || val.workshop;
            this.fab_labour_rate = val.fabrication;
            this.bodyshop_labour_rate = val.bodyshop;

            this.main_tuning_labour_rate = val.tuning;
            this.main_workshop_labour_rate = val.workshop;
            this.main_fab_labour_rate = val.fabrication;
            this.main_bodyshop_labour_rate = val.bodyshop;
        }
    }
}
</script>
<style lang="scss">
.part_table_header {
    background: #f9fafb;
    color: rgba(0, 0, 0, .6);
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
    user-select: none;
    font-size: .75rem;
    height: 48px;
    padding: 0 16px;
    text-align: center;

    &:not(:last-of-type) {
        border-right: 1px solid rgba(0, 0, 0, .12);
    }
}

.parts_table tbody tr {
    td {
        border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
        font-size: .875rem;
        height: 48px;
        padding: 0 16px;

        &:not(:last-of-type) {
            border-right: 1px solid rgba(0, 0, 0, .12);
        }
    }
}

.icon.isReverse {
    transform: rotate(180deg);
}

.ant-table-measure-row {
    display: none;
}
.ant-table-thead {
    .ant-table-cell {
        background: #f9fafb;
        color: rgba(0, 0, 0, .6);
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 12%);
        user-select: none;
        font-size: .75rem;
        height: 48px;
        padding: 0 16px;
        text-align: center;
        border-left:0;
        border-top:0;
        border-bottom:0;

        &:not(:last-of-type) {
            border-right: 1px solid rgba(0, 0, 0, .12);
        }
    }
}
.ant-table-row {
    .ant-table-cell {
        border-bottom: 1px solid rgba(0, 0, 0, .1) !important;
        font-size: .875rem;
        height: 48px;
        padding: 0 16px;
        border-left:0;
        border-top:0;
        border-bottom:0;
        &:not(:last-of-type) {
            border-right: 1px solid rgba(0, 0, 0, .12);
        }
    }
}

.partStatus.picked, .partStatus.notPicked, .partStatus.ordered {
    /*border: 1px solid black !important;*/
    cursor: pointer;
    font-size: 12px;
    text-align: center;
}

.partStatus.picked, td.in-stock {
    background-color: #99ffc0;
}

.partStatus.notPicked {
    background-color: #ffb2b2;
}

.partStatus.ordered {
    background-color: #ffdcb2;
}

tr.complete {
    background-color: #99ffc0;
}
tr.incomplete {
    background-color: #f9aaaa;
}
.loading-container {
    @apply absolute top-0 left-0 w-full h-full flex items-center justify-center z-50;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,0.4);
    }
}
</style>
