<template>
    <div v-if="job">
        <div class="p-4">
            <h4 class="font-semibold text-base">Missing Tuning Fields</h4>
            <ul class="list">
                <li v-for="field in missingFields" v-bind:key="field">{{field}}</li>
            </ul>
        </div>

        <job-checklists :job="job"></job-checklists>

        <div class="border-t border-gray-300 audit-log">
            <job-audit ref="audit" :job="job"></job-audit>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref} from 'vue';
import JobAudit from '../AuditLog.vue';
import jobChecklists from './Checklists.vue';
export default defineComponent({
    props: ['job', 'labourRates'],
    components: {
        JobAudit,
        jobChecklists
    },
    data() {
        return {
            tuning_labour_rate: 0,
            workshop_labour_rate: 0,
            fab_labour_rate: 0,
            bodyshop_labour_rate: 0,
            main_labour_costs: {
                tuning_labour_rate: 0,
                workshop_labour_rate: 0,
                fab_labour_rate: 0,
                bodyshop_labour_rate: 0,
            }
        }
    },
    setup() {
        const containerRef = ref();
        return {
            containerRef,
        };
    },
    mounted() {
    },
    computed: {
        missingFields() {
            const fields = [];
            if (this.job) {
                const veh = this.job.vehicle;
                if (veh) {
                    if (veh.enginecode == null || veh.enginecode === "") {
                        fields.push('Engine Code')
                    }
                    if (veh.customer_expected_power == null || veh.customer_expected_power === "") {
                        fields.push('Expected Power')
                    }
                    if (veh.customer_expected_peak_torque == null || veh.customer_expected_peak_torque === "") {
                        fields.push('Expected Torque')
                    }
                    if (veh.boost_level == null || veh.boost_level === "") {
                        fields.push('Boost Level')
                    }
                    if (veh.final_power == null || veh.final_power === "") {
                        fields.push('Final Power')
                    }
                    if (veh.final_torque == null || veh.final_torque === "") {
                        fields.push('Final Torque')
                    }
                    if (veh.timing == null || veh.timing === "") {
                        fields.push('Timing')
                    }
                    if (veh.actuator_set == null || veh.actuator_set === "") {
                        fields.push('Actuator Setting')
                    }
                    if (veh.tuning_note == null || veh.tuning_note === "") {
                        fields.push('Tuning Note')
                    }
                    if (veh.tuning_file == null || veh.tuning_file === "") {
                        fields.push('Tuning File')
                    }
                    if (veh.tuning_folder == null || veh.tuning_folder === "") {
                        fields.push('Tuning Folder')
                    }
                    if (veh.ecu == null || veh.ecu === "") {
                        fields.push('ECU')
                    }
                    if (veh.ecu_type == null || veh.ecu_type === "") {
                        fields.push('ECU Type')
                    }
                    if (veh.customer_ecu_software == null || veh.customer_ecu_software === "") {
                        fields.push('ECU Software')
                    }
                    if (veh.customer_ecu_version == null || veh.customer_ecu_version === "") {
                        fields.push('Software Version')
                    }
                    if (veh.duration == null || veh.duration === "") {
                        fields.push('Duration')
                    }
                }
            }
            return fields;
        },
    },
    methods: {
        totalExVat() {
            let total;
            if (this.job.project_car === false) {
                total = (this.labourTotal() + this.partsTotal()) - this.job.deposit - this.getAdditionalDiscounts();
                return total
            } else {
                total = (this.labourTotal() + this.partsTotal()) - this.getAdditionalDiscounts();
                return total;
            }
        },
        totalToPay() {
            return this.totalExVat() * 1.2;
        },
        partsTotal() {
            return this.getTotals('tuning') +
                this.getTotals('workshop') +
                this.getTotals('fabrication') +
                this.getTotals('workshop_end') +
                this.getTotals('bodyshop') -
                this.job.job_detail.parts_disc;
        },
        labourTotal() {
            let total = 0;
            if (this.job.project_car === false) {
                this.job.workshop_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.tuning_parts.forEach(part => {
                    total += (part.labour_hours * this.tuning_labour_rate)
                });

                this.job.fabrication_parts.forEach(part => {
                    total += (part.labour_hours * this.fab_labour_rate)
                });

                this.job.workshop_end_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.bodyshop_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });
                total = total - this.job.job_detail.lab_disc;
            } else {
                if (this.job.times.total_time) {
                    if (this.job.times.total_time.time) {
                        const split = this.job.times.total_time.time.split(':');
                        const hours = split[0];
                        const minutes = split[1];
                        const project_lab_rate = 0;
                        const time = parseFloat(parseFloat(hours) + "." + parseFloat(minutes));
                        return project_lab_rate * time;
                    }
                }
            }
            //this.labourTotal = total;
            return total;
        },
        getTotals(type) {
            let model;
            if(type === "workshop") {model = "workshop_parts";}
            if(type === "workshop_end") {model = "workshop_end_parts";}
            if(type === "tuning") {model = "tuning_parts";}
            if(type === "fabrication") {model = "fabrication_parts";}
            if(type === "bodyshop") {model = "bodyshop_parts";}
            let total = 0;
            this.job[model].forEach(part => {
                if (this.job.project_car === false) {
                    total += (part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity)
                } else {
                    total += (part.purchase_price.toString().replace('£','').replace(',','') * part.quantity)
                }
            });
            return total;
        },
        updateAuditLog() {
            if(this.$refs.audit !== undefined) this.$refs.audit.updateAuditLog()
        },
        getAdditionalDiscounts() {
            let count = 0;
            this.job.discounts.forEach(element => {
                count += element.amount;
            });
            return count;
        },
    },
    watch: {
        labourRates(val) {
            this.tuning_labour_rate = val.tuning;
            this.workshop_labour_rate = val.workshop;
            this.fab_labour_rate = val.fabrication;
            this.bodyshop_labour_rate = val.bodyshop;

            this.main_labour_costs.tuning_labour_rate = val.tuning;
            this.main_labour_costs.workshop_labour_rate = val.workshop;
            this.main_labour_costs.fab_labour_rate = val.fabrication;
            this.main_labour_costs.bodyshop_labour_rate = val.bodyshop;
        }
    }
});
</script>

<style lang="scss">
.audit-log {
    //height: calc(100vh - 98px - 93px - 56px);
    max-height: 100%;
    overflow-y: auto;
}
</style>
