<template>
    <div class="bg-white rounded border border-gray-200 p-3 h-full" v-if="job">
        <h3 class="font-bold mb-3">VEHICLE</h3>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-3">
            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-car" class="mr-2 w-4"/><span class="opacity-60">Make:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.vehicle">{{job.vehicle.VRMake}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-car" class="mr-2 w-4"/><span class="opacity-60">Model:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.vehicle">{{job.vehicle.VRModel}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-car" class="mr-2 w-4"/><span class="opacity-60">Reg:</span></span>
                <div v-if="job.vehicle" class=" ml-4">
                    <input class="border-0 font-semibold" v-model="job.vehicle.VRVrm" @change="updateReg(job.vehicle.ID)" v-if="!viewOnly" />
                    <span class="font-semibold" v-else>{{job.vehicle.VRVrm}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-car" class="mr-2 w-4"/><span class="opacity-60">VIN:</span></span>
                <div v-if="job.vehicle" class=" ml-4">
                    <input class="border-0 font-semibold" v-model="job.vehicle.VRVin" @change="updateVin(job.vehicle.ID)" v-if="!viewOnly" />
                    <span class="font-semibold" v-else>{{job.vehicle.VRVin}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-calendar" class="mr-2 w-4"/><span class="opacity-60">Year:</span></span>
                <div class="font-semibold ml-4">
                    <span v-if="job.vehicle">{{job.vehicle.YearOfManufacture}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-gear" class="mr-2 w-4"/><span class="opacity-60">Gearbox Type:</span></span>
                <div class="font-semibold ml-4" v-if="job.vehicle">
                    {{job.vehicle.Transmission}}
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-engine" class="mr-2 w-4"/><span class="opacity-60">Engine Desc:</span></span>
                <div class="font-semibold ml-4" v-if="job.vehicle">
                    {{job.vehicle.engineDescription}}
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-engine" class="mr-2 w-4"/><span class="opacity-60">Engine Code:</span></span>
                <div class="font-semibold ml-4" v-if="job.vehicle">
                    {{job.vehicle.enginecode}}
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-palette" class="mr-2 w-4"/><span class="opacity-60">Colour:</span></span>
                <div class="font-semibold ml-4" v-if="job.vehicle">
                    <input class="font-semibold border-0 w-full" v-model="job.vehicle.Colour" @blur="updateColour($event)" v-if="!viewOnly" />
                    <span class="font-semibold" v-else>{{job.vehicle.Colour}}</span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-eye-dropper" class="mr-2 w-4"/><span class="opacity-60">Colour Code:</span></span>
                <div class="font-semibold ml-4" v-if="job.vehicle">
                    <span class="w-full" v-if="job.vehicle.paint_code !== null && job.vehicle.paint_code !== ''">
                        <input class="font-semibold border-0 w-full" v-model="job.vehicle.paint_code" @blur="updatePaintCode($event)" v-if="!viewOnly" />
                        <span class="font-semibold" v-else>{{job.vehicle.paint_code}}</span>
                    </span>
                    <span class="w-full" v-else-if="job.vehicle && job.vehicle.parts24">
                        <input class="font-semibold border-0 w-full" :value="getColourCode()" @blur="addPaintCode($event)" v-if="!viewOnly" />
                        <span class="font-semibold" v-else>{{getColourCode()}}</span>
                    </span>
                    <span v-else>
                        <input class="font-semibold border-0" v-model="job.vehicle.paint_code" @blur="updatePaintCode($event)" v-if="!viewOnly" />
                        <span class="font-semibold" v-else>{{job.vehicle.paint_code}}</span>
                    </span>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-gauge-low" class="mr-2 w-4"/><span class="opacity-60">Mileage In:</span></span>
                <div class="font-semibold ml-4">
                    {{job.vehicle_mileage_in}}
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-gauge-med" class="mr-2 w-4"/><span class="opacity-60">Mileage Out:</span></span>
                <div class="font-semibold ml-4">
                    {{job.vehicle_mileage_out}}
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-gauge-low" class="mr-2 w-4"/><span class="opacity-60">MOT:</span></span>
                <div class="font-semibold ml-4" :class="{'text-red-500 font-bold': unMotOpts.includes(job.vehicle.hasMOT)}">
                    {{job.vehicle.hasMOT}}
                    <template v-if="job.vehicle.hasMOT === 'Valid'">({{job.vehicle.motDueDate}})</template>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-gauge-med" class="mr-2 w-4"/><span class="opacity-60">TAX:</span></span>
                <div class="font-semibold ml-4" :class="{'text-red-500 font-bold': untaxedOpts.includes(job.vehicle.hasTax)}">
                    {{job.vehicle.hasTax}}
                    <template v-if="job.vehicle.hasTax === 'Taxed'">({{job.vehicle.taxDueDate}})</template>
                </div>
            </div>

            <div>
                <span class="block font-semibold"><fa-icon solid icon="fa-lock-med" class="mr-2 w-4"/><span class="opacity-60">Ghost Alarm Code:</span></span>
                <div class="font-semibold ml-4" >
                    {{job.vehicle.ghost_alarm_code}}
                </div>
            </div>

            <div class="px-2 mt-3">
                <a-button class="block mb-2" type="primary" size="small" @click="getMotStatus" :loading="gettingMotStatus" v-if="!viewOnly">Get MOT & Tax
                    Status</a-button>

                <a-button type="primary" size="small" @click="showVehicleSwapModal = true" :loading="swappingVehicle" v-if="!viewOnly">Swap Vehicle</a-button>
            </div>

            <a-modal title="Swap Vehicle" :visible="showVehicleSwapModal" :confirm-loading="swappingVehicle" @cancel="showVehicleSwapModal = false" :footer="null">
                <a-input-search placeholder="Search Reg/Vin..." class="w-full" @search="searchVehicle" :loading="searching" />
                <p class="my-2">Tuning page will not be complete unless the vehicle you wish to swap to has been booked in previously as the tuning data is assigned to the vehicle and not the job.</p>
                <table class="w-full mt-3">
                    <tbody class="border-t border-gray-200">
                        <tr v-if="vehiclesFound" class="border-b border-gray-200">
                            <td class="border-l border-gray-200 px-2 py-1">{{vehiclesFound.VRMake}}</td>
                            <td class="border-l border-gray-200 px-2 py-1">{{vehiclesFound.VRModel}}</td>
                            <td class="border-l border-gray-200 px-2 py-1">{{vehiclesFound.VRVrm}}</td>
                            <td class="border-l border-gray-200 px-2 py-1">{{vehiclesFound.VRVin}}</td>
                            <td class="border-l border-r border-gray-200 px-2 py-1"><a-button type="primary" class="rounded py-1 px-2 bg-blue-600 text-white outline-none focus:outline-none" @click="swapVehicle(vehiclesFound.ID)" :loading="swappingVehicleLoading">Assign</a-button></td>
                        </tr>
                    </tbody>
                </table>
            </a-modal>
        </div>
    </div>
</template>

<script>
import { Modal } from 'ant-design-vue';
import {useAuthStore} from "@/store/authStore";
import FaIcon from "@/components/extras/Icon.vue";
export default {
    props: {
        job: {
            type: Object
        },
        viewOnly: {
            type: Boolean,
            default: false
        }
    },
    components: {
        FaIcon
    },
    data() {
        return {
            swappingVehicleLoading: false,
            showVehicleSwapModal: false,
            swappingVehicle: false,
            searching: false,
            vehiclesFound: [],
            gettingMotStatus: false,
            untaxedOpts: ['Not Taxed for on Road Use', 'SORN', 'Untaxed'],
            unMotOpts: ['No details held by DVLA', 'No results returned', 'Not valid'],
        }
    },
    methods: {
        swapVehicle(vehicleID) {
              this.swappingVehicle = true;
              axios.post(api_url + '/api/job/' + this.job.id + '/vehicle/swap', {
                  veh_id: vehicleID
              }).then(() => {
                  this.swappingVehicle = false;
                  this.showVehicleSwapModal = false;
                  location.reload();
              }).catch((err) => {
                  console.log(err)
              })
        },
        // this will search our DB for the Vehicle, if vehicle is not found, it will run a PL24 search and create a
        // new Vehicle record in our DB so that we have an ID to work with - as we need this to swap the
        // vehicle over.
        searchVehicle(e) {
            this.searching = true;
            axios.get(api_url + '/api/job/search/vehicle/' + e).then((resp) => {
                this.vehiclesFound = resp.data
              console.log('Vehicle: ', this.vehiclesFound);
                this.searching = false;
            }).catch(err => {
                this.searching = false;
                console.log(err)
            })
        },
        updateReg(id) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/vehicle/reg', {
                veh_id: id,
                reg: this.job.vehicle.VRVrm.toUpperCase(),
            }).then(resp => {
                if (resp.data.success) {
                    this.$message.success('Reg Updated.');
                } else {
                    this.$message.error('Failed to update Reg.');
                }
            })
        },
        updateVin(id) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/vehicle/vin', {
                veh_id: id,
                vin: this.job.vehicle.VRVin,
            }).then(resp => {
                if (resp.data.success) {
                    this.$message.success('VIN Updated.');
                } else {
                    this.$message.error('Failed to update VIN.');
                }
            })
        },
        addPaintCode(e) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/vehicle/paintcode', {
                veh_id: this.job.vehicle.ID,
                code: e.target.value
            }).then(resp => {
                if (resp.data.status === 'Success') {
                    this.$message.success('Updated paint code.');
                } else {
                    this.$message.error('Failed to update paint code.');
                }
            }).catch(() => {
                this.$message.error('Failed to update paint code.');
            })
        },
        updatePaintCode() {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/vehicle/paintcode', {
                veh_id: this.job.vehicle.ID,
                code: this.job.vehicle.paint_code
            }).then(resp => {
                if (resp.data.success) {
                    this.$message.success('Updated paint code.');
                } else {
                    this.$message.error('Failed to update paint code.');
                }
            }).catch(() => {
                this.$message.error('Failed to update paint code.');
            })
        },
        updateColour() {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/vehicle/colour', {
                veh_id: this.job.vehicle.ID,
                colour: this.job.vehicle.Colour
            }).then(resp => {
                if (resp.data.success) {
                    this.$message.success('Updated colour.');
                } else {
                    this.$message.error('Failed to update colour.');
                }
            }).catch(() => {
                this.$message.error('Failed to update colour.');
            })
        },
        getColourCode() {
            const paint = this.job.vehicle.parts24.find(el => el.pr_code_name === 'Exterior color / Paint Code' || el.pr_code_name === 'Colour');
            if (paint) {
                return paint.pr_code_val
            } else {
                return "";
            }
        },
        getMotStatus() {
            this.gettingMotStatus = true;
            axios.post(api_url + '/api/vehicle/mot', {
                reg: this.job.vehicle.VRVrm,
            }).then(resp => {
                this.gettingMotStatus = false;
                location.reload();
            }).catch(() => {
                this.gettingMotStatus = false;
                this.$message.error('Failed to get data.');
            })
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.text-bold {
    font-weight: bold;
}
</style>
