<template>
    <div class="tuning container-fluid">
        <div class="pt-12" v-if="job && cylinders.length > 0">
            <div class="flex py-3 items-center">
                <h4 class="text-xl mt-2">Job Name : {{job.customer.firstname}} {{job.customer.lastname}} - {{job.customer.emails[0].email}} - {{job.customer.phones[0].phonenumber}} [{{job.vehicle.VRVrm}}]</h4>
                <div class="ml-auto">
                    <div class="flex gap-2">
                        <a-button size="small" title="Tuning" @click="goToCustomerView"><i class="fa fa-user"></i></a-button>
                        <a-button size="small" title="Jobsheet" @click="goToJobsheetView"><i class="fa fa-file-alt"></i></a-button>
                    </div>
                </div>
            </div>

            <div class="row pb-4">
                <div class="col-md-4">
                    <div class="bg-white rounded border border-gray-200 h-auto">
                        <div class="bg-white px-3 py-2 rounded-t-lg font-bold border-b border-gray-200">Job Info</div>
                        <div class="p-2">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Booked in by:</td>
                                        <td>{{ job.booked_in_by }}</td>
                                    </tr>

                                    <tr>
                                        <td>Customer Name:</td>
                                        <td>{{ job.customer.firstname }} {{ job.customer.lastname }}</td>
                                    </tr>

                                    <tr>
                                        <td>Drop off time:</td>
                                        <td>{{ job.drop_off_time }}</td>
                                    </tr>

                                    <tr>
                                        <td>Pick up time:</td>
                                        <td>{{ job.pick_up_time }}</td>
                                    </tr>

                                    <tr>
                                        <td>Customer Activity:</td>
                                        <td>{{ job.customer_activity }}</td>
                                    </tr>

                                    <tr>
                                        <td>Contact Num:</td>
                                        <td>
                                            <div v-for="phone in job.customer.phones" :key="phone.id">{{ phone.phonenumber }}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Contact Email:</td>
                                        <td>
                                            <div v-for="email in job.customer.emails" :key="email.id">{{ email.email }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="bg-white rounded border border-gray-200 h-auto mt-2">
                        <div class="bg-white px-3 py-2 rounded-t-lg font-bold border-b border-gray-200">Vehicle Info</div>
                        <div class="p-2">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Make:</td>
                                        <td>{{ job.vehicle.VRMake }}</td>
                                    </tr>

                                    <tr>
                                        <td>Model:</td>
                                        <td>{{ job.vehicle.VRModel }}</td>
                                    </tr>

                                    <tr>
                                        <td>Registration:</td>
                                        <td>{{ job.vehicle.VRVrm.toUpperCase() }}</td>
                                    </tr>

                                    <tr>
                                        <td>VIN:</td>
                                        <td>{{ job.vehicle.VRVin.toUpperCase() }}</td>
                                    </tr>

                                    <tr>
                                        <td>Engine Capacity:</td>
                                        <td>{{ job.vehicle.EngineCapacity }}</td>
                                    </tr>

                                    <tr>
                                        <td>Year:</td>
                                        <td>{{ job.vehicle.YearOfManufacture }}</td>
                                    </tr>

                                    <tr>
                                        <td>BHP:</td>
                                        <td>{{ job.vehicle.hp }}</td>
                                    </tr>

                                    <tr>
                                        <td>Torque:</td>
                                        <td>{{ job.vehicle.Torque }}</td>
                                    </tr>

                                    <tr>
                                        <td>Gearbox Type:</td>
                                        <td>{{ job.vehicle.Transmission }}</td>
                                    </tr>

                                    <tr>
                                        <td>Track Use:</td>
                                        <td>
                                            <template v-if="job.vehicle.track_use">
                                                Yes
                                            </template>

                                            <template v-else>
                                                No
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="bg-white rounded border border-gray-200">
                        <div class="bg-white px-3 py-2 rounded-t-lg font-bold border-b border-gray-200">Completed By Darkside</div>
                        <div class="p-2">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>Previously Tuned By:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_tuned_by" @change="updateField('customer_tuned_by')" :options="tuners" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Engine Code:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.enginecode == null || job.vehicle.enginecode === ''}" v-model:value="job.vehicle.enginecode" @blur="updateField('enginecode')"/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Expected Power:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.customer_expected_power == null || job.vehicle.customer_expected_power === ''}" v-model:value="job.vehicle.customer_expected_power" @blur="updateField('customer_expected_power')"/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Expected Torque:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.customer_expected_peak_torque == null || job.vehicle.customer_expected_peak_torque === ''}" v-model:value="job.vehicle.customer_expected_peak_torque" @blur="updateField('customer_expected_peak_torque')"/>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Power Tune:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.power_tune" @change="updateField('power_tune')">
                                                <a-radio value="Yes">Yes</a-radio>
                                                <a-radio value="No">No</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Gearbox Tune:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.gearbox_tune" @change="updateField('gearbox_tune')">
                                                <a-radio value="NA">NA</a-radio>
                                                <a-radio value="Yes">Yes</a-radio>
                                                <a-radio value="No">No</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Hardcut:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_hardcut" @change="updateField('customer_hardcut')">
                                                <a-radio value="Yes">Yes</a-radio>
                                                <a-radio value="No">No</a-radio>
                                                <a-radio value="If Possible">If Possible</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Launch:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_launch" @change="updateField('customer_launch')">
                                                <a-radio value="Yes">Yes</a-radio>
                                                <a-radio value="No">No</a-radio>
                                                <a-radio value="If Possible">If Possible</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Hot Start Fix:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_hot_start_fix" @change="updateField('customer_hot_start_fix')">
                                                <a-radio value="Yes">Yes</a-radio>
                                                <a-radio value="No">No</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>EGR Hardware:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_egr_hardware" @change="updateField('customer_egr_hardware')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Deleted">Deleted</a-radio>
                                                <a-radio value="Blanked">Blanked</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>EGR Software:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_egr_software" @change="updateField('customer_egr_software')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="Deleted">Deleted</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>ASV:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_asv" @change="updateField('customer_asv')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>CAT:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_cat" @change="updateField('customer_cat')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="Hollow">Hollow</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>DPF:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_DPF" @change="updateField('customer_DPF')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>DPF Sensors:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_dpf_sensors" @change="updateField('customer_dpf_sensors')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Swirl Flaps:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_swirl_flaps" @change="updateField('customer_swirl_flaps')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Adblue:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.add_blue" @change="updateField('add_blue')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Left Foot Braking:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.left_foot_braking" @change="updateField('left_foot_braking')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                                <a-radio value="NA">NA</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Stop/Start:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.stopStart" @change="updateField('stopStart')">
                                                <a-radio value="NA">NA</a-radio>
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>MAF:</td>
                                        <td>
                                            <a-radio-group v-model:value="job.vehicle.customer_maf" @change="updateField('customer_maf')">
                                                <a-radio value="On">On</a-radio>
                                                <a-radio value="Off">Off</a-radio>
                                            </a-radio-group>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>MAF Type:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.maf_type" @change="updateField('maf_type')" :options="[{value:'Stock', label: 'Stock'},{value:'Upgraded', label: 'Upgraded'}]"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Gearbox Type:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.gearboxType" @change="updateField('gearboxType')" :options="gearbox_types" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Drivetrain:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.driveType" @change="updateField('driveType')" :options="drivetrain" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Turbocharger:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_turbocharger" @change="updateField('customer_turbocharger')" :options="turbos" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Injectors:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_injectors" @change="updateField('customer_injectors')" :options="injectors" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Pump:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_pump" @change="updateField('customer_pump')" :options="pumps" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>MAP Sensor:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_map_sensor" @change="updateField('customer_map_sensor')" :options="maps" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Intercooler:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_intercooler" @change="updateField('customer_intercooler')" :options="intercoolers" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Camshaft:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_camshaft" @change="updateField('customer_camshaft')" :options="camshaft" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Cylinder Head:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_cylinder" @change="updateField('customer_cylinder')" :options="cylinders" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Clutch &amp; Flywheel:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_clutch_and_flywheel" @change="updateField('customer_clutch_and_flywheel')" :options="clutches" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Smoke:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.customer_smoke_level" @change="updateField('customer_smoke_level')" :options="smoke_detail" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Other Mods:</td>
                                        <td>
                                            <a-input type="textarea" v-model:value="job.vehicle.customer_other_mods" name="customer_other_mods" @blur="updateField('customer_other_mods')"></a-input>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Gearbox Mods:</td>
                                        <td>
                                            <a-input type="textarea" v-model:value="job.vehicle.customer_gearbox_mods" name="customer_gearbox_mods" @blur="updateField('customer_gearbox_mods')"></a-input>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="bg-white rounded border border-gray-200 h-auto">
                        <div class="bg-white px-3 py-2 rounded-t-lg font-bold border-b border-gray-200">Updated By Tuner</div>
                        <div class="p-2">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>ECU Num:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.ecu === '' || job.vehicle.ecu == null}" v-model:value="job.vehicle.ecu" @blur="updateField('ecu')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ECU Type:</td>
                                        <td>
                                            <custom-select class="w-full" v-model="job.vehicle.ecu_type" @change="updateField('ecu_type')" :options="ecu_types" vparam="name" lparam="name" :class="{'emtpy': job.vehicle.ecu_type === '' || job.vehicle.ecu_type == null}"></custom-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Software:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.customer_ecu_software === '' || job.vehicle.customer_ecu_software == null}" v-model:value="job.vehicle.customer_ecu_software" @blur="updateField('customer_ecu_software')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Software Version:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.customer_ecu_version === '' || job.vehicle.customer_ecu_version == null}" v-model:value="job.vehicle.customer_ecu_version" @blur="updateField('customer_ecu_version')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Boost Level:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.boost_level === '' || job.vehicle.boost_level == null}" v-model:value="job.vehicle.boost_level" @blur="updateField('boost_level')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Duration:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.duration === '' || job.vehicle.duration == null}" v-model:value="job.vehicle.duration" @blur="updateField('duration')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Connection:</td>
                                        <td>
                                            <custom-select class="w-full" v-model:value="job.vehicle.connection" @change="updateField('connection')" :options="connectionOptions" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tool:</td>
                                        <td>
                                            <custom-select class="w-full" v-model:value="job.vehicle.tool" @change="updateField('tool')" :options="toolOptions" vparam="name" lparam="name"></custom-select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Connection Notes:</td>
                                        <td>
                                            <a-input type="textarea" v-model:value="job.vehicle.connection_notes"></a-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Actuator Setting:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.actuator_set === '' || job.vehicle.actuator_set == null}" v-model:value="job.vehicle.actuator_set" @blur="updateField('actuator_set')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Timing (for ADV):</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.timing === '' || job.vehicle.timing == null}" v-model:value="job.vehicle.timing" @blur="updateField('timing')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Final Torque:</td>
                                        <td>
                                            <a-input size="small" type="number" :class="{'empty': job.vehicle.final_torque === '' || job.vehicle.final_torque == null}" v-model:value="job.vehicle.final_torque" @blur="updateField('final_torque')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Final Power:</td>
                                        <td>
                                            <a-input size="small" type="number" :class="{'empty': job.vehicle.final_power === '' || job.vehicle.final_power == null}" v-model:value="job.vehicle.final_power" @blur="updateField('final_power')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tuning Notes (Internal Only):</td>
                                        <td>
                                            <a-input type="textarea" :class="{'empty': job.vehicle.tuning_note === '' || job.vehicle.tuning_note == null}" v-model:value="job.vehicle.tuning_note" @blur="updateField('tuning_note')"></a-input>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tuning Folder:</td>
                                        <td>
                                            <a-input size="small" :class="{'empty': job.vehicle.tuning_folder === '' || job.vehicle.tuning_folder == null}" v-model:value="job.vehicle.tuning_folder" @blur="updateField('tuning_folder')"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tuning File (.bin):</td>
                                        <td>
                                            <a-input size="small" class="form-control" :class="{'empty': job.vehicle.tuning_file === '' || job.vehicle.tuning_file == null}" type="file" id="tuning_file" @change="updateTuningFile"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a-button type="primary" size="small" @click="processInSwiftech" :disabled="job.vehicle.tuning_file === '' || job.vehicle.tuning_file == null">
                                                Process
                                            </a-button>
                                        </td>
                                        <td>{{ swiftechMsg }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a-button size="small" type="primary" @click="openSwiftecModal" :disabled="job.vehicle.tuning_file === '' || job.vehicle.tuning_file == null">Process
                                                Single
                                            </a-button>
                                        </td>
                                        <td>{{ swiftechMsgSingle }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a-button size="small" @click="checkSwiftechProgress">Swiftec Progress</a-button>
                                        </td>
                                        <td>{{ swiftechProgressMsg }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="row mt-3 mb-5" v-if="dropzoneOptions.url">
                <div class="col-md-12">
                    <div class="maincard">
                        <div class="maincard-header">Upload Dyno Graph</div>
                        <div class="p-2">
                            <div class="row" v-if="job.graphs.length > 0">
                                <div class="col-md-12">
                                    <div class="mb-2" v-for="(graph, index) in job.graphs" :key="graph.id">
                                        Graph {{ index + 1 }} -
                                        <button class="btn-sm btn-xs btn btn-primary ld-over" @click="openFile($event,graph.file_id)">
                                            View Graph
                                            <div class="ld ld-spinner ld-spin"></div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <vue-dropzone ref="dynograph_dropzone" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="uploadSuccess" v-on:vdropzone-file-added="fileAdded"></vue-dropzone>
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>

        <!--<div class="lightbox-wrap" v-if="lightbox.show">
            <div class="lightbox">
                <span class="close-lightbox" @click="closeLightbox"><i class="fa fa-times"></i></span>
                <img :src="lightbox.src" height="300px" width="300px" alt="lightbox">
            </div>
        </div>

        <a-modal v-model:visible="swiftecModal" title="Swiftec Individual Code" :footer="null">
            <div class="flex gap-2">
                <a-input placeholder="Code..." v-model:value="code"/>
                <a-button type="primary" :disabled="code === ''" @click="processInSingleSwiftech">Submit</a-button>
            </div>
        </a-modal>-->
    </div>
</template>

<script>
import {useAuthStore} from "@/store/authStore";
import axios from 'axios'
import customSelect from '@/components/extras/Select.vue';
export default {
    name: 'Tuning',
    components: {
        customSelect
    },
    data() {
        return {
            job: null,
            all_jobs: [],
            users: [],
            tuners: [],
            cylinders: [],
            drivetrain: [],
            clutches: [],
            engine_codes: [],
            gearbox_types: [],
            injectors: [],
            intercoolers: [],
            maps: [],
            pipework: [],
            pumps: [],
            smoke_detail: [],
            turbos: [],
            camshaft: [],
            ecu_types: [],
            code: "",
            dropzoneOptions: {
                autoProcessQueue: false,
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                url: null,
                thumbnailWidth: 200,
                maxFilesize: 3,
                headers: {"Authorization": "Bearer " + useAuthStore().token},
                params: {
                    'job_id': this.$route.params.id,
                    'file_type': "",
                    'file_name': ""
                },
            },
            lightbox: {
                show: false,
                src: "",
            },
            swiftechMsg: "",
            swiftechMsgSingle: "",
            swiftechProgressMsg: "",
            swiftechProgressInterval: null,
            swiftecModal: false,
            connectionOptions: [
                {id: 1, name: "OBD & OBD Unlock"},
                {id: 2, name: "Bench"},
                {id: 3, name: "Bench Unlock"},
                {id: 4, name: "Socket"}
            ],
            toolOptions: [
                {id: 1, name: "bFlash"},
                {id: 2, name: "bFlash/MPPS"},
                {id: 3, name: "bFlash/X17/Flex"},
                {id: 4, name: "Chip Programmer"},
                {id: 5, name: "MPPS"},
                {id: 6, name: "X17"},
                {id: 7, name: "X17/Flex"}
            ]
        }
    },
    computed: {
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            const id = this.$route.params.id;
            axios.get(api_url + '/v2/jobs/part/types/list').then((response) => {
                const res = response.data.data;
                this.cylinders = res.cylinders;
                this.drivetrain = res.drivetrain
                this.clutches = res.clutches
                this.engine_codes = res.engine_codes
                this.gearbox_types = res.gearbox_types
                this.injectors = res.injectors
                this.intercoolers = res.intercoolers
                this.maps = res.maps
                this.pipework = res.pipework
                this.pumps = res.pumps
                this.smoke_detail = res.smoke_detail
                this.turbos = res.turbos
                this.camshaft = res.camshaft
                this.ecu_types = res.ecu_types
                this.tuners = res.tuners
            }).catch(() => {
            });

            /*axios.get(api_url + '/api/job/list/ecu').then((resp) => {
                this.ecu_types = resp.data;
            })*/

            axios.get(api_url + '/v2/jobs/all').then((response) => {
                this.all_jobs = response.data;
            }).catch(function (error) {
            });

            axios.get(api_url + '/v2/jobs/' + id).then((response) => {
                this.job = response.data;
                this.users = response.data.users;
                if (this.job.vehicle.add_blue == null) {
                    this.job.vehicle.add_blue = 'NA';
                }
                this.dropzoneOptions.url = api_url + '/v2/jobs/' + this.job.id + '/tuning/graph';
            }).catch(() => {
            }).finally(() => {
                setTimeout(() => {
                    this.getToolType();
                }, 1000);
            });
        },
        updateTuningFile(e) {
            this.job.vehicle.tuning_file = e.target.value;
        },
        updateField(type) {
            console.log(this.job.vehicle[type], type)
            axios.post(api_url + '/v2/jobs/' + this.job.id + '/tuning/update', {
                type: type,
                value: this.job.vehicle[type],
                veh_id: this.job.vehicle.ID
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success(resp.data.data);
                } else {
                    this.$message.error('Failed to update ' + type);
                }
            }).catch(() => {
                this.$message.error('Failed to update ' + type);
            })
        },
        updateSelectField(type, value) {
            axios.post(api_url + '/v2/jobs/' + this.job.id + '/tuning/update', {
                type: type,
                value: value,
                veh_id: this.job.vehicle.ID
            }).then(() => {
                type = type.replace(/_/g, ' ');
                this.$message.success('Updated ' + type);
            }).catch(() => {
                this.$message.error('Failed to update ' + type);
            })
        },
        uploadSuccess(file, response) {
            if (response.status === "Success") {
                this.$message.success('File Uploaded');
                this.$refs.dynograph_dropzone.removeAllFiles();
            } else {
                this.$message.success('File Error: ' + response);
            }
        },
        fileAdded(file) {
            setTimeout(() => {
                this.$refs.dynograph_dropzone.dropzoneSettings.params.file_type = file.type;
                this.$refs.dynograph_dropzone.dropzoneSettings.params.file_name = file.name;
                this.$refs.dynograph_dropzone.processQueue();
            }, 500);
        },
        openFile(e, file_id) {
            axios.get(api_url + '/v2/jobs/' + this.job.id + '/tuning/graph/get?id=' + file_id).then((resp) => {
                this.lightbox.show = true;
                this.lightbox.src = resp.data.thumbnailLink;
            }).catch(() => {
            });
        },
        closeLightbox() {
            this.lightbox.show = false;
        },
        goToCustomerView() {
            this.$router.push({name: 'Customer', params: {id: this.job.id}})
        },
        goToJobsheetView() {
            this.$router.push({name: 'Jobsheet', params: {id: this.job.id}})
        },
        processInSwiftech() {
            const tuning_file = document.getElementById("tuning_file").files[0].name;
            const folder = this.job.vehicle.tuning_folder.trim();
            let name = this.job.customer.firstname + "-" + this.job.customer.lastname;
            name = name.trim();

            const phone = this.job.customer.phones[0].phonenumber;
            const reg = this.job.vehicle.VRVrm.toUpperCase();
            const ecu_type = this.job.vehicle.ecu_type;
            const tool = this.job.vehicle.tool;
            let make = this.job.vehicle.VRMake;
            make = make.replace(/\s/g, "-");

            let model = this.job.vehicle.VRModel;
            model = model.replace(/\s/g, "-");

            const eng_cap = this.job.vehicle.EngineCapacity;
            let hp = this.job.vehicle.hp;
            hp = Math.round(hp);

            const year = this.job.vehicle.YearOfManufacture;
            const vin = this.job.vehicle.VRVin;
            let gbt = this.job.vehicle.gearboxType;
            gbt = gbt.replace(/\s/g, "-");

            const json = {
                "tuning_file": tuning_file.trim(),
                "folder": folder.trim(),
                "name": name,
                "phone": phone,
                "reg": reg.trim(),
                "ecu_type": ecu_type.trim(),
                "tool": tool.trim(),
                "make": make.trim(),
                "model": model.trim(),
                "engine_capacity": eng_cap,
                "bhp": hp,
                "year": year,
                "vin": vin.trim(),
                "gearbox": gbt.trim()
            };

            axios.post('https://api.darkside-developments.com/api/jobs/swiftec', json).then((resp) => {
                if (resp.data.success) {
                    this.swiftechMsg = "Processing, ETA: " + resp.data.eta;
                } else {
                    this.swiftechMsg = resp.data.reason;
                }
            })
        },
        openSwiftecModal() {
            this.swiftecModal = true;
        },
        processInSingleSwiftech() {
            const tuning_file = document.getElementById("tuning_file").files[0].name;
            const folder = this.job.vehicle.tuning_folder.trim();
            let name = this.job.customer.firstname + "-" + this.job.customer.lastname;
            name = name.trim();

            const phone = this.job.customer.phones[0].phonenumber;
            const reg = this.job.vehicle.VRVrm.toUpperCase();
            const ecu_type = this.job.vehicle.ecu_type;
            const tool = this.job.vehicle.tool;
            let make = this.job.vehicle.VRMake;
            make = make.replace(/\s/g, "-");

            let model = this.job.vehicle.VRModel;
            model = model.replace(/\s/g, "-");

            const eng_cap = this.job.vehicle.EngineCapacity;
            let hp = this.job.vehicle.hp;
            hp = Math.round(hp);

            const year = this.job.vehicle.YearOfManufacture;
            const vin = this.job.vehicle.VRVin;
            let gbt = this.job.vehicle.gearboxType;
            gbt = gbt.replace(/\s/g, "-");

            const json = {
                "job_id": this.job.id,
                "tuning_file": tuning_file.trim(),
                "folder": folder.trim(),
                "name": name,
                "phone": phone,
                "reg": reg.trim(),
                "ecu_type": ecu_type.trim(),
                "tool": tool.trim(),
                "make": make.trim(),
                "model": model.trim(),
                "engine_capacity": eng_cap,
                "bhp": hp,
                "year": year,
                "vin": vin.trim(),
                "gearbox": gbt.trim(),
                "code": this.code
            };

            axios.post('https://api.darkside-developments.com/api/jobs/swiftec/single', json).then((resp) => {
                if (resp.data.success) {
                    this.swiftechMsgSingle = "Processing, ETA: " + resp.data.eta;
                    this.swiftecModal = false;
                    this.swiftechProgressInterval = setInterval(() => {
                        this.getSwiftechProgress(resp.data.processId);
                    }, 5000);
                } else {
                    this.swiftechMsgSingle = resp.data.reason;
                    this.swiftecModal = false;
                }
            });
        },
        checkSwiftechProgress() {
            let folder = this.job.vehicle.tuning_folder;
            if (folder) {
                folder = folder.trim();
                axios.post("https://api.darkside-developments.com/api/jobs/swiftec/progress", {folder: folder}).then(resp => {
                    if (resp.data !== null) {
                        this.swiftechProgressMsg = resp.data.state;
                    }
                });
            }
        },
        getSwiftechProgress(id) {
            axios.get("https://api.darkside-developments.com/api/jobs/swiftec/progress/" + id).then(resp => {
                if (resp.data !== null) {
                    this.swiftechProgressMsg = resp.data;
                    if (resp.data === 'Completed' || resp.data === "Failed") {
                        if (this.swiftechProgressInterval) {
                            clearInterval(this.swiftechProgressInterval);
                            this.swiftechProgressInterval = null;
                        }
                    }
                }
            });
        },
        getToolType() {
            if (this.job.vehicle.ecu_type !== "" && this.job.vehicle.ecu_type !== null) {
                axios.get(api_url + '/v2/jobs/' + this.job.id + '/tuning/tool?ecu_type=' + this.job.vehicle.ecu_type + '&ecu_num=' + this.job.vehicle.ecu).then((resp) => {
                    if (resp.data !== null) {
                        if (resp.data.Tool && resp.data.Tool !== '') {
                            this.job.vehicle.tool = resp.data.Tool;
                            if (resp.data.OBD === "Y" || resp.data.OBDUnlock === "Y") {
                                this.job.vehicle.connection = "OBD & OBD Unlock";
                            }

                            if (resp.data.BenchUnlock === "Y") {
                                this.job.vehicle.connection = "Bench Unlock";
                            }

                            if (resp.data.Bench === "Y") {
                                this.job.vehicle.connection = "Bench";
                            }

                            if (resp.data.Socket === "Y") {
                                this.job.vehicle.connection = "Socket";
                            }

                            this.job.vehicle.connection_notes = resp.data.notes
                        }
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
tr td:first-child {
    font-weight: 600;
    opacity: 0.8;
}

.form-control.empty {
    border: 1px solid red;
}

.form-control {
    width: 100%;
}

textarea.form-control {
    border: 1px solid #ced4da;
}

.maincard-header {
    font-weight: bold;
}
</style>
<style>
.tuning span.ant-radio + * {
    font-weight: 500;
}
</style>
