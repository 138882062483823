import server from "@/plugins/axiosMiddleware.js";
export class ApiService {
    constructor(controller) {
        this.controller = controller + "/";
    }
    getAll(url = "GetAll", headers) {
        return new Promise((resolve, reject) => {
            server
                .get(`${url}`, {headers})
                .then((response) => {
                    if (response.status === 204) {
                        // Not found
                        resolve([]);
                    } else {
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    get(url, id = "", headers) {
        if (id) {
            url += "/" + id;
        }
        return new Promise((resolve, reject) => {
            server
                .get(url, {
                    headers,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    post(url, data, headers) {
        return new Promise((resolve, reject) => {
            server.post(`${url}`, data, {
                headers,
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            });
        });
    }

    download(url, data, headers) {
        return new Promise((resolve, reject) => {
            server
                .post(`${url}`, data, {
                    responseType: "blob",
                    headers,
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    upload(url, fileData) {
        return new Promise((resolve, reject) => {
            server
                .post(`${url}`, fileData, {
                    headers: {"Content-Type": "multipart/form-data"},
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    put(url, id, data, headers) {
        return new Promise((resolve, reject) => {
            if(id) {
                server
                    .put(`${url}/${id}`, data, {
                        headers,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            } else {
                server
                    .put(`${url}`, data, {
                        headers,
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }
        });
    }

    delete(url, id, headers) {
        if (id) {
            url += "/" + id;
        }
        return new Promise((resolve, reject) => {
            server
                .delete(`${url}`, {
                    headers,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
