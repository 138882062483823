<template>
<div class="booking">
    <div class="container mt-20 pb-3">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                <h2 class="text-3xl">Booking <small>From Deposit</small></h2>
                <h6 class="text-base">{{today}}</h6>
            </div>
        </div>
    </div>
    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="maincard border-0">
                    <div class="p-3">
                        <a-select class="w-full" placeholder="Select one..." @change="getDeposit">
                            <a-select-option v-for="deposit in deposits" :key="deposit.id" :value="deposit.id">
                                {{deposit.customer.firstname}} {{deposit.customer.lastname}} - {{deposit.customer.email.email}} ({{deposit.reg.toUpperCase()}})
                            </a-select-option>
                        </a-select>
                        {{selectedDeposit}}
                        <div class="mt-3" v-if="selectedDeposit !== undefined">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Customer Name</td>
                                        <td>{{selectedDeposit.customer.firstname}} {{selectedDeposit.customer.lastname}}</td>
                                    </tr>

                                    <tr>
                                        <td>Customer Email</td>
                                        <td>{{selectedDeposit.customer.email.email}}</td>
                                    </tr>

                                    <tr>
                                        <td>Customer Phone</td>
                                        <td>{{selectedDeposit.customer.phone.phonenumber}}</td>
                                    </tr>

                                    <tr>
                                        <td>Job Type</td>
                                        <td>{{selectedDeposit.type}}</td>
                                    </tr>

                                    <tr>
                                        <td>Booking Date/Time</td>
                                        <td>{{selectedDeposit.booking_date}} {{selectedDeposit.booking_time}}</td>
                                    </tr>

                                    <tr>
                                        <td>Activity</td>
                                        <td>{{selectedDeposit.customer_activity}}</td>
                                    </tr>

                                    <tr>
                                        <td>Work Description</td>
                                        <td>{{selectedDeposit.work_desc}}</td>
                                    </tr>

                                    <tr>
                                        <td>Make</td>
                                        <td>{{selectedDeposit.vehicle.VRMake}}</td>
                                    </tr>

                                    <tr>
                                        <td>Model</td>
                                        <td>{{selectedDeposit.vehicle.VRModel}}</td>
                                    </tr>

                                    <tr>
                                        <td>Transmission</td>
                                        <td>{{selectedDeposit.transmission}}</td>
                                    </tr>

                                    <tr>
                                        <td>Drivetrain</td>
                                        <td>{{selectedDeposit.drivetrain}}</td>
                                    </tr>

                                    <tr>
                                        <td>Expected Power</td>
                                        <td>{{selectedDeposit.expected_power}}</td>
                                    </tr>

                                    <tr>
                                        <td>Expected Torque</td>
                                        <td>{{selectedDeposit.expected_torque}}</td>
                                    </tr>

                                    <tr>
                                        <td>Deposit Paid</td>
                                        <td>{{selectedDeposit.deposit_paid}}</td>
                                    </tr>

                                    <tr v-for="(dep,key) in selectedDeposit" :key="key">
                                        <td>{{key}}</td>
                                        <td>{{dep}}</td>
                                    </tr>
                                    <!--<tr><td><b>Order ID</b></td><td>{{selectedDeposit.order_id}}</td></tr>
                                    <tr><td><b>Type</b></td><td>{{selectedDeposit.type}}</td></tr>
                                    <tr><td><b>Booking Date</b></td><td>{{selectedDeposit.booking_date}}</td></tr>
                                    <tr :class="{'invalid': !validateTime(selectedDeposit.booking_time)}"><td><b>Booking Time</b></td><td>{{selectedDeposit.booking_time}}</td></tr>
                                    <tr><td><b>Make</b></td><td>{{selectedDeposit.make}}</td></tr>
                                    <tr><td><b>Model</b></td><td>{{selectedDeposit.model}}</td></tr>
                                    <tr><td><b>Transmission</b></td><td>{{selectedDeposit.transmission}}</td></tr>
                                    <tr v-if="selectedDeposit.vehicle"><td><b>Gearbox Code</b></td><td>{{selectedDeposit.vehicle.gearbox_code}}</td></tr>
                                    <tr><td><b>Drivetrain</b></td><td>{{selectedDeposit.drivetrain}}</td></tr>
                                    <tr><td><b>Photo Permission</b></td><td>{{selectedDeposit.photo_permission}}</td></tr>
                                    <tr><td><b>Expected Power</b></td><td>{{selectedDeposit.expected_power}}</td></tr>
                                    <tr><td><b>Expected Torque</b></td><td>{{selectedDeposit.expected_torque}}</td></tr>
                                    <tr><td><b>Reg</b></td><td>{{selectedDeposit.reg}}</td></tr>
                                    <tr><td><b>Customer Activity</b></td><td>{{selectedDeposit.customer_activity}}</td></tr>
                                    <tr><td><b>Work Desc</b></td><td>{{selectedDeposit.work_desc}}</td></tr>
                                    <tr><td><b>Customer</b></td><td>{{selectedDeposit.customer.firstname}} {{selectedDeposit.customer.lastname}}</td></tr>
                                    <tr><td><b>Customer Email</b></td><td>{{selectedDeposit.customer.email.email}}</td></tr>
                                    <tr><td></td><td></td></tr>
                                    <tr v-if="selectedDeposit.vehicle">
                                        <td><b>Vehicle Make</b></td>
                                        <td>{{selectedDeposit.vehicle.VRMake}}</td>
                                    </tr>
                                    <tr v-if="selectedDeposit.vehicle">
                                        <td><b>Vehicle Model</b></td>
                                        <td>{{selectedDeposit.vehicle.VRModel}}</td>
                                    </tr>
                                    <tr v-if="selectedDeposit.vehicle">
                                        <td><b>VIN</b></td>
                                        <td>{{selectedDeposit.vehicle.VRVin}}</td>
                                    </tr>-->
                                </tbody>
                            </table>

                            <div class="mt-4">
                                <button class="btn btn-primary" @click="book_in">Book in</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import axios from 'axios'
import Cookies from 'js-cookie';
import { required, minLength, email, not } from 'vuelidate/lib/validators'
import _ from 'lodash';
import {useBookingStore} from "../../../store/bookingStore";
import {useAuthStore} from "@/store/authStore";
export default {
    created(){
        //this.scrollToTop();
    },
    mounted(){
        axios.get(api_url + '/v2/jobs/booking/deposits').then((resp) => {
            this.deposits = resp.data;
        });
		/*this.vehicle_id = "";
		this.email = "";
		this.name = "";
		this.phonenum = "";
		this.cpref = "Email";
		this.dep_amount = "";
		this.dep_ref = "";
		this.vehicle = "";
		this.reg = "";
		this.vin = "";
		this.engine_code = "Select One";
		this.engine_code_other = "";
		this.gearbox = "Select One";
		this.gearbox_other = "";
		this.gearbox_type = "Select One";
		this.gearbox_type_other = "";
		this.drivetrain = "Select One";
		this.tun = false;
		this.ws = false;
		this.fab = false;
		this.tvvt = false;
		this.bodyshop = false;
		this.ecu_num = "";
		this.tuned_by = "Select One";
		this.tuned_by_other = "";
		this.claimed_power = "";
		this.claimed_torque = "";
		this.current_power = "";
		this.current_torque = "";
		this.turbocharger = "Select One";
		this.injectors = "Select One";
		this.pump = "Select One";
		this.power_tune = "No";
		this.map_sensor = "Select One";
		this.intercooler = "Select One";
		this.pipework = "Select One";
		this.camshaft = "Select One";
		this.cylinder_head = "Select One";
		this.clutch_flywheel = "Select One";
		this.turbocharger_other = "";
		this.injectors_other = "";
		this.pump_other = "";
		this.map_sensor_other = "";
		this.intercooler_other = "";
		this.pipework_other = "";
		this.camshaft_other = "";
		this.cylinder_head_other = "";
		this.clutch_flywheel_other = "";
		this.other_mods = "";
		this.gearbox_mods = "";
		this.maf = "On";
		this.egr_hardware = "On";
		this.egr_software = "On";
		this.asv = "On";
		this.cat = "On";
		this.dpf = "On";
		this.dpf_sensors = "On";
		this.swirl_flaps = "On";
		this.hardcut = "No";
		this.launch = "No";
		this.hot_start_fix = "No";
		this.adblue = "NA";
		this.left_foot_braking = "NA";
		this.gearbox_tune = "NA";
		this.expected_power = "";
		this.expected_peak_torque = "";
		this.smoke_level = "Smoke Free";
		this.dropoff_date = "";
		this.dropoff_time = "";
		this.start_date = "";
		this.start_time = "";
		this.finish_date = "";
		this.finish_time = "";
		this.collect_date = "";
		this.collect_time = "";
		this.customer = "";
		this.notes = "";
		this.bhp = "";
		this.torque = "";
		this.stopStart = "No";
        this.parts = [];
        this.getParts();*/
    },
    data: () => ({
        today: moment().format('MMMM Do YYYY, h:mm:ss a'),
        now: moment().format('YYYY-MM-DD'),
        deposits: [],
        selectedDeposit: undefined
    }),
    validations: {
        name: {
            required,
            minLength: minLength(1)
        },
        email: {
            required,
            minLength: minLength(1)
        },
        cpref: {
            required
        },
        vehicle: {
            required,
            minLength: minLength(1)
        },
        reg: {
            required,
            minLength: minLength(1)
        },
        vin: {
            required,
            minLength: minLength(1)
        },
        engine_code: {
            required
        },
        gearbox_type: {
            required
        },
        drivetrain: {
            required
        },
    },
    computed: {
        booked_in_reg: {
            get() {
                return useBookingStore().booked_in_reg
            },
            set(value) {
                useBookingStore().setBookedInReg(value)
            }
        },
        vehicle_id: {
            get() {
                return useBookingStore().vehicle_id
            },
            set(value) {
                useBookingStore().setVehicleId(value)
            }
        },
        customer_id: {
            get() {
                return useBookingStore().customer_id
            },
            set(value) {
                useBookingStore().setCustomerId(value)
            }
        },
        customer: {
            get() {
                return useBookingStore().customer
            },
            set(value) {
                useBookingStore().setCustomer(value)
            }
        },
        dropoff_date: {
            get() {
                return useBookingStore().dropoff_date
            },
            set(value) {
                useBookingStore().setDropoffDate(value)
            }
        },
        dropoff_time: {
            get() {
                return useBookingStore().dropoff_time
            },
            set(value) {
                useBookingStore().setDropoffTime(value)
            }
        },
        booked_in_by: {
            get() {
                return useBookingStore().booked_in_by
            },
            set(value) {
                useBookingStore().setBookedInBy(value)
            }
        },
        email: {
            get() {
                return useBookingStore().email
            },
            set(value) {
                useBookingStore().setEmail(value)
            }
        },
        name: {
            get() {
                return useBookingStore().name
            },
            set(value) {
                useBookingStore().setName(value)
            }
        },
        phonenum: {
            get() {
                return useBookingStore().phonenum
            },
            set(value) {
                useBookingStore().setPhone(value)
            }
        },
        cpref: {
            get() {
                return useBookingStore().cpref
            },
            set(value) {
                useBookingStore().setContactPref(value)
            }
        },
        dep_amount: {
            get() {
                return useBookingStore().dep_amount
            },
            set(value) {
                useBookingStore().setDepAmount(value)
            }
        },
        dep_ref: {
            get() {
                return useBookingStore().dep_ref
            },
            set(value) {
                useBookingStore().setDepRef(value)
            }
        },
        vehicle: {
            get() {
                return useBookingStore().vehicle
            },
            set(value) {
                useBookingStore().setVehicle(value)
            }
        },
        reg: {
            get() {
                return useBookingStore().reg
            },
            set(value) {
                useBookingStore().setReg(value)
            }
        },
        vin: {
            get() {
                return useBookingStore().vin
            },
            set(value) {
                useBookingStore().setVin(value)
            }
        },
        ecu_num: {
            get() {
                return useBookingStore().ecu_num
            },
            set(value) {
                useBookingStore().setECUNum(value)
            }
        },
        engine_code: {
            get() {
                return useBookingStore().engine_code
            },
            set(value) {
                useBookingStore().setEngineCode(value)
            }
        },
        engine_code_other: {
            get() {
                return useBookingStore().engine_code_other
            },
            set(value) {
                useBookingStore().setEngineCodeOther(value)
            }
        },
        gearbox: {
            get() {
                return useBookingStore().gearbox
            },
            set(value) {
                useBookingStore().setGearbox(value)
            }
        },
        gearbox_other: {
            get() {
                return useBookingStore().gearbox_other
            },
            set(value) {
                useBookingStore().setGearboxOther(value)
            }
        },
        gearbox_type: {
            get() {
                return useBookingStore().gearbox_type
            },
            set(value) {
                useBookingStore().setGearboxType(value)
            }
        },
        gearbox_type_other: {
            get() {
                return useBookingStore().gearbox_type_other
            },
            set(value) {
                useBookingStore().setGearboxTypeOther(value)
            }
        },
        drivetrain: {
            get() {
                return useBookingStore().drivetrain
            },
            set(value) {
                useBookingStore().setDrivetrain(value)
            }
        },
        tun: {
            get() {
                return useBookingStore().tun
            },
            set(value) {
                useBookingStore().setTuning(value)
            }
        },
        ws: {
            get() {
                return useBookingStore().ws
            },
            set(value) {
                useBookingStore().setWorkshop(value)
            }
        },
        fab: {
            get() {
                return useBookingStore().fab
            },
            set(value) {
                useBookingStore().setFabrication(value)
            }
        },
        vvt: {
            get() {
                return useBookingStore().tvvt
            },
            set(value) {
                useBookingStore().setTvvt(value)
            }
        },
        bodyshop: {
            get() {
                return useBookingStore().bodyshop
            },
            set(value) {
                useBookingStore().setBodyshop(value)
            }
        },
        parts: {
            get() {
                return useBookingStore().parts
            },
            set(value) {
                useBookingStore().setParts(value)
            }
        },
        current_power: {
            get() {
                return useBookingStore().current_power
            },
            set(value) {
                useBookingStore().setCurrentPower(value)
            }
        },
        current_torque: {
            get() {
                return useBookingStore().current_torque
            },
            set(value) {
                useBookingStore().setCurrentTorque(value)
            }
        },
        turbocharger: {
            get() {
                return useBookingStore().turbocharger
            },
            set(value) {
                useBookingStore().setTurbocharger(value)
            }
        },
        injectors: {
            get() {
                return useBookingStore().injectors
            },
            set(value) {
                useBookingStore().setInjectors(value)
            }
        },
        pump: {
            get() {
                return useBookingStore().pump
            },
            set(value) {
                useBookingStore().setPump(value)
            }
        },
        map_sensor: {
            get() {
                return useBookingStore().map_sensor
            },
            set(value) {
                useBookingStore().setMapSensor(value)
            }
        },
        intercooler: {
            get() {
                return useBookingStore().intercooler
            },
            set(value) {
                useBookingStore().setIntercooler(value)
            }
        },
        camshaft: {
            get() {
                return useBookingStore().camshaft
            },
            set(value) {
                useBookingStore().setCamshaft(value)
            }
        },
        cylinder_head: {
            get() {
                return useBookingStore().cylinder_head
            },
            set(value) {
                useBookingStore().setCylHead(value)
            }
        },
        clutch_flywheel: {
            get() {
                return useBookingStore().clutch_flywheel
            },
            set(value) {
                useBookingStore().setClutchFlywheel(value)
            }
        },
        smoke_level: {
            get() {
                return useBookingStore().smoke_level
            },
            set(value) {
                useBookingStore().setSmokeLevel(value)
            }
        },
        other_mods: {
            get() {
                return useBookingStore().other_mods
            },
            set(value) {
                useBookingStore().setOtherMods(value)
            }
        },
        gearbox_mods: {
            get() {
                return useBookingStore().gearbox_mods
            },
            set(value) {
                useBookingStore().setGearboxMods(value)
            }
        },
        expected_power: {
            get() {
                return useBookingStore().expected_power
            },
            set(value) {
                useBookingStore().setExpectedPower(value)
            }
        },
        expected_peak_torque: {
            get() {
                return useBookingStore().expected_peak_torque
            },
            set(value) {
                useBookingStore().setExpectedPeakTorque(value)
            }
        },
        power_tune: {
            get() {
                return useBookingStore().power_tune
            },
            set(value) {
                useBookingStore().setPowerTune(value)
            }
        },
        gearbox_tune: {
            get() {
                return useBookingStore().gearbox_tune
            },
            set(value) {
                useBookingStore().setGearboxTune(value)
            }
        },
        maf: {
            get() {
                return useBookingStore().maf
            },
            set(value) {
                useBookingStore().setMaf(value)
            }
        },
        egr_hardware: {
            get() {
                return useBookingStore().egr_hardware
            },
            set(value) {
                useBookingStore().setEGRHardware(value)
            }
        },
        egr_software: {
            get() {
                return useBookingStore().egr_software
            },
            set(value) {
                useBookingStore().setEGRSoftware(value)
            }
        },
        asv: {
            get() {
                return useBookingStore().asv
            },
            set(value) {
                useBookingStore().setASV(value)
            }
        },
        cat: {
            get() {
                return useBookingStore().cat
            },
            set(value) {
                useBookingStore().setCat(value)
            }
        },
        dpf: {
            get() {
                return useBookingStore().dpf
            },
            set(value) {
                useBookingStore().setDPF(value)
            }
        },
        dpf_sensors: {
            get() {
                return useBookingStore().dpf_sensors
            },
            set(value) {
                useBookingStore().setDPFSensors(value)
            }
        },
        swirl_flaps: {
            get() {
                return useBookingStore().swirl_flaps
            },
            set(value) {
                useBookingStore().setSwirlFlaps(value)
            }
        },
        hardcut: {
            get() {
                return useBookingStore().hardcut
            },
            set(value) {
                useBookingStore().setHardcut(value)
            }
        },
        launch: {
            get() {
                return useBookingStore().launch
            },
            set(value) {
                useBookingStore().setLaunch(value)
            }
        },
        hot_start_fix: {
            get() {
                return useBookingStore().hot_start_fix
            },
            set(value) {
                useBookingStore().setHotStartFix(value)
            }
        },
        adblue: {
            get() {
                return useBookingStore().adblue
            },
            set(value) {
                useBookingStore().setAdblue(value)
            }
        },
        left_foot_braking: {
            get() {
                return useBookingStore().left_foot_braking
            },
            set(value) {
                useBookingStore().setLeftFootBraking(value)
            }
        },
        track_use: {
            get() {
                return useBookingStore().track_use
            },
            set(value) {
                useBookingStore().setTrackUse(value)
            }
        },
        stopStart: {
            get() {
                return useBookingStore().stopStart
            },
            set(value) {
                useBookingStore().setStopStart(value)
            }
        },
        pipework: {
            get() {
                return useBookingStore().pipework
            },
            set(value) {
                useBookingStore().setPipework(value)
            }
        },
    },
    methods: {
        getDeposit(id) {
            var find = this.deposits.find(el => el.id = id);
            if(find) {
                this.selectedDeposit = find;
            }
        },
        toggleLoader: function (event, status) {
            var button = event.target.closest('button');
            if(status == "stop") {
                button.classList.remove('running')
                button.disabled = false
            } else if(status == "start") {
                button.classList.add('running')
                button.disabled = true
            }
        },
        validateTime(time) {
            var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
            return isValid;
        },
        book_in() {
            var name = this.selectedDeposit.customer.id;
            var reg = this.selectedDeposit.vehicle.VRVin;
            var usr = useAuthStore().user.nicename;
            var power = this.selectedDeposit.expected_power;
            var torque = this.selectedDeposit.expected_torque;
            var date = this.selectedDeposit.booking_date;
            var time = this.selectedDeposit.booking_time;

            this.vehicle_id = this.selectedDeposit.vehicle.ID;
            this.customer_id = this.selectedDeposit.customer.id;
            this.booked_in_by = usr;
            this.reg = this.selectedDeposit.reg;
            this.vin = this.selectedDeposit.vehicle.VRVin;
            this.expected_peak_torque = torque;
            this.expected_power = power;
            this.email = this.selectedDeposit.customer.email.email;
            this.phonenum = this.selectedDeposit.customer.phone.phonenumber;
            this.name = this.selectedDeposit.customer.firstname + " " + this.selectedDeposit.customer.lastname;
            this.dep_amount = this.selectedDeposit.deposit_paid;
            this.vehicle = this.selectedDeposit.vehicle.VRModel;
            this.customer = this.selectedDeposit.customer_activity;
            this.dropoff_date = this.selectedDeposit.booking_date;
            this.dropoff_time = this.selectedDeposit.booking_time;
            this.cpref = this.selectedDeposit.customer.customer_contact_pref.method;
            this.dep_ref = this.selectedDeposit.order_id;
            this.gearbox = this.selectedDeposit.vehicle.gearbox_code;
            this.gearbox_type = this.selectedDeposit.vehicle.gearboxType;
            this.engine_code = this.selectedDeposit.vehicle.enginecode;
            this.drivetrain = this.selectedDeposit.vehicle.driveType;
            this.$router.push(
                {
                    name: 'Booking',
                    query: {
                        name: name,
                        reg: reg,
                        usr: usr,
                        from: 'Deposit'
                    }
                }
            )
        }
    }
}
</script>

<style>
    tr.invalid {
        background-color: crimson;
        color: #fff;
    }
</style>
