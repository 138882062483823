<template>
    <div class="pt-14 pb-5 container-fluid">
        <h3 class="text-2xl py-4">Consumables</h3>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="maincard rounded overflow-hidden">
                    <div class="bg-white px-3 py-2 rounded-t-lg border-b border-gray-200 font-semibold">All Consumables</div>
                    <div class="overflow-x-auto">
                        <table class="table" id="allConsumables">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>SKU</th>
                                    <th>Minimum</th>
                                    <th>Stock Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="consumable in allConsumables">
                                    <td>{{ consumable.Title }}</td>
                                    <td>{{ consumable.SKU }}</td>
                                    <td>{{ consumable.MinimumLevel }}</td>
                                    <td>{{ consumable.StockLevel }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-md-12">
                <div class="maincard rounded overflow-hidden">
                    <div class="bg-white px-3 py-2 rounded-t-lg border-b border-gray-200 font-semibold">Low in stock</div>
                    <div class="overflow-x-auto">
                        <table class="table" id="lowStock">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>SKU</th>
                                    <th>Minimum</th>
                                    <th>Stock Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="consumable in lowStock">
                                    <td>{{ consumable.ItemTitle }}</td>
                                    <td>{{ consumable.ItemNumber }}</td>
                                    <td>{{ consumable.MinimumLevel }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!--<div class="row mb-2">
            <div class="col-md-12">
                <div class="maincard rounded overflow-hidden">
                    <div class="bg-white px-3 py-2 rounded-t-lg border-b border-gray-200 font-semibold">Used - Open Order</div>
                    <div class="overflow-x-auto">
                        <table class="table" id="openOrder">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="order in openOrders">
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
export default {
    name: 'Consumables',
    data() {
        return {
            openOrder: null,
            lowStock: [],
            allConsumables: [],
            openOrders: []
        }
    },
    mounted() {
        this.getAll();
        this.getLowStock();
    },
    methods: {
        getAll() {
            axios.get(api_url + '/v2/jobs/consumables/all').then((resp) => {
                this.allConsumables = resp.data.data;
            });
        },
        getLowStock() {
            axios.get(api_url + '/v2/jobs/consumables/lowstock').then((resp) => {
                this.lowStock = resp.data.data;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.table th {
    border-top: 0;
    background: #f9fafb;
}
.table thead th {
    border-top: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background: #f9fafb;
    &:last-of-type {
        border-right: 0;
    }
}
.table.parts-table tbody tr {
    td {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    &:first-of-type {
        td {
            border-top: 0;
        }
    }
}

</style>
