<template>
    <div class="pt-12 pb-4 container-fluid">
        <div class="row mb-3 pt-3">
            <div class="col-md-12">
                <div class="bg-white rounded border border-gray-200">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200 flex items-center font-semibold">Vehicles Due Servicing (Within the next month)</div>
                    <div class="overflow-x-auto">
                        <a-table :columns="columns" :data-source="jobs" :rowKey="record => record.id" :pagination="false" ref="extTable">
                        </a-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return {
            jobs: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.id - b.id,
                },
                {
                    title: 'Customer',
                    dataIndex: 'customer',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => {
                        const a1 = a.customer.firstname + " " + a.customer.lastname;
                        const b1 = b.customer.firstname + " " + b.customer.lastname;
                        return a1.localeCompare(b1)
                    },
                    customRender: (val) => {
                        return val.text.firstname + " " + val.text.lastname;
                    }
                },
                {
                    title: 'Customer Email',
                    dataIndex: 'customer.email.email',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.customer.email.email.localeCompare(b.customer.email.email),
                },
                {
                    title: 'Vehicle',
                    dataIndex: 'vehicle',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => {
                        const a1 = a.vehicle.VRMake + " " + a.vehicle.VRModel;
                        const b1 = b.vehicle.VRMake + " " + b.vehicle.VRModel;
                        return a1.localeCompare(b1)
                    },
                    customRender: (val) => {
                        return val.text.VRMake + " " + val.text.VRModel;
                    }
                },
                {
                    title: 'Reg',
                    dataIndex: 'vehicle.VRVrm',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.vehicle.VRVrm.localeCompare(b.vehicle.VRVrm)
                },
                {
                    title: 'Service Due Date',
                    dataIndex: 'next_service_date',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => new Date(a.next_service_date) - new Date(b.next_service_date),
                },
                {
                    title: 'Mileage Out',
                    dataIndex: 'mileage_out',
                    align: 'center',
                    width: "80px",
                    sorter: (a, b) => a.mileage_out - b.mileage_out,
                },
            ],
            pagination: {
                disabled: true
            }
        }
    },
    mounted(){
        this.getJobsDueService()
    },
    methods: {
        getJobsDueService() {
            axios.get(api_url+ "/v2/jobs/service-work").then((resp) => {
                this.jobs = resp.data;
            })
        }
    }
}
</script>

<style>

</style>
