<template>
<div class="booking">
    <div class="container mt-5 pb-3">
        <div class="row justify-content-center">
            <div class="col-md-6 text-center">
                <h2 class="text-3xl">Booking <small>Stage 1/4</small></h2>
                <h6 class="text-base">{{today}}</h6>
                <a-button class="flex items-center justify-center mx-auto mt-2" @click="loadJson">Load From File<input style="width:0; height:0;" type="file" id="load" ref="load" @change="populateJson"></a-button>
                <div v-if="errors.length" class="bg-red-300 border border-solid border-red-400 rounded w-full error-list mt-3 p-3">
                    <strong>Please correct the following error(s):</strong>
                    <ul class="list">
                        <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="container pb-5">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <a-select v-model:value="selectedDeposit" class="w-full mb-3" placeholder="Import from deposit..." @change="getDeposit" v-if="!$route.query.name">
                    <a-select-option v-for="deposit in deposits" :key="deposit.id" :value="deposit.id">
                       {{deposit.customer.firstname}} {{deposit.customer.lastname}} - {{deposit.customer.email.email}} ({{deposit.reg.toUpperCase()}})
                    </a-select-option>
                </a-select>

                <div class="maincard border-0">
                    <div class="p-3">
                        <div class="form-group">
                            <label class="block mb-1">Booked in by*</label>
                            <a-input size="small" type="text" class="form-control" :disabled="readonly === true" v-model:value="booked_in_by"/>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Customers Name*</label>
                            <a-input size="small"  type="text" class="form-control" placeholder="Customers name" v-model:value="name" required :loading="!gotCustomerData" :disabled="!gotCustomerData"></a-input>
                            <span v-show="mainErrors.name !== ''" class="block text-danger">{{mainErrors.name}}</span>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Contact Number*</label>
                            <a-input size="small"  type="text" class="form-control" placeholder="Contact Number" v-model:value="phonenum" required :disabled="!gotCustomerData" :loading="!gotCustomerData" />
                            <span v-show="mainErrors.phonenum !== ''" class="block text-danger">{{mainErrors.number}}</span>
                        </div>

                        <div class="mt-3">
                            <div class="form-group">
                                <label class="block mb-1">Customers Email*</label>
                                <a-input size="small"  type="email" class="form-control" placeholder="Customers Email" v-model:value="email" required :disabled="!gotCustomerData" :loading="!gotCustomerData"/>
                            </div>
                            <span v-show="mainErrors.email !== ''" class="block text-danger">{{mainErrors.email}}</span>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Contact Preference*</label>
                            <a-radio-group v-model:value="cpref">
                                <a-radio value="Phone">Phone</a-radio>
                                <a-radio value="Email">Email</a-radio>
                            </a-radio-group>
                            <span v-show="mainErrors.cpref !== ''" class="block text-danger">{{mainErrors.cpref}}</span>
                        </div>

                        <div class="mt-3 flex gap-3">
                            <div class="w-full">
                                <label class="block mb-1">Deposit Amount (Ex Vat)</label>
                                <a-input size="small"  prefix="£" type="text" class="form-control" placeholder="Deposit Amount" v-model:value="dep_amount" :disabled="!gotCustomerData" :loading="!gotCustomerData" />
                            </div>

                            <div class="w-full">
                                <label class="block mb-1">Deposit Ref</label>
                                <a-input size="small"  type="text" class="form-control" placeholder="Deposit Ref" v-model:value="dep_ref" :disabled="!gotCustomerData" :loading="!gotCustomerData" />
                            </div>
                        </div>

                        <div class="mt-3 flex gap-3">
                            <div class="w-full">
                                <label class="block mb-1">Vehicle*</label>
                                <a-input size="small"  type="text" class="form-control" placeholder="Vehicle" v-model:value="vehicle" required :disabled="!gotVehicleData" :loading="!gotVehicleData"/>
                                <span v-show="mainErrors.vehicle !== ''" class="block text-danger">{{mainErrors.vehicle}}</span>
                            </div>

                            <div class="w-full">
                                <label class="block mb-1">Reg*</label>
                                <a-input size="small"  type="text" class="form-control" placeholder="Reg num" v-model:value="reg" required :disabled="!gotVehicleData" :loading="!gotVehicleData"/>
                                <span v-show="mainErrors.reg !== ''" class="block text-danger">{{mainErrors.reg}}</span>
                            </div>

                            <div class="w-full">
                                <label class="block mb-1">VIN</label>
                                <a-input size="small"  type="text" class="form-control" placeholder="VIN" v-model:value="vin" required :disabled="!gotVehicleData" :loading="!gotVehicleData" />
                            </div>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Engine Code*</label>
                            <div class="flex gap-3 w-full">
                                <div class="w-full">
                                    <a-input size="small"  type="text" class="form-control" placeholder="Engine Code" v-model:value="engine_code" required :disabled="!gotVehicleData" :loading="!gotVehicleData" />
                                </div>
                                <div v-if="vin !== ''">
                                    <a-button size="small" type="primary" @click="getEngineCode($event)" :loading="gettingEngineCode">Get</a-button>
                                </div>
                            </div>
                            <span v-show="mainErrors.engine_code !== ''" class="block text-danger">{{mainErrors.engineCode}}</span>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Gearbox Code</label>
                            <div class="flex gap-3 w-full">
                                <div class="w-full">
                                    <a-input size="small"  type="text" class="form-control" placeholder="Gearbox Code" v-model:value="gearbox" required :disabled="!gotVehicleData" :loading="!gotVehicleData" />
                                </div>
                                <div v-if="vin !== ''">
                                    <a-button size="small" type="primary" @click="getGearboxCode($event)" :loading="gettingGearboxCode">Get</a-button>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Gearbox Type*</label>
                            <div class="flex gap-3 w-full">
                                <div class="w-full">
                                    <custom-select class="w-full" v-model="gearbox_type" :options="parts.gearbox_types" vparam="value" lparam="label"></custom-select>
                                </div>
                                <div v-if="vin !== ''">
                                    <a-button size="small" type="primary" @click="getGearboxTypeFromCode($event)" :loading="gettingGearboxTypeFromCode">Get</a-button>
                                </div>
                            </div>
                            <span v-show="mainErrors.gearbox_type !== ''" class="block text-danger">{{mainErrors.gearboxType}}</span>
                        </div>

                        <div class="mt-3">
                            <label class="block mb-1">Drivetrain*</label>
                            <custom-select class="w-full" v-model="drivetrain" :options="drivetrain_options" vparam="value" lparam="label"></custom-select>
                            <span v-show="mainErrors.drivetrain !== ''" class="block text-danger">{{mainErrors.drivetrain}}</span>
                        </div>

                        <!--<div class="mt-3">
                            <a-checkbox v-model:checked="has_ghost_alarm">Vehicle Has Ghost Alarm System?</a-checkbox>
                            <div v-if="has_ghost_alarm" class="mt-1">
                                <a-input type="text" class="form-control" v-model:value="ghost_alarm_code" placeholder="Ghost Alarm Code..."/>
                            </div>
                        </div>-->

                        <div class="mt-3">
                            <label class="block mb-1"><b>Diary*</b></label>
                            <div class="form-group fs-sm">
                                <a-checkbox v-model:checked="tun">Tuning - Darkside</a-checkbox>
                                <a-checkbox v-model:checked="ws">Workshop</a-checkbox>
                                <a-checkbox v-model:checked="fab">Fabrication</a-checkbox>
                                <!--<a-checkbox v-model:checked="bodyshop">Bodyshop</a-checkbox>-->
                            </div>
                            <span v-show="mainErrors.diary !== ''" class="block text-danger">{{mainErrors.diary}}</span>
                        </div>

                        <div class="mt-3">
                            <div class="form-group fs-sm">
                                <a-checkbox v-model:checked="is_warranty">Is Warranty Job?</a-checkbox>
                            </div>
                        </div>

                        <div class="row mt-2 justify-content-center">
                            <div class="col-md-6">
                                <a-button type="primary" class="w-full" @click="changeStep(2, 'next')" :disabled="!gotVehicleData || !gotCustomerData">Next Stage</a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import {useBookingStore} from "@/store/bookingStore";
import {useAuthStore} from "@/store/authStore";
import customSelect from '@/components/extras/Select.vue';
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    created(){
    },
    components: {
        customSelect
    },
    mounted(){
        this.customer_id = this.$route.query.name;
        this.booked_in_by = useAuthStore().user.nicename;
        this.booked_in_reg = this.$route.query.reg;
        if(this.booked_in_by !== undefined) {this.readonly = true;}

        this.tun = false;
        this.ws = false;
        this.fab = false;
        this.tvvt = false;
        this.bodyshop = false;
        this.ecu_num = "";
        this.tuned_by = "Select One";
        this.tuned_by_other = "";
        this.claimed_power = "";
        this.claimed_torque = "";
        this.current_power = "";
        this.current_torque = "";
        this.turbocharger = "Select One";
        this.injectors = "Select One";
        this.pump = "Select One";
        this.power_tune = "No";
        this.map_sensor = "Select One";
        this.intercooler = "Select One";
        this.pipework = "Select One";
        this.camshaft = "Select One";
        this.cylinder_head = "Select One";
        this.clutch_flywheel = "Select One";
        this.turbocharger_other = "";
        this.injectors_other = "";
        this.pump_other = "";
        this.map_sensor_other = "";
        this.intercooler_other = "";
        this.pipework_other = "";
        this.camshaft_other = "";
        this.cylinder_head_other = "";
        this.clutch_flywheel_other = "";
        this.other_mods = "";
        this.gearbox_mods = "";
        this.maf = "On";
        this.egr_hardware = "On";
        this.egr_software = "On";
        this.asv = "On";
        this.cat = "On";
        this.dpf = "On";
        this.dpf_sensors = "On";
        this.swirl_flaps = "On";
        this.hardcut = "No";
        this.launch = "No";
        this.hot_start_fix = "No";
        this.adblue = "NA";
        this.left_foot_braking = "NA";
        this.track_use = false;
        this.gearbox_tune = "NA";
        this.smoke_level = "Smoke Free";
        this.dropoff_date = null;
        this.dropoff_time = null;
        this.start_date = null;
        this.start_time = null;
        this.finish_date = null;
        this.finish_time = null;
        this.collect_date = null;
        this.collect_time = null;
        this.notes = "";
        this.bhp = "";
        this.torque = "";
        this.stopStart = "NA";
        this.has_ghost_alarm = false;
        this.ghost_alarm_code = null;
        this.parts = [];
        if(!this.$route.query.from) {
            this.expected_power = "";
            this.expected_peak_torque = "";
            this.vehicle_id = "";
            this.email = "";
            this.name = "";
            this.phonenum = "";
            this.dep_amount = "";
            this.vehicle = "";
            this.reg = "";
            this.vin = "";
            this.dropoff_date = null;
            this.dropoff_time = null;
            this.customer = "";
            this.cpref = "Email";
            this.dep_ref = "";
            this.gearbox = "Select One";
            this.gearbox_other = "";
            this.gearbox_type = "Select One";
            this.gearbox_type_other = "";
            this.engine_code = "Select One";
            this.engine_code_other = "";
            this.drivetrain = "Select One";
        }
        this.getDeposits();
        this.getParts();
    },
    data(){
        return {
            selectedDeposit: null,
            today: moment().format('MMMM Do YYYY, h:mm:ss a'),
            now: moment().format('YYYY-MM-DD'),
            readonly: false,
            gotCustomerData: false,
            gotVehicleData: false,
            gotPartsData: false,
            gettingGearboxTypeFromCode: false,
            gettingGearboxCode: false,
            gettingEngineCode: false,
            deposits: [],
            mainErrors: {
                name: "",
                number: "",
                email: "",
                cpref: "",
                vehicle: "",
                engineCode: "",
                gearboxType: "",
                drivetrain: "",
                diary: ""
            },
            errors: [],
            drivetrain_options: [{"value": "Select One","label": "Select One"},{"value": "FWD","label": "FWD"},{"value": "Haldex","label": "Haldex"},{"value": "RWD","label": "RWD"},{"value": "Quattro","label": "Quattro"},{"value": "X-Drive","label": "X-Drive"},{"value": "Confirm With Customer","label": "Confirm With Customer"}],
            depositModal: false,
        }
    },
    validations: {
        name: {
            required,
            minLength: minLength(1)
        },
        email: {
            required,
            minLength: minLength(1)
        },
        cpref: {
            required
        },
        vehicle: {
            required,
            minLength: minLength(1)
        },
        reg: {
            required,
            minLength: minLength(1)
        },
        vin: {
            required,
            minLength: minLength(1)
        },
        engine_code: {
            required
        },
        gearbox_type: {
            required
        },
        drivetrain: {
            required
        },
    },
    computed: {
        booked_in_reg: {
            get() {
                return useBookingStore().booked_in_reg
            },
            set(value) {
                useBookingStore().setBookedInReg(value)
            }
        },
        vehicle_id: {
            get() {
                return useBookingStore().vehicle_id
            },
            set(value) {
                useBookingStore().setVehicleId(value)
            }
        },
        customer_id: {
            get() {
                return useBookingStore().customer_id
            },
            set(value) {
                useBookingStore().setCustomerId(value)
            }
        },
        booked_in_by: {
            get() {
                return useBookingStore().booked_in_by
            },
            set(value) {
                useBookingStore().setBookedInBy(value)
            }
        },
        email: {
            get() {
                return useBookingStore().email
            },
            set(value) {
                useBookingStore().setEmail(value)
            }
        },
        name: {
            get() {
                return useBookingStore().name
            },
            set(value) {
                useBookingStore().setName(value)
            }
        },
        phonenum: {
            get() {
                return useBookingStore().phonenum
            },
            set(value) {
                useBookingStore().setPhone(value)
            }
        },
        cpref: {
            get() {
                return useBookingStore().cpref
            },
            set(value) {
                useBookingStore().setContactPref(value)
            }
        },
        dep_amount: {
            get() {
                return useBookingStore().dep_amount
            },
            set(value) {
                useBookingStore().setDepAmount(value)
            }
        },
        dep_ref: {
            get() {
                return useBookingStore().dep_ref
            },
            set(value) {
                useBookingStore().setDepRef(value)
            }
        },
        vehicle: {
            get() {
                return useBookingStore().vehicle
            },
            set(value) {
                useBookingStore().setVehicle(value)
            }
        },
        reg: {
            get() {
                return useBookingStore().reg
            },
            set(value) {
                useBookingStore().setReg(value)
            }
        },
        vin: {
            get() {
                return useBookingStore().vin
            },
            set(value) {
                useBookingStore().setVin(value)
            }
        },
        ecu_num: {
            get() {
                return useBookingStore().ecu_num
            },
            set(value) {
                useBookingStore().setECUNum(value)
            }
        },
        engine_code: {
            get() {
                return useBookingStore().engine_code
            },
            set(value) {
                useBookingStore().setEngineCode(value)
            }
        },
        engine_code_other: {
            get() {
                return useBookingStore().engine_code_other
            },
            set(value) {
                useBookingStore().setEngineCodeOther(value)
            }
        },
        gearbox: {
            get() {
                return useBookingStore().gearbox
            },
            set(value) {
                useBookingStore().setGearbox(value)
            }
        },
        gearbox_other: {
            get() {
                return useBookingStore().gearbox_other
            },
            set(value) {
                useBookingStore().setGearboxOther(value)
            }
        },
        gearbox_type: {
            get() {
                return useBookingStore().gearbox_type
            },
            set(value) {
                useBookingStore().setGearboxType(value)
            }
        },
        gearbox_type_other: {
            get() {
                return useBookingStore().gearbox_type_other
            },
            set(value) {
                useBookingStore().setGearboxTypeOther(value)
            }
        },
        drivetrain: {
            get() {
                return useBookingStore().drivetrain
            },
            set(value) {
                useBookingStore().setDrivetrain(value)
            }
        },
        has_ghost_alarm: {
            get() {
                return useBookingStore().has_ghost_alarm
            },
            set(value) {
                useBookingStore().setHasGhostAlarm(value)
            }
        },
        ghost_alarm_code: {
            get() {
                return useBookingStore().ghost_alarm_code
            },
            set(value) {
                useBookingStore().setGhostAlarmCode(value)
            }
        },
        tun: {
            get() {
                return useBookingStore().tun
            },
            set(value) {
                useBookingStore().setTuning(value)
            }
        },
        ws: {
            get() {
                return useBookingStore().ws
            },
            set(value) {
                useBookingStore().setWorkshop(value)
            }
        },
        fab: {
            get() {
                return useBookingStore().fab
            },
            set(value) {
                useBookingStore().setFabrication(value)
            }
        },
        vvt: {
            get() {
                return useBookingStore().tvvt
            },
            set(value) {
                useBookingStore().setTvvt(value)
            }
        },
        bodyshop: {
            get() {
                return useBookingStore().bodyshop
            },
            set(value) {
                useBookingStore().setBodyshop(value)
            }
        },
        is_warranty: {
            get() {
                return useBookingStore().is_warranty
            },
            set(value) {
                useBookingStore().setIsWarranty(value)
            }
        },
        parts: {
            get() {
                return useBookingStore().parts
            },
            set(value) {
                useBookingStore().setParts(value)
            }
        },
        current_power: {
            get() {
                return useBookingStore().current_power
            },
            set(value) {
                useBookingStore().setCurrentPower(value)
            }
        },
        current_torque: {
            get() {
                return useBookingStore().current_torque
            },
            set(value) {
                useBookingStore().setCurrentTorque(value)
            }
        },
        turbocharger: {
            get() {
                return useBookingStore().turbocharger
            },
            set(value) {
                useBookingStore().setTurbocharger(value)
            }
        },
        injectors: {
            get() {
                return useBookingStore().injectors
            },
            set(value) {
                useBookingStore().setInjectors(value)
            }
        },
        pump: {
            get() {
                return useBookingStore().pump
            },
            set(value) {
                useBookingStore().setPump(value)
            }
        },
        map_sensor: {
            get() {
                return useBookingStore().map_sensor
            },
            set(value) {
                useBookingStore().setMapSensor(value)
            }
        },
        intercooler: {
            get() {
                return useBookingStore().intercooler
            },
            set(value) {
                useBookingStore().setIntercooler(value)
            }
        },
        camshaft: {
            get() {
                return useBookingStore().camshaft
            },
            set(value) {
                useBookingStore().setCamshaft(value)
            }
        },
        cylinder_head: {
            get() {
                return useBookingStore().cylinder_head
            },
            set(value) {
                useBookingStore().setCylHead(value)
            }
        },
        clutch_flywheel: {
            get() {
                return useBookingStore().clutch_flywheel
            },
            set(value) {
                useBookingStore().setClutchFlywheel(value)
            }
        },
        smoke_level: {
            get() {
                return useBookingStore().smoke_level
            },
            set(value) {
                useBookingStore().setSmokeLevel(value)
            }
        },
        other_mods: {
            get() {
                return useBookingStore().other_mods
            },
            set(value) {
                useBookingStore().setOtherMods(value)
            }
        },
        gearbox_mods: {
            get() {
                return useBookingStore().gearbox_mods
            },
            set(value) {
                useBookingStore().setGearboxMods(value)
            }
        },
        expected_power: {
            get() {
                return useBookingStore().expected_power
            },
            set(value) {
                useBookingStore().setExpectedPower(value)
            }
        },
        expected_peak_torque: {
            get() {
                return useBookingStore().expected_peak_torque
            },
            set(value) {
                useBookingStore().setExpectedPeakTorque(value)
            }
        },
        power_tune: {
            get() {
                return useBookingStore().power_tune
            },
            set(value) {
                useBookingStore().setPowerTune(value)
            }
        },
        gearbox_tune: {
            get() {
                return useBookingStore().gearbox_tune
            },
            set(value) {
                useBookingStore().setGearboxTune(value)
            }
        },
        maf: {
            get() {
                return useBookingStore().maf
            },
            set(value) {
                useBookingStore().setMaf(value)
            }
        },
        egr_hardware: {
            get() {
                return useBookingStore().egr_hardware
            },
            set(value) {
                useBookingStore().setEGRHardware(value)
            }
        },
        egr_software: {
            get() {
                return useBookingStore().egr_software
            },
            set(value) {
                useBookingStore().setEGRSoftware(value)
            }
        },
        asv: {
            get() {
                return useBookingStore().asv
            },
            set(value) {
                useBookingStore().setASV(value)
            }
        },
        cat: {
            get() {
                return useBookingStore().cat
            },
            set(value) {
                useBookingStore().setCat(value)
            }
        },
        dpf: {
            get() {
                return useBookingStore().dpf
            },
            set(value) {
                useBookingStore().setDPF(value)
            }
        },
        dpf_sensors: {
            get() {
                return useBookingStore().dpf_sensors
            },
            set(value) {
                useBookingStore().setDPFSensors(value)
            }
        },
        swirl_flaps: {
            get() {
                return useBookingStore().swirl_flaps
            },
            set(value) {
                useBookingStore().setSwirlFlaps(value)
            }
        },
        hardcut: {
            get() {
                return useBookingStore().hardcut
            },
            set(value) {
                useBookingStore().setHardcut(value)
            }
        },
        launch: {
            get() {
                return useBookingStore().launch
            },
            set(value) {
                useBookingStore().setLaunch(value)
            }
        },
        hot_start_fix: {
            get() {
                return useBookingStore().hot_start_fix
            },
            set(value) {
                useBookingStore().setHotStartFix(value)
            }
        },
        adblue: {
            get() {
                return useBookingStore().adblue
            },
            set(value) {
                useBookingStore().setAdblue(value)
            }
        },
        left_foot_braking: {
            get() {
                return useBookingStore().left_foot_braking
            },
            set(value) {
                useBookingStore().setLeftFootBraking(value)
            }
        },
        track_use: {
            get() {
                return useBookingStore().track_use
            },
            set(value) {
                useBookingStore().setTrackUse(value)
            }
        },
        stopStart: {
            get() {
                return useBookingStore().stopStart
            },
            set(value) {
                useBookingStore().setStopStart(value)
            }
        },
        pipework: {
            get() {
                return useBookingStore().pipework
            },
            set(value) {
                useBookingStore().setPipework(value)
            }
        },
    },
    methods: {
        changedGearboxCode(){
            console.log("CHANGED")
        },
        getParts(){
            axios.get(api_url + '/v2/jobs/booking/parts').then((response) => {
                const d = response.data.data;
                this.parts = {
                    engine_codes: this.addExtraSelectOptions(d.engine_codes),
                    gearbox_codes: this.addExtraSelectOptions(d.gearbox_codes),
                    gearbox_types: this.addExtraSelectOptions(d.gearbox_types),
                    layout: d.layout,
                    ecu_family: {
                        ecu_type: d.ecu_fam.ecu_type,
                        ecu_fam: d.ecu_fam.ecu_fam
                    },
                    tuned_by: this.addExtraSelectOptions(d.tuned_by),
                    turbo: this.addExtraSelectOptions(d.turbo),
                    injectors: this.addExtraSelectOptions(d.injectors),
                    pump: this.addExtraSelectOptions(d.pump),
                    mapsensor: this.addExtraSelectOptions(d.map),
                    intercooler: this.addExtraSelectOptions(d.intercooler),
                    pipework: this.addExtraSelectOptions(d.pipework),
                    camshaft: this.addExtraSelectOptions(d.camshaft),
                    cyl_head: this.addExtraSelectOptions(d.cyl_head),
                    clutch: this.addExtraSelectOptions(d.clutch),
                }
                this.gotPartsData = true;

                if(this.email === "" || this.phonenum === "") {
                    this.getCustomerDetails();
                } else {
                    this.gotCustomerData = true;
                }
                this.getVehicleDetails();
            }).catch((error) => {
                console.log(error);
            });
        },
        getVehicleDetails(){
            let p_reg = this.booked_in_reg;
            if(p_reg != undefined) {
                p_reg = p_reg.replace(' ', '')
                // Make a request for a vehicle with a given REG/VIN
                /*axios.post('https://api.darkside-developments.com/api/crm/vehicle/search?id=' + p_reg).then((resp) => {
                    const data = resp.data;
                    if(data.ecu_type !== null) {this.ecu_num = data.ecu_type}
                    if(data.engine_code !== null) {this.engine_code = data.engine_code}
                    if(data.transmission !== null) {this.gearbox_type = data.transmission}

                    if(data.engine_code !== null) {
                        this.engine_code = data.engine_code;
                        const newOption = new Option(this.engine_code, this.engine_code, true, true);
                    }
                    if(data.gearbox_code !== null) {
                        this.gearbox = data.gearbox_code
                    }

                    if (data.drive_type !== null) {
                        if(data.drive_type === "4WD" && data.layout === "Longitudinal"){
                            this.drivetrain = "Quattro"
                        } else if(data.drive_type === "4WD" && data.layout === "Transverse"){
                            this.drivetrain = "Haldex"
                        } else {
                            this.drivetrain = data.drive_type
                        }
                    }

                    axios.get(api_url + '/api/jobs/vehicle/'+p_reg).then((response) => {
                        const d = response.data;
                        this.vehicle_id = d.ID;
                        if (d.VRVrm !== null){ this.reg = d.VRVrm }
                        if (d.VRVin !== null){ this.vin = d.VRVin }
                        if (d.VRModel !== null){ this.vehicle = d.VRModel }
                        if (d.customer_camshaft !== null) { this.camshaft = d.customer_camshaft }
                        if (d.customer_claimed_power !== null) { this.claimed_power = d.customer_claimed_power }
                        if (d.customer_claimed_torque !== null) { this.claimed_torque = d.customer_claimed_torque }
                        if (d.customer_clutch_and_flywheel !== null) { this.clutch_flywheel = d.customer_clutch_and_flywheel }
                        if (d.customer_expected_peak_torque !== null) { this.expected_peak_torque = d.customer_expected_peak_torque}
                        if (d.customer_expected_power !== null) { this.expected_power = d.customer_expected_power}
                        if (d.fuel_type !== null) { this.fuel_type = d.fuel_type}
                        if (d.gearbox_code !== null) { this.gearbox = d.gearbox_code}
                        if (d.customer_gearbox_mods !== null) { this.gearbox_mods = d.customer_gearbox_mods}
                        if (d.customer_injectors !== null) { this.injectors = d.customer_injectors}
                        if (d.customer_intercooler !== null) { this.intercooler = d.customer_intercooler}
                        if (d.customer_map_sensor !== null) { this.map_sensor = d.customer_map_sensor}
                        if (d.customer_other_mods !== null) { this.other_mods = d.customer_other_mods}
                        if (d.customer_pipework !== null) { this.pipework = d.customer_pipework}
                        if (d.customer_power !== null) { this.current_power = d.customer_power}
                        if (d.customer_pump !== null) { this.pump = d.customer_pump}
                        if (d.customer_smoke_level !== null) { this.smoke_level = d.customer_smoke_level}
                        if (d.customer_turbocharger !== null) { this.turbocharger = d.customer_turbocharger}
                        if (d.driveType !== null) {
                            if(d.driveType === "4WD" && d.layout === "Longitudinal"){
                                this.drivetrain = "Quattro"
                            } else if(d.driveType === "4WD" && d.layout === "Transverse"){
                                this.drivetrain = "Haldex"
                            } else {
                                this.drivetrain = d.driveType
                            }
                        }
                        if (d.hp !== null) {this.bhp = d.hp}
                        if (d.Torque !== null) {this.torque = d.Torque}
                        if (d.customer_cylinder !== null) { this.cylinder_head = d.customer_cylinder}

                        if (d.enginecode !== null) {
                            const ec = d.enginecode;
                            axios.get(api_url + '/api/jobs/vehicle/ecu?engineCode=' + ec)
                            .then((response) => {
                                if (response.data.message !== "No Results") {
                                    this.ecu_num = response.data.message;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                        }

                        if(this.gearbox_type == null || this.gearbox_type === '' || this.gearbox_type === '-') {
                            if (d.gearboxType !== null) { this.gearbox_type = d.gearboxType}
                        }

                        if(this.ecu_num == null || this.ecu_num === '' || this.ecu_num === '-') {
                            if (d.ecu !== null) { this.ecu_num = d.ecu}
                        }

                        if(this.engine_code == null || this.engine_code === '' || this.engine_code === '-') {
                            if (d.enginecode !== null) { this.engine_code = d.enginecode}
                        }

                        this.gotVehicleData = true;
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        if(this.gearbox === "" || this.gearbox == null) {
                            axios.post(api_url + '/api/jobs/vehicle/get/gearboxcode',{
                                vin: this.vin
                            }).then((resp) => {
                                if(resp.data !== "none") {
                                    this.gearbox = resp.data;
                                }
                            });
                        }
                        this.loading = false;
                    });
                });*/
                axios.get(api_url + '/v2/jobs/vehicle/'+p_reg).then((response) => {
                    const d = response.data;
                    this.vehicle_id = d.ID;
                    if (d.VRVrm !== null){ this.reg = d.VRVrm }
                    if (d.VRVin !== null){ this.vin = d.VRVin }
                    if (d.VRModel !== null){ this.vehicle = d.VRModel }
                    if (d.customer_camshaft !== null) { this.camshaft = d.customer_camshaft }
                    if (d.customer_claimed_power !== null) { this.claimed_power = d.customer_claimed_power }
                    if (d.customer_claimed_torque !== null) { this.claimed_torque = d.customer_claimed_torque }
                    if (d.customer_clutch_and_flywheel !== null) { this.clutch_flywheel = d.customer_clutch_and_flywheel }
                    if (d.customer_expected_peak_torque !== null) { this.expected_peak_torque = d.customer_expected_peak_torque}
                    if (d.customer_expected_power !== null) { this.expected_power = d.customer_expected_power}
                    if (d.fuel_type !== null) { this.fuel_type = d.fuel_type}
                    if (d.gearbox_code !== null) { this.gearbox = d.gearbox_code}
                    if (d.customer_gearbox_mods !== null) { this.gearbox_mods = d.customer_gearbox_mods}
                    if (d.customer_injectors !== null) { this.injectors = d.customer_injectors}
                    if (d.customer_intercooler !== null) { this.intercooler = d.customer_intercooler}
                    if (d.customer_map_sensor !== null) { this.map_sensor = d.customer_map_sensor}
                    if (d.customer_other_mods !== null) { this.other_mods = d.customer_other_mods}
                    if (d.customer_pipework !== null) { this.pipework = d.customer_pipework}
                    if (d.customer_power !== null) { this.current_power = d.customer_power}
                    if (d.customer_pump !== null) { this.pump = d.customer_pump}
                    if (d.customer_smoke_level !== null) { this.smoke_level = d.customer_smoke_level}
                    if (d.customer_turbocharger !== null) { this.turbocharger = d.customer_turbocharger}
                    if (d.driveType !== null) {
                        if(d.driveType === "4WD" && d.layout === "Longitudinal"){
                            this.drivetrain = "Quattro"
                        } else if(d.driveType === "4WD" && d.layout === "Transverse"){
                            this.drivetrain = "Haldex"
                        } else {
                            this.drivetrain = d.driveType
                        }
                    }
                    if (d.hp !== null) {this.bhp = d.hp}
                    if (d.Torque !== null) {this.torque = d.Torque}
                    if (d.customer_cylinder !== null) { this.cylinder_head = d.customer_cylinder}
                    if (d.enginecode !== null) { this.engine_code = d.enginecode}
                    if (d.gearboxType !== null) { this.gearbox_type = d.gearboxType}

                    if (d.enginecode !== null) {
                        const ec = d.enginecode;
                        axios.get(api_url + '/v2/jobs/vehicle/ecu?engineCode=' + ec)
                            .then((response) => {
                                if (response.data.message !== "No Results" && response.data.message !== "No ECU Found!") {
                                    this.ecu_num = response.data.message;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                    }

                    if(this.gearbox_type == null || this.gearbox_type === '' || this.gearbox_type === '-') {
                        if (d.gearboxType !== null) { this.gearbox_type = d.gearboxType}
                    }


                    if(d.ecu_type !== null) {
                        this.ecu_num = d.ecu_type
                    } else {
                        this.ecu_num = d.ecu
                    }

                    if(this.ecu_num === null || this.ecu_num === '' || this.ecu_num === '-') {
                        if (d.ecu !== null) {
                            this.ecu_num = d.ecu
                        }
                    }

                    if(this.engine_code == null || this.engine_code === '' || this.engine_code === '-') {
                        if (d.enginecode !== null) { this.engine_code = d.enginecode}
                    }

                    this.gotVehicleData = true;
                })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        if(this.gearbox === "" || this.gearbox == null) {
                            axios.post(api_url + '/v2/jobs/vehicle/get/gearboxcode',{
                                vin: this.vin
                            }).then((resp) => {
                                if(resp.data !== "none") {
                                    this.gearbox = resp.data;
                                }
                            });
                        }
                        this.loading = false;
                    });

            } else {
                this.gotCustomerData = true;
                this.gotVehicleData = true;
            }
        },
        getCustomerDetails(){
            const p_cust_id = this.customer_id;
            if(p_cust_id != undefined) {
                // Make a request for a customer with a given ID
                axios.get(api_url + '/v2/jobs/customer/' + p_cust_id)
                .then((response) => {
                    const d = response.data;
                    if(!d['message']) {
                        this.name = d.firstname+" "+d.lastname;
                        if(d.email) {
                            this.email = d.email.email;
                        } else {
                            this.email = "";
                            alert('No email for customer.');
                        }
                        if(d.phone) {
                            this.phonenum = d.phone.phonenumber;
                        } else {
                            this.phonenum = "";
                            alert('No phone number for customer.');
                        }
                        this.customer_id = d.id;
                        this.gotCustomerData = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        changeStep(step, direction){
            this.errors = [];
            if(this.v$.$invalid) {
                if(this.v$.name.$invalid) {this.errors.push('Customer Name is required.')}
                if(this.v$.email.$invalid) {this.errors.push('Customer Email is required.')}
                if(this.v$.cpref.$invalid) {this.errors.push('Contact Preference is required.')}
                if(this.v$.vehicle.$invalid) {this.errors.push('Customer Vehicle is required.')}
                if(this.v$.reg.$invalid) {this.errors.push('Vehicle Reg is required.')}
                if(this.v$.vin.$invalid) {this.errors.push('Vehicle VIN is required.')}
                if(this.v$.engine_code.$invalid) {this.errors.push('Vehicle Engine Code is required.')}
                if(this.v$.gearbox_type.$invalid) {this.errors.push('Vehicle Gearbox Type is required.')}
                if(this.v$.drivetrain.$invalid) {this.errors.push('Vehicle Drivetrain is required.')}
                this.scrollToTop();
            } else {
                if(this.ws !== true && this.fab !== true && this.tun !== true && this.vvt !== true && this.bodyshop !== true){
                    this.errors.push('Choose a diary to add job to.');
                    this.scrollToTop();
                    return;
                }
                if(this.engine_code === "Select One..." ){this.errors.push('Vehicle Engine Code is required.'); this.scrollToTop(); return;}
                if(this.gearbox_type === "Select One..." ){this.errors.push('Vehicle Gearbox Type is required.'); this.scrollToTop(); return;}

                if(this.tun === true || this.vvt === true) {
                    this.$parent.step = 2;
                    this.scrollToTop();
                } else {
                    this.$parent.step = 3;
                    this.scrollToTop();
                }
            }
        },
        scrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        },
        addExtraSelectOptions(arr) {
            const first = {"value": "Select One", "label": "Select One..."};
            //var other = {"value": "Other", "label": "Other"};
            const last = {"value": "Confirm With Customer", "label": "Confirm With Customer"};
            arr.unshift(first);
            //arr.push(other);
            arr.push(last);
            return arr;
        },
        log(text){
            console.log(text)
        },
        getEngineCode(e){
            this.gettingEngineCode = true;
            axios.post(api_url + '/v2/jobs/vehicle/get/enginecode',{
                vin: this.vin
            }).then((resp) => {
                if(resp.data !== "none") {
                    this.engine_code = resp.data;
                }
                this.gettingEngineCode = false;
            });
        },
        getGearboxCode(e){
            this.gettingGearboxCode = true;
            axios.post(api_url + '/v2/jobs/vehicle/get/gearboxcode',{
                vin: this.vin
            }).then((resp) => {
                if(resp.data !== "none") {
                    this.gearbox = resp.data;
                }
                this.gettingGearboxCode = false;
            });
        },
        getGearboxType(e){
            this.toggleLoader(e, 'start');
            axios.post(api_url + '/v2/jobs/vehicle/get/gearboxtype',{
                vin: this.vin
            }).then((resp) => {
                if(resp.data !== "none") {
                    this.gearbox_type = resp.data;
                }
                this.toggleLoader(e, 'stop');
            });
        },
        getGearboxTypeFromCode(e){
            if(this.gearbox === '' || this.gearbox == null) {
                return;
            }
            this.gettingGearboxTypeFromCode = true;
            axios.get(api_url + '/api/crm/vehicle/gearbox/' + this.gearbox).then((response) => {
                if (response.data !== "No Results" && response.data !== "") {
                    this.gearbox_type = response.data;
                }
                this.gettingGearboxTypeFromCode = false;
            });
        },
        getDeposits() {
            axios.get(api_url + '/v2/jobs/booking/deposits').then((resp) => {
                resp.data.forEach(el => {
                    if(el.customer) {
                        this.deposits.push(el)
                    }
                })
            });
        },
        getDeposit(id) {
            const find = this.deposits.find(el => el.id === id);
            if(find) {
                console.log(find)
                this.gotCustomerData = false;
                this.gotVehicleData = false;
                this.customer_id = find.customer_id;
                this.customer = find.customer_activity;
                this.booked_in_reg = find.vehicle.VRVin;
                this.getCustomerDetails();
                this.getVehicleDetails();
                this.dep_amount = find.deposit_paid_inc_vat;
                this.expected_power = find.expected_power;
                this.expected_peak_torque = find.expected_torque;
                this.dropoff_date = find.booking_date;
                this.dropoff_time = find.booking_time;
                console.log(find)
            }
        },
        loadJson() {
            this.$refs.load.click()
        },
        populateJson(e) {
            const self = this;
            if(e.target.files[0].type === "application/json") {
                const store = this.$store;
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = function (evt) {
                    const result = evt.target.result;
                    if(JSON.parse(result)) {
                        const json = JSON.parse(result);
                        store.commit('setState', json)
                        console.log(json)
                        self.$forceUpdate();
                    } else {
                        alert('Error reading file!')
                    }
                }
                reader.onerror = function () {
                    alert("Error reading file!")
                }
            } else {
                alert('Invalid JSON File!')
            }
        }
    },
    watch: {
        gearbox(val) {
            if (val !== null && val !== '') {
            }
        }
    }
}
</script>

<style>

</style>
