<template>
    <div class="pt-12 pb-4 container-fluid">
        <div v-if="gotData">
            <div class="flex py-3 items-center">
                <h4 class="text-xl mt-2">Job Name : {{job.customer.firstname}} {{job.customer.lastname}} -
                    <template v-if="job.customer.emails.length">
                        {{job.customer.emails[0].email}}
                    </template>
                    -
                    <template v-if="job.customer.phones.length">
                        {{job.customer.phones[0].phonenumber}}
                    </template>
                    [{{job.vehicle.VRVrm}}]</h4>
                <div class="ml-auto">
                    <div class="flex gap-2">
                        <a-button size="small" title="Tuning" @click="goToTuningView"><i class="fa fa-cogs"></i></a-button>
                        <a-button size="small" title="Jobsheet" @click="goToJobsheetView"><i class="fa fa-file-alt"></i></a-button>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <div class="h-full">
                    <job-customer :job="job" view-only></job-customer>
                </div>

                <div class="h-full">
                    <job-vehicle :job="job" view-only></job-vehicle>
                </div>
            </div>

            <div class="grid grid-cols-1 mt-3">
                <job-details :job="job" :users="users" view-only></job-details>
            </div>

            <div class="grid grid-cols-1 mt-3">
                <job-prices :job="job" ref="price_table" :labour-rates="labRateProps" view-only></job-prices>
            </div>

            <div class="bg-white px-3 py-2 mt-3 border border-gray-200" style="border-radius: 0.375rem;">
                <label class="block font-semibold mb-2"><fa-icon solid icon="fa-notes" class="mr-2"/><span class="">Notes</span></label>
                <text-editor ref="jobnote" v-model="job.note" class-name="main-notes" :readonly="true"></text-editor>
            </div>

            <div class="bg-white px-3 py-2 mt-3 border border-gray-200" style="border-radius: 0.375rem;">
                <label class="block font-semibold mb-2"><fa-icon solid icon="fa-notes" class="mr-2"/><span class="">Customer Notes</span></label>
                <text-editor ref="jobnote" v-model="job.customer_notes" class-name="customer-notes" :readonly="true"></text-editor>
            </div>

            <div class="mt-3" v-if="unauthed_parts.length > 0">
                <div class="border border-gray-200 rounded">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200">
                        <div class="flex items-center w-full">
                            <span class="font-semibold">Unauthed Parts</span>
                            <span class="ml-auto">
                                <a-button class="mr-3" type="primary" size="small" @click="copyToClipboard"><i class="fa fa-clipboard mr-2"></i> Copy Parts List to Clipboard</a-button>
                                <a-button size="small" @click="exportToExcel('parts_table')"><i class="fa fa-clipboard mr-2"></i> Export to Excel</a-button>
                                <a id="dlink1" class="d-none">DL</a>
                            </span>
                        </div>
                    </div>
                    <div class="table-responsive rounded-b">
                        <table class="table" id="unauthedparts_table">
                            <thead>
                                <th style="width:300px"><b>Task</b></th>
                                <th><b>Retail</b></th>
                                <th><b>Part No</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Total</b></th>
                                <th><b>Labour Hrs</b></th>
                                <th><b>Job Type</b></th>
                                <th><b>Total Labour</b></th>
                                <th><b>Row Total</b></th>
                            </thead>
                            <tbody class="parts_table">
                                <tr v-for="part in unauthed_parts" v-bind:key="part.id" v-bind:class="{'complete': part.complete}">
                                <td>{{part.part_name}}</td>
                                <td>{{ConvertPrice(part.cost_per_part)}}</td>
                                <td>{{part.part_no}}</td>
                                <td>{{part.quantity}}</td>
                                <td>{{ConvertPrice(part.cost_per_part * part.quantity)}}</td>
                                <td>{{part.labour_hours}}</td>
                                <td>
                                    <span v-if="part.type !== ''">{{part.type}}</span>
                                    <span v-else>WorkshopEnd</span>
                                </td>
                                <td>{{ConvertPrice((getLabour(part.labour_hours, part.type) || 0))}}</td>
                                <td>{{ConvertPrice((part.cost_per_part * part.quantity) + (getLabour(part.labour_hours, part.type) || 0))}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="mt-3">
                <div class="border border-gray-200 rounded">
                    <div class="bg-white px-3 py-2 rounded-t border-b border-gray-200">
                        <div class="flex items-center w-full">
                            <span class="font-semibold">Parts</span>
                            <span class="ml-auto">
                                <a-button class="mr-3" type="primary" size="small" @click="copyToClipboard"><i class="fa fa-clipboard mr-2"></i> Copy Parts List to Clipboard</a-button>
                                <a-button size="small" @click="exportToExcel('parts_table')"><i class="fa fa-clipboard mr-2"></i> Export to Excel</a-button>
                                <a id="dlink2" class="d-none">DL</a>
                            </span>
                        </div>
                    </div>
                    <div class="table-responsive rounded-b" v-if="parts.length">
                        <a-table v-if="1===0" :columns="columns" :data-source="parts" :row-key="record => record.id" :pagination="false"></a-table>

                        <table class="table parts-table" id="parts_table">
                            <thead>
                                <th style="width:300px; cursor:pointer; user-select: none" @click="sortTable">
                                    <span class="flex items-center">
                                        <b>Task</b>
                                        <span class="ml-auto" v-if="sortDir === 'asc'"><svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><g
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"><path d="M12 5v14"></path><path d="M16 9l-4-4"></path><path d="M8 9l4-4"></path></g></svg></span>

                                        <span class="ml-auto" v-if="sortDir === 'desc'">
                                            <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
                                                <g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 5v14"></path><path d="M16 15l-4 4"></path><path d="M8 15l4 4"></path></g></svg></span>
                                    </span>
                                </th>
                                <th><b>Retail</b></th>
                                <th><b>Part No</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Total</b></th>
                                <th><b>Labour Hrs</b></th>
                                <th><b>Job Type</b></th>
                                <th><b>Total Labour</b></th>
                                <th><b>Row Total</b></th>
                            </thead>
                            <tbody class="parts_table">
                                <tr v-for="part in jobParts" v-bind:key="part.id" v-bind:class="{'complete': part.complete}">
                                    <td>{{part.part_name}}</td>
                                    <td>{{ConvertPrice(part.cost_per_part)}}</td>
                                    <td>{{part.part_no}}</td>
                                    <td>{{part.quantity}}</td>
                                    <td>{{ConvertPrice(part.cost_per_part * part.quantity)}}</td>
                                    <td>{{part.labour_hours}}</td>
                                    <td>
                                        <span v-if="part.type !== ''">{{part.type}}</span>
                                        <span v-else>WorkshopEnd</span>
                                    </td>
                                    <td>{{ConvertPrice(part.labour_hours * lab_rate(part.type))}}</td>
                                    <td>{{ConvertPrice((part.cost_per_part * part.quantity) + (getLabour(part.labour_hours, part.type) || 0))}}</td>
                                    <!--<td>
                                        {{ConvertPrice(((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity) + (part.labour_hours*lab_rate(part.type))))}}
                                    </td>-->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <textarea id="copydata"></textarea>
        </div>
        <div v-else class="flex justify-center pt-10">
            <a-spin :indicator="indicator" size="large"/>
        </div>
    </div>
</template>

<script>
import {h} from "vue";
import axios from 'axios';
import table from 'text-table';
import TextEditor from './extras/TextEditor.vue'
import { LoadingOutlined } from '@ant-design/icons-vue';
import {useAuthStore} from "@/store/authStore";
import jobCustomer from './jobsheet/Customer.vue'
import jobVehicle from './jobsheet/Vehicle.vue'
import jobDetails from './jobsheet/Details.vue'
import jobPrices from './jobsheet/Prices.vue'
import jobTable from './jobsheet/tables/Parts.vue'
import jobTopbar from './jobsheet/Topbar.vue'
import jobChecklists from './jobsheet/Checklists.vue'
import FaIcon from "@/components/extras/Icon.vue";
import _ from 'lodash'
export default {
    name: "Customer",
    components: {
        TextEditor,
        jobCustomer,
        jobVehicle,
        jobDetails,
        jobPrices,
        jobTable,
        jobTopbar,
        jobChecklists,
        FaIcon
    },
    data() {
        const self = this;
        return {
            gotData: false,
            job: null,
            users: [],
            parts: [],
            unauthed_parts: [],
            tuning_labour_rate: 0,
            workshop_labour_rate: 0,
            fab_labour_rate: 0,
            bodyshop_labour_rate: 0,
            sku_list: [],
            columns: [
                {
                    title: 'Task',
                    dataIndex: 'part_name',
                    sorter: (a, b) => (a.part_name || "").localeCompare(b.part_name || "", undefined, {sensitivity: 'base'})
                },
                {
                    title: 'Retail',
                    dataIndex: 'cost_per_part',
                    sorter: {
                        compare: (a, b) => {
                            const aa = (a.cost_per_part || 0) * a.quantity
                            const bb = (b.cost_per_part || 0) * b.quantity
                            return aa - bb;
                        },
                        multiple: 1,
                    },
                    customRender({text, record, index}) {
                        return self.ConvertPrice(record.cost_per_part)
                    }
                },
                {
                    title: 'Part No',
                    dataIndex: 'part_no',
                    sorter: (a, b) => (a.part_no || "").localeCompare(b.part_no || "", undefined, {sensitivity: 'base'}),
                },
                {
                    title: 'Qty',
                    dataIndex: 'quantity',
                    sorter: (a, b) => a.quantity - b.quantity,
                },
                {
                    title: 'Total',
                    sorter: {
                        compare: (a, b) => {
                            const aa = a.cost_per_part * a.quantity
                            const bb = b.cost_per_part * b.quantity
                            return aa - bb;
                        },
                        multiple: 2,
                    },
                    customRender({text, record, index}) {
                        return self.ConvertPrice(record.cost_per_part * record.quantity)
                    }
                },
                {
                    title: 'Labour Hrs',
                    sorter: (a, b) => a.labour_hours - b.labour_hours,
                    dataIndex: 'labour_hours'
                },
                {
                    title: 'Job Type',
                    sorter: (a, b) => (a.type || "").localeCompare(b.type || "", undefined, {sensitivity: 'base'}),
                    dataIndex: 'type',
                    customRender({text, record, index}) {
                        if(record.type !== '') {
                            return record.type
                        } else {
                            return "WorkshopEnd"
                        }
                    }
                },
                {
                    title: 'Total Labour',
                    customRender({text, record, index}) {
                        return self.ConvertPrice((self.getLabour(record.labour_hours, record.type) || 0))
                    },
                    sorter: {
                        compare: (a, b) => {
                            const aa = (self.getLabour(a.labour_hours, a.type) || 0)
                            const bb = (self.getLabour(b.labour_hours, b.type) || 0)
                            return aa - bb;
                        },
                        multiple: 3,
                    },
                },
                {
                    title: 'Row Total',
                    customRender({text, record, index}) {
                        return self.ConvertPrice((record.cost_per_part * record.quantity) + (self.getLabour(record.labour_hours, record.type) || 0))
                    },
                    sorter: {
                        compare: (a, b) => {
                            const aa = (a.cost_per_part * a.quantity) + (self.getLabour(a.labour_hours, a.type) || 0)
                            const bb = (b.cost_per_part * b.quantity) + (self.getLabour(b.labour_hours, b.type) || 0)
                            return aa - bb;
                        },
                        multiple: 4,
                    },
                },
            ],
            sortDir: null,
        }
    },
    setup() {
        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '24px',
            },
            spin: true,
        });
        return {
            indicator,
        };
    },
    mounted() {
        this.getData();
    },
    filters: {
        status(val) {
            let value = null;
            if (val === "6") {
                value = {
                    status: "Processed",
                    background: '#79d2a6'
                }
            }
            else if (val === "7") {
                value = {
                    status: "Deleted",
                    background: '#79d2a6'
                }
            }
            else if (val === "5") {
                value = {
                    status: "Picked",
                    background: '#79d2a6'
                }
            }
            else if (val === "2") {
                value = {
                    status: "Ordered",
                    background: '#ff751a'
                }
            }
            else if (val === "1") {
                value = {
                    status: "Partially Ordered",
                    background: '#ff751a'
                }
            }
            else if (val === "4") {
                value = {
                    status: "Partially Picked",
                    background: '#ff751a'
                }
            }
            else if (val === "3") {
                value = {
                    status: "Partially Picked and Ordered",
                    background: '#ff751a'
                }
            }
            else if (val === "0") {
                value = {
                    status: "Not Started",
                    background: '#de1212'
                }
            } else {
                value = {
                    status: "Not Started",
                    background: '#de1212'
                }
            }
            return value.status
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user;
            } else {
                return null
            }
        },
        combined_parts() {
            let all_parts = [];
            const workshop_parts = this.job.workshop_parts;
            const fabrication_parts = this.job.fabrication_parts;
            const tuning_parts = this.job.tuning_parts;
            const workshop_end_parts = this.job.workshop_end_parts;
            all_parts = all_parts.concat(workshop_parts, fabrication_parts, tuning_parts, workshop_end_parts);
            return all_parts;
        },
        labRateProps() {
            return {
                tuning: this.tuning_labour_rate,
                workshop: this.job.job_detail.lab_rate || this.workshop_labour_rate,
                bodyshop: this.bodyshop_labour_rate,
                fabrication: this.fab_labour_rate,
            }
        },
        jobParts() {
            let p = [...this.parts];
            if(this.sortDir) {
                if(this.sortDir === 'asc') {
                    p.sort((a, b) => {
                        return (a.part_name || "").localeCompare((b.part_name || ""), undefined, {sensitivity: 'base'})
                    })
                } else if(this.sortDir === 'desc') {
                    p.sort((a, b) => {
                        return (a.part_name || "").localeCompare((b.part_name || ""), undefined, {sensitivity: 'base'})
                    }).reverse();
                }
            } else {
                p.sort(function (a, b) {
                    return (
                        (a.cost_per_part === undefined || a.cost_per_part === null) - (b.cost_per_part === undefined || b.cost_per_part === null) ||
                        a.cost_per_part - b.cost_per_part ||
                        a.labour_hours - b.labour_hours
                    );
                });
                p = p.reverse();
            }
            return p;
        }
    },
    methods: {
        getData() {
            const id = this.$route.params.id;
            axios.get(api_url + '/v2/jobs/' + id).then(resp1 => {
                const job = resp1.data;
                axios.get(api_url + '/v2/jobs/' + id + '/parts').then(resp2 => {
                    const parts = resp2.data;
                    axios.get(api_url + '/v2/jobs/' + id + '/bookings/previous').then(resp3 => {
                        const previous_bookings = resp3.data;
                        if (job) {
                            if (!job.job_detail) {
                                alert('There are no details for this job!')
                                this.job = null;
                                this.hasJobData = false;
                                return;
                            }
                            job.previous_bookings = previous_bookings;
                            job.vehicle.VRVrm = job.vehicle.reg;
                            job.vehicle.VRVin = job.vehicle.vin;
                            job.workshop_parts = parts.workshop;
                            job.workshop_end_parts = parts.workshop_end;
                            job.fabrication_parts = parts.fabrication;
                            job.tuning_parts = parts.tuning;
                            job.bodyshop_parts = parts.bodyshop;
                            job.unauthed_parts = parts.unauthed;

                            job.bodyshop_insurance_parts_parts = parts.bodyshop_insurance_parts_parts || [];
                            job.bodyshop_insurance_labour_parts = parts.bodyshop_insurance_labour_parts || [];
                            job.bodyshop_insurance_paint_parts = parts.bodyshop_insurance_paint_parts || [];
                            job.bodyshop_insurance_extra_parts = parts.bodyshop_insurance_extra_parts || [];
                            job.bodyshop_insurance_additional_parts = parts.bodyshop_ins_additional_parts || [];

                            this.job = job;
                            this.old_note = this.job.note;
                            this.old_customer_note = this.job.customer_notes;
                            this.doesHaveFreeLabour = this.job.free_labour;
                            if (this.job.task && this.job.task.times) {
                                this.job.task.times.forEach(element => {
                                    if (element.user === this.user.id && element.pause_time == null) {
                                        this.jobTaskInProgress = true;
                                    }
                                });
                            }
                            if (this.job.customer) {
                                window.document.title = this.job.id + " - " + this.job.customer.firstname + " " + this.job.customer.lastname + " Jobsheet | Darkside Developments"
                            }
                            this.getLabourRates();
                            //this.getNoteRevisions();

                            axios.get(api_url + '/v2/jobs/' + id + '/parts').then((resp) => {
                                const prts = [];
                                for (const [key, value] of Object.entries(resp.data)) {
                                    if(key !== "unauthed") {
                                        resp.data[key].forEach(el => {
                                            prts.push(el)
                                        })
                                    }
                                }
                                this.parts = prts;
                                setTimeout(() => {
                                    this.convertPartNames();
                                }, 500);
                            });

                            axios.get(api_url + '/v2/jobs/' + id + '/parts/unauthed').then((resp) => {
                                this.unauthed_parts = resp.data;
                                setTimeout(() => {
                                    this.convertPartNames();
                                }, 500);
                            });

                            this.hasJobData = true;

                            const partTypes = ['unauthed_parts', 'workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts', 'bodyshop_insurance_parts_parts', 'bodyshop_insurance_labour_parts', 'bodyshop_insurance_paint_parts', 'bodyshop_insurance_extra_parts', 'bodyshop_insurance_additional_parts'];
                            partTypes.forEach((type) => {
                                this.job[type].forEach((part, key) => {
                                    if (this.job.project_car === 1) {
                                        if (parseFloat(part.purchase_price) === 0) {
                                            part.purchase_price = part.cost_per_part
                                        }
                                    }
                                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP'
                                    });
                                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP'
                                    });
                                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                        part.estimate_fixed = "Fixed";
                                    }
                                    this.job[type][key].complete = Boolean(part.complete)
                                    this.job[type][key].deleting = false
                                });
                            })

                            this.gotData = true
                        }
                    });
                });
            });
            /*axios.all([
                axios.get(api_url + '/api/job/' + id),
                axios.get(api_url + '/api/job/' + id + '/parts'),
                axios.get(api_url + '/api/job/' + id + '/bookings/previous')
            ]).then(response => {
                const job = response[0].data;
                const parts = response[1].data;
                const previous_bookings = response[2].data;
                if (job) {
                    if (!job.job_detail) {
                        alert('There are no details for this job!')
                        this.job = null;
                        this.hasJobData = false;
                        return;
                    }
                    job.previous_bookings = previous_bookings;
                    job.vehicle.VRVrm = job.vehicle.reg;
                    job.vehicle.VRVin = job.vehicle.vin;
                    job.workshop_parts = parts.workshop;
                    job.workshop_end_parts = parts.workshop_end;
                    job.fabrication_parts = parts.fabrication;
                    job.tuning_parts = parts.tuning;
                    job.bodyshop_parts = parts.bodyshop;
                    job.unauthed_parts = parts.unauthed;
                    this.job = job;
                    this.old_note = this.job.note;
                    this.old_customer_note = this.job.customer_notes;
                    this.doesHaveFreeLabour = this.job.free_labour;
                    if (this.job.task && this.job.task.times) {
                        this.job.task.times.forEach(element => {
                            if (element.user === this.user.id && element.pause_time == null) {
                                this.jobTaskInProgress = true;
                            }
                        });
                    }
                    if (this.job.customer) {
                        window.document.title = this.job.id + " - " + this.job.customer.firstname + " " + this.job.customer.lastname + " Jobsheet | Darkside Developments"
                    }
                    this.getLabourRates();
                    this.getNoteRevisions();
                    this.hasJobData = true;

                    const partTypes = ['unauthed_parts', 'workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts'];
                    partTypes.forEach((type) => {
                        this.job[type].forEach((part, key) => {
                            if (this.job.project_car === 1) {
                                if (parseFloat(part.purchase_price) === 0) {
                                    part.purchase_price = part.cost_per_part
                                }
                            }
                            part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                part.estimate_fixed = "Fixed";
                            }
                            this.job[type][key].complete = Boolean(part.complete)
                            this.job[type][key].deleting = false
                        });
                    })
                }
            });*/
        },
        lab_rate(partType) {
            if (partType === "Workshop" || partType === "WorkshopEnd") {
                if (this.job.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            } else if (partType === "Tuning") {
                if (this.job.free_labour) {
                    return 0;
                } else {
                    return this.tuning_labour_rate;
                }
            } else if (partType === "Fabrication") {
                if (this.job.free_labour) {
                    return 0;
                } else {
                    return this.fab_labour_rate
                }
            } else if (partType === "Bodyshop") {
                if (this.job.free_labour) {
                    return 0;
                } else {
                    return this.bodyshop_labour_rate
                }
            } else {
                if (this.job.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            }

            /*if (this.type === "workshop" || this.type === "workshop_end") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.workshop_labour_rate;
                }
            } else if (this.type === "tuning") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.tuning_labour_rate;
                }
            } else if (this.type === "fabrication") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.fab_labour_rate
                }
            } else if (this.type === "bodyshop") {
                if (this.free_labour) {
                    return 0;
                } else {
                    return this.bodyshop_labour_rate
                }
            } else {
                return 0;
            }*/
        },
        /*getData() {
            const id = this.$route.params.id;
            axios.all([
                axios.get(api_url + '/api/job/' + id),
                axios.get(api_url + '/api/job/' + id + '/parts'),
                axios.get(api_url + '/api/job/' + id + '/bookings/previous')
            ]).then(response => {
                const job = response[0].data;
                const parts = response[1].data;
                const previous_bookings = response[2].data;
                if(job) {
                    if (!job.job_detail) {
                        alert('There are no details for this job!')
                        this.job = null;
                        this.hasJobData = false;
                        return;
                    }
                    job.previous_bookings = previous_bookings;
                    job.vehicle.VRVrm = job.vehicle.reg;
                    job.vehicle.VRVin = job.vehicle.vin;
                    job.workshop_parts = parts.workshop;
                    job.workshop_end_parts = parts.workshop_end;
                    job.fabrication_parts = parts.fabrication;
                    job.tuning_parts = parts.tuning;
                    job.bodyshop_parts = parts.bodyshop;
                    job.unauthed_parts = parts.unauthed;
                    this.job = job;
                    this.old_note = this.job.note;
                    this.old_customer_note = this.job.customer_notes;
                    this.doesHaveFreeLabour = this.job.free_labour;
                    if (this.job.task && this.job.task.times) {
                        this.job.task.times.forEach(element => {
                            if (element.user === this.$store.state.auth.user.id && element.pause_time == null) {
                                this.jobTaskInProgress = true;
                            }
                        });
                    }
                    if (this.job.customer) {
                        window.document.title = this.job.id + " - " + this.job.customer.firstname + " " + this.job.customer.lastname + " Jobsheet | Darkside Developments"
                    }
                    this.hasJobData = true;
                    const partTypes = ['workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts'];
                    this.parts = [];
                    partTypes.forEach((type) => {
                        this.job[type].forEach((part, key) => {
                            if (this.job.project_car === 1) {
                                if (parseFloat(part.purchase_price) === 0) {
                                    part.purchase_price = part.cost_per_part
                                }
                            }
                            if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                part.estimate_fixed = "Fixed";
                            }
                            this.job[type][key].complete = Boolean(part.complete)
                            this.job[type][key].deleting = false;
                            this.parts.push(this.job[type][key])
                        });
                    })

                    this.unauthed_parts = this.job.unauthed_parts;
                    this.convertPartNames();
                }
            });
        },*/
        /*getData() {
            const id = this.$route.params.id;
            axios.get(api_url + '/api/job/' + id).then((response) => {
                this.job = response.data;
                this.job.workshop_parts.forEach(part => {
                    if (part.part_no) {
                        this.sku_list.push(part.part_no)
                    }
                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                        part.estimate_fixed = "Fixed";
                    }
                });

                this.job.tuning_parts.forEach(part => {
                    if (part.part_no) {
                        this.sku_list.push(part.part_no)
                    }
                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                        part.estimate_fixed = "Fixed";
                    }
                });

                this.job.fabrication_parts.forEach(part => {
                    if (part.part_no) {
                        this.sku_list.push(part.part_no)
                    }
                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                        part.estimate_fixed = "Fixed";
                    }
                });

                this.job.workshop_end_parts.forEach(part => {
                    if (part.part_no) {
                        this.sku_list.push(part.part_no)
                    }
                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                        style: 'currency',
                        currency: 'GBP'
                    });
                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                        part.estimate_fixed = "Fixed";
                    }
                });
                axios.get(api_url + '/api/job/' + id + '/parts').then((resp) => {
                    this.parts = resp.data;
                    setTimeout(() => {
                        this.convertPartNames();
                    }, 500);
                });

                axios.get(api_url + '/api/job/' + id + '/parts/unauthed').then((resp) => {
                    this.unauthed_parts = resp.data;
                    setTimeout(() => {
                        this.convertPartNames();
                    }, 500);
                });
            }).catch(() => {}).finally(() => {
                this.getLabourRates();
            });

            axios.get(api_url + '/api/users/all').then((resp) => {
                this.users = resp.data;
            })
        },*/
        convertPartNames() {
            const skus = [];
            this.parts.forEach((element, indx) => {
                skus.push(element.part_no);
                if (element.note && element.note.includes('Torque Setting')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?Torque Setting<\/a>/g, '');
                }

                if (element.note && element.note.includes('http://drksi.de/MnG')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/http:\/\/drksi.de\/MnG/g, '<a href="https://jobs.darkside-developments.com/checklist/prework/' + this.job.id + '" target="_blank">Prework Check</a>');
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?http:\/\/drksi.de\/MnG<\/a>/g, '<a href="https://jobs.darkside-developments.com/checklist/prework/' + this.job.id + '" target="_blank">Prework Check</a>');
                }

                if (element.note && element.note.includes('http://drksi.de/RT')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/http:\/\/drksi.de\/RT/g, '<a href="https://jobs.darkside-developments.com/checklist/roadtest/' + this.job.id + '" target="_blank">Road Test Check</a>');
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?http:\/\/drksi.de\/RT<\/a>/g, '<a href="https://jobs.darkside-developments.com/checklist/roadtest/' + this.job.id + '" target="_blank">Road Test Check</a>');
                }

                if (element.note && element.note.includes('http://drksi.de/WS')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/http:\/\/drksi.de\/WS/g, '<a href="https://jobs.darkside-developments.com/checklist/workshop/' + this.job.id + '" target="_blank">Workshop Check</a>');
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?http:\/\/drksi.de\/WS<\/a>/g, '<a href="https://jobs.darkside-developments.com/checklist/workshop/' + this.job.id + '" target="_blank">Workshop Check</a>');
                }

                if (element.note && element.note.includes('http://drksi.de/PW')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/http:\/\/drksi.de\/PW/g, '<a href="https://jobs.darkside-developments.com/checklist/postwork/' + this.job.id + '" target="_blank">Postwork Check</a>');
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?http:\/\/drksi.de\/PW<\/a>/g, '<a href="https://jobs.darkside-developments.com/checklist/postwork/' + this.job.id + '" target="_blank">Postwork Check</a>');
                }

                if (element.note && element.note.includes('http://drksi.de/FRT')) {
                    this.parts[indx].note = this.parts[indx].note.replace(/http:\/\/drksi.de\/FRT/g, '<a href="https://jobs.darkside-developments.com/checklist/frt/' + this.job.id + '" target="_blank">Final Road Test Check</a>');
                    this.parts[indx].note = this.parts[indx].note.replace(/<a.*?http:\/\/drksi.de\/FRT<\/a>/g, '<a href="https://jobs.darkside-developments.com/checklist/frt/' + this.job.id + '" target="_blank">Final Road Test Check</a>');
                }
            });
            axios.post(api_url + '/api/job/' + this.job.id + '/bc/prods', {
                skus: _.join(this.sku_list, ',')
            }).then((resp) => {
                const data = resp.data;
                data.forEach(el => {
                    const found = this.parts.find(el1 => el1.part_no === el.sku);
                    if (found) {
                        found.part_name = el.name;
                    }
                });
            })
        },
        getLabour(hours, type) {
            if (type === 'Workshop' || type === 'WorkshopEnd') {
                return hours * this.workshop_labour_rate;
            }

            if (type === 'Tuning') {
                return hours * this.tuning_labour_rate;
            }

            if (type === 'Fabrication') {
                return hours * this.fab_labour_rate;
            }

            if (type === 'Bodyshop') {
                return hours * this.bodyshop_labour_rate;
            }
        },
        getLabourRates() {
            axios.get(api_url + '/v2/jobs/' + this.job.id + '/labour/rates').then((resp) => {
                this.tuning_labour_rate = resp.data.tuning;
                this.workshop_labour_rate = this.job.job_detail.lab_rate || resp.data.workshop;
                this.fab_labour_rate = resp.data.fabrication;
                this.bodyshop_labour_rate = resp.data.bodyshop;
                if (this.job.vehicle.VRMake === "KTM") {
                    this.workshop_labour_rate = resp.data.ktm;
                }

                if (this.job.free_labour) {
                    this.workshop_labour_rate = 0;
                    this.tuning_labour_rate = 0;
                    this.fab_labour_rate = 0;
                    this.bodyshop_labour_rate = 0;
                }

                if (this.job.old_lab_rate && !this.job.free_labour) {
                    this.workshop_labour_rate = 60;
                }
            });
        },
        getUser(val) {
            let name = null;
            this.users.find((e) => {
                if (e.id === val) {
                    name = e.nicename;
                }
            });
            return name;
        },
        partsTotalNoDisc() {
            return this.tuningTotal() + this.workshopTotal() + this.fabricationTotal() + this.workshopEndTotal() + this.bodyshopTotal();
        },
        tuningTotal() {
            let total = 0;
            this.job.tuning_parts.forEach(part => {
                if (this.job.project_car === false) {
                    total += ((part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.tuning_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.tuning_labour_rate))
                }
            });
            return total;
        },
        workshopTotal() {
            let total = 0;
            this.job.workshop_parts.forEach(part => {
                if (this.job.project_car === false) {
                    total += ((part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.workshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.workshop_labour_rate))
                }
            });
            return total;
        },
        bodyshopTotal() {
            let total = 0;
            this.job.bodyshop_parts.forEach(part => {
                if (this.job.project_car === false) {
                    total += ((part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.bodyshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.bodyshop_labour_rate))
                }
            });
            return total;
        },
        workshopEndTotal() {
            let total = 0;
            this.job.workshop_end_parts.forEach(part => {
                if (this.job.project_car === false) {
                    total += ((part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.workshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.workshop_labour_rate))
                }
            });
            return total;
        },
        fabricationTotal() {
            let total = 0;
            this.job.fabrication_parts.forEach(part => {
                if (this.job.project_car === false) {
                    total += ((part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.fab_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)) // + (part.labourHours*this.fab_labour_rate))
                }
            });
            return total;
        },
        PartsTotal() {
            const total = this.GetTotals('tuning') +
                this.GetTotals('workshop') +
                this.GetTotals('fabrication') +
                this.GetTotals('workshop_end') +
                this.GetTotals('bodyshop_insurance_labour') +
                this.GetTotals('bodyshop_insurance_paint') +
                this.GetTotals('bodyshop_insurance_parts') +
                this.GetTotals('bodyshop_insurance_additional') +
                this.GetTotals('bodyshop_insurance_extra') +
                this.GetTotals('bodyshop') -
                this.job.job_detail.parts_disc;
            this.partsTotal = total;
            return total;
        },
        GetTotals(type) {
            let model;
            if(type === "workshop") {model = "workshop_parts";}
            if(type === "workshop_end") {model = "workshop_end_parts";}
            if(type === "tuning") {model = "tuning_parts";}
            if(type === "fabrication") {model = "fabrication_parts";}
            //if(type === "bodyshop") {model = "bodyshop_parts";}
            /*if(type === "bodyshop_insurance_labour") {model = "bodyshop_insurance_labour_parts";}
            if(type === "bodyshop_insurance_paint") {model = "bodyshop_insurance_paint_parts";}
            if(type === "bodyshop_insurance_parts") {model = "bodyshop_insurance_parts_parts";}
            if(type === "bodyshop_insurance_additional") {model = "bodyshop_insurance_additional_parts";}
            if(type === "bodyshop_insurance_extra") {model = "bodyshop_insurance_extra_parts";}*/
            if(type === "bodyshop") {
                let total = 0;
                this.job["bodyshop_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });
                this.job["bodyshop_insurance_labour_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_paint_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_parts_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_additional_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_extra_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });
                return total;
            } else {
                let total = 0;
                if(model) {
                    this.job[model].forEach(part => {
                        if (this.job.project_car === false) {
                            total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                        } else {
                            total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                        }
                    });
                }
                return total;
            }
        },
        labourTotal() {
            let total = 0;
            if (this.job.project_car === 0 || this.job.project_car === false) {
                this.job.workshop_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.tuning_parts.forEach(part => {
                    total += (part.labour_hours * this.tuning_labour_rate)
                });

                this.job.fabrication_parts.forEach(part => {
                    total += (part.labour_hours * this.fab_labour_rate)
                });

                this.job.workshop_end_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.bodyshop_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_labour_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_paint_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_parts_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_additional_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_extra_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                total = total - this.job.job_detail.lab_disc;
            } else {
                if (this.job.times.total_time) {
                    if (this.job.times.total_time.time) {
                        const split = this.job.times.total_time.time.split(':');
                        const hours = split[0];
                        const minutes = split[1];
                        const seconds = split[2];
                        const project_lab_rate = 0;
                        const time = parseFloat(parseFloat(hours) + "." + parseFloat(minutes));
                        return project_lab_rate * time;
                    }
                }
            }
            //this.labourTotal = total;
            return total;
        },
        labourTotalNoDisc() {
            let total = 0;
            if (this.job.project_car === false) {
                this.job.workshop_parts.forEach(part => {
                    total += (part.labourHours * this.workshop_labour_rate)
                });

                this.job.tuning_parts.forEach(part => {
                    total += (part.labourHours * this.tuning_labour_rate)
                });

                this.job.fabrication_parts.forEach(part => {
                    //total += (part.labourHours * 75)
                    total += (part.labourHours * this.fab_labour_rate)
                });

                this.job.workshop_end_parts.forEach(part => {
                    total += (part.labourHours * this.workshop_labour_rate)
                });

                this.job.bodyshop_parts.forEach(part => {
                    total += (part.labourHours * this.bodyshop_labour_rate)
                });
            } else {
                if (this.job.times.total) {
                    if (this.job.times.total) {
                        const split = this.job.times.total.split(':');
                        const hours = split[0];
                        const minutes = split[1];
                        const project_lab_rate = 0;
                        const time = parseFloat(parseFloat(hours) + "." + parseFloat(minutes));
                        return project_lab_rate * time
                    }
                }
            }
            return total;
        },
        labourHours() {
            var total = 0;
            this.job.workshop_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.tuning_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.fabrication_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.workshop_end_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });


            this.job.bodyshop_insurance_labour_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_paint_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_parts_parts.forEach(part => {
                total +=parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_additional_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_extra_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });
            return total;
        },
        totalExVat() {
            if (this.job.project_car === false) {
                return (this.labourTotal() + this.PartsTotal()) - this.job.deposit // - lab_hrs;
            } else {
                return this.labourTotal() + this.PartsTotal();
            }
        },
        totalToPay() {
            return this.totalExVat() * 1.2;
        },
        partsDiscountPercent() {
            if ((this.job.job_detail.parts_disc === 0 && this.PartsTotal() === 0) || this.job.job_detail.parts_disc > 0 && this.PartsTotal() === 0)
                return 0;
            else
                return (this.job.job_detail.parts_disc / this.PartsTotal()) * 100
        },
        labourDiscountPercent() {
            if ((this.job.job_detail.lab_disc === 0 && this.labourTotal() === 0) || (this.job.job_detail.lab_disc > 0 && this.labourTotal() === 0))
                return 0;
            else
                return (this.job.job_detail.lab_disc / this.labourTotal()) * 100
        },
        async copyToClipboard() {
            const default_tasks = ['Vehicle Check with Customer',
                'Road Test',
                'Analyse logs, notify customer',
                'Analyse logs',
                'Rebook customer for remedial work',
                'Vehicle Check In Workshop',
                'Check ECU Read procedure with X17',
                'Connect Charger',
                'Disconnect fans',
                'OBD Unlock',
                'Check vehicle starts',
                'Remove ECU',
                'Split ECU',
                'Solder 1st Socket',
                'Solder 2nd Socket',
                'Read Chips using Programmer',
                'Write stock file to chips',
                'Read ECU on bench (Eeprom + Irom and/or X-Rom)',
                'Read File via OBD using TOOL',
                'Re Seal ECU',
                'Dyno Test',
                'Flash Modified File',
                'Road Log (Boost + Fuel + EGT and Fault Codes)',
                'Photos of Fabrication Work',
                'Final Check',
                'Final Road Test',
                'Tuning Complete - Job sheet filled',
                'Customer Notified',
                'Keys In Safe',
                'Graph Edited and Printed',
                'TBC',
                'Photos of Fitted Parts',
                'Adjust Actuator Rod as long as possible',
                'Tuning file ready?',
                'Adjust timing on Dyno',
                'Tap Manifold for Boost Gauge'
            ];
            const partsArray = [];
            this.parts.forEach(element => {
                if (!default_tasks.includes(element.part_name)) {
                    const total = (element.cost_per_part * element.quantity) + (element.labour_hours * this.job.job_detail.lab_rate);
                    let labrate = null;
                    if (element.type === "Workshop") {
                        labrate = this.workshop_labour_rate;
                    }

                    if (element.type === "Tuning") {
                        labrate = this.tuning_labour_rate;
                    }

                    if (element.type === "Fabrication") {
                        labrate = this.fab_labour_rate;
                    }

                    if (element.type === "Bodyshop") {
                        labrate = this.bodyshop_labour_rate;
                    }

                    if (element.part_no == null) {
                        partsArray.push([element.part_name.trim(), '', 'Qty:' + element.quantity, element.labour_hours + "Hrs - £" + element.labour_hours * labrate, "Total: " + total.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        })])
                    } else {
                        partsArray.push([element.part_name.trim(), element.part_no, 'Qty:' + element.quantity, element.labour_hours + "Hrs - £" + element.labour_hours * labrate, "Total: " + total.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        })])
                    }
                }
            });
            let deposit = 0;
            if (parseInt(this.job.deposit)) {
                deposit = parseInt(this.job.deposit);
            }
            partsArray.push(['Labour hours:', '', '', this.labourHours()])
            partsArray.push(['Labour price:', '', '', '£' + this.labourTotal().toFixed(2)])
            partsArray.push(['Discount:', '', '', '£' + (this.job.job_detail.parts_disc + this.job.job_detail.lab_disc + this.job.job_detail.labour_hr_disc)])
            partsArray.push(['Deposit:', '', '', '£' + (deposit.toFixed(2))])
            partsArray.push(['Total ex Vat:', '', '', '£' + this.totalExVat().toFixed(2)])
            partsArray.push(['Vat:', '', '', '£' + (this.totalToPay() - this.totalExVat()).toFixed(2)])
            partsArray.push(['Total inc Vat:', '', '', '£' + this.totalToPay().toFixed(2)])

            await navigator.clipboard.writeText(table(partsArray, {
                align: ['l', 'l', 'l', 'l', 'l']
            }))

            /*document.getElementById('copydata').value = table(partsArray, {
                align: ['l', 'l', 'l', 'l', 'l']
            });
            document.getElementById('copydata').select();
            document.execCommand('copy');
            window.getSelection().removeAllRanges();*/
            this.$message.success('Copied parts to clipboard');
        },
        copyUnauthedToClipboard() {
            const default_tasks = ['Vehicle Check with Customer',
                'Road Test',
                'Analyse logs, notify customer',
                'Analyse logs',
                'Rebook customer for remedial work',
                'Vehicle Check In Workshop',
                'Check ECU Read procedure with X17',
                'Connect Charger',
                'Disconnect fans',
                'OBD Unlock',
                'Check vehicle starts',
                'Remove ECU',
                'Split ECU',
                'Solder 1st Socket',
                'Solder 2nd Socket',
                'Read Chips using Programmer',
                'Write stock file to chips',
                'Read ECU on bench (Eeprom + Irom and/or X-Rom)',
                'Read File via OBD using TOOL',
                'Re Seal ECU',
                'Dyno Test',
                'Flash Modified File',
                'Road Log (Boost + Fuel + EGT and Fault Codes)',
                'Photos of Fabrication Work',
                'Final Check',
                'Final Road Test',
                'Tuning Complete - Job sheet filled',
                'Customer Notified',
                'Keys In Safe',
                'Graph Edited and Printed',
                'TBC',
                'Photos of Fitted Parts',
                'Adjust Actuator Rod as long as possible',
                'Tuning file ready?',
                'Adjust timing on Dyno',
                'Tap Manifold for Boost Gauge'
            ];

            const partsArray = [];
            this.unauthed_parts.forEach(element => {
                if (!default_tasks.includes(element.part_name)) {
                    const total = (element.cost_per_part * element.quantity) + (element.labourHours * this.job.job_detail.lab_rate);
                    let labrate = null;
                    if (element.type === "Workshop") {
                        labrate = this.workshop_labour_rate;
                    }

                    if (element.type === "Tuning") {
                        labrate = this.tuning_labour_rate;
                    }

                    if (element.type === "Fabrication") {
                        labrate = this.fab_labour_rate;
                    }

                    if (element.type === "Bodyshop") {
                        labrate = this.bodyshop_labour_rate;
                    }

                    if (element.part_no == null) {
                        partsArray.push([element.part_name.trim(), '', 'Qty:' + element.quantity, element.labourHours + "Hrs - £" + element.labourHours * labrate, "Total: " + total.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        })])
                    } else {
                        partsArray.push([element.part_name.trim(), element.part_no, 'Qty:' + element.quantity, element.labourHours + "Hrs - £" + element.labourHours * labrate, "Total: " + total.toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        })])
                    }
                }
            });
            let deposit = 0;
            if (parseInt(this.job.deposit)) {
                deposit = parseInt(this.job.deposit);
            }
            document.getElementById('copydata').value = table(partsArray, {
                align: ['l', 'l', 'l', 'l', 'l']
            });
            document.getElementById('copydata').select();
            document.execCommand('copy');
            window.getSelection().removeAllRanges();
            this.$message.success('Copied parts to clipboard');
        },
        exportToExcel(table_id) {
            const tableToExcel = (function () {
                const uri = 'data:application/vnd.ms-excel; charset=UTF-8; base64,',
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/xhtml+xml; charset=UTF-8" /><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                    base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)))
                    },
                    format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        })
                    };
                return function (table, name, filename) {
                    if (!table.nodeType) table = document.getElementById(table)
                    const ctx = {
                        worksheet: name || 'Worksheet',
                        table: table.innerHTML
                    };
                    document.getElementById("dlink2").href = uri + base64(format(template, ctx));
                    document.getElementById("dlink2").download = filename;
                    document.getElementById("dlink2").target = "_blank";
                    document.getElementById("dlink2").click();
                }
            })();
            const name = this.job.id;
            tableToExcel(table_id, 'Sheet 1', name + '.xls')
        },
        goToTuningView() {
            this.$router.push({
                name: 'Tuning',
                params: {
                    id: this.job.id
                }
            })
        },
        goToJobsheetView() {
            this.$router.push({
                name: 'Jobsheet',
                params: {
                    id: this.job.id
                }
            })
        },
        sortTable(e) {
            if(this.sortDir === null) {
                this.sortDir = "asc"
            } else if(this.sortDir === "asc") {
                this.sortDir = "desc"
            } else if(this.sortDir === "desc") {
                this.sortDir = null
            }
            if(this.sortDir === null) {

            }
        },
    },
    watch: {
        job() {
            this.getLabourRates();
        },
        labourRates(val) {
            this.tuning_labour_rate = val.tuning;
            this.workshop_labour_rate = this.job.job_detail.lab_rate || val.workshop;
            this.fab_labour_rate = val.fabrication;
            this.bodyshop_labour_rate = val.bodyshop;

            this.main_tuning_labour_rate = val.tuning;
            this.main_workshop_labour_rate = val.workshop;
            this.main_fab_labour_rate = val.fabrication;
            this.main_bodyshop_labour_rate = val.bodyshop;
        }
    }
}
</script>

<style lang="scss">
.table-responsive .table thead th {
    border-top: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background: #f9fafb;
    &:last-of-type {
        border-right: 0;
    }
}
.table-responsive .table.parts-table tbody tr {
    td {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    &:first-of-type {
        td {
            border-top: 0;
        }
    }
}

.ant-table-thead tr th, .ant-table-tbody tr td {
    border-top: none !important;
    &:first-of-type {
        border-left: none !important;
    }
    &:last-of-type {
        border-right: none !important;
    }
}
</style>
