import axios from "axios";
import Cookies from 'js-cookie'
import { useAuthStore } from '@/store/authStore';
const apiUrl = import.meta.env.VITE_APP_SERVER_URL.toString();
const config = {
    baseURL: apiUrl,
};
const server = axios.create(config);

server.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
server.interceptors.request.use(
    (config) => {
        // @ts-ignore
        //window.$Progress.start(5000);
        if (!config.headers) {
            return config;
        }
        const authToken = Cookies.get('token');
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

server.interceptors.response.use(
    (response) => {
        // @ts-ignore
        //window.$Progress.finish();
        return response;
    },
    async (error) => {
        // @ts-ignore
        //window.$Progress.fail();
        if (error.response.status === 401|| error.status === 401) {
            console.log('should logout')
            const auth = useAuthStore();
            await auth.logout();
            location.reload()
            return;
        }
        if (error.response.data) {
            return Promise.reject(error.response.data);
        } else if (error.message) {
            return Promise.reject(error.message);
        } else {
            return Promise.reject(error);
        }
    }
);
export default server;
