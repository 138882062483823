import axios from 'axios';
import { createWebHistory, createRouter } from "vue-router";
import login from "./components/Login.vue";
import overview from "./components/Overview.vue";
import bodyshop_overview from "./components/BodyshopOverview.vue";
import dsd_overview from "./components/DarksideOverview.vue";
import jobsheet from "./components/Jobsheet.vue";
import quote from "./components/Quote.vue";
import jobsheetBodyshop from "./components/JobsheetBodyshop.vue";
import tuning from "./components/Tuning.vue";
import customer from "./components/Customer.vue";
import consumables from "./components/Consumables.vue";
import swiftech from "./components/Swiftech.vue";
import swiftechStatus from "./components/SwiftechStatus.vue";
import jobTimes from "./components/JobTimes.vue";
import booking from "./components/extras/booking/booking.vue";
import booking_deposit from "./components/extras/booking/deposit.vue";
import bodyshop from "./components/Bodyshop.vue";
import Checklist from "./components/Checklist.vue";
import quickBook from "./components/extras/booking/QuickBook.vue";
import ServiceWork from "./components/ServiceWork.vue";
import {useAuthStore} from "@/store/authStore";
import jwtDecode from "jwt-decode";
let router = createRouter({
	mode: "history",
    history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/login",
			name: "Login",
			component: login,
			meta: { requiresAuth: false },
			//beforeEnter: ifNotAuthenticated,
		},
		{
			path: "/",
			name: "Overview",
			component: overview,
			props: { startDSDTable: false },
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Overview | Darkside Developments",
			},
		},
		{
			path: "/bodyshop",
			name: "OverviewBodyshop",
			component: bodyshop_overview,
			props: { startDSDTable: false },
			meta: {
				requiresAuth: true,
				title: "Jobs Bodyshop Overview | Darkside Developments",
			},
		},
		{
			path: "/internal",
			name: "DSDOverview",
			component: dsd_overview,
			props: { startDSDTable: true },
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Internal Overview | Darkside Developments",
			},
		},
		{
			path: "/jobsheet",
			meta: { requiresAuth: true },
			//beforeEnter: ifAuthenticated,
		},
		{
			path: "/quote/:id",
			name: "JobsheetQuote",
			component: quote,
			meta: {
				requiresAuth: true,
				title: "Quote | Darkside Developments",
			},
		},
		{
			path: "/jobsheet/:id",
			name: "Jobsheet",
			component: jobsheet,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobsheet | Darkside Developments",
			},
		},
		{
			path: "/jobsheet/:id/bodyshop",
			name: "JobsheetBodyshop",
			component: jobsheetBodyshop,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobsheet Bodyshop | Darkside Developments",
			},
		},
		{
			path: "/jobsheet/:id/times",
			name: "Jobsheet Times",
			component: jobTimes,
			meta: { requiresAuth: true },
			//beforeEnter: ifAuthenticated,
		},
		{
			path: "/tuning",
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Tuning | Darkside Developments",
			},
		},
		{
			path: "/tuning/:id",
			name: "Tuning",
			component: tuning,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Tuning | Darkside Developments",
			},
		},
		{
			path: "/consumables",
			component: consumables,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Consumables | Darkside Developments",
			},
		},
		{
			path: "/customer",
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Customer | Darkside Developments",
			},
		},
		{
			path: "/customer/:id",
			name: "Customer",
			component: customer,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Customer | Darkside Developments",
			},
		},
		{
			path: "/booking",
			name: "Booking",
			component: booking,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Booking | Darkside Developments",
			},
		},
		{
			path: "/quick-book",
			name: "QuickBook",
			component: quickBook,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Quick Book | Darkside Developments",
			},
		},
		{
			path: "/booking/deposit",
			name: "BookingDeposit",
			component: booking_deposit,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Booking | Darkside Developments",
			},
		},
		{
			path: "/swiftec",
			name: "Swiftech",
			component: swiftech,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Swiftec | Darkside Developments",
			},
		},
		{
			path: "/swiftec/status",
			name: "SwiftechCheck",
			component: swiftechStatus,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Swiftec | Darkside Developments",
			},
		},
		{
			path: "/swiftec/status/:id",
			name: "SwiftechCheckId",
			component: swiftechStatus,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Jobs Swiftec | Darkside Developments",
			},
		},
		{
			path: "/paint",
			name: "Paint",
			component: bodyshop,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: true,
				title: "Paint Jobs | Darkside Developments",
			},
		},
		{
			path: "/checklist/:type/:id",
			name: "Checklist",
			component: Checklist,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: false,
				title: "Checklist | Darkside Developments",
			},
		},
		{
			path: "/service-work",
			name: "ServiceWork",
			component: ServiceWork,
			//beforeEnter: ifAuthenticated,
			meta: {
				requiresAuth: false,
				title: "Service Work | Darkside Developments",
			},
		},
	],
});

/*router.beforeEach((to, from, next) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    //const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    const authRequired = to.meta.requiresAuth; //privatePages.includes(to.path);
    const loggedIn = Cookies.get("user") || false;
	if (authRequired && !loggedIn) {
		localStorage.setItem('redirect', to.fullPath);
		next("/login");
	} else {
		const usr = JSON.parse(loggedIn);
		axios.defaults.headers.common["Authorization"] = "Bearer " + usr.token;
		next();
	}
});*/
router.beforeEach((to, _from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const token = Cookies.get('token');
		if (!token) {
			const redirect = to.query.redirect ? "" : to.fullPath;
			next("/login?redirect=" + redirect);
			return;
		}

		const decodedJWT = jwtDecode(token);
		const expires = new Date(Math.floor(decodedJWT.exp) * 1000);
		const now = new Date();
		if(expires < now) {
			Cookies.remove('token');
			localStorage.clear();
			const redirect = to.query.redirect ? "" : to.fullPath;
			next("/login?redirect=" + redirect);
			return;
		} else {
			useAuthStore().getCurrentUser();
		}

		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
	}
	next();
});
/*router.beforeEach((to, _from, next) => {
	const { authenticated } = storeToRefs(useAuthStore())
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		const token = Cookies.get('token');
		if (!authenticated.value || !token) {
			const redirect = to.query.redirect ? "" : to.fullPath;
			next("/login?redirect=" + redirect);
			return;
		}

		const decodedJWT = jwtDecode(token);
		const expires = new Date(Math.floor(decodedJWT.exp) * 1000);
		const now = new Date();
		if(expires < now) {
			Cookies.remove('token');
			localStorage.clear();
			const redirect = to.query.redirect ? "" : to.fullPath;
			next("/login?redirect=" + redirect);
			return;
		} else {
			if(token && !localStorage.getItem('auth')) {
				useAuthStore().getCurrentUser();
			}
		}

		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
	}
	next();
});*/
export default router;
