//import store from "@/store";
import { ApiService } from "@/services/api/ApiService.js";
import { useAuthStore } from '@/store/authStore';
export class AuthenticationService extends ApiService {
    constructor() {
        super("Authentication");
    }
    login(payload) {
        return new Promise((resolve, reject) => {
            console.log(payload)
            const { loginSuccessfull } = useAuthStore();
            super.post(window.api_url + "/v2/auth", payload)
                .then(async (response) => {
                    axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
                    await loginSuccessfull(response.data)
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error)
                    reject(error);
                });
        });
    }
}
