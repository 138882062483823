<template>
    <!--STEP 3-->
    <div class="booking">
        <div class="container mt-5 pb-3">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h2 class="text-3xl">Booking <small>Stage 3/4</small></h2>
                    <h6 class="text-base">{{today}}</h6>
                    <a-button class="flex items-center justify-center mx-auto mt-2" @click="saveJson">Save To File</a-button>

                    <div v-if="errors.length" class="bg-red-300 border border-solid border-red-400 rounded w-full error-list mt-3 p-3">
                        <strong>Please correct the following error(s):</strong>
                        <ul class="list">
                            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pb-5">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="maincard border-0">
                        <div class="p-3">
                            <div>
                                <div class="flex">
                                    <label>Dropping Date and Time*</label>
                                    <a-button type="primary" class="ml-auto" size="small" @click="defaultDrop">Default Start Date / Time</a-button>
                                </div>
                                <div class="flex gap-3 mt-1">
                                    <flat-pickr v-model="dropoff_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                    <flat-pickr v-model="dropoff_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                                </div>
                            </div>

                            <div class="mt-3">
                                <div class="flex">
                                    <label>Start Date and Time*</label>
                                    <a-button type="primary" class="ml-auto" size="small" @click="defaultStart">Default Start Date / Time</a-button>
                                </div>
                                <div class="flex gap-3 mt-1">
                                    <flat-pickr v-model="start_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                    <flat-pickr v-model="start_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                                </div>
                            </div>

                            <div class="mt-3">
                                <div class="flex">
                                    <label>Finish Date and Time*</label>
                                    <a-button type="primary" class="ml-auto" size="small" @click="defaultFinish">Default Finish Date / Time</a-button>
                                </div>
                                <div class="flex gap-3 mt-1">
                                    <flat-pickr v-model="finish_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                    <flat-pickr v-model="finish_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                                </div>
                            </div>

                            <div class="mt-3">
                                <div class="flex">
                                    <label>Collection Date and Time*</label>
                                    <a-button type="primary" class="ml-auto" size="small" @click="defaultPick">Default Collection Date / Time</a-button>
                                </div>
                                <div class="flex gap-3 mt-1">
                                    <flat-pickr v-model="collect_date" class="flatPickrInput" :config="pickerConfigDate"></flat-pickr>
                                    <flat-pickr v-model="collect_time" class="flatPickrInputTime" :config="pickerConfigTime"></flat-pickr>
                                </div>
                            </div>

                            <div class="mt-3">
                                <label class="block mb-1">Customer*</label>
                                <a-radio-group v-model:value="customer">
                                    <a-radio value="Leaving - Collect same day">Leaving - Collect same day</a-radio>
                                    <a-radio value="Leaving - Collect when done">Leaving - Collect when done</a-radio>
                                    <a-radio value="Waiting in Reception">Waiting in Reception</a-radio>
                                    <a-radio value="Staying in Hotel">Staying in Hotel</a-radio>
                                </a-radio-group>
                            </div>

                            <div class="mt-3">
                                <a-checkbox v-model:checked="old_lab">Old Labour Rate</a-checkbox>
                            </div>

                            <div class="mt-3">
                                <label class="block mb-1">Booking Notes</label>
                                <text-editor v-model="notes" class-name="main-notes" class="w-full"></text-editor>
                            </div>

                            <div class="flex gap-3 mt-3">
                                <div class="w-full flex">
                                    <a-button class="ml-auto" @click="changeStep(2, 'back')">Go back</a-button>
                                </div>
                                <div class="w-full">
                                    <a-button type="primary" @click="changeStep(4, 'next')">Review Booking</a-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/STEP 3-->
    </div>
</template>

<script>
import moment from 'moment-timezone';
import axios from 'axios';
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import TextEditor from '../TextEditor.vue';
import {useBookingStore} from "@/store/bookingStore";
export default {
    components: {
        TextEditor
    },
    setup () {
        return { v$: useVuelidate() }
    },
    mounted(){
        //this.setupFlatpickr();

        if(this.dropoff_date !== "" && this.dropoff_time !== "" && this.dropoff_date !== null && this.dropoff_time !== null) {
          //  this.defaultStart();
          //  this.defaultFinish();
          //  this.defaultPick();
        }
    },
    data() {
        return {
            today: moment().tz("Europe/London").format('MMMM Do YYYY, h:mm:ss a'),
            now: moment().tz("Europe/London").format('YYYY-MM-DD'),
            errors: [],
            dropoffDatePicker: null,
            dropoffTimePicker: null,
            startDatePicker: null,
            startTimePicker: null,
            endDatePicker: null,
            endTimePicker: null,
            pickupDatePicker: null,
            pickupTimePicker: null,
            pickerConfigDate: {
                dateFormat: 'Y-m-d',
                altFormat: 'd-m-Y',
                altInput: true,
                enableTime: false,
                defaultDate: null
            },
            pickerConfigTime: {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
            },
        }
    },
    computed: {
        dropoff_date: {
            get() {
                return useBookingStore().dropoff_date
            },
            set(value) {
                useBookingStore().setDropoffDate(value)
            }
        },
        dropoff_time: {
            get() {
                return useBookingStore().dropoff_time
            },
            set(value) {
                useBookingStore().setDropoffTime(value)
            }
        },
        start_date: {
            get() {
                return useBookingStore().start_date
            },
            set(value) {
                useBookingStore().setStartDate(value)
            }
        },
        start_time: {
            get() {
                return useBookingStore().start_time
            },
            set(value) {
                useBookingStore().setStartTime(value)
            }
        },
        finish_date: {
            get() {
                return useBookingStore().finish_date
            },
            set(value) {
                useBookingStore().setFinishDate(value)
            }
        },
        finish_time: {
            get() {
                return useBookingStore().finish_time
            },
            set(value) {
                useBookingStore().setFinishTime(value)
            }
        },
        collect_date: {
            get() {
                return useBookingStore().collect_date
            },
            set(value) {
                useBookingStore().setCollectDate(value)
            }
        },
        collect_time: {
            get() {
                return useBookingStore().collect_time
            },
            set(value) {
                useBookingStore().setCollectTime(value)
            }
        },
        customer: {
            get() {
                return useBookingStore().customer
            },
            set(value) {
                useBookingStore().setCustomer(value)
            }
        },
        notes: {
            get() {
                return useBookingStore().notes
            },
            set(value) {
                useBookingStore().setNotes(value)
            }
        },
        tvvt: {
            get() {
                return useBookingStore().tvvt
            },
            set(value) {
                useBookingStore().setTvvt(value)
            }
        },
        bodyshop: {
            get() {
                return useBookingStore().bodyshop
            },
            set(value) {
                useBookingStore().setBodyshop(value)
            }
        },
        tun: {
            get() {
                return useBookingStore().tun
            },
            set(value) {
                useBookingStore().setTuning(value)
            }
        },
        old_lab: {
            get() {
                return useBookingStore().old_lab
            },
            set(value) {
                useBookingStore().setOldLab(value)
            }
        },
    },
    validations: {
        dropoff_date: {
            required
        },
        dropoff_time: {
            required
        },
        start_date: {
            required
        },
        start_time: {
            required
        },
        finish_date: {
            required
        },
        finish_time: {
            required
        },
        collect_date: {
            required
        },
        collect_time: {
            required
        },
        customer: {
            required
        },
    },
    methods: {
        setupFlatpickr(){
            var self = this;
            /*this.dropoffDatePicker = flatpickr($('#date-dropoff'), {
                position: 'auto',
                enableTime: false,
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                //minDate: new Date(),
                onClose: function(selectedDates, dateStr, instance){
                    if(self.dropoff_time !== null && self.dropoff_time !== "") {
                        if(moment(dateStr).format('dddd') == "Saturday" && moment(dateStr).format('dddd') !== "Sunday" && moment(self.dropoff_time,"HH:mm").format('H') < 9) {
                            self.dropoff_time = "09:00";
                            self.dropoffTimePicker.setDate(self.dropoff_time);
                            alert("Time is before 9am")
                            return;
                        }
                        if(moment(dateStr).format('dddd') !== "Saturday" && moment(dateStr).format('dddd') !== "Sunday" && moment(self.dropoff_time,"HH:mm").format('H') < 8) {
                            self.dropoff_time = "08:00";
                            self.dropoffTimePicker.setDate(self.dropoff_time);
                            alert("Time is before 8am")
                            return;
                        }
                        console.log(moment(dateStr), moment())
                    }
                    self.dropoff_date = dateStr;
                }
            });

            this.dropoffTimePicker = flatpickr($('#time-dropoff'), {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
                onClose: function(selectedDates, dateStr, instance){
                    if(self.dropoff_date !== null && self.dropoff_date !== "") {
                        if(moment(self.dropoff_date).format('dddd') == "Saturday" && moment(dateStr).format('dddd') !== "Sunday" && moment(dateStr,"HH:mm").format('H') < 9) {
                            self.dropoff_time = "09:00";
                            self.dropoffTimePicker.setDate(self.dropoff_time);
                            alert("Time is before 9am");
                            return;
                        }
                        if(moment(self.dropoff_date).format('dddd') !== "Saturday" && moment(dateStr).format('dddd') !== "Sunday" && moment(dateStr,"HH:mm").format('H') < 8) {
                            self.dropoff_time = "08:00";
                            self.dropoffTimePicker.setDate(self.dropoff_time);
                            alert("Time is after 8am");
                            return;
                        }
                    }
                    self.dropoff_time = dateStr;
                }
            });

            this.pickupDatePicker = flatpickr($('#date-pickup'), {
                position: 'auto',
                enableTime: false,
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                //minDate: new Date(),
                onClose: function(selectedDates, dateStr, instance){
                    self.collect_date = dateStr;
                }
            });

            this.pickupTimePicker = flatpickr($('#time-pickup'), {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
                onClose: function(selectedDates, dateStr, instance){
                    self.collect_time = dateStr;
                }
            });

            this.startDatePicker = flatpickr($('#date-start'), {
                position: 'auto',
                enableTime: false,
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                //minDate: new Date(),
                onClose: function(selectedDates, dateStr, instance){
                    self.start_date = dateStr;
                }
            });

            this.startTimePicker = flatpickr($('#time-start'), {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
                onClose: function(selectedDates, dateStr, instance){
                    self.start_time = dateStr;
                }
            });

            this.endDatePicker = flatpickr($('#date-end'), {
                position: 'auto',
                enableTime: false,
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                //minDate: new Date(),
                onClose: function(selectedDates, dateStr, instance){
                    self.finish_date = dateStr;
                }
            });

            this.endTimePicker = flatpickr($('#time-end'), {
                position: 'auto',
                enableTime: true,
                noCalendar: true,
                time_24hr: true,
                dateFormat: 'H:i',
                onClose: function(selectedDates, dateStr, instance){
                    self.finish_time = dateStr;
                }
            });*/
        },
        defaultDrop(){
            if(this.dropoff_date == "" || this.dropoff_date == null) {
                this.dropoff_date = moment().tz("Europe/London").format('YYYY-MM-DD');
                console.log(moment('08:00', 'HH:mm').tz("Europe/London"))
                this.dropoff_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                if(moment(this.dropoff_date).format('dddd') == 'Saturday') {
                  this.dropoff_time = moment('08:30', 'HH:mm').tz("Europe/London").format("HH:mm");
                } else {
                  this.dropoff_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                }
            }
        },
        defaultStart(){
            this.start_date = this.dropoff_date || moment().tz("Europe/London").format('YYYY-MM-DD');
            if(this.dropoff_time) {
                this.start_time = moment(this.dropoff_time, 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.start_time = moment('08:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            }
        },
        defaultFinish(){
            if(this.start_date == "") {
                this.finish_date = this.start_date || moment().tz("Europe/London").format('YYYY-MM-DD');
                this.finish_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.finish_date = this.start_date || moment().tz("Europe/London").format('YYYY-MM-DD');
                if(moment(this.start_date).tz("Europe/London").format('dddd') == 'Saturday') {
                    this.finish_time = moment('13:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                } else {
                    this.finish_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
                }
            }
        },
        defaultPick(){
            this.collect_date = this.finish_date || moment().tz("Europe/London").format('YYYY-MM-DD');
            if(this.finish_time) {
                this.collect_time = moment(this.finish_time, 'HH:mm').tz("Europe/London").format("HH:mm");
            } else {
                this.collect_time = moment('17:00', 'HH:mm').tz("Europe/London").format("HH:mm");
            }
        },
        changeStep(step, direction){
            if(step == 4) {
                this.errors = [];
                if(this.v$.$invalid) {
                    if(this.v$.dropoff_date.$invalid) {this.errors.push('Dropoff Date is required.')}
                    if(this.v$.dropoff_time.$invalid) {this.errors.push('Dropoff Time is required.')}
                    if(this.v$.start_date.$invalid) {this.errors.push('Start Date is required.')}
                    if(this.v$.start_time.$invalid) {this.errors.push('Start Time is required.')}
                    if(this.v$.finish_date.$invalid) {this.errors.push('Finish Date is required.')}
                    if(this.v$.finish_time.$invalid) {this.errors.push('Finish Time is required.')}
                    if(this.v$.collect_date.$invalid) {this.errors.push('Collect Date is required.')}
                    if(this.v$.collect_time.$invalid) {this.errors.push('Collect Time is required.')}
                    if(this.v$.customer.$invalid) {this.errors.push('Customer Activity is required.')}
                    this.scrollToTop();
                } else {
                    this.$parent.step = 4;
                    this.scrollToTop();
                }
            } else {
                if(this.vvt == true || this.tun == true) {
                    this.$parent.step = 2;
                } else {
                    this.$parent.step = 1;
                }
                this.scrollToTop();
            }
        },
        scrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        },
        saveJson() {
            const json = useBookingStore().$state
            delete json.parts;
            const a = document.createElement("a");
            const file = new Blob([JSON.stringify(json, undefined, 4)], {type: 'text/plain'});
            a.href = URL.createObjectURL(file);
            a.download = this.booked_in_reg +'_booking.json';
            a.click();
        },
    },
    watch: {
        /*dropoff_date(val) {
            if(moment(val) < moment()) {
                alert("Date is in the past, if you continue with this date then no events will be added to the calendar.")
            }
        },
        start_date(val) {
            if(moment(val) < moment()) {
                alert("Date is in the past, if you continue with this date then no events will be added to the calendar.")
            }
        },
        collect_date(val) {
            if(moment(val) < moment()) {
                alert("Date is in the past, if you continue with this date then no events will be added to the calendar.")
            }
        },
        finish_date(val) {
            if(moment(val) < moment()) {
                alert("Date is in the past, if you continue with this date then no events will be added to the calendar.")
            }
        }*/
    }
}
</script>

<style>

</style>
