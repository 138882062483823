<template>
    <div ref="collectDateChange">
        <a-select v-model:value="reason" style="width:100%" :options="reasons" placeholder="Select Reason..."></a-select>

        <a-input class="mt-2" v-if="reason === 'Other'" v-model:value="reason_other" placeholder="Other Reason..."></a-input>
    </div>
</template>

<script>
export default {
    props: ['reasons'],
    data() {
        return {
            reason: null,
            reason_other: null
        }
    },
    emits: ['updated'],
    computed: {
        value() {
            if(this.reason === "Other") {
                return this.reason_other;
            } else {
                return this.reason;
            }
        }
    },
    watch: {
        value(newValue) {
            this.$emit('updated', newValue);
        }
    }
}
</script>
