<template>
    <div>
        <div class="fixed top-14 w-full bg-white jobsheet-topbar container-fluid py-3 border-b border-gray-300 z-10" v-if="job">
            <div class="flex items-center gap-3">
                <h3 class="text-xl font-bold">
                    <router-link to="/" class="transition-all duration-150 text-gray-400 hover:text-gray-800">Jobs/</router-link>#{{job.id}}
                    <span class="text-gray-400" v-if="quote">QUOTE</span>
                </h3>

                <a-tag class="m-0" color="blue" v-if="job.project_car === true">Project Car</a-tag>
                <a-tag class="m-0" color="green" v-if="job.customer && job.customer.lastname === 'Paint&Body'">Bodyshop Consumables</a-tag>
                <custom-select class="w-60" v-model="job.status" @change="updateStatus" :options="statusOptions" vparam="value" lparam="value" v-if="!quote"></custom-select>
                <a-tag class="m-0" color="green" v-if="!quote">Booked in by: <b>{{job.booked_in_by}}</b></a-tag>
                <a-tag class="m-0" color="green" v-if="quote">Quoted by: <b>{{job.quoted_by}}</b></a-tag>

                <span v-if="!quote && job.workshop === true" class="rounded-sm px-2 text-xs font-bold w-auto bg-blue-400 text-white" style="padding: 0 7px;font-size: 12px;line-height: 20px;">Workshop</span>
                <span v-if="!quote && job.tuning === true" class="rounded-sm px-2 text-xs font-bold w-auto bg-gray-500 text-white" style="padding: 0 7px;font-size: 12px;line-height: 20px;">Tuning</span>
                <span v-if="!quote && job.fabrication === true" class="rounded-sm px-2 text-xs font-bold w-auto bg-gray-800 text-white" style="padding: 0 7px;font-size: 12px;line-height: 20px;">Fabrication</span>
                <span v-if="!quote && job.vvt === true" class="rounded-sm px-2 text-xs font-bold w-auto bg-yellow-400" style="padding: 0 7px;font-size: 12px;line-height: 20px;">VVT</span>
                <span v-if="!quote && job.bodyshop === true" class="rounded-sm px-2 text-xs font-bold w-auto bg-yellow-400" style="padding: 0 7px;font-size: 12px;line-height: 20px;">Bodyshop</span>

                <!--<div v-if="!job.customer"><a-button size="small" @click="addCustomerModalOpen = true">Add Customer</a-button></div>
                <div v-if="!job.vehicle"><a-button size="small" @click="addVehicleToJob">Add Vehicle</a-button></div>-->

                <a-button v-if="quote" size="small" class="ml-auto" @click="generatePDF">Generate PDF</a-button>

                <div class="ml-auto hidden lg:block" v-if="!quote">
                    <div class="flex flex-wrap gap-1">
                        <div class="btn-group mr-1">
                            <a-button v-tooltip="'Tuning'" @click="goToTuningView" size="small"><fa-icon solid icon="fa-cogs"/></a-button>
                        </div>
                        <div class="btn-group mr-1">
                            <a-button v-tooltip="'Customer'" @click="goToCustomerView" size="small"><fa-icon solid icon="fa-user"/></a-button>
                        </div>
                        <div class="btn-group mr-1" v-if="job.folder">
                            <a-button :href="job.folder" target="_blank" v-tooltip="'Folder'" size="small"><fa-icon solid icon="fa-folder"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-else>
                            <a-button v-tooltip="'Create Job Folder'" @click="createFolder($event)" size="small"><fa-icon solid icon="fa-folder-plus"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-if="job.task !== null">
                            <a-button v-tooltip="'Task'" @click="taskStop($event)" size="small" v-if="jobTaskInProgress"><fa-icon solid icon="fa-pause"/></a-button>
                            <a-button v-tooltip="'Task'" @click="taskStart($event)" size="small" v-else><fa-icon solid icon="fa-play"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-if="job.task !== null">
                            <a-button v-tooltip="'Go to Task'" @click="goToTask" size="small"><fa-icon solid icon="fa-external-link-alt"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-else>
                            <a-button v-tooltip="'New Task'" @click="createJobTask($event)" size="small"><fa-icon solid icon="fa-plus"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-if="job.project_car === false">
                            <a-button v-tooltip="'Project Car'" @click="makeProjectCar($event)" size="small" :loading="makingProjectCar"><fa-icon solid icon="fa-car"/></a-button>
                        </div>
                        <div class="btn-group mr-1" v-else>
                            <a-button disabled v-tooltip="'Project Car'" size="small"><fa-icon solid icon="fa-car"/></a-button>
                        </div>

                        <div class="btn-group mr-1">
                            <a-button v-tooltip="'Duplicate Jobcard'" size="small" @click="duplicateJobcard($event)" :loading="duplicatingJobcard"><fa-icon solid icon="fa-copy"/></a-button>
                        </div>

                        <div class="btn-group mr-1" v-if="job.old_job_id">
                            <a-button v-tooltip="'Previous Jobcard'" size="small" @click="openPrevJobcard"><fa-icon solid icon="fa-backward"/></a-button>
                        </div>

                        <div class="btn-group mr-1">
                            <a-button v-tooltip="'Add parts from separate Jobcard'" size="small" @click="addPartsBtn"><fa-icon solid icon="fa-cart-plus"/></a-button>
                        </div>

                        <div class="btn-group mr-1">
                            <a-button v-tooltip="'Rebuild Calendar'" size="small" @click="rebuildCalendar" :loading="rebuildingCalendar"><fa-icon solid icon="fa-calendar-alt"/></a-button>
                        </div>

                        <div class="btn-group">
                            <a-button v-tooltip="'Print Label'" size="small" @click="printLabel"><fa-icon solid icon="fa-tag"/></a-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="items-center gap-3 mt-2 hidden lg:flex">
                <div class="flex items-center gap-3" v-if="!quote">
                    <div class="flex items-center gap-3">
                        <div v-if="job.checked === false && job.customer.lastname !== 'Paint&Body'">
                            <label class="mr-2 font-bold">Job Check:</label>
                            <a-select placeholder="Select user..." class="w-60" v-model:value="job.requires_checking_by" @change="updateCheckUser" :options="allUsers.map(item => ({ value: item.id, label: item.nicename }))" size="small"></a-select>
                        </div>

                        <span class="ml-2" v-if="(job.requires_checking_by !== null && job.requires_checking_by !== undefined) && job.checked === false">
                            <i class="fa fa-times text-danger"></i>
                            <a-button type="primary" size="small" v-if="job.requires_checking_by === user.id" class="ml-2" @click="completeCheck($event)" :loading="completingCheck">Complete Check</a-button>
                        </span>
                    </div>
                    <div>
                        <span v-if="job.requires_checking_by !== null && job.checked === true">
                            <i class="fa fa-check text-success"></i>
                            <span class="ml-2">Job Checked by: {{getUser(job.checked_by)}} at {{ConvertDate(job.checked_at)}}</span>
                        </span>
                    </div>
                </div>
                <div class="ml-auto flex items-center gap-2" v-if="!quote">
                    <a-checkbox v-model:checked="job.is_warranty_work" disabled>Is Warranty Job</a-checkbox>
                    <a-dropdown :trigger="['click']">
                        <a-button size="small">Add Job Type<DownOutlined style="top: -3px;position: relative;"/></a-button>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item key="1" v-if="job.workshop === false" @click="addType('workshop')"><PlusCircleTwoTone/>Add Workshop</a-menu-item>
                                <a-menu-item key="2" v-if="job.tuning === false" @click="addType('tuning')"><PlusCircleTwoTone/>Add Tuning</a-menu-item>
                                <a-menu-item key="3" v-if="job.fabrication === false" @click="addType('fabrication')"><PlusCircleTwoTone/>Add Fabrication</a-menu-item>
                                <!--<a-menu-item key="3" v-if="job.bodyshop === false" @click="addType('bodyshop')"><PlusCircleTwoTone/>Add Bodyshop</a-menu-item>-->

                                <a-menu-item key="4" v-if="job.workshop === true" @click="removeType('workshop')"><MinusCircleTwoTone/> Remove Workshop</a-menu-item>
                                <a-menu-item key="5" v-if="job.tuning === true" @click="removeType('tuning')"><MinusCircleTwoTone/>Remove Tuning</a-menu-item>
                                <a-menu-item key="6" v-if="job.fabrication === true" @click="removeType('fabrication')"><MinusCircleTwoTone/>Remove Fabrication</a-menu-item>
                                <!--<a-menu-item key="7" v-if="job.bodyshop === true" @click="removeType('bodyshop')"><MinusCircleTwoTone/>Remove Bodyshop</a-menu-item>-->
                            </a-menu>
                        </template>
                    </a-dropdown>
                    <div class="ml-2 btn-group" v-if="job.previous_bookings && job.previous_bookings.length > 0">
                        <a-button size="small" class="w-full opacity-70" title="Old Jobs" @click="openPrevBookingsModal">
                            <i class="fa fa-arrow-alt-circle-left mr-2"></i> Previous Bookings
                        </a-button>
                    </div>
                    <!--<div>
                        <a-button size="small" class="trigger flex items-center justify-center" @click="() => (this.sidebarCollapsed = !sidebarCollapsed)"><menu-outlined/></a-button>
                    </div>-->
                </div>
            </div>
        </div>

        <a-modal v-model:visible="prevBookingsModal" width="80%" :footer="null" v-if="job">
            <div class="p-4">
                <div class="table-responsive">
                    <table class="w-full table-striped border-b border-gray-200">
                        <thead>
                            <tr>
                                <td class="px-1 py-2 border-t border-l border-r border-gray-200 bg-gray-50">Job ID</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Status</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Owner</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Tuning Owner</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Make</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Model</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Reg</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">VIN</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Collected</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50"></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job in job.previous_bookings" :key="job.id" style="cursor:pointer">
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-l border-r border-gray-200">{{ job.id }}</td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">{{ job.status }}</td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">{{ getUser(job.owner) }}</td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">{{ getUser(job.default_tuning_owner) }}</td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">
                                    <span v-if="job.vehicle">{{ job.vehicle.VRMake }}</span>
                                </td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">
                                    <span v-if="job.vehicle">{{ job.vehicle.VRModel }}</span>
                                </td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">
                                    <span v-if="job.vehicle">{{ job.vehicle.VRVrm }}</span>
                                </td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">
                                    <span v-if="job.vehicle">{{ job.vehicle.VRVin }}</span>
                                </td>
                                <td @click="loadJobsheet(job.id)" class="px-1 py-2 border-t border-r border-gray-200">{{ job.pick_up_time }}</td>
                                <td class="px-1 py-2 border-t border-r border-gray-200">
                                    <button class="btn btn-sm btn-primary" @click="loadOldJobNotes(job)" title="Notes">
                                        <i class="far fa-file-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </a-modal>

        <a-modal v-model:visible="prevBookingNotesModal" width="80%" :footer="null">
            <div class="p-4">
                <div>
                    <label class="mb-0 font-bold">Notes:</label>
                    <div v-html="oldJobNote"></div>
                </div>

                <div class="mt-2">
                    <label class="mb-0 font-bold">Customer Advisory Notes:</label>
                    <div v-html="oldJobCustomerNote"></div>
                </div>
            </div>
        </a-modal>

        <a-modal v-model:visible="addCustomerModalOpen" :okButtonProps="{disabled: selectedCustomer === null}" @cancel="selectedCustomer = null" :closable="false" @ok="addCustomerToJob">
            <a-input-search v-model:value="addCustomerValue" placeholder="Search customer..." style="width: 100%" @search="searchCustomers" :loading="searchingCustomer" />
            <div>
                <ul class="border-l border-r">
                    <li class="py-1 px-2 border-b hover:bg-gray-50 cursor-pointer" :class="{'active': item.value === selectedCustomer}" v-for="item in customerList" :key="item.value" @click="selectedCustomer=item.value">{{item.label}}</li>
                </ul>
            </div>
        </a-modal>

        <a-modal v-model:visible="addVehicleModalOpen" :okButtonProps="{disabled: selectedVehicle === null}" @cancel="selectedVehicle = null" :closable="false" @ok="addVehicleToJob">
            <a-input-search v-model:value="addVehicleValue" placeholder="Search vehicle..." style="width: 100%" @search="searchVehicles" :loading="searchingVehicle" />
            <div>
                <ul class="border-l border-r">
                    <li class="py-1 px-2 border-b hover:bg-gray-50 cursor-pointer" :class="{'active': item.value === selectedVehicle}" v-for="item in vehicleList" :key="item.value" @click="selectedVehicle=item.value">{{item.label}}</li>
                </ul>
            </div>
        </a-modal>

        <a-modal v-model:visible="dupeCardModalOpen" title="Duplicate/Clone Jobcard">
            <template #footer>
                <a-button key="back" @click="dupeCardModalOpen = false">Cancel</a-button>
                <a-button key="submit" type="primary" :loading="dupeCardModalLoading">Duplicate</a-button>
                <a-button key="submit" type="primary" :loading="dupeCardModalLoading">Clone</a-button>
            </template>
            <p>CLONE: Completely clone a jobcard.</p>
            <p>DUPLICATE: Clones a jobcard and removes completed tasks.</p>
        </a-modal>
    </div>
</template>

<script>
import doT from 'dot';
import {DownOutlined, ExclamationCircleOutlined, MinusCircleTwoTone, PlusCircleTwoTone, MenuOutlined} from '@ant-design/icons-vue';
import {Modal} from 'ant-design-vue';
import {createVNode, defineComponent, h} from 'vue';
import _ from 'lodash'
import Swal from 'sweetalert2'
import {useAuthStore} from "@/store/authStore";
import customSelect from '../extras/Select.vue';
import FaIcon from "@/components/extras/Icon.vue";
export default defineComponent({
    props: ['job', 'users', 'jobTaskInProgress', 'isCollapsed', 'quote'],
    components: {
        DownOutlined,
        PlusCircleTwoTone,
        MinusCircleTwoTone,
        MenuOutlined,
        customSelect,
        FaIcon
    },
    data: () => ({
        dupeCardModalOpen: false,
        dupeCardModalLoading: false,
        addCustomerModalOpen: false,
        addVehicleModalOpen: false,

        customerList: [],
        addCustomerValue: "",
        selectedCustomer: null,
        searchingCustomer: false,

        vehicleList: [],
        addVehicleValue: "",
        selectedVehicle: null,
        searchingVehicle: false,

        tester: "testing",
        racelist_tasks: [],
        oldJobNote: "",
        oldJobCustomerNote: "",
        prevBookingsModal: false,
        prevBookingNotesModal: false,
        makingProjectCar: false,
        duplicatingJobcard: false,
        rebuildingCalendar: false,
        completingCheck: false,
        creatingFolder: false,
        statusOptions: [
            {
                id: 1,
                value: "Awaiting Authority"
            },
            {
                id: 2,
                value: "Awaiting Collection"
            },
            {
                id: 3,
                value: "Awaiting Invoice"
            },
            {
                id: 4,
                value: "Awaiting Parts"
            },
            {
                id: 5,
                value: "Awaiting Payment"
            },
            {
                id: 6,
                value: "Awaiting Road Test"
            },
            {
                id: 7,
                value: "Awaiting Technician"
            },
            {
                id: 8,
                value: "Awaiting Vehicle"
            },
            {
                id: 9,
                value: "File Read - Awaiting Vehicle"
            },
            {
                id: 10,
                value: "Cancelled"
            },
            {
                id: 11,
                value: "Collected / Paid"
            },
            {
                id: 12,
                value: "Complete"
            },
            {
                id: 13,
                value: "Customer Notified"
            },
            {
                id: 14,
                value: "Final Checks"
            },
            {
                id: 15,
                value: "Final Road Test Complete"
            },
            {
                id: 16,
                value: "Rebooking"
            },
            {
                id: 17,
                value: "Road Test"
            },
            {
                id: 18,
                value: "Started - Fabrication"
            },
            {
                id: 19,
                value: "Started - Tuning"
            },
            {
                id: 20,
                value: "Started - Workshop"
            },
            {
                id: 21,
                value: "Started - Bodyshop"
            },
            {
                id: 22,
                value: "Vehicle Present Not Started"
            },
            {
                id: 23,
                value: "Wait Cust Auth"
            },
            {
                id: 24,
                value: "Paid - Awaiting Collection"
            }
        ],
        jobTypeOptions: [
            {
                id: 1,
                value: "External Customer"
            },
            {
                id: 2,
                value: "Internal Customer"
            },
            {
                id: 3,
                value: "RnD"
            },
            {
                id: 4,
                value: "Warranty"
            },
            {
                id: 5,
                value: "Free"
            }
        ],
        sidebarCollapsed: false,
        confirmOpen: false,
    }),
    created() {
        if(this.job.bodyshop) {
            const bStatus = [
                {
                    id: 24,
                    value: "Awaiting Quote"
                },
                {
                    id: 25,
                    value: "Quoted - Customer"
                },
                {
                    id: 26,
                    value: "Quoted - Insurance"
                },
                {
                    id: 27,
                    value: "Referred - Insurance"
                },
                {
                    id: 28,
                    value: "Awaiting Authority - Insurance"
                },
                {
                    id: 29,
                    value: "Accepted"
                },
                {
                    id: 30,
                    value: "Approved"
                },
                {
                    id: 31,
                    value: "Vehicle in Transit"
                },
                {
                    id: 32,
                    value: "Vehicle on Site"
                }

            ]
            for(let i = 0; i < bStatus.length; i++) {
                this.statusOptions.push(bStatus[i]);
            }
        }
    },
    mounted() {
        this.sidebarCollapsed = this.isCollapsed;
    },
    methods: {
        searchCustomers(e) {
            if(e.length > 3) {
                this.searchingCustomer = true;
                this.customerList = [];
                axios.get(api_url + "/api/crm/search?term=" + e).then(resp => {
                    this.customerList = resp.data.map(el => ({label: el.firstname + " " + el.lastname + " (" + el.email + ")", value: el.id}))
                    this.searchingCustomer = false;
                }).catch(err => {
                    console.log(err)
                    this.searchingCustomer = false;
                    this.customerList = [];
                })
            }
        },
        searchVehicles(e) {
            if(e.length > 3) {
                this.searchingCustomer = true;
                this.customerList = [];
                axios.get(api_url + "/api/crm/search?term=" + e).then(resp => {
                    this.customerList = resp.data.map(el => ({label: el.firstname + " " + el.lastname + " (" + el.email + ")", value: el.id}))
                    this.searchingCustomer = false;
                }).catch(err => {
                    console.log(err)
                    this.searchingCustomer = false;
                    this.customerList = [];
                })
            }
        },
        selectCustomer(e) {
            console.log(e)
        },
        addCustomerToJob() {
            const selectedCust = this.selectedCustomer;
            this.customerList = [];
            this.addCustomerModalOpen = false;
            console.log(selectedCust)
        },
        addVehicleToJob(e) {
        },

        taskStart() {
            axios.post(api_url + '/api/job/' + this.job.id + '/task/start', {
                task_id: this.job.task.id,
                user: this.user.id,
                owner: this.job.task.owner
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Task Started.');
                    this.$emit('task-changed', true);
                } else {
                    this.$message.error('There was a problem starting the task.');
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error('There was a problem starting the task.');
            });
        },
        taskStop() {
            axios.post(api_url + '/api/job/' + this.job.id + '/task/stop', {
                task_id: this.job.task.id,
                user: this.user.id,
                owner: this.job.task.owner
            }).then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Task Stopped.');
                    this.$emit('task-changed', false);
                } else {
                    this.$message.error('There was a problem stopping the task.');
                }
            }).catch((err) => {
                console.log(err)
                this.$message.error('There was a problem stopping the task.');
            });
        },
        addPartsBtn() {
            const p = prompt('Job ID');
            if (p !== null && p !== "") {
                axios.post(api_url + '/api/job/' + this.job.id + '/parts-to-job', {
                    from_id: p,
                    to_id: this.job.id
                }).then(() => {
                    location.reload();
                })
            }
        },
        rebuildCalendar() {
            const conf = confirm("Are you sure you want to reinsert dates into calendar?");
            if (conf) {
                this.rebuildingCalendar = true;
                axios.post(api_url + '/api/job/' + this.job.id + '/calendar').then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Calendar Updated');
                    } else {
                        this.$message.error('Calendar Updated Failed');
                    }
                    this.rebuildingCalendar = false;
                }).catch(() => {
                    this.rebuildingCalendar = false;
                    this.$message.error('Calendar Updated Failed');
                });
            }
        },
        makeProjectCar() {
            const conf = confirm('Are you sure you want to turn this into a project car jobsheet?');
            if (conf) {
                this.makingProjectCar = true;
                axios.post(api_url + '/api/job/' + this.job.id + '/project/assign').then((resp) => {
                    if (resp.data.success) {
                        this.$message.success('Created Project Car');
                        location.reload();
                    } else {
                        this.$message.error('Failed To Create Project Car');
                    }
                    this.makingProjectCar = false
                }).catch(() => {
                    this.makingProjectCar = false
                    this.$message.error('Failed To Create Project Car');
                });
            }
        },
        duplicateJobcard() {
            //this.dupeCardModalOpen = true;


            const conf = confirm('Are you sure you want to duplicate this jobsheet?');
            if (conf) {
                this.duplicatingJobcard = true;
                axios.post(api_url + '/api/job/' + this.job.id + '/duplicate').then((resp) => {
                    this.duplicatingJobcard = false;
                    window.open("/jobsheet/" + resp.data.data, "_blank");
                }).catch(() => {
                    this.duplicatingJobcard = false;
                    this.$message.error('Failed to create jobsheet.');
                });
            }
        },
        completeCheck() {
            this.completingCheck = true;
            axios.post(api_url + '/api/job/' + this.job.id + '/update/check', {
                id: this.job.id,
                user: this.user.id
            }).then((resp) => {
                if (resp.data.success) {
                    this.job.checked = 1;
                    this.job.checked_by = this.user.id;
                    this.job.checked_at = new Date();
                    this.$message.success('Check Completed.');
                } else {
                    this.$message.error('Check Failed.');
                }
                this.completingCheck = false;
            }).catch(() => {
                this.completingCheck = false;
                this.$message.error('Check Failed.');
            });
        },
        createJobTask() {
            this.creatingJobTask = true;
            axios.post(api_url + '/api/job/' + this.job.id + '/task/create').then((resp) => {
                if (resp.data.success) {
                    this.$message.success('Task Created.');
                    location.reload();
                } else {
                    this.$message.error('Task Failed.');
                }
                this.creatingJobTask = false;
            }).catch(() => {
                this.creatingJobTask = false;
                this.$message.error('Task Failed.');
            });
        },
        createFolder() {
            this.creatingFolder = true;
            axios.get(api_url + '/api/job/' + this.job.id + '/folder/create').then((resp) => {
                if (resp.data.success) {
                    this.creatingFolder = false
                    location.reload();
                } else {
                    this.creatingFolder = false
                    this.$message.error(resp.status);
                }
            }).catch((err) => {
                this.creatingFolder = false
                this.$message.error(err);
            });
        },
        updateStatus(val, oldVal) {
            const fabParts = []
            if(val === "Collected / Paid") {
                for (let i = 0; i < this.job.workshop_parts.length; i++) {
                    const part = this.job.workshop_parts[i];
                    if ((part.cost_per_part !== "£0.00" && part.cost_per_part !== 0 && part.cost_per_part !== "0") && (part.purchase_price === "0" || part.purchase_price === "£0.00" ||
                        part.purchase_price === 0)) {
                        this.$message.error("Parts with retail price require a cost price!");
                        setTimeout(() => {
                            this.job.status = oldVal;
                        }, 200)
                        return false;
                    }
                }

                for (let i = 0; i < this.job.fabrication_parts.length; i++) {
                    const part = this.job.fabrication_parts[i];
                    if ((part.cost_per_part !== "£0.00" && part.cost_per_part !== 0 && part.cost_per_part !== "0") && (part.purchase_price === "0" || part.purchase_price === "£0.00" ||
                        part.purchase_price === 0)) {
                        if (part.part_no) {
                            fabParts.push(part.part_no)
                        } else {
                            fabParts.push(part.part_name)
                        }
                    }
                }
            }
            if(fabParts.length && val === "Collected / Paid") {
                const vnode = h('ul', fabParts.map((v, i) => {
                    return h('li', {key: i}, v)
                }));
                const self = this;
                Modal.confirm({
                    title: () => 'The parts below have no cost price, continue?',
                    content: vnode,
                    icon: () => createVNode(ExclamationCircleOutlined),
                    okText: "Yes",
                    onOk() {
                        axios.post(api_url + '/api/job/' + self.job.id + '/update/status', {
                            value: val
                        }).then((resp) => {
                            if (resp.data.success) {
                                self.$message.success('Status Updated.');
                            } else {
                                if (resp.data.message && resp.data.message.status === "Job not processed on Linnworks or Website!") {
                                    alert('Job not processed on Linnworks or Website!')
                                }

                                self.$message.error('Status Failed: ' + resp.data.message.status);
                                self.job.status = resp.data.message.value;
                            }
                        }).catch((err) => {
                            self.$message.error(err);
                        });
                    },
                    onCancel() {
                        self.job.status = oldVal;
                    }
                });
            } else {
                axios.post(api_url + '/api/job/' + this.job.id + '/update/status', {
                    value: val
                }).then((resp) => {
                    if (resp.data.success) {
                        this.$message.success('Status Updated.');
                    } else {
                        if (resp.data.message && resp.data.message.status === "Job not processed on Linnworks or Website!") {
                            alert('Job not processed on Linnworks or Website!')
                        }
                        this.$message.error('Status Failed: ' + resp.data.message.status);
                        this.job.status = resp.data.message.value;
                    }
                }).catch((err) => {
                    console.log(err)
                    this.$message.error('Status Failed');
                });
            }

            /*if(val === "Rebooking") {
                Swal.fire({
                    title: 'What\'s happening to the parts?',
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Keep Pick Status',
                    denyButtonText: `Reset Pick Status`,
                }).then((result) => {
                    if(result.isDenied) {
                        axios.post(api_url + '/api/job/' + this.job.id + '/update/status', {
                            value: val,
                            reset: true
                        }).then((resp) => {
                            if (resp.data.success) {
                                this.$message.success('Status Updated.');
                                location.reload();
                            } else {
                                if (resp.data.message && resp.data.message.status === "Job not processed on Linnworks or Website!") {
                                    alert('Job not processed on Linnworks or Website!')
                                }
                                this.$message.error('Status Failed: ' + resp.data.status);
                            }
                        }).catch((err) => {
                            console.log(err)
                            this.$message.error('Status Failed');
                        });
                    }

                    if(result.isConfirmed) {
                        axios.post(api_url + '/api/job/' + this.job.id + '/update/status', {
                            value: val
                        }).then((resp) => {
                            if (resp.data.success) {
                                this.$message.success('Status Updated.');
                            } else {
                                if (resp.data.message && resp.data.message.status === "Job not processed on Linnworks or Website!") {
                                    alert('Job not processed on Linnworks or Website!')
                                }
                                this.$message.error('Status Failed: ' + resp.data.status);
                            }
                        }).catch((err) => {
                            console.log(err)
                            this.$message.error('Status Failed');
                        });
                    }

                    if(result.isDismissed) {
                        if(oldVal) {
                            this.job.status = oldVal;
                        }
                    }
                })
            } else {
                axios.post(api_url + '/api/job/' + this.job.id + '/update/status', {
                    value: val
                }).then((resp) => {
                    if (resp.data.success) {
                        this.$message.success('Status Updated.');
                    } else {
                        if (resp.data.message && resp.data.message.status === "Job not processed on Linnworks or Website!") {
                            alert('Job not processed on Linnworks or Website!')
                        }
                        this.$message.error('Status Failed: ' + resp.data.status);
                    }
                }).catch((err) => {
                    console.log(err)
                    this.$message.error('Status Failed');
                });
            }*/
        },
        updateType(val) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/type', {
                value: val
            }).then(() => {
                this.$message.success('Type Updated.');
            }).catch(() => {
                this.$message.error('Type Failed.');
            })
        },
        updateIsWarrantyJob(val) {
            const value = val.target.checked;
            let canContinue = false;
            if(value) {
                let conf = confirm("Are you sure you want to mark this job as a warranty job?")
                if(conf) {
                    canContinue = true;
                } else {
                    this.job.is_warranty_work = !value;
                }
            } else {
                let conf = confirm("Are you sure you want remove the warranty marker for this job?")
                if(conf) {
                    canContinue = true;
                } else {
                    this.job.is_warranty_work = !value;
                }
            }
            if(canContinue) {
                axios.post(api_url + '/api/job/' + this.job.id + '/update/warranty-work', {
                    value: value
                }).then(() => {
                    this.$message.success('Warranty Updated.');
                }).catch(() => {
                    this.$message.error('Warranty Failed.');
                })
            }
        },
        updateCheckUser(val) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/checker', {
                value: val
            }).then(() => {
                this.$message.success('Checker Updated.');
            }).catch(() => {
                this.$message.error('Checker Failed.');
            })
        },
        updateOldLab(val) {
            axios.post(api_url + '/api/job/' + this.job.id + '/update/oldlab', {
                id: this.job.id,
                value: val
            }).then(() => {
                this.$message.success('Updated old labour.');
                location.reload();
            }).catch(() => {
                this.$message.error('Failed to update old labour!');
            })
        },
        openPrevJobcard() {
            const id = this.job.old_job_id;
            window.open("https://jobs.darkside-developments.com/jobsheet/" + id, '_blank');
        },
        printLabel() {
            const printLabel = doT.template('<html><head><style>html { margin: 0}body { width: 100%; height: auto; text-align: center; margin: 0 auto}.pagebreak { page-break-after: always}h4 { font-family: Helvetica,serif; font-size: 20px; margin: 0}@page { margin: 0; size: landscape}hr { color: black; border-bottom: 1px solid; margin: 5pt}.boxesUsed { width: 60%; text-align: left; margin: 0; font-size: 8pt}.ref { text-align: center; margin: 0; font-size: 12pt}.ref2 { text-align: right; margin: 0; font-size: 8pt; font-weight: bold}.clear { clear: both}.font20 { font-size: 20pt; font-weight: bold}.font15 { font-size: 15pt; font-weight: bold}.font12 { font-size: 10pt; font-weight: bold}.font9 { font-size: 9pt}.font10 { font-size: 10pt}.username { text-align: center; margin: 0; font-size: 9pt}.itemCount { text-align: right; margin: 0; font-size: 8pt}.delivery { text-align: center; font-size: 8pt}.fullwidth { width: 100%}.bottom { text-align: left}#pdfprint { padding-top: 30px; width: 90%; margin: 0 auto}.textcenter { text-align: center; font-family: Helvetica,serif; font-size: 20px}.border-top td { border-top: 1px solid black}.border-bottom td { border-bottom: 1px solid black}.border-top-black { border-top: 1px solid black}.space td { padding-bottom: 4pt}</style></head><body id="pdfprint"><table class="fullwidth"><tbody><tr><td class="ref"><b>{{=it.id}}</b></td></tr></tbody></table><table class="fullwidth textcenter border-top-black"><tbody><tr><td class="font20">{{=it.name}}</td></tr><tr class="space"><td class="font15">{{=it.reg}}</td></tr><tr class="space"><td class="font12">Dropoff Date: {{=it.date}}</td></tr></tbody></table><table class="bottom fullwidth border-top-black"><tbody><tr><td></td><td class="username">{{=it.username}}</td><td></td></tr></tbody></table><div class="pagebreak"></div></body></html>');
            const printText = printLabel({
                id: this.job.id,
                name: this.job.customer.firstname + " " + this.job.customer.lastname,
                reg: this.job.vehicle.VRVrm,
                date: this.job.drop_off_time,
                username: this.user.nicename,
            });
            const win = window.open('', '_blank', 'left=200, top=200, width=950, height=500, toolbar=0, resizable=0');
            win.document.write(printText);
            win.print();
            win.document.close();
        },
        loadJobsheet(jobid) {
            let route = this.$router.resolve({
                name: 'Jobsheet',
                params: {
                    id: jobid
                }
            });
            window.open(route.href, '_blank');
        },
        loadOldJobNotes(job) {
            this.oldJobNote = _.unescape(job.note)
            this.oldJobCustomerNote = _.unescape(job.customer_note)
            this.prevBookingNotesModal = true;
        },
        openPrevBookingsModal() {
            this.prevBookingsModal = true;
        },
        goToCustomerView() {
            this.$router.push({
                name: 'Customer',
                params: {
                    id: this.job.id
                }
            })
        },
        goToTuningView() {
            this.$router.push({
                name: 'Tuning',
                params: {
                    id: this.job.id
                }
            })
        },
        goToTask() {
            window.open("https://tasks.darkside-developments.com/task/" + this.job.task.id, '_blank');
        },
        getUser(val) {
            val = parseInt(val);
            let name = null;
            const find = this.users.find(el => el.id === val);
            if(find) {
                name = find.nicename;
            }
            return name;
        },
        addType(type) {
            const self = this;
            Modal.confirm({
                title: () => 'Are you sure you want to add ' + type + ' to this job?',
                icon: () => createVNode(ExclamationCircleOutlined),
                onOk() {
                    axios.post(api_url + '/api/job/' + self.job.id + '/add/type', {
                        type: type
                    }).then(resp => {
                        if (resp.data.success) {
                            self.$message.success('Added work type, Reloading page...');
                            location.reload();
                        } else {
                            self.$message.error('Failed to add work type.');
                        }
                    }).catch(() => {
                        self.$message.error('Failed to add work type.');
                    });
                },
            });
        },
        removeType(type) {
            const self = this;
            Modal.confirm({
                title: () => 'Are you sure you want to remove ' + type + ' from this job?',
                icon: () => createVNode(ExclamationCircleOutlined),
                onOk() {
                    axios.post(api_url + '/api/job/' + self.job.id + '/remove/type', {
                        type: type
                    }).then(resp => {
                        if (resp.data.success) {
                            self.$message.success('Removed work type, Reloading page...');
                            location.reload();
                        } else {
                            self.$message.error('Failed to remove work type.');
                        }
                    }).catch(() => {
                        self.$message.error('Failed to remove work type.');
                    });
                }
            });
        },
        /*assignRacelist(e) {
            var racelist = e.srcElement[0].value;
            var user = e.srcElement[1].value;
            if (racelist !== "Select Racelist..." && user !== "Select a user...") {
                axios.post(api_url + '/api/job/'+this.job.id+'/racelist/assign', {
                    racelist_id: racelist,
                    user_id: user,
                    job_id: this.job.id,
                }).then((resp) => {
                    if (resp.data == "Success") {
                        location.reload();
                    } else {
                        alert(resp.data)
                    }
                })
            }
        },*/
        generatePDF() {
            this.$parent.generatePDF()
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
        allUsers() {
            return this.users.sort(function (a, b) {
                if (a.nicename < b.nicename) {
                    return -1;
                }
                if (a.nicename > b.nicename) {
                    return 1;
                }
                return 0;
            });
        },
    },
    watch: {
        job(value) {
            if (value) {
                if (this.job.requires_checking_by == null) {
                    this.job.requires_checking_by = undefined
                }
                if (this.job.task && this.job.task.times) {
                    this.job.task.times.forEach(element => {
                        if (element.user === this.user.id && element.pause_time == null) {
                            this.jobTaskInProgress = true;
                        }
                    });
                }

                if (this.job.project_car === 1 && this.racelist_tasks.length === 0) {
                    axios.get(api_url + '/api/racelist/all').then((resp) => {
                        if (resp.data) {
                            this.racelist_tasks = resp.data;
                        }
                    });
                }
            }
        },
        sidebarCollapsed(val) {
            this.$emit('sidebar-toggled', val)
        },
        addCustomerModalOpen(val) {
            if(!val) {
                this.customerList = [];
                this.addCustomerValue = null;
            }
        }
    }
});
</script>

<style>
    .jobsheet-topbar .ant-select .ant-select-selector {
        border-color: #91d5ff;
    }
    .jobsheet-topbar .ant-select .ant-select-selector .ant-select-selection-item{
        color: #096dd9;
    }
    .swal2-title {
        font-size: 16px;
    }
    .swal2-actions button.swal2-styled {
        padding: 15px 15px;
        display: flex;
        height: auto;
        line-height: 0px;
        font-size: 14px;
        border-radius: 2px;
    }
    .swal2-popup {
        border-radius: 2px;
        padding: 0 0 0.25em;
    }
    .swal2-styled.swal2-confirm:focus,
    .swal2-styled.swal2-deny:focus,
    .swal2-styled.swal2-cancel:focus {
        box-shadow: none;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-right-width: 0;
    }

    li.active {
        font-weight: 600;
    }
</style>
