<template>
    <div class="bg-white border border-gray-200 rounded overflow-hidden">
        <div class="w-full">
            <div class="table-responsive">
                <table class="table table-striped price-table" v-if="!job">
                    <tbody>
                        <tr class="borders">
                            <th class="t-b-l" contenteditable="false">
                                <b>Parts Discount:</b>
                            </th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <th contenteditable="false">
                                <b>Labour Discount:</b>
                            </th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                        </tr>

                        <tr class="borders t-b-t">
                            <th class="t-b-l" contenteditable="false"><b>Deposit:</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td contenteditable="false">Plus VAT</td>
                            <td class="t-b-l"><b>Extra Funds:</b></td>
                            <td id="Agreed_Amount"><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td class="t-b-r"></td>
                        </tr>

                        <tr class="borders">
                            <th><b>Tuning</b></th>
                            <td><b>Total Ex VAT :</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Labour Rate :</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <th><b>Fabrication</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Labour Rate :</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <th><b>Workshop</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Labour Rate :</b></td>
                            <td width="80px"><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <th><b>Bodyshop</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Labour Rate :</b></td>
                            <td width="80px"><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <th><b>Totals</b></th>
                            <th><b>Total Ex VAT:</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Total Inc VAT:</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>

                        <tr>
                            <th><b>Totals (minus parts discount)</b></th>
                            <th><b>Total Ex VAT:</b></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td><b>Total Inc VAT:</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <th>Total Cost</th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                        </tr>

                        <tr class="price-totals">
                            <th><h4><b>Parts Total:</b></h4></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td><h4><b>Labour Total:</b></h4></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td style="text-align:right"><b>Labour Hrs:</b></td>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                        </tr>

                        <tr class="price-totals2">
                            <th><h5><b>Total Left To Pay Ex VAT:</b></h5></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <th><h4><b>Total Left To Pay:</b></h4></th>
                            <td><skeleton type="text" width="100%"></skeleton></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <table class="table table-striped price-table" v-else>
                    <tbody>
                        <tr class="borders" style="border-radius: 0.375rem;">
                            <td>
                                <b v-if="!viewOnly">Has VAT?</b>
                                <a-checkbox v-model:checked="job.has_vat" v-if="!viewOnly" @change="UpdateHasVat" class="ml-2"></a-checkbox>
                                <div v-else>
                                    <span v-if="!viewOnly">{{job.has_vat}}</span>
                                </div>
                            </td>

                            <td v-if="!quote">
                                <b v-if="!viewOnly">Free Labour?</b>
                                <a-checkbox v-model:checked="job.free_labour" v-if="!viewOnly" @change="UpdateFreeLabour" class="ml-2"></a-checkbox>
                            </td>

                            <td>
                                <b v-if="!viewOnly">Customer VAT Registered?</b>
                                <a-checkbox v-model:checked="job.customer_vat_registered" v-if="!viewOnly" @change="UpdateVatRegistered" class="ml-2"></a-checkbox>
                            </td>
                        </tr>

                        <tr class="borders" style="border-radius: 0.375rem;">
                            <th class="t-b-l" contenteditable="false" colspan="2">
                                <b>Parts Discount:</b>
                            </th>
                            <td :contenteditable="!viewOnly" id="parts_disc" @blur="UpdatePartDisc($event)" class="editable t-b-l" :class="{'red-borders': job.job_detail.parts_disc == '0'}">
                                {{ConvertPrice(job.job_detail.parts_disc)}}
                            </td>
                            <td :contenteditable="!viewOnly" id="part_disc_per" @blur="UpdatePartDiscPerc($event)" class="editable t-b-l" :class="{'red-borders': PartsDiscountPercent().toFixed(2) == '0.00'}">
                                {{PartsDiscountPercent().toFixed(2)}}%
                            </td>
                            <th class="t-b-l" contenteditable="false" colspan="2">
                                <b>Labour Discount:</b>
                            </th>
                            <td :contenteditable="!viewOnly" id="lab_disc" @blur="UpdateLabourDisc($event)" class="editable t-b-l" :class="{'red-borders': job.job_detail.lab_disc == '0'}" colspan="2">
                                {{ConvertPrice(job.job_detail.lab_disc)}}
                            </td>
                            <td :contenteditable="!viewOnly" id="lab_disc_per"  @blur="UpdateLabourDiscPerc($event)" class="editable t-b-l" :class="{'red-borders': LabourDiscountPercent().toFixed(2) == '0.00'}" v-if="LabourTotalNoDisc() > 0" colspan="1">
                                {{LabourDiscountPercent().toFixed(2)}}%
                            </td>
                            <td :contenteditable="!viewOnly"  id="lab_disc_per" @blur="UpdateLabourDiscPerc($event)" class="editable t-b-l red-borders" colspan="1" v-else>0%</td>
                        </tr>

                        <tr class="borders t-b-t">
                            <th class="t-b-l" contenteditable="false" colspan="2"><b>Deposit:</b></th>
                            <td :contenteditable="!viewOnly" id="deposit" @blur="UpdatePaidDeposit($event)" class="editable t-b-l" :class="{'red-borders': job.deposit == '0'}">{{ConvertPrice(job.deposit)}}</td>
                            <td class="t-b-l" contenteditable="false">Plus VAT</td>
                            <td class="t-b-l" ><b>Extra Funds:</b></td>
                            <!--<td id="Agreed_Amount">
                                {{ConvertPrice(job.authorised_costs)}}
                            </td>-->
                            <td :contenteditable="!viewOnly && !quote" id="Agreed_Amount" @blur="UpdateAuthedCosts($event)" class="editable t-b-l">{{ConvertPrice(job.authorised_costs)}}</td>
                            <th class="t-b-l" contenteditable="false" colspan="2"><b>Additional Discount:</b></th>
                            <td class="t-b-l cursor-pointer" contenteditable="false" @click="DisplayDiscounts()">£{{GetAdditionalDiscounts()}}</td>
                        </tr>

                        <tr class="borders">
                            <th><b>Tuning</b></th>
                            <td><b>Total Ex VAT :</b></td>
                            <td>{{ConvertPrice(GetTotals('tuning'))}}</td>

                            <td><b>Total Labour :</b></td>
                            <td>{{ConvertPrice(GetLabourTotal('tuning'))}}</td>

                            <td><b>Total Labour Hours :</b></td>
                            <td>{{GetLabourHoursTotal('tuning')}}</td>

                            <td><b>Labour Rate :</b></td>
                            <td>{{ConvertPrice(tuning_labour_rate)}}</td>
                        </tr>

                        <tr>
                            <th><b>Fabrication</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td>{{ConvertPrice(GetTotals('fabrication'))}}</td>

                            <td><b>Total Labour :</b></td>
                            <td>{{ConvertPrice(GetLabourTotal('fabrication'))}}</td>

                            <td><b>Total Labour Hours :</b></td>
                            <td>{{GetLabourHoursTotal('fabrication')}}</td>

                            <td><b>Labour Rate :</b></td>
                            <td>{{ConvertPrice(fab_labour_rate)}}</td>
                        </tr>

                        <tr>
                            <th><b>Workshop</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td>{{ConvertPrice(GetTotals('workshop'))}}</td>

                            <td><b>Total Labour :</b></td>
                            <td>{{ConvertPrice(GetLabourTotal('workshop'))}}</td>

                            <td><b>Total Labour Hours :</b></td>
                            <td>{{GetLabourHoursTotal('workshop')}}</td>

                            <td><b>Labour Rate :</b></td>
                            <td id="lab_rate">{{ConvertPrice(workshop_labour_rate)}}</td>
                        </tr>

                        <tr>
                            <th><b>Workshop End</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td>{{ConvertPrice(GetTotals('workshop_end'))}}</td>

                            <td><b>Total Labour :</b></td>
                            <td>{{ConvertPrice(GetLabourTotal('workshop_end'))}}</td>

                            <td><b>Total Labour Hours :</b></td>
                            <td>{{GetLabourHoursTotal('workshop_end')}}</td>

                            <td><b>Labour Rate :</b></td>
                            <td id="lab_rate">{{ConvertPrice(workshop_labour_rate)}}</td>
                        </tr>

                        <tr>
                            <th><b>Bodyshop</b></th>
                            <th><b>Total Ex VAT :</b></th>
                            <td>{{ConvertPrice(GetTotals('bodyshop'))}}</td>

                            <td><b>Total Labour :</b></td>
                            <td>{{ConvertPrice(GetLabourTotal('bodyshop'))}}</td>

                            <td><b>Total Labour Hours :</b></td>
                            <td>{{GetLabourHoursTotal('bodyshop')}}</td>

                            <td><b>Labour Rate :</b></td>
                            <td id="lab_rate">{{ConvertPrice(bodyshop_labour_rate)}}</td>
                        </tr>

                        <tr>
                            <th><b>Totals</b></th>
                            <th><b>Total Ex VAT:</b></th>
                            <td id="TotalExVat">{{ConvertPrice(DeptTotals())}}</td>
                            <td><b>Total Inc VAT:</b></td>
                            <td id="TotalExVat">{{ConvertPrice((DeptTotals() * 1.2))}}</td>

                            <th class="inset-danger-border idb-lt" v-if="!viewOnly">INTERNAL USE ONLY</th>
                            <th class="inset-danger-border idb-t" v-if="!viewOnly"></th>
                            <th class="inset-danger-border idb-t" v-if="!viewOnly">MARGIN</th>
                            <th class="inset-danger-border idb-rt" v-if="!viewOnly">{{ConvertPrice(getMargin())}} ({{getMarginPercentage()}})</th>

                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                        </tr>

                        <tr>
                            <th><b>Totals <span class="text-xs">(minus parts discount)</span></b></th>
                            <th><b>Total Ex VAT:</b></th>
                            <td id="TotalExVat">{{ConvertPrice((DeptTotals() - job.job_detail.parts_disc))}}</td>
                            <td><b>Total Inc VAT:</b></td>
                            <td id="TotalExVat">{{ConvertPrice((DeptTotals() - job.job_detail.parts_disc)* 1.2)}}</td>

                            <th class="inset-danger-border idb-lb" v-if="!viewOnly">Total Cost</th>
                            <td class="inset-danger-border idb-b" v-if="!viewOnly">{{ConvertPrice(totalPartsCosts())}}</td>
                            <th class="inset-danger-border idb-b" v-if="!viewOnly">Total Labour <span class="text-xs">(Minus Discount)</span></th>
                            <td class="inset-danger-border idb-rb" v-if="!viewOnly">{{ConvertPrice(getTotalLabourCost())}}</td>

                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                            <td v-if="viewOnly"></td>
                        </tr>

                        <tr class="price-totals">
                            <th>
                                <h4><b>Parts Total:</b></h4>
                            </th>
                            <td id="PartsTotal">
                                <h4 id="PartsTotal_text" :class="{'text-danger': PartsTotal() == '0.00'}">
                                    <b>{{ConvertPrice(PartsTotal(false))}}</b>
                                </h4>
                            </td>
                            <td></td>
                            <td><h4><b>Labour Total:</b></h4></td>
                            <td id="LabourTotal"><h4 id="lab_total_text"><b>{{ConvertPrice(LabourTotal(false))}}</b></h4></td>
                            <td style="text-align:right"><b>Labour Hrs:</b></td>
                            <td id="LabourTotal">{{LabourHours()}}</td>
                            <td><a-button v-if="!viewOnly && !quote" size="small" type="primary" @click="AddDiscount">Add Discount</a-button></td>
                            <td v-if="!quote" ><a-checkbox v-model:checked="job.old_lab_rate" style="font-size: 11px;" @change="UpdateOldLabRate">Old Labour Rate</a-checkbox></td>
                            <td v-else></td>
                        </tr>

                        <tr class="price-totals" v-if="job.job_detail.parts_disc > 0 || job.job_detail.lab_disc > 0">
                            <th>
                                <h4><b>Parts Total (minus part discount):</b></h4>
                            </th>
                            <td id="PartsTotal">
                                <h4 id="PartsTotal_text" :class="{'text-danger': PartsTotal() == '0.00'}">
                                    <b>{{ConvertPrice(PartsTotal())}}</b>
                                </h4>
                            </td>
                            <td></td>
                            <td><h4 style="white-space: break-spaces;"><b>Labour Total (minus labour discount):</b></h4></td>
                            <td><h4><b>{{ConvertPrice(LabourTotal())}}</b></h4></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td v-if="!quote"></td>
                            <td v-else></td>
                        </tr>

                        <tr class="price-totals2">
                            <th><h5><b>Total Left To Pay<span v-if="job.has_vat"> Ex VAT</span>:</b></h5></th>
                            <td id="TotalToPay"><h5><b>{{ConvertPrice(TotalExVat())}}</b></h5></td>
                            <td></td>
                            <th><h4 v-if="job.has_vat"><b>Total Left To Pay:</b></h4></th>
                            <td id="TotalToPay2"><b v-if="job.has_vat"><h4><b>{{ConvertPrice(TotalToPay())}}</b></h4></b></td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td v-if="!quote">
                                <a-checkbox v-model:checked="job.has_bodyshop_labour" style="font-size: 11px;" v-if="!viewOnly" @change="UpdateBodyshopLabour">Bodyshop Labour</a-checkbox>
                                <div v-else>
                                    <span v-if="!viewOnly">{{job.has_bodyshop_labour}}</span>
                                </div>
                            </td>
                            <td v-else></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <a-modal v-model:visible="discountModal" @ok="SubmitDiscount" ref="discountModal" :ok-button-props="{props:{'disabled': ShouldDisableDiscountModal()}}" :confirm-loading="discountModalLoading" v-if="job">
            <div class="mt-2">
                <label class="font-semibold">Discount Amount</label>
                <a-input-number v-model:value="discountModalAmount" ref="discAmount" class="w-full" :default-value="0" :formatter="value => `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\£\s?|(,*)/g, '')"/>
            </div>

            <div class="mt-2">
                <label class="font-semibold">Notes</label>
                <a-input type="textarea" v-model:value="discountModalNotes" ref="discNotes" class="w-full"/>
            </div>

            <div class="grid grid-cols-2 gap-2 mt-2">
                <div>
                    <span class="font-semibold">Total:</span> {{ConvertPrice(TotalExVat() - discountModalAmount)}}
                </div>

                <div>
                    <span class="font-semibold">Total Inc Vat:</span> {{ConvertPrice((TotalExVat() - discountModalAmount) * 1.2)}}
                </div>
            </div>
        </a-modal>

        <a-modal v-model:visible="displayDiscountModal" :footer="null">
            <h4 class="font-bold">Discounts</h4>
            <table class="w-full border mt-2" v-if="job">
                <thead>
                    <tr class="bg-gray-100">
                        <th class="px-2 py-1 border-r border-gray-200">Amount</th>
                        <th class="px-2 py-1 border-r border-gray-200">Notes</th>
                        <th class="px-2 py-1 border-r border-gray-200">Added By</th>
                        <th class="px-2 py-1 border-r border-gray-200">Added On</th>
                        <th class="px-2 py-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(discount, index) in job.discounts" :key="discount.id" :class="{'border-b border-gray-200': index !== job.discounts.length - 1}">
                        <td class="px-2 py-1 border-r border-gray-200">£{{discount.amount}}</td>
                        <td class="px-2 py-1 border-r border-gray-200">{{discount.notes}}</td>
                        <td class="px-2 py-1 border-r border-gray-200">{{discount.added_by_user}}</td>
                        <td class="px-2 py-1 border-r border-gray-200">{{ConvertDate(discount.created_at)}}</td>
                        <td class="px-2 py-1"><a-button danger size="small" @click="DeleteDiscount(discount, $event)">Delete</a-button></td>
                    </tr>
                </tbody>
            </table>
        </a-modal>
    </div>
</template>

<script>
import {useAuthStore} from "@/store/authStore";

let urlStart = '/api/job/'
export default {
    props: {
        job: {
            type: Object
        },
        viewOnly: {
            type: Boolean,
            default: false
        },
        labourRates: {
            type: Object,
            default: () => {}
        },
        quote: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        //if(this.tuning_labour_rate == 0) {
        //    this.getLabourRates();
        //}
        this.tuning_labour_rate = this.labourRates.tuning;
        this.workshop_labour_rate = this.job.job_detail.lab_rate || this.labourRates.workshop;
        this.fab_labour_rate = this.labourRates.fabrication;
        this.bodyshop_labour_rate = this.labourRates.bodyshop;

        this.main_labour_costs.tuning_labour_rate = this.labourRates.tuning;
        this.main_labour_costs.workshop_labour_rate = this.job.job_detail.lab_rate || this.labourRates.workshop;
        this.main_labour_costs.fab_labour_rate = this.labourRates.fabrication;
        this.main_labour_costs.bodyshop_labour_rate = this.labourRates.bodyshop;
        setTimeout(() => {
            if(this.job.has_bodyshop_labour) {
                this.workshop_labour_rate = 25;
            }
        }, 500)
    },
    created() {
        if(this.quote) {
            urlStart = '/api/quote/';
        }
    },
    data: () => ({
        labourFree: false,
        labourTotal: 0,
        partsTotal: 0,
        costsTotal: 0,
        tuning_labour_rate: 0,
        workshop_labour_rate: 0,
        fab_labour_rate: 0,
        bodyshop_labour_rate: 0,
        discountModal: false,
        discountModalLoading: false,
        discountModalAmount: 0,
        discountModalNotes: "",
        displayDiscountModal: false,
        main_labour_costs: {
            tuning_labour_rate: 0,
            workshop_labour_rate: 0,
            fab_labour_rate: 0,
            bodyshop_labour_rate: 0,
        }
    }),
    filters: {
        price(val) {
            let new1;
            if (isNaN(val) || val === 0) {
                val = 0;
                new1 = val.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
                if (!new1.includes("£")) {
                    new1 = "£" + new1;
                }
                return new1;
            } else {
                //val = val.toString().replace('.00','')
                new1 = parseFloat(val).toLocaleString('en-GB', { style: 'currency', currency: 'GBP' });
                if (!new1.includes("£")) {
                    new1 = "£" + new1;
                }
                return new1;
            }
        }
    },
    methods: {
        UpdateHasVat(e) {
            let url = api_url + urlStart + this.job.id+'/update/vat';
            axios.post(url, {
                enabled: this.job.has_vat
            }).then((resp) => {
                if(this.job.has_vat) {
                    this.$message.success('VAT Enabled.');
                } else {
                    this.$message.success('VAT Disabled.');
                }
            }).catch(err => {
                this.$message.error('VAT Update Failed.');
            })
        },
        UpdateVatRegistered(e) {
            let url = api_url + urlStart +this.job.id+'/update/customer-vat';
            axios.post(url, {
                enabled: this.job.customer_vat_registered
            }).then((resp) => {
                if(this.job.has_vat) {
                    this.$message.success('Customer VAT Enabled.');
                } else {
                    this.$message.success('Customer VAT Disabled.');
                }
            }).catch(err => {
                this.$message.error('Customer VAT Update Failed.');
            })
        },
        UpdateBodyshopLabour(e) {
            if(e.target.checked) {
                this.workshop_labour_rate = 25;
            } else {
                this.workshop_labour_rate = this.main_labour_costs.workshop_labour_rate;
            }
            let url = api_url + urlStart +this.job.id+'/update/bodyshop-labour';
            axios.post(url, {
                enabled: this.job.has_bodyshop_labour
            }).then((resp) => {
                if(this.job.has_bodyshop_labour) {
                    this.$message.success('Bodyshop Labour Enabled.');
                } else {
                    this.$message.success('Bodyshop Labour Disabled.');
                }
            }).catch(err => {
                this.$message.error('Bodyshop Labour Update Failed.');
            })
        },
        UpdateFreeLabour(e) {
            var self = this;
            var msg = "Are you sure you want to add Free Labour?"
            if (this.job.free_labour) {
                msg = "Are you sure you want to remove Free Labour?"
            }
            this.$confirm({
                title: msg,
                onOk() {
                    self.job.free_labour = !self.job.free_labour
                    let url = api_url + urlStart +self.job.id+'/update/free-labour';
                    axios.post(url, {
                        enabled: self.job.free_labour
                    }).then((resp) => {
                        if(self.job.free_labour) {
                            self.$message.success('Free Labour Enabled.');
                        } else {
                            self.$message.success('Free Labour Disabled.');
                        }
                    })
                },
                onCancel() {
                },
            })
        },
        UpdatePartDisc(e) {
            var value = e.target.textContent.replace('£', '');
            this.job.job_detail.parts_disc = value;
            let url = api_url + urlStart + this.job.id+'/update/parts-discount'
            axios.post(url, {
                value: value
            }).then(response => {
                this.$message.success('Updated parts discount.');
                this.job.job_detail.parts_disc = value;
            }).catch(e => {
                this.$message.error('Failed to update parts discount.');
            })
        },
        UpdatePartDiscPerc(e) {
            var value = e.target.textContent.replace('%', '');
            var part_total = this.PartsTotalNoDisc();
            var newparts = (value / 100);
            var newparts1 = (newparts * part_total);
            this.job.job_detail.parts_disc = newparts1;
            let url = api_url + urlStart + this.job.id+'/update/parts-discount'
            axios.post(url, {
                value: newparts1,
            }).then(resp => {
                this.$message.success('Updated parts discount.');
            }).catch(e => {
                this.$message.error('Failed to update parts discount.');
            })
        },
        UpdateLabourDisc(e) {
            var value = e.target.textContent.replace('£', '');
            this.job.job_detail.lab_disc = value;
            let url = api_url + urlStart +this.job.id+'/update/labour-discount'
            axios.post(url, {
                value: value
            }).then(response => {
                this.$message.success('Updated labour discount.');
            }).catch(e => {
                this.$message.error('Failed to update labour discount.');
            })
        },
        UpdateLabourDiscPerc(e) {
            var self = this;
            var value = e.target.textContent.replace('%', '');
            var LabourTotal = this.LabourTotalNoDisc();
            var newlabour = (value / 100);
            var newlabour1 = (newlabour * LabourTotal);
            this.job.job_detail.lab_disc = newlabour1;
            let url = api_url + urlStart +this.job.id+'/update/labour-discount'
            axios.post(url, {
                value: newlabour1,
            }).then(response => {
                this.$message.success('Updated labour discount percent.');
            }).catch(e => {
                this.$message.error('Failed to update labour discount percent.');
            })
        },
        UpdatePaidDeposit(e) {
            const jobid = this.job.id;
            let deposit = e.target.innerHTML;
            const depositInt = (deposit.substring(0, 1));
            if (depositInt === "£") {
                deposit = deposit.substring(1);
            }
            deposit = deposit.replace(',', '');
            this.job.deposit = deposit;
            let url = api_url + urlStart + this.job.id+'/update/deposit'
            axios.post(url, {
                deposit: deposit
            }).then((resp) => {
                this.$message.success('Deposit Updated!.');
            }).catch((err) => {
                this.$message.error('Failed to update deposit.');
            });
        },
        UpdateAuthedCosts(e) {
            const value = e.target.textContent.replace('£', '');
            this.job.authorised_costs = value;
            let url = api_url + urlStart +this.job.id+'/update/extra-funds'
            axios.post(url, {
                value: value
            }).then(response => {
                this.$message.success('Updated Extra Funds.');
            }).catch(e => {
                this.$message.error('Failed to update extra funds.');
            })
        },

        PartsDiscountPercent() {
            if ((this.job.job_detail.parts_disc.toString().replace(',', '').replace('£', '') === 0 && this.PartsTotalNoDisc() === 0) || this.job.job_detail.parts_disc.toString().replace(',', '').replace('£', '') > 0 && this.PartsTotalNoDisc() === 0) {
                return 0;
            } else {
                const value = (this.job.job_detail.parts_disc.toString().replace(',', '').replace('£', '') / this.PartsTotalNoDisc()) * 100;
                if(isNaN(value)) {
                    return 0;
                }
                return value
            }
        },
        LabourDiscountPercent() {
            if ((this.job.job_detail.lab_disc.toString().replace(',', '').replace('£', '') === 0 && this.LabourTotalNoDisc() === 0) || (this.job.job_detail.lab_disc.toString().replace(',', '').replace('£', '') > 0 && this.LabourTotalNoDisc() === 0))
                return 0;
            else
                return (this.job.job_detail.lab_disc.toString().replace(',', '').replace('£', '') / this.LabourTotalNoDisc()) * 100
        },
        PartsTotalNoDisc() {
            return this.GetTotals('tuning') +
                this.GetTotals('workshop') +
                this.GetTotals('fabrication') +
                this.GetTotals('workshop_end') +
                this.GetTotals('bodyshop');
        },

        GetTotals(type) {
            let model;
            if(type === "workshop") {model = "workshop_parts";}
            if(type === "workshop_end") {model = "workshop_end_parts";}
            if(type === "tuning") {model = "tuning_parts";}
            if(type === "fabrication") {model = "fabrication_parts";}
            if(type === "bodyshop") {
                let total = 0;
                this.job["bodyshop_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });
                this.job["bodyshop_insurance_labour_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_paint_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_parts_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_additional_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });

                this.job["bodyshop_insurance_extra_parts"].forEach(part => {
                    if (this.job.project_car === false) {
                        total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                    } else {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    }
                });
                return total;
            } else {
                let total = 0;
                if(model) {
                    this.job[model].forEach(part => {
                        if (this.job.project_car === false) {
                            total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                        } else {
                            total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                        }
                    });
                }
                return total;
            }
        },
        GetCostTotals(type) {
            let model;
            if(type === "workshop") {model = "workshop_parts";}
            if(type === "workshop_end") {model = "workshop_end_parts";}
            if(type === "tuning") {model = "tuning_parts";}
            if(type === "fabrication") {model = "fabrication_parts";}
            if(type === "bodyshop") {
                let total = 0;
                this.job["bodyshop_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });
                this.job["bodyshop_insurance_labour_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });

                this.job["bodyshop_insurance_paint_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });

                this.job["bodyshop_insurance_parts_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });

                this.job["bodyshop_insurance_additional_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });

                this.job["bodyshop_insurance_extra_parts"].forEach(part => {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                });
                return total;
            } else {
                let total = 0;
                if(model) {
                    this.job[model].forEach(part => {
                        total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                    });
                }
                return total;
            }
        },
        GetLabourTotal(type) {
            let labrate;
            let model;
            if(type === "workshop") {model = "workshop_parts"; labrate = this.workshop_labour_rate}
            if(type === "workshop_end") {model = "workshop_end_parts"; labrate = this.workshop_labour_rate}
            if(type === "tuning") {model = "tuning_parts"; labrate = this.tuning_labour_rate}
            if(type === "fabrication") {model = "fabrication_parts"; labrate = this.fab_labour_rate}
            if(type === "bodyshop") {model = "bodyshop_parts"; labrate = this.bodyshop_labour_rate}

            if(type === "bodyshop") {
                let total = 0;
                this.job["bodyshop_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                this.job["bodyshop_insurance_labour_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                this.job["bodyshop_insurance_paint_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                this.job["bodyshop_insurance_parts_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                this.job["bodyshop_insurance_additional_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                this.job["bodyshop_insurance_extra_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                return total;
            } else {
                let total = 0;
                this.job[model].forEach(part => {
                    //if (this.job.project_car === false) {
                        total += (part.labour_hours * labrate)
                    //}
                });
                return total;
            }
        },
        GetLabourHoursTotal(type) {
            let model;
            if(type === "workshop") {model = "workshop_parts";}
            if(type === "workshop_end") {model = "workshop_end_parts";}
            if(type === "tuning") {model = "tuning_parts";}
            if(type === "fabrication") {model = "fabrication_parts";}
            //if(type === "bodyshop") {model = "bodyshop_parts";}
            if(type === "bodyshop") {
                let total = 0;
                this.job["bodyshop_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                this.job["bodyshop_insurance_labour_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                this.job["bodyshop_insurance_paint_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                this.job["bodyshop_insurance_parts_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                this.job["bodyshop_insurance_additional_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                this.job["bodyshop_insurance_extra_parts"].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                return total;
            } else {
                let total = 0;
                this.job[model].forEach(part => {
                    //if (this.job.project_car === 0 || this.job.project_car === false) {
                        total += parseFloat(part.labour_hours)
                    //}
                });
                return total;
            }
        },
        DeptTotals() {
            return parseFloat(this.LabourTotalNoDisc()) + parseFloat(this.PartsTotalNoDisc());
        },
        PartsTotal(minusDiscount = true) {
            let total = this.GetTotals('tuning') +
                        this.GetTotals('workshop') +
                        this.GetTotals('fabrication') +
                        this.GetTotals('workshop_end') +
                        this.GetTotals('bodyshop_insurance_labour') +
                        this.GetTotals('bodyshop_insurance_paint') +
                        this.GetTotals('bodyshop_insurance_parts') +
                        this.GetTotals('bodyshop_insurance_additional') +
                        this.GetTotals('bodyshop_insurance_extra') +
                        this.GetTotals('bodyshop');
            if(minusDiscount) {
                total = total - this.job.job_detail.parts_disc;
            }
            this.partsTotal = total;
            return total;
        },
        CostTotal() {
            const total = this.GetCostTotals('tuning') +
                        this.GetCostTotals('workshop') +
                        this.GetCostTotals('fabrication') +
                        this.GetCostTotals('workshop_end') +
                        this.GetCostTotals('bodyshop_insurance_labour') +
                        this.GetCostTotals('bodyshop_insurance_paint') +
                        this.GetCostTotals('bodyshop_insurance_parts') +
                        this.GetCostTotals('bodyshop_insurance_additional') +
                        this.GetCostTotals('bodyshop_insurance_extra') +
                        this.GetCostTotals('bodyshop')
            this.costsTotal = total;
            return total;
        },

        LabourTotal(minusDiscount = true) {
            let total = 0;
            //if (this.job.project_car === 0 || this.job.project_car === false) {
                this.job.workshop_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.tuning_parts.forEach(part => {
                    total += (part.labour_hours * this.tuning_labour_rate)
                });

                this.job.fabrication_parts.forEach(part => {
                    total += (part.labour_hours * this.fab_labour_rate)
                });

                this.job.workshop_end_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.bodyshop_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });


                this.job.bodyshop_insurance_labour_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_paint_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_parts_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_additional_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_extra_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                if(minusDiscount) {
                    total = total - this.job.job_detail.lab_disc;
                }
            //}
            /* else {
                console.log(this.job.times)
                if (this.job.times.total) {
                    if (this.job.times.total) {
                        const split = this.job.times.total.split(':');
                        const hours = split[0];
                        const minutes = split[1];
                        const seconds = split[2];
                        const project_lab_rate = 0;
                        const time = parseFloat(parseFloat(hours) + "." + parseFloat(minutes));
                        const cost = project_lab_rate * time;
                        this.labourTotal = cost
                        console.log("cost is: ", cost)
                        return cost
                    }
                }
            }*/
            this.labourTotal = total;
            return total;
        },
        LabourTotalNoDisc() {
            let total = 0;
            //if (this.job.project_car === 0 || this.job.project_car === false) {
                this.job.workshop_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.tuning_parts.forEach(part => {
                    total += (part.labour_hours * this.tuning_labour_rate)
                });

                this.job.fabrication_parts.forEach(part => {
                    //total += (part.labour_hours * 75)
                    total += (part.labour_hours * this.fab_labour_rate)
                });

                this.job.workshop_end_parts.forEach(part => {
                    total += (part.labour_hours * this.workshop_labour_rate)
                });

                this.job.bodyshop_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });


                this.job.bodyshop_insurance_labour_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_paint_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_parts_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_additional_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });

                this.job.bodyshop_insurance_extra_parts.forEach(part => {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                });
            /*} else {
                if (this.job.times.total_time) {
                    if (this.job.times.total_time.time) {
                        const split = this.job.times.total_time.time.split(':');
                        const hours = split[0];
                        const minutes = split[1];
                        const seconds = split[2];
                        const project_lab_rate = 0;
                        const time = parseFloat(parseFloat(hours) + "." + parseFloat(minutes));
                        return project_lab_rate * time;
                    }
                }
            }*/
            return total;
        },
        LabourHours() {
            var total = 0;
            this.job.workshop_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.tuning_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.fabrication_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.workshop_end_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });


            this.job.bodyshop_insurance_labour_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_paint_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_parts_parts.forEach(part => {
                total +=parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_additional_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });

            this.job.bodyshop_insurance_extra_parts.forEach(part => {
                total += parseFloat(part.labour_hours)
            });
            return total;
        },

        TotalExVat() {
            let total;
            //if (this.job.project_car === 0 || this.job.project_car === false) {
                total = (this.LabourTotal() + this.PartsTotal()) - this.job.deposit - this.GetAdditionalDiscounts();
                return total
            /*} else {
                total = (this.LabourTotal() + this.PartsTotal()) - this.GetAdditionalDiscounts();
                return total;
            }*/
        },
        TotalToPay() {
            return this.TotalExVat() * 1.2;
        },

        //USES API
        GetTotalLabourCost() {
            const total = this.labourTotal;
            axios.post(api_url + urlStart +this.job.id+'/update/price/labour', {
                id: this.job.id,
                price: total
            }).then((resp) => {

            });
        },
        GetTotalPartsCost() {
            const total = this.partsTotal;
            axios.post(api_url + urlStart +this.job.id+'/update/price/parts', {
                id: this.job.id,
                price: total
            }).then((resp) => {

            });
            return total;
        },
        SubmitDiscount(e) {
            if(this.quote) return;
            this.discountModalLoading = true;
            const amount = this.discountModalAmount;
            const notes = this.discountModalNotes;
            axios.post(api_url + urlStart +this.job.id+'/update/new-discount', {
                job_id: this.job.id,
                amount: amount,
                notes: notes,
                user_id: this.user.id,
                user: this.user.nicename,
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Discount Added!');
                    this.discountModalLoading = false;
                    this.discountModal = false;
                    this.discountModalAmount = 0;
                    this.discountModalNotes = "";
                    location.reload();
                } else {
                    this.$message.error('Discount Failed!');
                    this.discountModalLoading = false;
                }
            }).catch((err) => {
                this.$message.error('Discount Failed!');
                this.discountModalLoading = false;
            })
        },
        DeleteDiscount(discount, e) {
            if(this.quote) return;
            e.target.disabled = true
            axios.post(api_url + urlStart + this.job.id+'/update/delete-discount', {
                id: discount.id,
                user: this.user.nicename,
            }).then((resp) => {
                if(resp.data.success) {
                    this.job.discounts = resp.data.data;
                    e.target.innerText = "Deleted"
                    this.$message.success('Discount Removed!');
                } else {
                    e.target.disabled = false
                    this.$message.error('Discount Removal Failed!');
                }
            }).catch((err) => {
                e.target.disabled = false
                this.$message.error('Discount Removal Failed!');
            })
        },
        UpdateOldLabRate(e) {
            if(this.quote) return;
            let url = api_url + '/api/job/'+this.job.id+'/update/old-lab-rate'
            if(this.quote) {
                url = api_url + '/api/quote/'+this.job.id+'/update/old-lab-rate'
            }
            axios.post(url, {
                checked: e.target.checked,
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Old Labour Rate Updated!');
                    location.reload();
                } else {
                    this.$message.error('Old Labour Rate Failed!');
                }
            }).catch((err) => {
                this.$message.error('Old Labour Rate Failed!');
            })
        },

        ShouldDisableDiscountModal() {
            return this.discountModalAmount === 0 || this.discountModalAmount === '';
        },
        AddDiscount() {
            this.discountModal = true;
        },
        GetAdditionalDiscounts() {
            var count = 0;
            this.job.discounts.forEach(element => {
                count += element.amount;
            });
            return count;
        },
        DisplayDiscounts() {
            this.displayDiscountModal = true;
        },
        /*UnauthedTotal() {
            var total = 0;
            this.job.unauthed_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.cost_per_part.toString().replace('£', '').replace(',', '') * part.quantity)
                } else {
                    total += (part.purchase_price.toString().replace('£', '').replace(',', '') * part.quantity)
                }
            });
            return total;
        },
        TuningTotal() {
            var total = 0;
            this.job.tuning_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += ((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity))
                } else {
                    total += ((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity))
                }
            });
            return total;
        },
        WorkshopTotal() {
            var total = 0;
            this.job.workshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += ((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.workshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.workshop_labour_rate))
                }
            });
            return total;
        },
        BodyshopTotal() {
            var total = 0;
            this.job.bodyshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += ((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.bodyshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.bodyshop_labour_rate))
                }
            });
            return total;
        },
        WorkshopEndTotal() {
            var total = 0;
            this.job.workshop_end_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += ((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.workshop_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.workshop_labour_rate))
                }
            });
            return total;
        },
        FabricationTotal() {
            var total = 0;
            this.job.fabrication_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += ((part.cost_per_part.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.fab_labour_rate))
                } else {
                    total += ((part.purchase_price.toString().replace('£','').replace(',','') * part.quantity))// + (part.labour_hours*this.fab_labour_rate))
                }
            });
            return total;
        },
        TuningLabourTotal() {
            var total = 0;
            this.job.tuning_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.labour_hours * this.tuning_labour_rate)
                }
            });
            return total;
        },
        WorkshopLabourTotal() {
            var total = 0;
            this.job.workshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.labour_hours * this.workshop_labour_rate)
                }
            });
            return total;
        },
        WorkshopEndLabourTotal() {
            var total = 0;
            this.job.workshop_end_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.labour_hours * this.workshop_labour_rate)
                }
            });
            return total;
        },
        FabricationLabourTotal() {
            var total = 0;
            this.job.fabrication_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.labour_hours * this.fab_labour_rate)
                }
            });
            return total;
        },
        BodyshopLabourTotal() {
            var total = 0;
            this.job.bodyshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += (part.labour_hours * this.bodyshop_labour_rate)
                }
            });
            return total;
        },
        TuningLabourHoursTotal() {
            var total = 0;
            this.job.tuning_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += parseFloat(part.labour_hours)
                }
            });
            return total;
        },
        WorkshopLabourHoursTotal() {
            var total = 0;
            this.job.workshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += parseFloat(part.labour_hours)
                }
            });
            return total;
        },
        WorkshopEndLabourHoursTotal() {
            var total = 0;
            this.job.workshop_end_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += parseFloat(part.labour_hours)
                }
            });
            return total;
        },
        FabricationLabourHoursTotal() {
            var total = 0;
            this.job.fabrication_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += parseFloat(part.labour_hours)
                }
            });
            return total;
        },
        BodyshopLabourHoursTotal() {
            var total = 0;
            this.job.bodyshop_parts.forEach(part => {
                if (this.job.project_car == 0) {
                    total += parseFloat(part.labour_hours)
                }
            });
            return total;
        },*/

        getMargin() {
            if(this.job.report) {
                return this.job.report.margin;
            } else {
                return 0;
            }
            /*const totalRetail = this.GetTotals('workshop') +
                this.GetTotals('workshop_end') +
                this.GetTotals('bodyshop_insurance_labour') +
                this.GetTotals('bodyshop_insurance_paint') +
                this.GetTotals('bodyshop_insurance_parts') +
                this.GetTotals('bodyshop_insurance_additional') +
                this.GetTotals('bodyshop_insurance_extra') +
                this.GetTotals('bodyshop') +
                this.GetTotals('tuning');
            const totalPartsCost = this.totalPartsCosts();
            const totalParts = totalRetail;
            const totalLabour = this.LabourTotal();
            const totalPartsAndLabour = totalParts + totalLabour;
            const total = totalPartsAndLabour - totalPartsCost - this.getTotalLabourCost();

            console.log("Parts Total Retail: ", totalRetail)
            console.log("Parts Total Cost: ", totalPartsCost)
            try {
                return total.toFixed(2);
            } catch (e) {
                return 0;
            }*/
        },
        getMarginPercentage() {
            if(this.job.report) {
                return this.job.report.margin_percentage + "%";
            } else {
                return 0 + "%";
            }
            /*const totalRetail = this.GetTotals('workshop') +
                this.GetTotals('workshop_end') +
                this.GetTotals('bodyshop_insurance_labour') +
                this.GetTotals('bodyshop_insurance_paint') +
                this.GetTotals('bodyshop_insurance_parts') +
                this.GetTotals('bodyshop_insurance_additional') +
                this.GetTotals('bodyshop_insurance_extra') +
                this.GetTotals('bodyshop') +
                this.GetTotals('tuning');
            const costs = this.totalPartsCosts() + this.getTotalLabourCost();
            const retail = totalRetail + this.LabourTotal();
            const profit = (retail - costs) / retail * 100;
            if(profit === Infinity || profit === -Infinity) {
                return 0 + "%";
            }
            if(Number.isNaN(profit)) {
                return 0 + "%";
            }
            return profit.toFixed(2) + "%";*/

        },
        getTotalLabourCost() {
            if(this.quote) return 0;
            return this.job.report.total_labour_cost;
            /*let wsTimes = this.hmsToSecondsOnly(this.job.times.workshop)/3600;
            let tunTimes = this.hmsToSecondsOnly(this.job.times.tuning)/3600;
            let bodyshopTimes = this.hmsToSecondsOnly(this.job.times.bodyshop)/3600;
            let fabTimes = this.hmsToSecondsOnly(this.job.times.fabrication)/3600;
            wsTimes = wsTimes * (60/2);
            tunTimes = tunTimes * (80/2);
            bodyshopTimes = bodyshopTimes * (60/2);
            fabTimes = fabTimes * (80/2);
            return wsTimes + tunTimes + bodyshopTimes + fabTimes;*/
        },
        hmsToSecondsOnly(str) {
            if(str == null) {
                return 0;
            }
            var p = str.split(':'),
                s = 0, m = 1;

            while (p.length > 0) {
                s += m * parseInt(p.pop(), 10);
                m *= 60;
            }

            return s;
        },
        totalPartsCosts() {
            if(this.quote) return 0;
            return this.job.report.total_parts_purchase;
            /*let totalPartsPurchase = 0;
            for (let i = 0; i < this.job.workshop_parts.length; i++) {
                const part = this.job.workshop_parts[i];
                const formatCost = part.purchase_price.toString().replace('£','').replace(',','')
                totalPartsPurchase += (formatCost * part.quantity);
            }

            for (let i = 0; i < this.job.tuning_parts.length; i++) {
                const part = this.job.tuning_parts[i];
                const formatCost = part.purchase_price.toString().replace('£','').replace(',','')
                totalPartsPurchase += (formatCost * part.quantity);
            }

            for (let i = 0; i < this.job.fabrication_parts.length; i++) {
                const part = this.job.fabrication_parts[i];
                const formatCost = part.purchase_price.toString().replace('£','').replace(',','')
                totalPartsPurchase += (formatCost * part.quantity);
            }

            for (let i = 0; i < this.job.workshop_end_parts.length; i++) {
                const part = this.job.workshop_end_parts[i];
                const formatCost = part.purchase_price.toString().replace('£','').replace(',','')
                totalPartsPurchase += (formatCost * part.quantity);
            }

            for (let i = 0; i < this.job.bodyshop_parts.length; i++) {
                const part = this.job.bodyshop_parts[i];
                const formatCost = part.purchase_price.toString().replace('£','').replace(',','')
                totalPartsPurchase += (formatCost * part.quantity);
            }
            return totalPartsPurchase;*/
        }
    },
    computed: {
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
    },
    watch: {
        job(new_val, old_val) {
            if(old_val == null && new_val !== null) {
                //this.getLabourRates();
            }
        },
        partsTotal(new_val, old_val) {
            if(old_val !== new_val) {
                this.GetTotalPartsCost();
            }
        },
        labourTotal(new_val, old_val) {
            if(old_val !== new_val) {
                this.GetTotalLabourCost();
            }
        },
        'job.free_labour'(new_val, old_val) {
            if(new_val === true) {
                this.tuning_labour_rate = 0;
                this.workshop_labour_rate = 0;
                this.fab_labour_rate = 0;
                this.bodyshop_labour_rate = 0;
            } else {
                this.tuning_labour_rate = this.main_labour_costs.tuning_labour_rate;
                this.workshop_labour_rate = this.main_labour_costs.workshop_labour_rate;
                this.fab_labour_rate = this.main_labour_costs.fab_labour_rate;
                this.bodyshop_labour_rate = this.main_labour_costs.bodyshop_labour_rate;
            }
            this.$emit('hasFreeLabour', new_val);
        },
        labourRates(val) {
            this.tuning_labour_rate = val.tuning;
            this.workshop_labour_rate = this.job.job_detail.lab_rate || val.workshop;
            this.fab_labour_rate = val.fabrication;
            this.bodyshop_labour_rate = val.bodyshop;

            this.main_labour_costs.tuning_labour_rate = val.tuning;
            this.main_labour_costs.workshop_labour_rate = val.workshop;
            this.main_labour_costs.fab_labour_rate = val.fabrication;
            this.main_labour_costs.bodyshop_labour_rate = val.bodyshop;
        }
    }
}
</script>

<style lang="scss" scoped>
    .text-bold {
        font-weight: bold;
    }
    .table tr:first-of-type td, .table tr:first-of-type th {
        border-top: 0;
    }

    .inset-danger-border {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-color: red;
        }
    }
    .idb-l {
        &::after {
            border-style: solid;
            border-left-width: 1px;
        }
    }
    .idb-r {
        &::after {
            border-style: solid;
            border-right-width: 1px;
        }
    }
    .idb-t {
        &::after {
            border-style: solid;
            border-top-width: 1px;
        }
    }
    .idb-b {
        &::after {
            border-style: solid;
            border-bottom-width: 1px;
        }
    }
    .idb-lt {
        &::after {
            border-style: solid;
            border-left-width: 1px;
            border-top-width: 1px;
        }
    }
    .idb-rt {
        &::after {
            border-style: solid;
            border-right-width: 1px;
            border-top-width: 1px;
        }
    }
    .idb-lb {
        &::after {
            border-style: solid;
            border-left-width: 1px;
            border-bottom-width: 1px;
        }
    }
    .idb-rb {
        &::after {
            border-style: solid;
            border-right-width: 1px;
            border-bottom-width: 1px;
        }
    }
</style>
