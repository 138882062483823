<template>
    <div>
        <div class="pt-14 pb-5 container-fluid">
            <div class="flex items-center">
                <h3 class="text-2xl py-4">Swiftec Automation</h3>
                <a-button href="/swiftec/status" type="primary" class="ml-auto swiftec_status">Swiftec Status</a-button>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <a-select class="w-full" id="job-select" v-if="hasRouteParam === false" @change="GetJobById" placeholder="Select Job...">
                        <a-select-option v-for="j in all_jobs" v-bind:key="j.id" :value="j.id">
                            {{ j.id }} - {{ utfEncode(j.firstname) }} {{ utfEncode(j.lastname) }} [{{ j.VRVrm }} -
                            {{ j.VRMake }} {{ j.VRModel }}] - {{ j.status }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>

            <form @submit.prevent="SubmitForm" v-if="hasRouteParam === false">
                <div class="row pt-1">
                    <div class="col-md-12">
                        <div class="maincard">
                            <div class="p-4">
                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>Folder</label>
                                        <a-input type="text" v-model:value="folder" read-only :class="{'required': folder === '' || folder == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="d-block">File <small>(File must be in Shared drive "Workshop - Tuning Jobs")</small></label>
                                        <a-input type="file" ref="filebrowser" @change="fileChanged($event)" required/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>Name <small>(Firstname and surname)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="name" required :class="{'required': name === '' || name == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Phone</label>
                                        <a-input type="text" class="form-control" v-model:value="phone" required :class="{'required': phone === '' || phone == null}"/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>Registration</label>
                                        <a-input type="text" class="form-control" v-model:value="reg" required :class="{'required': reg === '' || reg == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>ECU Type <small>(E.G - PPD1.2 | EDC16C64)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="ecu_type" required :class="{'required': ecu_type === '' || ecu_type == null}"/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>Make <small>(E.G Audi | Skoda | VW)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="make" required :class="{'required': make === '' || make == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Model <small>(E.G A3 | Fabia | Golf)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="model" required :class="{'required': model === '' || model == null}"/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label class="block">Readout Tool</label>
                                        <a-select v-model:value="tool" required :class="{'required': tool === '' || tool == null}">
                                            <a-select-option value="bFlash">bFlash</a-select-option>
                                            <a-select-option value="bFlash/MPPS">bFlash/MPPS</a-select-option>
                                            <a-select-option value="bFlash/X17/Flex">bFlash/X17/Flex</a-select-option>
                                            <a-select-option value="Chip Programmer">Chip Programmer</a-select-option>
                                            <a-select-option value="MPPS">MPPS</a-select-option>
                                            <a-select-option value="X17">X17</a-select-option>
                                            <a-select-option value="X17/Flex">X17/Flex</a-select-option>
                                        </a-select>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Engine Capacity<small>(E.G 2698)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="engine" required :class="{'required': engine === '' || engine == null}"/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>HP</label>
                                        <a-input type="number" class="form-control" v-model:value="bhp" required :class="{'required': bhp === '' || bhp == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label>Year</label>
                                        <a-input type="text" class="form-control" v-model:value="year" required :class="{'required': year === '' || year == null}"/>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="form-group col-md-6">
                                        <label>VIN <small>(Full Vehicle VIN)</small></label>
                                        <a-input type="text" class="form-control" v-model:value="vin" required :class="{'required': vin === '' || vin == null}"/>
                                    </div>

                                    <div class="form-group col-md-6">
                                        <label class="block">Transmission</label>
                                        <a-select v-model:value="transmission" required :class="{'required': transmission === '' || transmission == null}">
                                            <a-select-option value="5 Speed Manual">5 Speed Manual</a-select-option>
                                            <a-select-option value="6 Speed DSG - DQ250 - 02E / 0D9">
                                                6 Speed DSG - DQ250 - 02E / 0D9
                                            </a-select-option>
                                            <a-select-option value="6 Speed Manual">6 Speed Manual</a-select-option>
                                            <a-select-option value="6HP Auto">6HP Auto</a-select-option>
                                            <a-select-option value="7 Speed DSG - DQ500 - 0BH / 0BT">
                                                7 Speed DSG - DQ500 - 0BH / 0BT
                                            </a-select-option>
                                            <a-select-option value="7 Speed S-Tronic - DL501 - 0B5">
                                                7 Speed S-Tronic - DL501 - 0B5
                                            </a-select-option>
                                            <a-select-option value="8HP Auto">8HP Auto</a-select-option>
                                            <a-select-option value="Auto">Auto</a-select-option>
                                            <a-select-option value="Other">Other</a-select-option>
                                            <a-select-option value="Other DSG - DQ400 / DQ200 / DQ380 / DQ381">
                                                Other DSG - DQ400 / DQ200 / DQ380 / DQ381
                                            </a-select-option>
                                            <a-select-option value="6 Speed Manual">6 Speed Manual</a-select-option>
                                        </a-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col-md-12">
                        <a-button type="primary">Submit</a-button>
                    </div>
                </div>
            </form>

            <div v-else class="pb-3">
                <h5>Check progress for job {{ job_id }}</h5>
                <div class="mt-3 alert alert-success" v-if="currentJob">
                    <ul>
                        <li><b>Job:</b> <span>{{ currentJob.folder }}</span></li>
                        <li><b>Status:</b> <span>{{ currentJob.state }}</span></li>
                        <li><b>Started:</b> <span>{{ currentJob.created_at }}</span></li>
                        <li><b>Finished:</b> <span>{{ currentJob.updated_at }}</span></li>
                        <li><b>ECU:</b> <span>{{ currentJob.ecu }}</span></li>
                        <li><b>Filename:</b> <span>{{ currentJob.filename }}</span></li>
                        <li><b>Codes:</b> <span>{{ currentJob.codes }}</span></li>
                    </ul>
                </div>
                <button class="btn btn-primary btn-block mt-2" @click="checkswiftecStatus($event)">Check</button>

                <div class="row mt-4">
                    <div class="col-md-12">
                        <h6>swiftec VM PREVIEW
                            <button class="btn btn-success btn-sm" @click="getScreenshot">Refresh</button>
                        </h6>
                        <img class="img-fluid" :src="screenshot" alt="Screenshot">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'underscore';

_.mixin({
    capitalize: function (val) {
        return val.charAt(0).toUpperCase() + val.substring(1).toLowerCase();
    }
});
export default {
    data: () => ({
        all_jobs: [],
        job_id: "",
        folder: "",
        file: "",
        name: "",
        phone: "",
        reg: "",
        ecu_type: "",
        make: "",
        model: "",
        tool: "",
        engine: "",
        bhp: "",
        year: "",
        vin: "",
        transmission: "",
        swiftecProgressMsg: "",
        hasRouteParam: false,
        currentJob: null,
        screenshot: "",
    }),
    mounted() {
        if (this.$route.params.id === undefined) {
            this.hasRouteParam = false;
        } else {
            this.hasRouteParam = true;
            axios.get(api_url + "/v2/jobs/swiftec/status").then((resp) => {
                this.currentJob = resp.data;
                this.getScreenshot();
            })
        }
        this.GetAllJobs();
        /*setTimeout(() => {
          $('#job-select').select2();
          $('#job-select').on('select2:select', function (e) {
            var jobid = e.target.value;
            self.job_id = jobid;
            self.GetJobById(jobid);
          });
        }, 500);*/
    },
    methods: {
        GetJobById(id) {
            axios.get(api_url + '/v2/jobs/' + id + '/swiftec').then((resp) => {
                const data = resp.data;
                this.name = data.customer.firstname + " " + data.customer.lastname;
                this.phone = data.customer.phone.phonenumber;
                this.reg = data.vehicle.VRVrm;
                this.ecu_type = data.vehicle.ecu_type;
                this.make = data.vehicle.VRMake;
                this.model = data.vehicle.VRModel;
                this.engine = data.vehicle.EngineCapacity;
                this.bhp = data.vehicle.hp;
                this.year = data.vehicle.YearOfManufacture;
                this.vin = data.vehicle.VRVin;
                this.transmission = data.vehicle.gearboxType;
                if (resp.data.tool !== null) {
                    this.tool = resp.data.tool.Tool;
                } else {
                    this.tool = "";
                }
                this.folder = resp.data.tuning_folder;

                if (this.hasRouteParam) {
                    this.checkswiftecProgress();
                }
            })
        },
        GetAllJobs() {
            axios.get(api_url + '/v2/jobs/all').then((resp) => {
                this.all_jobs = resp.data;
            })
        },
        FileChanged(e) {
            const files = e.target.files; // FileList object
            this.file = files[0].name;
        },
        utfEncode(s) {
            if (s !== null && s !== "" && s !== undefined) {
                s = s.replace(/Â£/g, '£');
                s = s.replace(/Â\s/g, '');
                s = s.replace(/Ã©/g, 'é');
                s = s.replace(/&quot;/g, '"');
                return s
            } else {
                return s;
            }
        },
        SubmitForm() {
            console.log("Form Should Submit")
            const make = this.make.replace(/\s/g, "-");
            const model = this.model.replace(/\s/g, "-");
            const transmission = this.transmission.replace(/\s/g, "-");

            if (
                this.file === "" ||
                this.folder === "" ||
                this.name === "" ||
                this.phone === "" ||
                this.reg === "" ||
                this.ecu_type === "" ||
                this.tool === "" ||
                this.make === "" ||
                this.model === "" ||
                this.engine === "" ||
                this.bhp === "" ||
                this.year === "" ||
                this.vin === "" ||
                this.transmission === ""
            ) {
                return;
            }
            const json = {
                "tuning_file": this.file.trim(),
                "folder": this.folder.trim(),
                "name": this.name.trim(),
                "phone": this.phone,
                "reg": this.reg.trim(),
                "ecu_type": this.ecu_type.trim(),
                "tool": this.tool.trim(),
                "make": make.trim(),
                "model": model.trim(),
                "engine_capacity": this.engine,
                "bhp": this.bhp,
                "year": this.year,
                "vin": this.vin.trim(),
                "gearbox": transmission.trim()
            };
            axios.post(api_url + '/v2/jobs/swiftec', json).then((resp) => {
                console.log(resp)
                if (resp.data.success) {
                    location.replace('/swiftec/status/' + this.job_id);
                } else {
                    alert(resp.data.reason)
                }
            })
        },
        checkswiftecProgress() {
            axios.post(api_url + "/v2/jobs/swiftec/progress", {folder: this.folder.trim()}).then(resp => {
                if (resp.data !== null) {
                    this.swiftecProgressMsg = resp.data.state;
                }
            });
        },
        checkswiftecStatus(e) {
            e.target.disabled = true;
            axios.get(api_url + "/v2/jobs/swiftec/status").then((resp) => {
                this.currentJob = resp.data;
                e.target.disabled = false;
            })
        },
        getScreenshot() {
            const Http = new XMLHttpRequest();
            const url = api_url + '/v2/jobs/swiftec/screenshot';
            Http.open("GET", url);
            Http.send();

            Http.onreadystatechange = () => {
                this.screenshot = 'data:image/png;base64,' + Http.responseText;
            }
        },
        hexToBase64(str) {
            return btoa(String.fromCharCode.apply(null, str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
        }
    }
}
</script>
<style scoped>
input.required,
select.required {
    border: 1px solid red;
}

ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

ul li span {
    margin-left: 10px;
}

.swiftec_title {
    display: inline-block;
}

.swiftec_status {
    float: right;
}
.form-group {
    opacity: 1;
}
</style>
