<template>
  <div class="bg-white border border-gray-200 rounded">
    <div class="w-full">
      <div class="table-responsive">
        <table class="mb-1 table table-borderless">
          <tbody class="info-table">
          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendar-arrow-down" class="mr-2"/>
              <span class="opacity-60">Due in:</span></td>
            <td class="relative">
              <flat-pickr v-model="job.drop_off_time" :config="pickerConfig1" class="flatPickrInput"
                          @on-close="updateDropoff" v-if="!viewOnly"></flat-pickr>
              <span v-else>{{ dropOffTime }}</span>
            </td>

            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendar-arrow-up" class="mr-2"/>
              <span class="opacity-60">Due out:</span></td>
            <td class="relative">
              <flat-pickr v-model="job.pick_up_time" :config="pickerConfig1" class="flatPickrInput"
                          @on-close.once="openUpdatePickupModal" v-if="!viewOnly"></flat-pickr>
              <span v-else>{{ pickUpTime }}</span>
            </td>

            <div class="flex flex-col">
              <a-button class="mb-2" danger @click="openAddDelayModal">Add Delay Reason</a-button>
              <a-button class="mb-2" success @click="openViewDelayModal">View Delay Reasons</a-button>
              <span class="text-center">Email Notification OFF | ON</span>
              <a-switch @click="changeEmailNotification" v-model:checked="emailNotification"
                        style="max-width: 55px; align-self: center;"/>
            </div>

            <a-modal v-model:visible="updatePickUpModal" width="80%" @ok="updatePickup"
                     :confirm-loading="UpdatePickUpLoading" id="ModalDueOut">
              <div>
                <h1 class="text-center text-2xl">Why are you changing the 'Due Out' date?</h1>
                <p class="text-center text-sm mt-2 font-semibold text-red-900 italic">Is their a common/regular reason
                  missing from this list?</p>
                <p class="text-center text-xs text-red-900 italic">Let Joel know and it will be added for you.</p>
                <div class="mt-4">
                  <a-form-item label="Reason" class="flex items-center">
                    <a-select v-model:value="modifiedPickUpreason" style="width:100%; position: relative;"
                              :options="modifiedPickUpreasons"
                              placeholder="Select Reason..."></a-select>
                    <a-input class="mt-2" v-if="modifiedPickUpreason === 'Other'"
                             v-model:value="modifiedPickUpreasonOther"
                             placeholder="Keep it Short and Sweet..."></a-input>
                  </a-form-item>
                </div>
              </div>
            </a-modal>

            <a-modal v-model:visible="addDelayModal" width="80%" @ok="addNewDelayRecord"
                     :confirm-loading="addNewDelayLoading">
              <div>
                <h1 class="text-center text-xl">Reporting reason for delay</h1>
                <a-form-item label="Job ID" class="flex items-center">
                  <a-input class="m-2" disabled :value="job.id"/>
                </a-form-item>
                <a-form-item label="Reason" class="flex items-center">
                  <a-input class="m-2" v-model:value="delay_reason"/>
                </a-form-item>
                <a-form-item label="Customer Informed" class="flex items-center">
                  <a-input class="m-2" v-model:value="customer_informed"/>
                </a-form-item>
              </div>
            </a-modal>

            <a-modal v-model:visible="viewDelayModal" :footer="false" width="80%">
              <div>
                <h1 class="text-center text-xl">Previously reported delay reasons</h1>
                <br/>
                <a-table bordered :data-source="previousDelaysData" :columns="viewDelayedReasonsColumn"
                         :rowKey="record => record.id">
                </a-table>
              </div>
            </a-modal>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendar-arrow-down" class="mr-2"/>
              <span class="opacity-60">Job Start:</span></td>
            <td class="relative">
              <flat-pickr v-model="job.start_time" :config="pickerConfig1" class="flatPickrInput"
                          @on-close="updateStart" v-if="!viewOnly"></flat-pickr>
              <span v-else>{{ startTime }}</span>
            </td>

            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendar-arrow-up" class="mr-2"/>
              <span class="opacity-60">Job end:</span></td>
            <td class="relative">
              <flat-pickr v-model="job.end_time" :config="pickerConfig1" class="flatPickrInput" @on-close="updateEnd"
                          v-if="!viewOnly"></flat-pickr>
              <span v-else>{{ endTime }}</span>
            </td>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-box-open" class="mr-2"/>
              <span class="opacity-60">Old Parts</span></td>
            <td>
              <a-select v-model:value="job.job_detail.old_parts" style="width:100%" @change="updateOldParts($event)"
                        v-if="!viewOnly">
                <a-select-option value="null">Select an option</a-select-option>
                <a-select-option value="Customer To Keep">Customer To Keep</a-select-option>
                <a-select-option value="Scrap">Scrap</a-select-option>
                <a-select-option value="Sell To SRS">Sell To SRS</a-select-option>
                <a-select-option value="Other">Other</a-select-option>
              </a-select>
            </td>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-location-crosshairs" class="mr-2"/>
              <span class="opacity-60">Parts Location:</span></td>
            <td>
                <span v-if="job.job_detail">
                    <a-input @blur="updatePartsLocation" v-model:value="job.job_detail.parts_location"
                             v-if="!viewOnly"/>
                    <span v-else>{{ job.job_detail.parts_location }}</span>
                </span>
            </td>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-shop" class="mr-2"/>
              <span class="opacity-60">Order Status:</span></td>
            <td id="parts_order">
              <a-select v-model:value="status" class="w-full" @change="updateOrderStatus($event)" v-if="!viewOnly">
                <a-select-option value="null">Select an option</a-select-option>
                <a-select-option v-for="stat in order_status_list" :key="stat.id" :value="stat">
                  <span><span :class="stat.replace(' ', '') + ' o-status'"></span>{{ stat }}</span>
                </a-select-option>
              </a-select>
              <span v-else>{{ status }}</span>
            </td>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-hashtag" class="mr-2"/>
              <span class="opacity-60">Order ID:</span></td>
            <td>
                <span v-if="job.job_detail">
                    <a-input v-model:value="job.job_detail.linnworks_id"
                             :class="{'null': job.job_detail.linnworks_id == null || job.job_detail.linnworks_id === ''}"
                             v-if="!viewOnly" disabled/>
                    <span v-else>{{ job.job_detail.linnworks_id }}</span>
                </span>
            </td>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-person" class="mr-2"/>
              <span class="opacity-60">Owner:</span></td>
            <td>
              <a-select v-model:value="job.owner" class="w-full" @change="updateOwner" v-if="!viewOnly">
                <a-select-option v-for="user in users" :key="user.id" :value="user.id">{{ user.nicename }}
                </a-select-option>
              </a-select>
              <span v-else>{{ getUser(job.owner) }}</span>
            </td>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-person-simple" class="mr-2"/>
              <span class="opacity-60">Tuner:</span></td>
            <td>
              <a-select v-model:value="job.default_tuning_owner" class="w-full" @change="updateTuner" v-if="!viewOnly">
                <a-select-option v-for="user in users" v-bind:key="user.id" :value="user.id">{{ user.nicename }}
                </a-select-option>
              </a-select>
              <span v-else>{{ getUser(job.default_tuning_owner) }}</span>
            </td>
          </tr>
          <tr>
            <td class="font-bold flex items-center">
            </td>
            <td>
            </td>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-person-simple" class="mr-2"/>
              <span class="opacity-60">Road Testing:</span></td>
            <td>
              <a-select v-model:value="job.default_road_tester" class="w-full" @change="updateRoadTest"
                        v-if="!viewOnly">
                <a-select-option v-for="department in departments" :key="department.id" :value="department.id">
                  {{ department.name }}
                </a-select-option>
              </a-select>
              <span v-else>{{ getUser(job.default_road_tester) }}</span>
            </td>
          </tr>

          <tr v-if="job.bodyshop === 'true' || job.bodyshop === true">
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-person-simple" class="mr-2"/>
              <span class="opacity-60">Bodyshop Owner:</span></td>
            <td>
              <a-select v-model:value="job.default_bodyshop_owner" class="w-full" @change="updateBodyshopOwner"
                        v-if="!viewOnly">
                <a-select-option v-for="user in users" v-bind:key="user.id" :value="user.id">{{ user.nicename }}
                </a-select-option>
              </a-select>
              <span v-else>{{ getUser(job.default_bodyshop_owner) }}</span>
            </td>
            <td class="font-bold"></td>
            <td></td>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-clock" class="mr-2"/>
              <span class="opacity-60">Time Spent:</span></td>
            <td>
              <ul class="list-simple" v-if="job.times">
                <li>
                  Workshop:
                  <span v-if="job.times.workshop">{{ job.times.workshop }}</span>
                  <span v-else>--</span>
                </li>
                <li>
                  Fabrication:
                  <span v-if="job.times.fabrication">{{ job.times.fabrication }}</span>
                  <span v-else>--</span>
                </li>
                <li>
                  Tuning:
                  <span v-if="job.times.tuning">{{ job.times.tuning }}</span>
                  <span v-else>--</span>
                </li>
                <li>
                  Bodyshop:
                  <span v-if="job.times.bodyshop">{{ job.times.bodyshop }}</span>
                  <span v-else>--</span>
                </li>
                <li>
                  Total:
                  <span v-if="!viewOnly">
                      <span v-if="job.times.total">
                          <router-link :to="'/jobsheet/'+job.id+'/times'"
                                       target="_blank">{{ job.times.total }}</router-link>
                      </span>
                      <span v-else>--</span>
                  </span>
                  <span v-else>{{ job.times.total }}</span>
                </li>
              </ul>
            </td>
            <td class="font-bold">
              <fa-icon solid icon="fa-hourglass" class="mr-2"/>
              <span class="opacity-60">Current:</span></td>
            <td>
              <div v-if="job.task">
                <div v-for="times in getCurrentTime()" v-bind:key="times.id">
                  <b>{{ getUser(times.user) }}</b> - {{ getTimeSpent(times.start_time) }}
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendars" class="mr-2"/>
              <span class="opacity-60">Calendar:</span></td>
            <td>
              <ul class="list-simple" v-if="job.cal_id !== null && job.cal_id !== ''">
                <li v-for="(cal, key) in JSON.parse(job.cal_id)" v-bind:key="key">
                  <a :href="'https://www.google.com/calendar/event?eid='+cal[0]+'&ctz=Europe/London'" target="_blank"
                     v-if="!viewOnly">{{ key }}</a>
                  <span v-else-if="viewOnly && key !== 'none'">{{ key }}</span>
                </li>
              </ul>
            </td>

            <td v-if="job.bc_id" class="font-bold flex items-center">
              <fa-icon solid icon="fa-calendars" class="mr-2"/>
              <span class="opacity-60">BigCommerce Order ID:</span></td>
            <td v-if="job.bc_id">
              {{ job.bc_id }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <hr style="margin:0" v-if="!viewOnly"/>

      <div class="px-3 py-3 block md:flex gap-2 items-center" v-if="job && !viewOnly">
        <div class="md:mb-0">
          <a-button class="w-full md:w-auto" danger @click="createOrder($event)"
                    v-if="job.job_detail && job.job_detail.linnworks_id == null && job.processed_to_bc === false"
                    :loading="creatingLinnworksOrder">Create Order in Linnworks
          </a-button>
          <a-button class="w-full md:w-auto" danger @click="processLinnworksOrder($event)"
                    v-if="job.job_detail && job.job_detail.linnworks_id !== null && job.processed_to_lw === false"
                    :loading="processingLinnworksOrder">Process in Linnworks
          </a-button>
          <a-button class="w-full md:w-auto" danger disabled
                    v-if="job.order_status === 6 || job.order_status === 'Processed' || job.processed_to_lw === true">
            Job already processed
          </a-button>
        </div>

        <div class="md:mb-0">
          <a-button class="w-full md:w-auto" type="primary"
                    v-if="job.order_status != 6 && job.order_status != 'Processed' && job.processed_to_bc === false && job.processed_to_lw === false"
                    @click="processWebsiteOrder($event)" :loading="processingToWebsite">Process on Website
          </a-button>
          <a-button class="w-full md:w-auto" type="primary" disabled v-else-if="job.processed_to_bc === true">Job
            already processed to website.
          </a-button>
        </div>

        <div class="md:mb-0">
          <a-button class="w-full md:w-auto" danger v-if="shouldForcePicking()" @click="forcePicking($event)"
                    :loading="forceNextPicking">Force Next Picking Job
          </a-button>
          <a-button class="w-full md:w-auto ml-2" danger
                    v-if="job.status === 'Final Checks' || job.status === 'Complete' || job.status === 'Customer Notified' || job.status === 'Awaiting Collection' || job.status === 'Collected / Paid'"
                    @click="setAllPicked($event)" :loading="setAllToPicked">Set All Picked
          </a-button>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="showBCModal" class="partsList" title="Parts List" width="80%" @ok="submitToBC"
             :confirmLoading="submittingToBc">
      <div class="p-2 border-b border-slate-200 flex items-center gap-3">
        <a-checkbox v-model:checked="allowMultipleSelect">Select Multiple</a-checkbox>
        <a-button class="ml-auto" size="small" v-if="selectedItemRows.length" @click="groupItems">Group Items</a-button>
      </div>
      <div v-if="pushToBcErrs.length" class="p-2 border-b border-slate-200">
        <h3>Errors:</h3>
        <ul>
          <li class="text-red-500" v-for="err in pushToBcErrs" :key="err">{{ err }}</li>
        </ul>
      </div>
      <div class="ant-table-content">
        <div class="ant-table-body">
          <table class="w-full">
            <thead class="ant-table-thead">
            <tr>
              <th key="isHidden" class="">
                <span class="ant-table-header-column">
                   <span class="ant-table-column-title">Remove</span>
                </span>
              </th>
              <th key="part_no" class="">
                <span class="ant-table-header-column">
                    <span class="ant-table-column-title">SKU</span>
                </span>
              </th>
              <th key="part_name" class="">
                  <span class="ant-table-header-column">
                    <span class="ant-table-column-title">Title</span>
                  </span>
              </th>
              <th key="cost_per_part" class="">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">Cost</span>
                  <span class="ant-table-column-sorter"></span>
                </span>
              </th>
              <th key="bc_price" class="ant-table-row-cell-last">
                <span class="ant-table-header-column">
                  <span class="ant-table-column-title">BC Cost</span>
                  <span class="ant-table-column-sorter"></span>
                </span>
              </th>
            </tr>
            </thead>
            <tbody class="ant-table-tbody">
            <tr class="ant-table-row ant-table-row-level-0" v-for="(part, index) in partslist" :key="part.id"
                :class="{'row-alert' : !part.hasBC, 'not-equal': (part.hasBC && part.cost_per_part !== part.bc_price), 'is-selected': selectedItemRows.find(el => el.id === part.id)}"
                @click="toggleSelectRow(part)">
              <td>
                <a-checkbox v-model:checked="part.hidden"/>
              </td>
              <td>{{ part.part_no }}</td>
              <td contenteditable="true" @blur="partTitleUpdated($event, part, index)">{{ part.part_name }}</td>
              <td contenteditable="true" @blur="partCostUpdated($event, part, index)">{{ part.cost_per_part }}</td>
              <td>{{ part.bc_price }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {defineComponent, getCurrentInstance} from 'vue';
import {CalendarTwoTone, ClockCircleTwoTone, StarTwoTone, TagTwoTone} from '@ant-design/icons-vue';
import CollectDateChangeReason from '../extras/CollectDateChangeReason.vue';
import {useAuthStore} from "@/store/authStore";
import FaIcon from "@/components/extras/Icon.vue";
import axios from 'axios';
import router from "@/routes";

let appContext;
export default defineComponent({
  props: {
    job: {
      type: Object
    },
    users: {
      type: Array
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    totalToPay: {
      type: Number,
    }
  },
  data() {
    return {
      order_status_list: [
        'Picked',
        'Processed',
        'Deleted',
        'Ordered',
        'Partially Ordered',
        'Partially Picked',
        'Partially Picked and Ordered',
        'Not Started'
      ],
      creatingLinnworksOrder: false,
      processingLinnworksOrder: false,
      processingToWebsite: false,
      forceNextPicking: false,
      setAllToPicked: false,
      pickerConfig1: {
        dateFormat: 'Y-m-d H:i',
        altFormat: 'd-m-Y H:i',
        altInput: true,
        enableTime: true,
        time_24hr: true,
        defaultDate: null //this.job.drop_off_time
      },
      showBCModal: false,
      submittingToBc: false,
      partslist: [],
      departments: [
        {id: 1, name: 'Workshop'},
        {id: 2, name: 'Tuning'}
      ],
      columns: [
        {
          title: 'SKU',
          dataIndex: 'part_no',
          key: 'part_no',
        },
        {
          title: 'Title',
          dataIndex: 'part_name',
          key: 'part_name',
          scopedSlots: {
            customRender: 'name'
          },
        },
        {
          title: 'Cost',
          dataIndex: 'cost_per_part',
          key: 'cost_per_part',
        },
        {
          title: 'BC Cost',
          dataIndex: 'bc_price',
          key: 'bc_price',
        },
      ],
      viewDelayedReasonsColumn: [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Reason',
          dataIndex: 'reason',
          key: 'reason',
        },
        {
          title: 'Customer Informed',
          dataIndex: 'customer_informed',
          key: 'customer_informed',
        },
        {
          title: 'Added by',
          dataIndex: 'added_by_name',
          key: 'added_by_name',
        },
      ],
      modifiedPickUpreasons: [],
      modifiedPickUpreason: '',
      modifiedPickUpreasonOther: '',
      oldPickUpTime: this.job.pick_up_time,
      pushToBcErrs: [],
      selectedItemRows: [],
      allowMultipleSelect: false,
      addDelayModal: false,
      addNewDelayLoading: false,
      UpdatePickUpLoading: false,
      updatePickUpModal: false,
      viewDelayModal: false,
      delay_reason: '',
      customer_informed: '',
      previousDelaysData: [],
      emailNotification: this.job.delayed_email_notification === 1
    }
  },
  components: {
    CollectDateChangeReason,
    CalendarTwoTone,
    ClockCircleTwoTone,
    TagTwoTone,
    StarTwoTone,
    FaIcon
  },
  mounted() {
    appContext = getCurrentInstance().appContext;
    axios.get(api_url + '/v2/job/date-change-reasons').then(response => {
      this.modifiedPickUpreasons = response.data
    });

    if (
        this.job &&
        this.job.job_detail &&
        this.job.pre_work_check &&
        this.job.job_detail.old_parts !== this.job.pre_work_check.old_parts
    ) {
      this.updateOldParts(this.job.pre_work_check.old_parts);
      this.job.job_detail.old_parts = this.job.pre_work_check.old_parts
    }
  },
  methods: {
    moment,
    openAddDelayModal() {
      this.addDelayModal = true;
    },
    openUpdatePickupModal() {
      this.updatePickUpModal = true;
    },
    openViewDelayModal() {
      this.getDelayReasons();
      this.viewDelayModal = true;
    },
    addNewDelayRecord() {
      this.addNewDelayLoading = true;
      axios.post(api_url + '/v2/jobs/delay/add', {
        jobId: this.job.id,
        delayReason: this.delay_reason,
        customerInformed: this.customer_informed
      }).then((resp) => {
        if (resp) {
          this.$message.success('Reason for delay added!');
        } else {
          this.$message.error('Reason for delay not added!');
        }
        this.addNewDelayLoading = false;
        this.addDelayModal = false;
      }).catch(() => {
        this.$message.error('Reason for delay failed!');
      });
    },
    getDelayReasons() {
      axios.get(api_url + '/v2/jobs/delay/get/' + this.job.id).then((resp) => {
        this.previousDelaysData = resp.data;
      });
    },
    createOrder(e) {
      const confirmation = confirm("Have you checked the order for all necessary items on the job and have you checked the calendar?");
      if (confirmation) {
        this.creatingLinnworksOrder = true;
        this.$message.success('All parts picked.');
        axios.post(api_url + '/v2/jobs/' + this.job.id + '/order/create').then((resp) => {
          if (resp.data.success) {
            this.$message.success('Order Created!');
            this.creatingLinnworksOrder = false;
            e.target.innerText = "Order Created"
            this.$emit('reload');
          } else {
            this.$message.error('Created order failed!');
            this.creatingLinnworksOrder = false;
          }
        }).catch(() => {
          this.$message.error('Created order failed!');
          this.creatingLinnworksOrder = false;
        });
      }
    },
    processLinnworksOrder() {
      const conf = confirm("The open order in Linnworks will now be processed, continue?");
      if (conf) {
        this.processingLinnworksOrder = true;
        axios.post(api_url + '/v2/jobs/' + this.job.id + '/order/process/linnworks').then((resp) => {
          if (resp.data.success) {
            this.$message.success('Order processed in Linnworks.');
            this.$emit('reload');
          } else {
            this.$message.error('There was a problem processing in linnworks, please try again!');
          }
          this.processingLinnworksOrder = false;
        }).catch(() => {
          this.$message.error('There was a problem processing in linnworks, please try again!');
          this.processingLinnworksOrder = false;
        });
      }
    },
    processWebsiteOrder() {
      /*this.processingToWebsite = true;
      axios.post(api_url + '/v2/job/'+this.job.id+'/order/process/bigcommerce').then((resp) => {
          if(resp.data.success) {
              this.$message.success('Order processed in Bigcommerce.');
              this.$emit('reload');
          } else {
              this.$message.error('There was a problem processing');
          }
          this.processingToWebsite = false;
      }).catch(() => {
          this.$message.error('There was a problem processing');
          this.processingToWebsite = false;
      });*/


      this.processingToWebsite = true;
      axios.get(api_url + '/api/job/' + this.job.id + '/order/parts').then((resp) => {
        this.partslist = resp.data;
        this.showBCModal = true;
        this.processingToWebsite = false;
      });
      /*const conf = confirm("The open order in Linnworks will now be deleted and an order put through bigcommerce");
      if(conf) {
          this.processingToWebsite = true;
          axios.post(api_url + '/api/job/'+this.job.id+'/order/process/bigcommerce').then((resp) => {
              if(resp.data.success) {
                  this.$message.success('Order processed in Bigcommerce.');
                  this.$emit('reload');
                  window.open(resp.data.data, '_blank').focus();
              } else {
                  this.$message.error('There was a problem generating order, please check the customer has all required data in CRM!');
              }
              this.processingToWebsite = false;
          }).catch(() => {
              this.$message.error('There was a problem generating order, please check the customer has all required data in CRM!');
              this.processingToWebsite = false;
          });
      } else {
          this.showBCModal = true;
      }*/
    },
    forcePicking() {
      if (this.job.job_detail.linnworks_id) {
        this.forceNextPicking = true;
        const orderID = this.job.job_detail.linnworks_id;
        axios.post(api_url + '/api/job/' + this.job.id + '/update/force-picking', {
          order_id: orderID
        }).then((resp) => {
          if (resp.data.success) {
            this.forceNextPicking = false;
            this.$message.success('Order made critical in dispatch');
          } else {
            this.forceNextPicking = false;
            this.$message.error('Error, please retry');
          }
        }).catch(() => {
          this.forceNextPicking = false;
          this.$message.error('Failed to force next picking!');
        });
      } else {
        alert('Linnworks order required to force picking!')
      }
    },
    setAllPicked() {
      this.setAllToPicked = true;
      axios.post(api_url + '/api/job/' + this.job.id + '/update/pick-parts').then(() => {
        this.$message.success('All parts picked.');
        this.setAllToPicked = false;
      }).catch(() => {
        this.$message.error('An error occurred!');
        this.setAllToPicked = false;
      })
    },

    updatePartsLocation() {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/part/location', {
        value: this.job.job_detail.parts_location
      }).then((response) => {
        if (response.data.success) {
          this.$message.success('Updated Location');
        } else {
          this.$message.error('Failed to Update Location',);
        }
      }).catch((error) => {
        this.$message.error('Failed to Update Owner - ' + error);
      });
    },
    updateOldParts(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/old-parts', {
        value: value
      }).then((response) => {
        if (response.data.success) {
          this.$message.success('Updated Old Parts');
        } else {
          this.$message.error('Failed to Update Old Parts');
        }
      }).catch((error) => {
        this.$message.error('Failed to Update Old Parts - ' + error);
      });
    },
    updateOrderStatus(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/order-status', {
        value: value,
      }).then((response) => {
        if (response.data.success) {
          this.$message.success('Updated Order Status');
        } else {
          this.$message.error('Failed to Update Order Status');
        }
      }).catch((error) => {
        this.$message.error('Failed to Update Order Status - ' + error);
      });
    },
    updateOwner(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/owner', {
        value: value
      }).then((resp) => {
        if (resp.data.success) {
          this.$message.success('Owner Updated.');
        } else {
          this.$message.error('Owner Failed.');
        }
      }).catch(() => {
        this.$message.error('Owner Failed.');
      });
    },
    updateTuner(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/tuner', {
        value: value
      }).then((resp) => {
        if (resp.data.success) {
          this.$message.success('Tuner Updated.');
        } else {
          this.$message.error('Tuner Failed.');
        }
      }).catch(() => {
        this.$message.error('Tuner Failed.');
      });
    },
    updateRoadTest(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/road-test', {
        value: value
      }).then(() => {
        this.$message.success('Road Test Department Updated.');
      }).catch(() => {
        this.$message.error('Road Test Department Failed.');
      });
    },
    updateBodyshopOwner(value) {
      axios.post(api_url + '/api/job/' + this.job.id + '/update/default-bodyshop-owner', {
        value: value
      }).then((resp) => {
        if (resp.data.success) {
          this.$message.success('Bodyshop Owner Updated.');
        } else {
          this.$message.error('Bodyshop Owner Failed.');
        }
      }).catch(() => {
        this.$message.error('Bodyshop Owner Failed.');
      });
    },

    updateDropoff(selectedDates, dateStr) {
      dateStr = dateStr + ":00"
      if (dateStr !== this.job.drop_off_time) {
        const newDropoff = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
        const oldDropoff = moment(this.job.drop_off_time, 'YYYY-MM-DD HH:mm:ss');
        const collectDate = moment(this.job.pick_up_time, 'YYYY-MM-DD HH:mm:ss');
        let diff = collectDate.diff(oldDropoff, 'days');
        if (diff < 0) {
          diff = 1
        }
        const newCollectionTime = newDropoff.clone().add(diff, 'days').format('YYYY-MM-DD') + ' ' + collectDate.format('HH:mm:ss');
        this.job.pick_up_time = moment(newCollectionTime).format('YYYY-MM-DD HH:mm:ss')
        this.oldPickUpTime = moment(newCollectionTime).format('YYYY-MM-DD HH:mm:ss')
        if (moment(this.job.start_time).tz("Europe/London") < newDropoff.tz("Europe/London") || newDropoff.tz("Europe/London") > moment(newCollectionTime).tz("Europe/London")) {
          const oldStart = moment(this.job.start_time);
          const oldEnd = moment(this.job.end_time);
          const diff2 = oldEnd.diff(oldStart, 'days');
          const newEndTime = newDropoff.clone().add(diff2, 'days').format('YYYY-MM-DD') + ' ' + oldEnd.format('HH:mm:ss');
          this.job.start_time = newDropoff.format('YYYY-MM-DD HH:mm:ss');
          this.job.end_time = moment(newEndTime).format('YYYY-MM-DD HH:mm:ss')

          axios.post(api_url + '/api/job/' + this.job.id + '/update/start', {
            id: this.job.id,
            username: this.user.nicename,
            start: this.job.start_time,
            end: this.job.end_time
          }).then(() => {
            this.$message.success('Updated start/end time');
          }).catch(() => {
            this.$message.error('Failed to Update start/end time');
          });
        }
        this.job.drop_off_time = newDropoff.format('YYYY-MM-DD HH:mm:ss')
        axios.post(api_url + '/api/job/' + this.job.id + '/update/drop', {
          id: this.job.id,
          username: this.user.nicename,
          start: this.job.drop_off_time,
          end: this.job.pick_up_time
        }).then(() => {
          this.$message.success('Updated dropoff/pickup time');
        }).catch(() => {
          this.$message.error('Failed to Update dropoff/pickup time');
        });
      }
    },
    updatePickup() {
      this.UpdatePickUpLoading = true;
      const date = moment(this.job.pick_up_time)
      axios.post(api_url + '/api/job/' + this.job.id + '/update/collect', {
        id: this.job.id,
        username: this.user.nicename,
        date: date.format('YYYY-MM-DD HH:mm:ss'),
        reason: this.modifiedPickUpreason !== "Other" ? this.modifiedPickUpreason : this.modifiedPickUpreasonOther
      }).then(() => {
        this.$message.success('Yeah Buddy!');
        this.$message.success('Updated pickup time');
        this.UpdatePickUpLoading = false;
        this.updatePickUpModal = false;
        router.back();
      }).catch(() => {
        this.$message.error('Failed to Update pickup time');
        this.UpdatePickUpLoading = false;
      });
    },
    updateStart(selectedDates, dateStr) {
      if (dateStr + ":00" !== this.job.start_time) {
        const oldDropoffFormatted = moment(this.job.start_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        const newDropoff = moment(dateStr, 'YYYY-MM-DD HH:mm:ss');
        const oldDropoff = moment(oldDropoffFormatted, 'YYYY-MM-DD HH:mm:ss');
        const collectDate = moment(this.job.end_time, 'YYYY-MM-DD HH:mm:ss');
        const diff = collectDate.diff(oldDropoff, 'days');
        let newPickup = null;
        const newDropoffClone = newDropoff.clone();
        if (diff === 0) {
          newPickup = newDropoffClone.format('YYYY-MM-DD') + ' ' + collectDate.format('HH:mm:ss');
        } else {
          newPickup = newDropoffClone.add(diff, 'days').format('YYYY-MM-DD') + ' ' + collectDate.format('HH:mm:ss');
        }
        this.job.end_time = newPickup
        this.job.start_time = newDropoff.format('YYYY-MM-DD HH:mm:ss');
        axios.post(api_url + '/api/job/' + this.job.id + '/update/start', {
          id: this.job.id,
          username: this.user.nicename,
          start: this.job.start_time,
          end: this.job.end_time
        }).then(() => {
          this.$message.success('Updated dropoff time');
        }).catch(() => {
          this.$message.error('Failed to Update dropoff time');
        });
      }
    },
    updateEnd(selectedDates, dateStr) {
      if (dateStr + ":00" !== this.job.end_time) {
        this.job.end_time = moment(dateStr, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        axios.post(api_url + '/api/job/' + this.job.id + '/update/end', {
          id: this.job.id,
          username: this.user.nicename,
          end: this.job.end_time
        }).then(() => {
          this.$message.success('Updated end time');
        }).catch(() => {
          this.$message.error('Failed to Update end time');
        });
      }
    },

    getCurrentTime() {
      const allCurrent = [];
      if (this.job.task && this.job.task.times) {
        this.job.task.times.forEach(time => {
          if (time.pause_time == null) {
            allCurrent.push(time)
          }
        });
      }
      return allCurrent;
    },
    shouldForcePicking() {
      let showBtn = false;
      this.job.workshop_parts.forEach(part => {
        if (part.picked === "N") {
          showBtn = true;
        }
      });

      this.job.tuning_parts.forEach(part => {
        if (part.picked === "N") {
          showBtn = true;
        }
      });

      this.job.bodyshop_parts.forEach(part => {
        if (part.picked === "N") {
          showBtn = true;
        }
      });

      this.job.fabrication_parts.forEach(part => {
        if (part.picked === "N") {
          showBtn = true;
        }
      });

      this.job.workshop_end_parts.forEach(part => {
        if (part.picked === "N") {
          showBtn = true;
        }
      });

      return showBtn;
    },
    getUser(val) {
      let name = null;
      this.users.find((e) => {
        if (e.id === val) {
          name = e.nicename;
        }
      });
      return name;
    },
    getTimeSpent(time) {
      if (time !== null) {
        const now = new Date;
        const start = new Date(time);
        const milliseconds = now - start;
        let hours = Math.floor(milliseconds / 3600000);
        let minutes = Math.floor((milliseconds - (hours * 3600000)) / 60000);
        let seconds = (milliseconds - (hours * 3600000) - (minutes * 60000)) / 1000;
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }
        return hours + "h " + minutes + "m " + seconds + "s";
      } else {
        return 0;
      }
    },
    partTitleUpdated(e, part, index) {
      if (this.partslist[index].name === null) {
        if (this.partslist[index].part_name === e.target.innerText) {
          return;
        }
        this.partslist[index].part_name = e.target.innerText;
        if (part.part_no) {
          axios.post(api_url + '/api/job/' + this.job.id + '/sku/log', {
            sku: part.part_no,
            title: this.partslist[index].part_name
          }).then(() => {
          })
        }
      } else {
        if (this.partslist[index].name === e.target.innerText) {
          return;
        }
        this.partslist[index].name = e.target.innerText;
        if (part.part_no) {
          axios.post(api_url + '/api/job/' + this.job.id + '/sku/log', {
            sku: part.part_no,
            title: this.partslist[index].name
          }).then(() => {
          })
        }
      }
    },
    partCostUpdated(e, part, index) {
      const val = parseFloat(e.target.innerText);
      if (val !== part.cost_per_part) {
        this.partslist[index].cost_per_part = e.target.innerText;
        axios.post(api_url + "/api/job/" + this.job.id + '/update/part/cost', {
          part_id: part.id,
          value: val.toString().replace('£', '').replace(',', '')
        }).then(resp => {
          if (resp.data.success) {
            this.$message.success('Updated parts cost.');
          } else {
            this.$message.error('Failed to update part cost.');
          }
        }).catch(() => {
          this.$message.error('Failed to update part cost.');
        });
      }
    },
    submitToBC() {
      const conf = confirm("The open order in Linnworks will now be deleted and an order put through bigcommerce, are you sure?");
      if (conf) {
        this.pushToBcErrs = [];
        this.submittingToBc = true;
        const parts = this.partslist.filter(el => el.hidden !== true);
        axios.post(api_url + '/v2/bc-create', {
          job_id: this.job.id,
          parts: parts
        }).then((resp) => {
          if (resp.data.success) {
            window.open("https://store-f4487.mybigcommerce.com/manage/orders/" + resp.data.data.id, '_blank').focus();
            this.showBCModal = false;
            location.reload();
          }
          this.submittingToBc = false;
        }).catch((err) => {
          this.submittingToBc = false;
          if (err.response.data) {
            const error = err.response.data;
            if (error.data) {
              this.pushToBcErrs = error.data.map(el => el.sku + " - " + el.name);
              alert("Some products have extra options and cannot be added automatically.")
            } else {
              alert("Something went wrong - See Matt");
            }
          } else {
            alert("Something went wrong - See Matt");
          }
        })
      }
    },
    toggleSelectRow(part) {
      if (!this.allowMultipleSelect) {
        return;
      }
      const index = this.selectedItemRows.findIndex(el => el.id === part.id);
      if (index > -1) {
        this.selectedItemRows.splice(index, 1)
      } else {
        this.selectedItemRows.push(part)
      }
    },
    groupItems() {
      let price = 0;
      let item = null;
      for (const row of this.selectedItemRows) {
        const index = this.partslist.findIndex(el => el.id === row.id);
        if (index > -1) {
          if (item === null) {
            item = this.partslist[index];
          }
          price += this.partslist[index].cost_per_part * this.partslist[index].quantity;
          this.partslist.splice(index, 1);
        }
      }

      item.part_no = null;
      item.part_name = "Grouped Items";
      item.cost_per_part = price;
      this.partslist.push(item);
      console.log(price);
    },
    changeEmailNotification() {
      axios.post(api_url + "/v2/job/" + this.job.id + '/update/emailNotification', {
        emailNotification: this.emailNotification ? 1 : 0
      }).then(resp => {
        if (resp.data.success) {
          this.$message.success('Updated email notification.');
        } else {
          this.$message.error('Failed to email notification.');
        }
      }).catch(() => {
        this.$message.error('Failed to update email notification.');
      });
    }
  },
  computed: {
    user() {
      if (useAuthStore().user) {
        return useAuthStore().user
      } else {
        return null
      }
    },
    status: {
      get: function () {
        let value = null;
        if (this.job.order_status === 6) {
          value = "Processed";
        }
        if (this.job.order_status === 7) {
          value = "Deleted";
        }
        if (this.job.order_status === 5) {
          value = "Picked";
        }
        if (this.job.order_status === 2) {
          value = "Ordered";
        }
        if (this.job.order_status === 1) {
          value = "Partially Ordered";
        }
        if (this.job.order_status === 4) {
          value = "Partially Picked";
        }
        if (this.job.order_status === 3) {
          value = "Partially Picked and Ordered";
        }
        if (this.job.order_status === 0) {
          value = "Not Started"
        }
        return value
      },
      set: function (newValue) {
        if (newValue === "Processed") {
          this.job.order_status = "6"
        }
        if (newValue === "Deleted") {
          this.job.order_status = "7"
        }
        if (newValue === "Picked") {
          this.job.order_status = "5"
        }
        if (newValue === "Ordered") {
          this.job.order_status = "2"
        }
        if (newValue === "Partially Ordered") {
          this.job.order_status = "1"
        }
        if (newValue === "Partially Picked") {
          this.job.order_status = "4"
        }
        if (newValue === "Partially Picked and Ordered") {
          this.job.order_status = "3"
        }
        if (newValue === "Not Started") {
          this.job.order_status = "0"
        }
      }
    },
    startTime: {
      get: function () {
        return moment(this.job.start_time).format('DD-MM-YYYY HH:mm');
      },
      set: function (newValue) {
        this.job.start_time = moment(newValue, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      }
    },
    endTime: {
      get: function () {
        return moment(this.job.end_time, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm');
      },
      set: function (newValue) {
        this.job.end_time = moment(newValue, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      }
    },
    dropOffTime: {
      get: function () {
        return moment(this.job.drop_off_time).format('DD-MM-YYYY HH:mm');
      },
      set: function (newValue) {
        this.job.drop_off_time = moment(newValue, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      }
    },
    pickUpTime: {
      get: function () {
        return moment(this.job.pick_up_time, 'YYYY-MM-DD HH:mm').format('DD-MM-YYYY HH:mm');
      },
      set: function (newValue) {
        this.job.pick_up_time = moment(newValue, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      }
    },
  },
  watch: {
    showBCModal(val) {
      if (!val) {
        this.selectedItemRows = [];
        this.allowMultipleSelect = false;
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.font-bold {
  font-weight: bold;
}
</style>
<style lang="scss">
tr.row-alert {
  td:first-of-type {
    //border-left: 4px solid red !important;
  }
}

tr.not-equal {
  //background: red;
  color: red;
  font-weight: 600;
}

.ant-table-thead {
  tr th {
    border-top: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;

    &:last-of-type {
      border-right: 1px solid #e8e8e8;
    }
  }
}

.ant-table-tbody {
  tr td {
    border-left: 1px solid #e8e8e8;

    &:last-of-type {
      border-right: 1px solid #e8e8e8;
    }
  }
}

.ant-modal.partsList .ant-modal-body {
  padding: 0;
  border-top: 1px solid #f0f0f0;
}

tr.is-selected {
  background: rgba(0, 0, 0, 0.2);
}
</style>
