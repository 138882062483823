<template>
    <div class="pt-14 bodyshop-overview">
        <div class="card">
            <div class="card-body">
                <div class="p-3 bg-white border-b border-gray-200 flex items-center">
                    <span class="font-bold">Bodyshop Tasks</span>
                    <a class="ml-auto px-2 py-1 rounded text-white shadow text-xs bg-green-500 hover:bg-green-600" target="_blank" href="https://bodyshop.darkside-developments.com/awaiting-payment">View 'Awaiting Payment' Jobs</a>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name/Vehicle</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Total Time Spent</th>
                                <th></th>
                                <th>Task</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="job in jobData" :key="job.id" :class="{'borderBlink': job.isPlaying}">
                                <td :class="{'insurance': job.is_insurance === 1}" @click="goToJobcard(job.id)">{{job.id}}</td>
                                <td>
                                    <h6 class="reg" v-if="job.reg">
                                        <span class="reg-logo">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="2484" viewBox="2.013 2 233.492 231.957"><path fill="#FC0" d="M118.76 2l-3.77 11.598h-12.22l9.88 7.17-3.77 11.636 9.879-7.171 9.878 7.17-3.768-11.634 9.84-7.171h-12.182L118.76 2zM68.38 15.5l-3.768 11.635h-12.22l9.878 7.17-3.768 11.599 9.84-7.171 9.88 7.17-3.77-11.597 9.879-7.171H72.15L68.38 15.5zm100.759 0l-3.77 11.635h-12.22l9.88 7.17-3.77 11.599 9.879-7.171 9.879 7.17-3.77-11.597 9.879-7.171h-12.22L169.139 15.5zM31.465 52.416l-3.768 11.598H15.513l9.842 7.17-3.77 11.599 9.879-7.171 9.879 7.17-3.77-11.597 9.879-7.171h-12.22l-3.767-11.598zm174.553 0l-3.77 11.598h-12.182l9.842 7.134-3.77 11.634 9.879-7.171 9.879 7.17-3.77-11.597 9.879-7.171h-12.22l-3.767-11.597zM17.965 102.795l-3.77 11.598H2.014l9.842 7.17-3.77 11.599 9.916-7.172 9.842 7.172-3.77-11.598 9.879-7.171h-12.22l-3.767-11.598zm201.553 0l-3.768 11.598h-12.184l9.842 7.17-3.77 11.599 9.879-7.172 9.879 7.172-3.77-11.562 9.879-7.208h-12.22l-3.767-11.597zM31.465 153.173l-3.768 11.598H15.513l9.842 7.172-3.77 11.635 9.879-7.172 9.879 7.172-3.77-11.635 9.879-7.172h-12.22l-3.767-11.598zm174.553 0l-3.77 11.598h-12.182l9.842 7.172-3.77 11.635 9.879-7.172 9.879 7.172-3.77-11.635 9.879-7.172h-12.22l-3.767-11.598zM68.38 190.052l-3.77 11.635h-12.22l9.879 7.17-3.768 11.599 9.878-7.171 9.842 7.17-3.77-11.597 9.879-7.171H72.15l-3.77-11.635zm100.758 0l-3.77 11.635h-12.22l9.88 7.135-3.77 11.634 9.879-7.171 9.879 7.17-3.77-11.633 9.879-7.135h-12.22l-3.767-11.635zm-50.38 13.5l-3.768 11.635h-12.22l9.879 7.135-3.77 11.635 9.879-7.172 9.878 7.172-3.768-11.635 9.84-7.135h-12.182l-3.767-11.635z"/></svg>
                                        </span>
                                        <span class="reg-text">{{job.reg}}</span>
                                    </h6>
                                    <h6 class="d-block" :class="{'mt-2': job.reg}"><b>{{job.firstname}} {{job.lastname}}</b></h6>
                                    <h6 class="d-block">{{job.make}} {{job.model}}</h6>
                                </td>
                                <td>{{job.status}}</td>
                                <td>{{ConvertDate(job.start_time)}}</td>
                                <td>{{ConvertDate(job.end_time)}}</td>
                                <td>
                                    <div>Total: {{timeSpent(job)}}</div>
                                    <div v-if="job.isPlaying">Current: <timer-count :ref="'timer_'+job.id"></timer-count></div>
                                </td>
                                <td>
                                    <button @click="goToJobcard(job.id)"><i class="fa fa-external-link-alt fa-1x"></i></button>
                                </td>
                                <td>
                                    <div v-if="job.has_task">
                                        <button class="playBtn" @click="playTask(job, $event)" v-if="!job.isPlaying"><i class="fa fa-play fa-3x"></i></button>
                                        <button class="pauseBtn" @click="pauseTask(job, $event)" v-else><i class="fa fa-pause fa-3x"></i></button>
                                    </div>
                                    <div v-else>
                                        <button type="button" class="ld-over newtask" title="New Task" @click="createJobTask(job, $event)">
                                            <i class="fa fa-plus fa-3x"></i>
                                            <div class="ld ld-spinner ld-spin fa-2x"></div>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <iframe ref="taskframe" id="taskframe" :src="iframeSrc + '/' + useAuthStore().user.id + '?frompaint=true'"></iframe>
            <!--<div class="flex align-items-center">
                <button class="mr-3" @click="newtask('Customer',$event)"><i class="pointer-events-none fa fa-user fa-1x mr-1"></i>With Customer</button>
                <button class="mr-3" @click="newtask('Cleaning',$event)"><i class="pointer-events-none fa fa-broom fa-1x mr-1"></i>Cleaning</button>
                <button class="mr-3" @click="newtask('Booth',$event)"><i class="pointer-events-none fa fa-spray-can fa-1x mr-1"></i>Booth</button>
                <input class="form-control mr-2" style="width: 50%; display:inline-flex" placeholder="Enter task...">
                <button class="mr-3">Create</button>
            </div>-->
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import {useAuthStore} from "@/store/authStore";

export default {
    data: () => ({
        statusList: ['Awaiting Vehicle', 'Bodyshop', 'Awaiting Technician', 'Awaiting Road Test', 'Vehicle Present Not Started', 'Road Test', 'Started - Workshop', 'Started - Bodyshop', 'Started - Tuning', 'Started - Fabrication', 'Awaiting Invoice', 'Final Checks', 'Customer Notified', 'Awaiting Collection', 'Final Road Test Complete', 'Complete', 'Awaiting Parts', 'Awaiting Authority', 'Awaiting Authority - Insurance', 'Approved'],
        jobData: []
    }),
    mounted()  {
        axios.post(api_url + '/v2/jobs/overview/bodyshop', {
            status: this.statusList
        }).then(resp => {
            this.jobData = resp.data.data;
            this.jobData.forEach((j, index) => {
                const oldDropoff = moment(j.drop_off_time).tz("Europe/London");
                const oldPickup = moment(j.pick_up_time).tz("Europe/London");
                this.jobData[index].daysDiff = oldPickup.diff(oldDropoff, 'days');

                j.isPlaying = false;
                if(j.task && j.task.times) {
                    const times = j.task.times.filter(el => el.pause_time == null);
                    times.forEach(element => {
                        if(element.user === useAuthStore().user.id) {
                            j.isPlaying = true;
                        }
                    });
                }
                if(j.isPlaying) {
                    const timerRef = 'timer_' + j.id;
                    setTimeout(() => {
                        this.$refs[timerRef][0].run(j.task.times[0].start_time)
                    }, 500);
                }
            });
            //this.$refs.taskframe.onload = this.iframeLoaded();
        });


        window.addEventListener("message", (event) => {
            if (event.origin !== this.iframeSrc)
                return;

            if(event.data[0] === "setHeight" && this.$refs.taskframe !== undefined) {
                this.$refs.taskframe.height = event.data[1] + "px"
            }
        }, false);
    },
    computed: {
        iframeSrc() {
            return "https://tasks2.darkside-developments.com";
        }
    },
    methods: {
        goToJobcard(id) {
            // let routeData = this.$router.resolve({ name: 'Jobsheet', params: { id: id } });
            // window.open(routeData.href, '_blank');
            window.open("https://bodyshop.darkside-developments.com/job/" + id, '_blank');
        },
        playTask(job, e) {
            e.target.setAttribute("disabled", true);
            axios.post(api_url + '/v2/jobs/'+job.id+'/task/start', {
                task_id: job.task.id,
                user: useAuthStore().user.id,
                owner: job.task.owner
            }).then((resp) => {
                if (resp.data.success) {
                    const j = this.jobData.findIndex(el => el.id === job.id);
                    this.jobData[j].isPlaying = true;
                    e.target.removeAttribute("disabled");
                    this.$forceUpdate();
                    setTimeout(() => {
                        const timerRef = 'timer_' + job.id;
                        this.$refs[timerRef].run(moment())
                    }, 500);
                } else {

                }
            }).catch(() => {

            });
        },
        pauseTask(job, e) {
            e.target.setAttribute("disabled", true);
            axios.post(api_url + '/v2/jobs/'+job.id+'/task/stop', {
                task_id: job.task.id,
                user: useAuthStore().user.id,
                owner: job.task.owner
            }).then((resp) => {
                if (resp.data.success) {
                    const j = this.jobData.findIndex(el => el.id === job.id);
                    this.jobData[j].isPlaying = false;
                    e.target.removeAttribute("disabled");
                    this.$forceUpdate();
                } else {

                }
            }).catch((err) => {
                console.log(err)
            });
        },
        timeSpent(job) {
            if(job.task) {
                let total = 0;
                job.task.times.forEach(element => {
                    const start = new Date(element.start_time);
                    let end;
                    if(element.pause_time == null) {
                        end = new Date();
                    } else {
                        end = new Date(element.pause_time);
                    }
                    total += end - start;
                });
                return this.msToTime(total);
            } else {
                return "00:00:00";
            }
        },
        msToTime(ms) {
            function pad(n, amount = 0) {
                if(n < 10) {
                    return 0+n.toFixed(amount);
                } else {
                    return n.toFixed(amount);
                }
            }

            let seconds = ms / 1000;
            // 2- Extract hours:
            const hours = seconds / 3600; // 3,600 seconds in 1 hour
            seconds = seconds % 3600; // seconds remaining after extracting hours
            const minutes = seconds / 60; // 60 seconds in 1 minute
            seconds = seconds % 60;
            return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds, 0);
        },
        getTimeSpent(job) {
            let time = null;
            if(job.task && job.task.times) {
                const times = job.task.times.filter(el => el.pause_time == null);
                times.forEach(element => {
                    if(element.user === useAuthStore().user.id) {
                        time = element;
                    }
                });
            }
            const start = time.start_time;
            const end = time.pause_time;
            const jobindex = this.jobData.findIndex(el => el.id === job.id);
            const timer = setInterval(() => {
                if (job.isPlaying === true) {
                    let endT;
                    let startT;
                    if (end == null) {
                        endT = moment().tz("Europe/London");
                    } else {
                        endT = moment(end.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }
                    if (start == null) {
                        startT = moment().tz("Europe/London");
                    } else {
                        startT = moment(start.replace(/\s/, 'T'), 'YYYY-MM-DD H:mm:ss');
                    }

                    let a;
                    let b;
                    if (end == null) {
                        a = endT;
                        b = startT;
                        this.jobData[jobindex].timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                        this.$forceUpdate();
                    } else {
                        a = endT;
                        b = startT;
                        this.jobData[jobindex].timeSpent = convertTimeHHMMSS(a.diff(b) / 1000);
                        this.$forceUpdate();
                    }
                } else {
                    clearInterval(timer);
                }
            }, 500);
        },
        createJobTask(job,e) {
            this.toggleLoader(e, 'start');
            let title = "";
            if (job.firstname !== "Darkside" && job.lastname !== "Developments") {
                title = job.firstname +
                    " " +
                    job.lastname + " - " +
                    job.email + " - " +
                    job.phonenumber + " - " +
                    job.make + " " + job.model +
                    " [" + job.reg + "]";
            } else {
                title = job.firstname +
                    " " +
                    job.lastname +
                    " - " +
                    job.phonenumber +
                    " - " + job.make + " " + job.model +
                    " [" + job.reg + "]";
            }
            axios.post(api_url + '/v2/jobs/'+job.id+'/task/create', {
                title: title,
                id: job.id,
                username: useAuthStore().user.nicename
            }).then((resp) => {
                if (resp.data.success) {
                    this.toggleLoader(e, 'stop');
                    location.reload();
                } else {
                    this.toggleLoader(e, 'stop');
                    this.$message.error(resp.status);
                }
            }).catch((err) => {
                this.toggleLoader(e, 'stop');
                this.$message.error(err);
            });
        },
        toggleLoader: function (event, status) {
            const button = event.target.closest('button');
            if (status === "stop") {
                button.classList.remove('running')
                button.disabled = false
            } else if (status === "start") {
                button.classList.add('running')
                button.disabled = true
            }
        },
        newtask(type, e) {
            if(type === "Customer") {
                if(e.target.firstChild.classList.contains("fa-user")) {
                    e.target.firstChild.classList.replace("fa-user", "fa-pause");
                } else {
                    e.target.firstChild.classList.replace("fa-pause", "fa-user");
                }
            } else if(type === "Cleaning") {
                if(e.target.firstChild.classList.contains("fa-broom")) {
                    e.target.firstChild.classList.replace("fa-broom", "fa-pause");
                } else {
                    e.target.firstChild.classList.replace("fa-pause", "fa-broom");
                }
            } else if(type === "Booth") {
                if(e.target.firstChild.classList.contains("fa-spray-can")) {
                    e.target.firstChild.classList.replace("fa-spray-can", "fa-pause");
                } else {
                    e.target.firstChild.classList.replace("fa-pause", "fa-spray-can");
                }
            }
        }
    },
    watch: {
        jobData: {
            handler() {
                this.jobData.forEach(job => {
                    if(job.isPlaying) {
                        //this.getTimeSpent(job);
                    }
                });
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
iframe {
    border: none;
    width:100%
}
button {
    background:white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    &:disabled {
        opacity: 0.7;
    }
    &:focus {
        outline: none;
    }
}
button.newtask {
    background: indianred;
    color: #fff;
}
i.fa {
    pointer-events:none;
}
.reg {
    display: inline-flex;
    width: auto;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    background: #FFE734;
    margin-bottom: 0;
    .reg-logo {
        background: #004899;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left:5px;
        padding-right:5px;
        svg {
            width: 15px;
            height:15px;
        }
    }

    .reg-text {
        font-family: 'Charles Wright',serif;
        font-style: normal;
        font-size: 1.2rem;
        padding:5px 10px 5px 10px;
    }
}
</style>

<style lang="scss">
.bodyshop-overview {
    button.newtask .svg-inline--fa path {
        fill: white;
        stroke: transparent;
        stroke-width: 0px;
    }
    button.playBtn, button.pauseBtn, button.newtask {
        width: 100%;
    }

    button.playBtn svg {
        position: relative;
        left: -4px;
    }
    button.pauseBtn svg {
        position: relative;
        left: 0;
    }

    button.playBtn .svg-inline--fa path,
    button.pauseBtn .svg-inline--fa path {
        fill: #333;
        stroke: transparent;
        stroke-width: 0;
    }
}

td.insurance {
    border-left: 20px solid green;
    position: relative;
    &::after {
        content: "Insurance";
        position: absolute;
        left: -29px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        color: white;
        transform: rotate(
                -90deg
        );
        font-size: 11px;
    }
}
</style>
