<template>
    <div class="booking">
        <div class="container mt-5 pb-3">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h2 class="text-3xl">Booking <small>Stage 4/4</small></h2>
                    <h6 class="text-base">{{today}}</h6>
                    <a-button class="flex items-center justify-center mx-auto mt-2" @click="saveJson">Save To File</a-button>
                </div>
            </div>
        </div>

        <!--CONFIRM-->
        <div class="container pb-5">
            <div class="row justify-content-center mb-2">
                <div class="col-md-10">
                    <div class="maincard p-4 border-0">
                        <h4 class="text-lg mb-2">Main</h4>
                        <table class="table table-borderless table-striped">
                            <tbody>
                                <tr>
                                    <td>Name:</td>
                                    <td class="font-weight-bold">{{name}}</td>
                                </tr>
                                <tr>
                                    <td>Phone Num:</td>
                                    <td class="font-weight-bold">{{phonenum}}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td class="font-weight-bold">{{email}}</td>
                                </tr>
                                <tr>
                                    <td>Contact Pref:</td>
                                    <td class="font-weight-bold">{{cpref}}</td>
                                </tr>
                                <tr>
                                    <td>Deposit Amount:</td>
                                    <td class="font-weight-bold">{{dep_amount}}</td>
                                </tr>
                                <tr>
                                    <td>Deposit Reference:</td>
                                    <td class="font-weight-bold">{{dep_ref}}</td>
                                </tr>
                                <tr>
                                    <td>Notes:</td>
                                    <td class="font-weight-bold" v-html="notes"></td>
                                </tr>
                                <tr>
                                    <td>Customer Activity:</td>
                                    <td class="font-weight-bold">{{customer}}</td>
                                </tr>
                                <tr>
                                    <td>Has Old Labour Rate:</td>
                                    <td class="font-weight-bold">
                                        <h3 class="text-3xl" v-if="old_lab"><i class="fas fa-check"></i></h3>
                                        <h3 class="text-3xl" v-if="!old_lab"><i class="fas fa-times"></i></h3>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-2">
                <div class="col-md-10">
                    <div class="maincard p-4 border-0">
                        <h4 class="text-lg mb-2">Vehicle</h4>
                        <table class="table table-borderless table-striped">
                            <tbody>
                                <tr>
                                    <td>Vehicle:</td>
                                    <td class="font-weight-bold">{{vehicle}}</td>
                                </tr>
                                <tr>
                                    <td>Reg:</td>
                                    <td class="font-weight-bold">{{reg}}</td>
                                </tr>
                                <tr>
                                    <td>VIN:</td>
                                    <td class="font-weight-bold">{{vin}}</td>
                                </tr>
                                <tr>
                                    <td>Engine Code:</td>
                                    <td class="font-weight-bold">{{engine_code}}</td>
                                </tr>
                                <tr>
                                    <td>Gearbox:</td>
                                    <td class="font-weight-bold">{{gearbox}}</td>
                                </tr>
                                <tr>
                                    <td>Gearbox Type:</td>
                                    <td class="font-weight-bold">{{gearbox_type}}</td>
                                </tr>
                                <tr>
                                    <td>Drivetrain:</td>
                                    <td class="font-weight-bold">{{drivetrain}}</td>
                                </tr>

                                <!--ONLY IF TUNING-->
                                <tr v-if="tun == true || vvt == true">
                                    <td>ECU Num:</td>
                                    <td class="font-weight-bold">{{ecu_num}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Tuned By:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="tuned_by !== 'Other'">{{tuned_by}}</span>
                                        <span v-else>{{tuned_by_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Turbocharger:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="turbocharger !== 'Other'">{{turbocharger}}</span>
                                        <span v-else>{{turbocharger_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Injectors:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="injectors !== 'Other'">{{injectors}}</span>
                                        <span v-else>{{injectors_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Pump:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="pump !== 'Other'">{{pump}}</span>
                                        <span v-else>{{pump_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>MAP Sensor:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="map_sensor !== 'Other'">{{map_sensor}}</span>
                                        <span v-else>{{map_sensor_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Intercooler:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="intercooler !== 'Other'">{{intercooler}}</span>
                                        <span v-else>{{intercooler_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Pipework:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="pipework !== 'Other'">{{pipework}}</span>
                                        <span v-else>{{pipework_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Camshaft:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="camshaft !== 'Other'">{{camshaft}}</span>
                                        <span v-else>{{camshaft_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Cyl Head:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="cylinder_head !== 'Other'">{{cylinder_head}}</span>
                                        <span v-else>{{cylinder_head_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Clutch:</td>
                                    <td class="font-weight-bold">
                                        <span v-if="clutch_flywheel !== 'Other'">{{clutch_flywheel}}</span>
                                        <span v-else>{{clutch_flywheel_other}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>MAF Type:</td>
                                    <td class="font-weight-bold">
                                        <span>{{maf_stock}}</span>
                                    </td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>MAF:</td>
                                    <td class="font-weight-bold">{{maf}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>EGR Hardware:</td>
                                    <td class="font-weight-bold">{{egr_hardware}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>EGR Software:</td>
                                    <td class="font-weight-bold">{{egr_software}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Power Tune:</td>
                                    <td class="font-weight-bold">{{power_tune}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>ASV:</td>
                                    <td class="font-weight-bold">{{asv}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>CAT:</td>
                                    <td class="font-weight-bold">{{cat}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>DPF:</td>
                                    <td class="font-weight-bold">{{dpf}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>DPF Sensors:</td>
                                    <td class="font-weight-bold">{{dpf_sensors}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Swirl Flaps:</td>
                                    <td class="font-weight-bold">{{swirl_flaps}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Hardcut:</td>
                                    <td class="font-weight-bold">{{hardcut}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Launch:</td>
                                    <td class="font-weight-bold">{{launch}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Hot Start Fix:</td>
                                    <td class="font-weight-bold">{{hot_start_fix}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Adblue:</td>
                                    <td class="font-weight-bold">{{adblue}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Left Foot Braking:</td>
                                    <td class="font-weight-bold">{{left_foot_braking}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Smoke Level:</td>
                                    <td class="font-weight-bold">{{smoke_level}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Gearbox Tune:</td>
                                    <td class="font-weight-bold">{{gearbox_tune}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Gearbox Mods:</td>
                                    <td class="font-weight-bold">{{gearbox_mods}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Other Mods:</td>
                                    <td class="font-weight-bold">{{other_mods}}</td>
                                </tr>
                                <tr v-if="tun == true || vvt == true">
                                    <td>Track Use:</td>
                                    <td class="font-weight-bold">{{track_use}}</td>
                                </tr>
                                <!--/ONLY IF TUNING-->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-2">
                <div class="col-md-10">
                    <div class="maincard p-4 border-0">
                        <h4 class="text-lg mb-2">Timings</h4>
                        <table class="table table-borderless table-striped">
                            <tbody>
                                <tr>
                                    <td>Dropoff DateTime:</td>
                                    <td class="font-weight-bold">{{dropoff_date}} {{dropoff_time}}</td>
                                </tr>
                                <tr>
                                    <td>Start DateTime:</td>
                                    <td class="font-weight-bold">{{start_date}} {{start_time}}</td>
                                </tr>
                                <tr>
                                    <td>Finish DateTime:</td>
                                    <td class="font-weight-bold">{{finish_date}} {{finish_time}}</td>
                                </tr>
                                <tr>
                                    <td>Collect DateTime:</td>
                                    <td class="font-weight-bold">{{collect_date}} {{collect_time}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-3">
                <div class="col-md-10">
                    <div class="maincard p-4 border-0">
                        <h4 class="text-lg mb-2">Diary</h4>
                        <div class="row text-center">
                            <div class="col">
                                <h3 class="text-3xl" v-if="tun"><i class="fas fa-check"></i></h3>
                                <h3 class="text-3xl" v-if="!tun"><i class="fas fa-times"></i></h3>
                                <h5 class="text-xl">Tuning</h5>
                            </div>

                            <div class="col">
                                <h3 class="text-3xl" v-if="ws"><i class="fas fa-check"></i></h3>
                                <h3 class="text-3xl" v-if="!ws"><i class="fas fa-times"></i></h3>
                                <h5 class="text-xl">Workshop</h5>
                            </div>

                            <div class="col">
                                <h3 class="text-3xl" v-if="fab"><i class="fas fa-check"></i></h3>
                                <h3 class="text-3xl" v-if="!fab"><i class="fas fa-times"></i></h3>
                                <h5 class="text-xl">Fabrication</h5>
                            </div>

                            <!--<div class="col">
                                <h3 class="text-3xl" v-if="bodyshop"><i class="fas fa-check"></i></h3>
                                <h3 class="text-3xl" v-if="!bodyshop"><i class="fas fa-times"></i></h3>
                                <h5 class="text-xl">Bodyshop</h5>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mb-3" v-if="errors.length > 0">
                <div class="col-md-10">
                    <div class="bg-red-500 text-white rounded p-3 text-center">
                        <ul class="m-0 p-0">
                            <li class="font-semibold" v-for="err in errors">{{err}}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="flex gap-3 mt-3 justify-center">
                <a-button @click="goBack()">Go back</a-button>
                <a-button type="primary" @click="submitBooking($event)" :loading="submittingBooking">Complete Booking</a-button>
            </div>
        </div>
        <!--/CONFIRM-->
    </div>
</template>

<script>
import {useBookingStore} from "@/store/bookingStore";
import axios from 'axios';
import moment from 'moment';
import useVuelidate from '@vuelidate/core'
export default {
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            submittingBooking: false,
            today: moment().tz("Europe/London").format('MMMM Do YYYY, h:mm:ss a'),
            now: moment().tz("Europe/London").format('YYYY-MM-DD'),
            errors: []
        }
    },
    mounted() {
        /*if(this.dropoff_time) {
            this.dropoff_time = moment(this.dropoff_time).tz("Europe/London")
        }
        if(this.start_time) {
            this.start_time = moment(this.start_time).tz("Europe/London")
        }
        if(this.finish_time) {
            this.finish_time = moment(this.finish_time).tz("Europe/London")
        }
        if(this.collect_time) {
            this.collect_time = moment(this.collect_time).tz("Europe/London")
        }*/
    },
    computed: {
        booked_in_reg: {
            get() {
                return useBookingStore().booked_in_reg
            },
            set(value) {
                useBookingStore().setBookedInReg(value)
            }
        },
        vehicle_id: {
            get() {
                return useBookingStore().vehicle_id
            },
            set(value) {
                useBookingStore().setVehicleId(value)
            }
        },
        customer_id: {
            get() {
                return useBookingStore().customer_id
            },
            set(value) {
                useBookingStore().setCustomerId(value)
            }
        },
        dropoff_date: {
            get() {
                return useBookingStore().dropoff_date
            },
            set(value) {
                useBookingStore().setDropoffDate(value)
            }
        },
        dropoff_time: {
            get() {
                return useBookingStore().dropoff_time
            },
            set(value) {
                useBookingStore().setDropoffTime(value)
            }
        },
        start_date: {
            get() {
                return useBookingStore().start_date
            },
            set(value) {
                useBookingStore().setStartDate(value)
            }
        },
        start_time: {
            get() {
                return useBookingStore().start_time
            },
            set(value) {
                useBookingStore().setStartTime(value)
            }
        },
        finish_date: {
            get() {
                return useBookingStore().finish_date
            },
            set(value) {
                useBookingStore().setFinishDate(value)
            }
        },
        finish_time: {
            get() {
                return useBookingStore().finish_time
            },
            set(value) {
                useBookingStore().setFinishTime(value)
            }
        },
        collect_date: {
            get() {
                return useBookingStore().collect_date
            },
            set(value) {
                useBookingStore().setCollectDate(value)
            }
        },
        collect_time: {
            get() {
                return useBookingStore().collect_time
            },
            set(value) {
                useBookingStore().setCollectTime(value)
            }
        },
        tvvt: {
            get() {
                return useBookingStore().tvvt
            },
            set(value) {
                useBookingStore().setTvvt(value)
            }
        },
        customer: {
            get() {
                return useBookingStore().customer
            },
            set(value) {
                useBookingStore().setCustomer(value)
            }
        },
        notes: {
            get() {
                return useBookingStore().notes
            },
            set(value) {
                useBookingStore().setNotes(value)
            }
        },
        tuned_by: {
            get() {
                return useBookingStore().tuned_by
            },
            set(value) {
                useBookingStore().setTunedBy(value)
            }
        },
        booked_in_by: {
            get() {
                return useBookingStore().booked_in_by
            },
            set(value) {
                useBookingStore().setBookedInBy(value)
            }
        },
        email: {
            get() {
                return useBookingStore().email
            },
            set(value) {
                useBookingStore().setEmail(value)
            }
        },
        name: {
            get() {
                return useBookingStore().name
            },
            set(value) {
                useBookingStore().setName(value)
            }
        },
        phonenum: {
            get() {
                return useBookingStore().phonenum
            },
            set(value) {
                useBookingStore().setPhone(value)
            }
        },
        cpref: {
            get() {
                return useBookingStore().cpref
            },
            set(value) {
                useBookingStore().setContactPref(value)
            }
        },
        dep_amount: {
            get() {
                return useBookingStore().dep_amount
            },
            set(value) {
                useBookingStore().setDepAmount(value)
            }
        },
        dep_ref: {
            get() {
                return useBookingStore().dep_ref
            },
            set(value) {
                useBookingStore().setDepRef(value)
            }
        },
        vehicle: {
            get() {
                return useBookingStore().vehicle
            },
            set(value) {
                useBookingStore().setVehicle(value)
            }
        },
        reg: {
            get() {
                return useBookingStore().reg
            },
            set(value) {
                useBookingStore().setReg(value)
            }
        },
        vin: {
            get() {
                return useBookingStore().vin
            },
            set(value) {
                useBookingStore().setVin(value)
            }
        },
        ecu_num: {
            get() {
                return useBookingStore().ecu_num
            },
            set(value) {
                useBookingStore().setECUNum(value)
            }
        },
        engine_code: {
            get() {
                return useBookingStore().engine_code
            },
            set(value) {
                useBookingStore().setEngineCode(value)
            }
        },
        engine_code_other: {
            get() {
                return useBookingStore().engine_code_other
            },
            set(value) {
                useBookingStore().setEngineCodeOther(value)
            }
        },
        gearbox: {
            get() {
                return useBookingStore().gearbox
            },
            set(value) {
                useBookingStore().setGearbox(value)
            }
        },
        gearbox_other: {
            get() {
                return useBookingStore().gearbox_other
            },
            set(value) {
                useBookingStore().setGearboxOther(value)
            }
        },
        gearbox_type: {
            get() {
                return useBookingStore().gearbox_type
            },
            set(value) {
                useBookingStore().setGearboxType(value)
            }
        },
        gearbox_type_other: {
            get() {
                return useBookingStore().gearbox_type_other
            },
            set(value) {
                useBookingStore().setGearboxTypeOther(value)
            }
        },
        drivetrain: {
            get() {
                return useBookingStore().drivetrain
            },
            set(value) {
                useBookingStore().setDrivetrain(value)
            }
        },
        tun: {
            get() {
                return useBookingStore().tun
            },
            set(value) {
                useBookingStore().setTuning(value)
            }
        },
        ws: {
            get() {
                return useBookingStore().ws
            },
            set(value) {
                useBookingStore().setWorkshop(value)
            }
        },
        fab: {
            get() {
                return useBookingStore().fab
            },
            set(value) {
                useBookingStore().setFabrication(value)
            }
        },
        vvt: {
            get() {
                return useBookingStore().tvvt
            },
            set(value) {
                useBookingStore().setTvvt(value)
            }
        },
        bodyshop: {
            get() {
                return useBookingStore().bodyshop
            },
            set(value) {
                useBookingStore().setBodyshop(value)
            }
        },
        parts: {
            get() {
                return useBookingStore().parts
            },
            set(value) {
                useBookingStore().setParts(value)
            }
        },
        current_power: {
            get() {
                return useBookingStore().current_power
            },
            set(value) {
                useBookingStore().setCurrentPower(value)
            }
        },
        current_torque: {
            get() {
                return useBookingStore().current_torque
            },
            set(value) {
                useBookingStore().setCurrentTorque(value)
            }
        },
        turbocharger: {
            get() {
                return useBookingStore().turbocharger
            },
            set(value) {
                useBookingStore().setTurbocharger(value)
            }
        },
        injectors: {
            get() {
                return useBookingStore().injectors
            },
            set(value) {
                useBookingStore().setInjectors(value)
            }
        },
        pump: {
            get() {
                return useBookingStore().pump
            },
            set(value) {
                useBookingStore().setPump(value)
            }
        },
        map_sensor: {
            get() {
                return useBookingStore().map_sensor
            },
            set(value) {
                useBookingStore().setMapSensor(value)
            }
        },
        maf_stock: {
            get() {
                return useBookingStore().maf_stock
            },
            set(value) {
                useBookingStore().setMafStock(value)
            }
        },
        intercooler: {
            get() {
                return useBookingStore().intercooler
            },
            set(value) {
                useBookingStore().setIntercooler(value)
            }
        },
        camshaft: {
            get() {
                return useBookingStore().camshaft
            },
            set(value) {
                useBookingStore().setCamshaft(value)
            }
        },
        cylinder_head: {
            get() {
                return useBookingStore().cylinder_head
            },
            set(value) {
                useBookingStore().setCylHead(value)
            }
        },
        clutch_flywheel: {
            get() {
                return useBookingStore().clutch_flywheel
            },
            set(value) {
                useBookingStore().setClutchFlywheel(value)
            }
        },
        smoke_level: {
            get() {
                return useBookingStore().smoke_level
            },
            set(value) {
                useBookingStore().setSmokeLevel(value)
            }
        },
        other_mods: {
            get() {
                return useBookingStore().other_mods
            },
            set(value) {
                useBookingStore().setOtherMods(value)
            }
        },
        gearbox_mods: {
            get() {
                return useBookingStore().gearbox_mods
            },
            set(value) {
                useBookingStore().setGearboxMods(value)
            }
        },
        expected_power: {
            get() {
                return useBookingStore().expected_power
            },
            set(value) {
                useBookingStore().setExpectedPower(value)
            }
        },
        expected_peak_torque: {
            get() {
                return useBookingStore().expected_peak_torque
            },
            set(value) {
                useBookingStore().setExpectedPeakTorque(value)
            }
        },
        power_tune: {
            get() {
                return useBookingStore().power_tune
            },
            set(value) {
                useBookingStore().setPowerTune(value)
            }
        },
        gearbox_tune: {
            get() {
                return useBookingStore().gearbox_tune
            },
            set(value) {
                useBookingStore().setGearboxTune(value)
            }
        },
        maf: {
            get() {
                return useBookingStore().maf
            },
            set(value) {
                useBookingStore().setMaf(value)
            }
        },
        egr_hardware: {
            get() {
                return useBookingStore().egr_hardware
            },
            set(value) {
                useBookingStore().setEGRHardware(value)
            }
        },
        egr_software: {
            get() {
                return useBookingStore().egr_software
            },
            set(value) {
                useBookingStore().setEGRSoftware(value)
            }
        },
        asv: {
            get() {
                return useBookingStore().asv
            },
            set(value) {
                useBookingStore().setASV(value)
            }
        },
        cat: {
            get() {
                return useBookingStore().cat
            },
            set(value) {
                useBookingStore().setCat(value)
            }
        },
        dpf: {
            get() {
                return useBookingStore().dpf
            },
            set(value) {
                useBookingStore().setDPF(value)
            }
        },
        dpf_sensors: {
            get() {
                return useBookingStore().dpf_sensors
            },
            set(value) {
                useBookingStore().setDPFSensors(value)
            }
        },
        swirl_flaps: {
            get() {
                return useBookingStore().swirl_flaps
            },
            set(value) {
                useBookingStore().setSwirlFlaps(value)
            }
        },
        hardcut: {
            get() {
                return useBookingStore().hardcut
            },
            set(value) {
                useBookingStore().setHardcut(value)
            }
        },
        launch: {
            get() {
                return useBookingStore().launch
            },
            set(value) {
                useBookingStore().setLaunch(value)
            }
        },
        hot_start_fix: {
            get() {
                return useBookingStore().hot_start_fix
            },
            set(value) {
                useBookingStore().setHotStartFix(value)
            }
        },
        adblue: {
            get() {
                return useBookingStore().adblue
            },
            set(value) {
                useBookingStore().setAdblue(value)
            }
        },
        left_foot_braking: {
            get() {
                return useBookingStore().left_foot_braking
            },
            set(value) {
                useBookingStore().setLeftFootBraking(value)
            }
        },
        track_use: {
            get() {
                return useBookingStore().track_use
            },
            set(value) {
                useBookingStore().setTrackUse(value)
            }
        },
        stopStart: {
            get() {
                return useBookingStore().stopStart
            },
            set(value) {
                useBookingStore().setStopStart(value)
            }
        },
        pipework: {
            get() {
                return useBookingStore().pipework
            },
            set(value) {
                useBookingStore().setPipework(value)
            }
        },
        old_lab: {
            get() {
                return useBookingStore().old_lab
            },
            set(value) {
                useBookingStore().setOldLab(value)
            }
        },
        is_warranty: {
            get() {
                return useBookingStore().is_warranty
            },
            set(value) {
                useBookingStore().setIsWarranty(value)
            }
        },
    },
    filters: {
        date(val) {
            return moment(val).tz("Europe/London").format('DD-MM-YYYY');
        },
    },
    methods: {
        goBack(step, direction){
            this.$parent.step = 3;
            this.scrollToTop();
        },
        scrollToTop() {
            const c = document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
                window.requestAnimationFrame(this.scrollToTop);
                window.scrollTo(0, c - c / 8);
            }
        },
        submitBooking(e){
            this.submittingBooking = true;
            const booking = JSON.parse(JSON.stringify(useBookingStore().$state));
            delete booking.parts;
            axios.post(api_url + '/v2/jobs/booking/create', {
                booking: booking
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Job booked in.');
                    this.submittingBooking = false;
                    this.$router.push({ name: 'Jobsheet', params: { id: resp.data.data.job_id } })
                } else {
                    this.submittingBooking = false;
                    this.$message.error('Something went wrong: '+ resp.data.data);
                }
            }).catch((err) => {
                console.log(err.response)
                this.submittingBooking = false;
                if(err.response.status === 422) {
                    this.errors = err.response.data;
                    this.$message.error('Something went wrong: ');
                } else {
                    this.$message.error('Something went wrong');
                }
            });
        },
        saveJson() {
            const json = useBookingStore().$state
            delete json.parts;
            const a = document.createElement("a");
            const file = new Blob([JSON.stringify(json, undefined, 4)], {type: 'text/plain'});
            a.href = URL.createObjectURL(file);
            a.download = this.booked_in_reg +'_booking.json';
            a.click();
        },
    }
}
</script>

<style>

</style>
