<template>
    <div>
        <job-topbar :job="job" :users="users" :job-task-in-progress="jobTaskInProgress" @task-changed="updateJobTaskChanged" @sidebar-toggled="sidebarToggled" :isCollapsed="sidebarCollapsed" v-if="gotData"></job-topbar>
        <a-layout class="h-screen">
            <a-layout class="content-layout" :class="{'collapsed': sidebarCollapsed}">
                <a-layout-content :style="{ padding: '10px 15px', marginTop: '140px' }">
                    <div v-if="gotData" class="pb-4">
                        <div>
                            <div class="mt-2" v-if="job.job_detail && job.job_detail.linnworks_open_order_id == null">
                                <div class="rounded py-2 text-white bg-red-400 text-center">
                                    <b>This job sheet does not have a linnworks open order yet</b>
                                </div>
                            </div>

                            <div class="mt-2" v-if="job.processed_to_lw === 1">
                                <div class="rounded alert alert-danger text-center">
                                    <b>THIS JOB HAS BEEN PROCESSED IN LINNWORKS!</b>
                                </div>
                            </div>
                        </div>

                        <div class="grid grid-cols-4 gap-3">
                            <div class="col-span-3">
                                <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 mt-2">
                                    <div class="h-full">
                                        <job-customer :job="job"></job-customer>
                                    </div>

                                    <div class="h-full">
                                        <job-vehicle :job="job"></job-vehicle>
                                    </div>
                                </div>

                                <div class="grid grid-cols-1 mt-3">
                                    <job-details-bodyshop :job="job" :users="users" @reload="getData"></job-details-bodyshop>
                                </div>

                                <div class="grid grid-cols-1 mt-3">
                                    <job-prices :job="job" ref="price_table" @hasFreeLabour="updateLabour" :labour-rates="labRateProps"></job-prices>
                                </div>
                            </div>

                            <div class="h-full pt-2">
                                <div class="bg-white rounded border border-gray-200 h-full relative">
                                    <job-sidebar class="absolute top-0 left-0 right-0 bottom-0 flex flex-col" :job="job" ref="sidebar" :labour-rates="labRateProps"></job-sidebar>
                                </div>
                            </div>
                        </div>

                        <div class="bg-white border border-gray-200 px-3 py-2 mt-3" style="border-radius: 0.375rem;">
                            <div class="block font-semibold mb-2 flex items-center">
                                <fa-icon solid icon="fa-notes" class="mr-2"/><span class="">Notes</span>
                                <a-button size="small" type="primary" class="ml-auto" v-if="note_revisions.length > 0" @click="openNoteRevisionModal">Note Revisions</a-button>
                            </div>
                            <notes-text-editor v-if="job" :job-id="job.id" ref="jobnote" v-model="job.note" class-name="main-notes" @keyup="isEditing" @blur="updateNote"></notes-text-editor>
                        </div>

                        <div class="bg-white border border-gray-200 px-3 py-2 mt-3" style="border-radius: 0.375rem;">
                            <label class="block font-semibold mb-2"><fa-icon solid icon="fa-notes" class="mr-2"/><span class="">Customer Notes</span></label>
                            <text-editor v-if="job" v-model="job.customer_notes" class-name="customer-notes" @keyup="isEditing" @blur="updateCustomerNotes"></text-editor>
                        </div>

                        <!--<div v-for="type in parts_types" :key="type">
                            <div class="mt-3" v-if="canShowTable(type)">
                                <job-table :job="job" :type="type" :free_labour="doesHaveFreeLabour" :labour-rates="labRateProps" @reload="getData" @reload-parts="getParts" @update-audit="updateAuditLog" :loading="loadingParts"></job-table>
                            </div>
                        </div>-->

                        <div v-if="job.is_insurance">
                            <div v-for="type in bodyshop_parts_types" :key="type">
                                <div class="mt-3">
                                    <bodyshop-table :job="job" :type="type" :free_labour="doesHaveFreeLabour" :labour-rates="labRateProps" @reload="getData" @reload-parts="getParts" @update-audit="updateAuditLog" :loading="loadingParts"></bodyshop-table>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div v-for="type in parts_types" :key="type">
                                <div class="mt-3" v-if="canShowTable(type)">
                                    <job-table :job="job" :type="type" :free_labour="doesHaveFreeLabour" :labour-rates="labRateProps" @reload="getData" @reload-parts="getParts" @update-audit="updateAuditLog" :loading="loadingParts"></job-table>
                                </div>
                            </div>
                        </div>

                        <div class="btn-float shadow" v-if="unauth_parts.length > 0">
                            <a-button :loading="unauthing" danger size="small" @click="unauthSelected($event)">
                                Unauthorise Selected
                            </a-button>
                        </div>
                    </div>
                    <div v-else class="flex justify-center">
                        <a-spin :indicator="indicator" size="large"/>
                    </div>

                    <!-- Note Revisions Modal -->
                    <a-modal v-model:visible="noteRevisionsModal" :footer="null" v-if="gotData" width="80%">
                        <div class="p-3">
                            <table class="w-full table-striped border-b border-gray-200 table-striped">
                                <thead>
                                <tr>
                                    <th class="px-1 py-2 border-t border-l border-r border-gray-200 bg-gray-50">Note</th>
                                    <th class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Date</th>
                                    <th class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Revision</th>
                                    <th class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">Difference</th>
                                    <th class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50">User</th>
                                    <th class="px-1 py-2 border-t border-r border-gray-200 bg-gray-50"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(note, index) in note_revisions" :key="note.id">
                                    <td class="px-1 py-2 border-t border-l border-r border-gray-200">
                                        <span v-html="note.notes"></span>
                                    </td>
                                    <td class="px-1 py-2 border-t border-r border-gray-200">{{note.created_at}}</td>
                                    <td class="px-1 py-2 border-t border-r border-gray-200">{{note.revision}}</td>
                                    <td class="px-1 py-2 border-t border-r border-gray-200">
                                        <span v-if="index !== 0" v-html="getDifferences(note_revisions[index-1].notes, note.notes)"></span>
                                    </td>
                                    <td class="px-1 py-2 border-t border-r border-gray-200">{{note.user}}</td>
                                    <td class="px-1 py-2 border-t border-r border-gray-200">
                                        <a-button size="small" type="primary" @click="useNote(note.id)">Use Note</a-button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </a-modal>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </div>
</template>

<script>
import { defineComponent, h } from 'vue';
import axios from 'axios';
import AuditLog from './AuditLog.vue'
import TextEditor from './extras/TextEditor.vue'
import NotesTextEditor from './extras/NotesTextEditor.vue'
import JobSidebar from './jobsheet/Sidebar.vue'
import { LoadingOutlined } from '@ant-design/icons-vue';
import {useAuthStore} from "@/store/authStore";
import diff from '../libs/HtmlDifference';
export default defineComponent({
    name: 'Jobsheet',
    components: {
        AuditLog,
        TextEditor,
        NotesTextEditor,
        JobSidebar,
        LoadingOutlined
    },
    setup() {
        const indicator = h(LoadingOutlined, {
            style: {
                fontSize: '24px',
            },
            spin: true,
        });
        return {
            indicator,
        };
    },
    data() {
        return {
            loadingParts: false,
            gotData: false,
            racelist_tasks: [],
            tuning_labour_rate: 0,
            workshop_labour_rate: 0,
            bodyshop_labour_rate: 0,
            fab_labour_rate: 0,
            oldJobNote: null,
            oldJobCustomerNote: null,
            job: null,
            //users: [],
            order_status_list: [
                'Picked',
                'Processed',
                'Deleted',
                'Ordered',
                'Partially Ordered',
                'Partially Picked',
                'Partially Picked and Ordered',
                'Not Started'
            ],
            unauthed_table_collapsed: false,
            workshop_table_collapsed: false,
            tuning_table_collapsed: false,
            bodyshop_table_collapsed: false,
            fabrication_table_collapsed: false,
            workshop_end_table_collapsed: false,
            all_jobs: [],
            jobTaskInProgress: false,
            hasJobData: null,
            notesEditing: false,
            editInterval: null,
            old_note: "",
            old_customer_note: "",
            unauth_parts: [],
            selectedChecklist: null,
            note_revisions: [],
            current_comp_stock: [],
            noteRevisionsModal: false,
            doesHaveFreeLabour: false,
            sidebarCollapsed: true,
            unauthing: false,
        }
    },
    created() {
        const local = localStorage.getItem('staff');
        if(!local) {
            //return JSON.parse(localStorage.getItem('staff'))
            axios.get(api_url + '/v2/staff').then((resp) => {
                localStorage.setItem('staff', JSON.stringify(resp.data))
            }).catch(() => {
            });
        }
    },
    mounted() {
        //var id = this.$route.params.id;
        this.hasJobData = null;
        this.getData();

        setTimeout(() => {
            this.updateAuditLog();
        }, 5000)
    },
    computed: {
        parts_types() {
            if (this.job.bodyshop) {
                return [
                    "unauthed",
                    "bodyshop",
                    "workshop",
                    "fabrication",
                    "tuning",
                    "workshop_end"
                ]
            } else {
                return [
                    "unauthed",
                    "workshop",
                    "fabrication",
                    "tuning",
                    "workshop_end"
                ]
            }
        },
        bodyshop_parts_types() {
            return [
                "bodyshop_insurance_labour",
                "bodyshop_insurance_paint",
                "bodyshop_insurance_parts",
                "bodyshop_insurance_extra",
                "bodyshop_insurance_additional",
                "bodyshop"
            ]
        },
        users() {
            const local = localStorage.getItem('staff');
            if(local) {
                return JSON.parse(localStorage.getItem('staff'))
            } else {
                axios.get(api_url + '/v2/staff').then((resp) => {
                    localStorage.setItem('staff', JSON.stringify(resp.data))
                    return resp.data;
                }).catch(() => {
                    return [];
                });
            }
        },
        isBodyshop() {
            if (this.job) {
                if (this.job.customer) {
                    return this.job.customer.lastname === 'Paint&Body';
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        only_bodyshop() {
            if (this.job) {
                return this.job.only_bodyshop === 'true' || this.job.only_bodyshop === true;
            } else {
                return false;
            }
        },
        user() {
            if (useAuthStore().user) {
                return useAuthStore().user
            } else {
                return null
            }
        },
        estimate_fixed() {
            if (this.job.estimate_fixed !== "Fixed" && this.job.estimate_fixed !== "Estimate") {
                return "Fixed";
            } else {
                return this.job.estimate_fixed;
            }
        },
        status: {
            get: function () {
                let value = null;
                if (this.job.order_status === "6") {
                    value = "Processed";
                }
                if (this.job.order_status === "7") {
                    value = "Deleted";
                }
                if (this.job.order_status === "5") {
                    value = "Picked";
                }
                if (this.job.order_status === "2") {
                    value = "Ordered";
                }
                if (this.job.order_status === "1") {
                    value = "Partially Ordered";
                }
                if (this.job.order_status === "4") {
                    value = "Partially Picked";
                }
                if (this.job.order_status === "3") {
                    value = "Partially Picked and Ordered";
                }
                if (this.job.order_status === "0") {
                    value = "Not Started"
                }
                return value
            },
            set: function (newValue) {
                if (newValue === "Processed") {
                    this.job.order_status = "6"
                }
                if (newValue === "Deleted") {
                    this.job.order_status = "7"
                }
                if (newValue === "Picked") {
                    this.job.order_status = "5"
                }
                if (newValue === "Ordered") {
                    this.job.order_status = "2"
                }
                if (newValue === "Partially Ordered") {
                    this.job.order_status = "1"
                }
                if (newValue === "Partially Picked") {
                    this.job.order_status = "4"
                }
                if (newValue === "Partially Picked and Ordered") {
                    this.job.order_status = "3"
                }
                if (newValue === "Not Started") {
                    this.job.order_status = "0"
                }
            }
        },
        allUsers() {
            return this.users.sort(function (a, b) {
                if (a.nicename < b.nicename) {
                    return -1;
                }
                if (a.nicename > b.nicename) {
                    return 1;
                }
                return 0;
            });
        },
        missingFields() {
            const fields = [];
            if (this.job) {
                const veh = this.job.vehicle;
                if (veh) {
                    if (veh.enginecode == null || veh.enginecode === "") {
                        fields.push('Engine Code')
                    }
                    if (veh.customer_expected_power == null || veh.customer_expected_power === "") {
                        fields.push('Expected Power')
                    }
                    if (veh.customer_expected_peak_torque == null || veh.customer_expected_peak_torque === "") {
                        fields.push('Expected Torque')
                    }
                    if (veh.boost_level == null || veh.boost_level === "") {
                        fields.push('Boost Level')
                    }
                    if (veh.final_power == null || veh.final_power === "") {
                        fields.push('Final Power')
                    }
                    if (veh.final_torque == null || veh.final_torque === "") {
                        fields.push('Final Torque')
                    }
                    if (veh.timing == null || veh.timing === "") {
                        fields.push('Timing')
                    }
                    if (veh.tool == null || veh.tool === "") {
                        fields.push('Tool')
                    }
                    if (veh.actuator_set == null || veh.actuator_set === "") {
                        fields.push('Actuator Setting')
                    }
                    if (veh.tuning_note == null || veh.tuning_note === "") {
                        fields.push('Tuning Note')
                    }
                    if (veh.tuning_file == null || veh.tuning_file === "") {
                        fields.push('Tuning File')
                    }
                    if (veh.tuning_folder == null || veh.tuning_folder === "") {
                        fields.push('Tuning Folder')
                    }
                    if (veh.ecu == null || veh.ecu === "") {
                        fields.push('ECU')
                    }
                    if (veh.ecu_type == null || veh.ecu_type === "") {
                        fields.push('ECU Type')
                    }
                    if (veh.customer_ecu_software == null || veh.customer_ecu_software === "") {
                        fields.push('ECU Software')
                    }
                    if (veh.customer_ecu_version == null || veh.customer_ecu_version === "") {
                        fields.push('Software Version')
                    }
                    if (veh.duration == null || veh.duration === "") {
                        fields.push('Duration')
                    }
                    if (veh.time_in == null || veh.time_in === "") {
                        fields.push('Time In')
                    }
                    if (veh.time_out == null || veh.time_out === "") {
                        fields.push('Time Out')
                    }
                }
            }
            return fields;
        },
        labRateProps() {
            return {
                tuning: this.tuning_labour_rate,
                workshop: this.workshop_labour_rate,
                bodyshop: this.bodyshop_labour_rate,
                fabrication: this.fab_labour_rate,
            }
        }
    },
    methods: {
        getData() {
            console.log('getting data')
            const id = this.$route.params.id;
            axios.get(api_url + '/v2/jobs/' + id).then(resp1 => {
                const job = resp1.data;
                axios.get(api_url + '/v2/jobs/' + id + '/parts').then(resp2 => {
                    const parts = resp2.data;
                    axios.get(api_url + '/v2/jobs/' + id + '/bookings/previous').then(resp3 => {
                        const previous_bookings = resp3.data;
                        if (job) {
                            if (!job.job_detail) {
                                alert('There are no details for this job!')
                                this.job = null;
                                this.hasJobData = false;
                                return;
                            }
                            job.previous_bookings = previous_bookings;
                            job.vehicle.VRVrm = job.vehicle.reg;
                            job.vehicle.VRVin = job.vehicle.vin;
                            job.workshop_parts = parts.workshop;
                            job.workshop_end_parts = parts.workshop_end;
                            job.fabrication_parts = parts.fabrication;
                            job.tuning_parts = parts.tuning;
                            job.bodyshop_parts = parts.bodyshop;
                            job.unauthed_parts = parts.unauthed;
                            job.bodyshop_insurance_parts_parts = parts.bodyshop_insurance_parts_parts || [];
                            job.bodyshop_insurance_labour_parts = parts.bodyshop_insurance_labour_parts || [];
                            job.bodyshop_insurance_paint_parts = parts.bodyshop_insurance_paint_parts || [];
                            job.bodyshop_insurance_extra_parts = parts.bodyshop_insurance_extra_parts || [];
                            job.bodyshop_insurance_additional_parts = parts.bodyshop_ins_additional_parts || [];

                            this.job = job;
                            this.old_note = this.job.note;
                            this.old_customer_note = this.job.customer_notes;
                            this.doesHaveFreeLabour = this.job.free_labour;
                            if (this.job.task && this.job.task.times) {
                                this.job.task.times.forEach(element => {
                                    if (element.user === this.user.id && element.pause_time == null) {
                                        this.jobTaskInProgress = true;
                                    }
                                });
                            }
                            if (this.job.customer) {
                                window.document.title = this.job.id + " - " + this.job.customer.firstname + " " + this.job.customer.lastname + " Jobsheet | Darkside Developments"
                            }
                            this.getLabourRates();
                            this.getNoteRevisions();
                            this.hasJobData = true;

                            const partTypes = ['unauthed_parts', 'workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts', 'bodyshop_insurance_parts_parts', 'bodyshop_insurance_labour_parts', 'bodyshop_insurance_paint_parts', 'bodyshop_insurance_extra_parts', 'bodyshop_insurance_additional_parts'];
                            partTypes.forEach((type) => {
                                this.job[type].forEach((part, key) => {
                                    if (this.job.project_car === 1) {
                                        if (parseFloat(part.purchase_price) === 0) {
                                            part.purchase_price = part.cost_per_part
                                        }
                                    }
                                    part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP'
                                    });
                                    part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP'
                                    });
                                    if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                        part.estimate_fixed = "Fixed";
                                    }
                                    this.job[type][key].complete = Boolean(part.complete)
                                    this.job[type][key].deleting = false
                                });
                            })
                            this.gotData = true
                        }
                    });
                });
            });
            /*axios.all([
                axios.get(api_url + '/api/job/' + id),
                axios.get(api_url + '/api/job/' + id + '/parts'),
                axios.get(api_url + '/api/job/' + id + '/bookings/previous')
            ]).then(response => {
                const job = response[0].data;
                const parts = response[1].data;
                const previous_bookings = response[2].data;
                if (job) {
                    if (!job.job_detail) {
                        alert('There are no details for this job!')
                        this.job = null;
                        this.hasJobData = false;
                        return;
                    }
                    job.previous_bookings = previous_bookings;
                    job.vehicle.VRVrm = job.vehicle.reg;
                    job.vehicle.VRVin = job.vehicle.vin;
                    job.workshop_parts = parts.workshop;
                    job.workshop_end_parts = parts.workshop_end;
                    job.fabrication_parts = parts.fabrication;
                    job.tuning_parts = parts.tuning;
                    job.bodyshop_parts = parts.bodyshop;
                    job.unauthed_parts = parts.unauthed;
                    this.job = job;
                    this.old_note = this.job.note;
                    this.old_customer_note = this.job.customer_notes;
                    this.doesHaveFreeLabour = this.job.free_labour;
                    if (this.job.task && this.job.task.times) {
                        this.job.task.times.forEach(element => {
                            if (element.user === this.user.id && element.pause_time == null) {
                                this.jobTaskInProgress = true;
                            }
                        });
                    }
                    if (this.job.customer) {
                        window.document.title = this.job.id + " - " + this.job.customer.firstname + " " + this.job.customer.lastname + " Jobsheet | Darkside Developments"
                    }
                    this.getLabourRates();
                    this.getNoteRevisions();
                    this.hasJobData = true;

                    const partTypes = ['unauthed_parts', 'workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts'];
                    partTypes.forEach((type) => {
                        this.job[type].forEach((part, key) => {
                            if (this.job.project_car === 1) {
                                if (parseFloat(part.purchase_price) === 0) {
                                    part.purchase_price = part.cost_per_part
                                }
                            }
                            part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                                style: 'currency',
                                currency: 'GBP'
                            });
                            if (part.estimate_fixed == null || part.estimate_fixed === "") {
                                part.estimate_fixed = "Fixed";
                            }
                            this.job[type][key].complete = Boolean(part.complete)
                            this.job[type][key].deleting = false
                        });
                    })
                }
            });*/
        },
        getParts() {
            this.loadingParts = true;
            const id = this.$route.params.id;
            axios.get(api_url + '/v2/jobs/' + id + '/parts').then(resp => {
                const parts = resp.data;
                this.job.workshop_parts = parts.workshop;
                this.job.workshop_end_parts = parts.workshop_end;
                this.job.fabrication_parts = parts.fabrication;
                this.job.tuning_parts = parts.tuning;
                this.job.bodyshop_parts = parts.bodyshop;
                this.job.unauthed_parts = parts.unauthed;
                const partTypes = ['unauthed_parts', 'workshop_parts', 'workshop_end_parts', 'fabrication_parts', 'tuning_parts', 'bodyshop_parts'];
                partTypes.forEach((type) => {
                    this.job[type].forEach((part, key) => {
                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                        part.purchase_price = parseFloat(part.purchase_price).toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        });
                        part.cost_per_part = parseFloat(part.cost_per_part).toLocaleString('en-GB', {
                            style: 'currency',
                            currency: 'GBP'
                        });
                        if (part.estimate_fixed == null || part.estimate_fixed === "") {
                            part.estimate_fixed = "Fixed";
                        }
                        this.job[type][key].complete = Boolean(part.complete)
                        this.job[type][key].deleting = false
                    });
                })
                this.loadingParts = false;
            }).catch(err => {
                this.loadingParts = false;
            });
        },
        updateJobTaskChanged(val) {
            this.jobTaskInProgress = val;
        },
        updateLabour(val) {
            this.doesHaveFreeLabour = val;
        },
        updateAuditLog() {
            if(this.$refs.sidebar !== undefined) this.$refs.sidebar.updateAuditLog()
        },
        getLabourRates() {
            axios.get(api_url + '/v2/jobs/' + this.job.id + '/labour/rates').then((resp) => {
                this.tuning_labour_rate = resp.data.tuning;
                this.workshop_labour_rate = resp.data.workshop;
                this.fab_labour_rate = resp.data.fabrication;
                this.bodyshop_labour_rate = resp.data.bodyshop;
                if (this.job.vehicle.VRMake === "KTM") {
                    this.workshop_labour_rate = resp.data.ktm;
                }

                if (this.job.free_labour) {
                    this.workshop_labour_rate = 0;
                    this.tuning_labour_rate = 0;
                    this.fab_labour_rate = 0;
                    this.bodyshop_labour_rate = 0;
                }

                if (this.job.old_lab_rate && !this.job.free_labour) {
                    this.workshop_labour_rate = 60;
                }
            });
        },
        useNote(id) {
            const conf = confirm('Are you sure you want to revert to this note?');
            if (conf) {
                axios.get(api_url + '/v2/jobs/' + this.job.id + '/note/revisions/update/' + id).then((resp) => {
                    if (resp.data.success) {
                        this.job.note = resp.data.data;
                        this.old_note = this.job.note;
                        this.$refs.jobnote.val = this.job.note;
                        this.$refs.jobnote.editor.commands.setContent(this.$refs.jobnote.val, true)
                        this.noteRevisionsModal = false;
                        this.$message.success('Note Reverted');
                        this.getNoteRevisions();
                    } else {
                        this.$message.error('Failed to revert note.');
                    }
                }).catch((err) => {
                    console.log(err)
                    this.$message.error('Failed to revert note.');
                })
            }
        },
        getNoteRevisions() {
            axios.get(api_url + '/v2/jobs/' + this.job.id + '/note/revisions').then((resp) => {
                if (Array.isArray(resp.data)) {
                    this.note_revisions = resp.data;
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        openNoteRevisionModal() {
            this.noteRevisionsModal = true;
        },
        unauthSelected() {
            this.unauthing = true;
            axios.post(api_url + '/v2/jobs/' + this.job.id + '/update/unauth-parts', {
                parts: this.unauth_parts
            }).then((resp) => {
                if(resp.data.success) {
                    this.$message.success('Updated unauthed parts.');
                    this.getData();
                    this.unauth_parts = [];
                    this.unauthing = false;
                } else {
                    this.$message.error('Failed to update unauthed parts.');
                    this.unauthing = false;
                }
            }).catch(() => {
                this.$message.error('Failed to update unauthed parts.');
                this.unauthing = false;
            })
        },
        updateNote(html) {
            if (this.job.note !== this.old_note) {
                this.job.note = html;
                axios.post(api_url + '/api/job/' + this.job.id + '/update/note', {
                    value: this.job.note,
                }).then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Updated note.');
                        this.old_note = this.job.note
                    } else {
                        this.$message.error('Failed to update note.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update note.');
                })
            }
        },
        updateCustomerNotes(e) {
            if (this.job.customer_notes !== this.old_customer_note) {
                this.job.customer_notes = e.event.target.innerHTML;
                axios.post(api_url + '/api/job/' + this.job.id + '/update/customer-notes', {
                    value: this.job.customer_notes
                }).then(resp => {
                    if (resp.data.success) {
                        this.$message.success('Updated customer notes.');
                        this.old_customer_note = this.job.customer_notes
                    } else {
                        this.$message.error('Failed to update customer notes.');
                    }
                }).catch(() => {
                    this.$message.error('Failed to update customer notes.');
                })
            }
        },
        isEditing() {
            if (this.editInterval == null) {
                this.editInterval = setTimeout(() => {
                }, 30000)
            } else {
                clearTimeout(this.editInterval);
                this.editInterval = setTimeout(() => {
                }, 30000)
            }
        },
        sidebarToggled(val) {
            this.sidebarCollapsed = val;
        },
        getDifferences(oldNote, newNote) {
            return diff(oldNote, newNote, "textDiff") || "";
        },
        canShowTable(type) {
            var the_type = type;
            if(the_type === "workshop_end") {
                the_type = "workshop";
            }
            if(!the_type.includes("bodyshop")) {
                return false;
            }
            return true;
        }
    },
    watch: {
        'job.workshop_parts': {
            handler(newVal) {
                if (newVal !== undefined) {
                    newVal.forEach((part) => {
                        if (part.unauth_checkbox === true) {
                            this.unauth_parts.push(part);
                        } else {
                            this.unauth_parts.forEach((element, index) => {
                                if (part.part_no === element.part_no && part.part_name === element.part_name && part.name === element.name) {
                                    this.unauth_parts.splice(index, 1)
                                }
                            });
                        }

                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                    });
                }
            },
            deep: true,
        },
        'job.tuning_parts': {
            handler(newVal) {
                if (newVal !== undefined) {
                    newVal.forEach((part) => {
                        if (part.unauth_checkbox === true) {
                            this.unauth_parts.push(part);
                        } else {
                            this.unauth_parts.forEach((element, index) => {
                                if (part.part_no === element.part_no && part.part_name === element.part_name && part.name === element.name) {
                                    this.unauth_parts.splice(index, 1)
                                }
                            });
                        }
                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                    });
                }
            },
            deep: true,
        },
        'job.bodyshop_parts': {
            handler(newVal) {
                if (newVal !== undefined) {
                    newVal.forEach((part) => {
                        if (part.unauth_checkbox === true) {
                            this.unauth_parts.push(part);
                        } else {
                            this.unauth_parts.forEach((element, index) => {
                                if (part.part_no === element.part_no && part.part_name === element.part_name && part.name === element.name) {
                                    this.unauth_parts.splice(index, 1)
                                }
                            });
                        }
                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                    });
                }
            },
            deep: true,
        },
        'job.fabrication_parts': {
            handler(newVal) {
                if (newVal !== undefined) {
                    newVal.forEach((part) => {
                        if (part.unauth_checkbox === true) {
                            this.unauth_parts.push(part);
                        } else {
                            this.unauth_parts.forEach((element, index) => {
                                if (part.part_no === element.part_no && part.part_name === element.part_name && part.name === element.name) {
                                    this.unauth_parts.splice(index, 1)
                                }
                            });
                        }
                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                    });
                }
            },
            deep: true,
        },
        'job.workshop_end_parts': {
            handler(newVal) {
                if (newVal !== undefined) {
                    newVal.forEach((part) => {
                        if (part.unauth_checkbox === true) {
                            this.unauth_parts.push(part);
                        } else {
                            this.unauth_parts.forEach((element, index) => {
                                if (part.part_no === element.part_no && part.part_name === element.part_name && part.name === element.name) {
                                    this.unauth_parts.splice(index, 1)
                                }
                            });
                        }
                        if (this.job.project_car === 1) {
                            if (parseFloat(part.purchase_price) === 0) {
                                part.purchase_price = part.cost_per_part
                            }
                        }
                    });
                }
            },
            deep: true,
        },
        'job.free_labour'() {
            this.getLabourRates();
        }
    }
});
</script>
<style scoped>
.jobsheet-main {
    height: 100%;
    position: relative;
    top: 96px;
}
.jobsheet-right {
    height: calc(100vh - 149px);
    position: fixed;
}
.partStatus {
    border: 1px solid black;
    cursor: pointer;
}

.picked {
    background-color: #99ffc0;
}

.notPicked {
    background-color: #ffb2b2;
}

.ordered {
    background-color: #ffdcb2;
}
.ant-layout.content-layout {
    transition: all 0.2s;
}
.ant-layout:not(.ant-layout-has-sider):not(.collapsed) {
    /*margin-right: 300px;*/
}
@media screen and (max-width: 992px) {
    .ant-layout:not(.ant-layout-has-sider) {
        margin-right: 0;
    }
    .ant-layout-content {
        margin-top: 70px !important;
    }
}
</style>
<style>
td span ins.textDiff, td span ins.textDiff * {
    background: limegreen !important;
}
td span del.textDiff, td span del.textDiff * {
    background: orangered !important;
}
</style>
