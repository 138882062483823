<template>
	<div class="pt-4 container-fluid">
        <div class="overview-checkboxes flex gap-3 flex-wrap">
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_payment">Awaiting Payment</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_vehicle">Awaiting Vehicle</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_technician">Awaiting Technician</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_road_test">Awaiting Road Test</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.vehicle_not_started">Vehicle Present Not Started</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.road_test">Road Test</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.final_road_test_complete">Final Road Test Complete</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.started_workshop">Started - Workshop</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.started_bodyshop">Started - Bodyshop</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.started_tuning">Started - Tuning</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.started_fabrication">Started - Fabrication</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.final_checks">Final Checks</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.complete">Complete</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.cancelled">Cancelled</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.customer_notified">Customer Notified</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_collection">Awaiting Collection</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_invoice">Awaiting Invoice</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.collected_paid">Collected / Paid</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.rebooking">Rebooking</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_parts">Awaiting Parts</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.awaiting_authority">Awaiting Authority</a-checkbox>
            <a-checkbox class="m-0" v-model:checked="checkboxes.approved">Approved</a-checkbox>

            <div class="flex gap-2">
                <a-button @click='CheckAll()' size="small">Check All</a-button>
                <a-button @click='UncheckAll()' size="small">Uncheck All</a-button>
                <a-button @click='Reload($event)' size="small">Reload</a-button>
            </div>
        </div>

		<div class="md:flex">
            <div class="mt-2"><router-link class="ant-btn ant-btn-sm" to="/paint">Paint Tasks</router-link></div>
			<div class="ml-auto mb-2">
				<ul class="flex border border-solid border-gray-200 rounded overflow-hidden">
					<li class="bg-white border-l border-solid border-gray-200 py-0 px-1 md:px-3 md:py-2" v-for="usersNOJ in nonJobUsers" :key="usersNOJ" v-show="usersNOJ !== 'NA'">{{usersNOJ}}</li>
				</ul>
			</div>
		</div>

        <div class="maincard mt-3">
            <div class="maincard-header">
                Bodyshop Customer Jobs
                <a-input-search v-model:value="search" class="ml-auto w-52"/>
            </div>
            <!--<v-data-table :headers="headers" :items="jobData" :items-per-page="30" class="elevation-1" :loading="overviewTableLoading" loading-text="Getting jobs..." :search="overviewSearch">
				<template v-slot:item="props">
					<tr @dblclick="OpenJobsheet(props.item.id)" class="tablerow" :class="{'dark': DarkRow(props.item.status)}" :style="{backgroundColor: RowBackgroundColour(props.item.status), color:RowTextColour(props.item.status)}">
						<td @click="OpenJobsheet(props.item.id)" style="cursor: pointer;">{{props.item.id}}</td>
						<td>{{props.item.name}}</td>
						<td>{{props.item.reg}}</td>
						<td>{{props.item.VRModel}}</td>
						<td>
							<flat-pickr v-model="props.item.drop_off_time" :config="flatPickrConf" @on-close="UpdateDropOffTime" :rowId="props.item.id"></flat-pickr>
						</td>
						<td>
							<a-select class="custom-select mw-100 w-100" v-model="props.item.customer_activity" :options="activityTypes" @change="UpdateCustomerActivity($event, props.item.id)"></a-select>
						</td>
						<td>
							<flat-pickr v-model="props.item.pick_up_time" :config="flatPickrConf" @on-close="UpdatePickUpTime" :rowId="props.item.id"></flat-pickr>
						</td>
						<td :style="{backgroundColor: StatusBackgroundColour(props.item.order_status), color: '#000'}">
							<template v-if="props.item.order_status == 2">Not Picked</template>
							<template v-else-if="props.item.order_status == 0">Not Started</template>
							<template v-else-if="props.item.order_status == 5">Picked</template>
							<template v-else-if="props.item.order_status == 6">Picked & Processed</template>
							<template v-else-if="props.item.order_status == 7">Picked & Deleted</template>
							<template v-else-if="props.item.order_status == 3">Partially Picked/Ordered</template>
							<template v-else-if="props.item.order_status == 4">Partially Picked</template>
							<template v-else-if="props.item.order_status == 1">Partially Ordered</template>
						</td>
						<td>
							<a-select class="custom-select mw-100 w-100" v-model="props.item.status" :options="statusTypes" @change="UpdateStatus($event, props.item)"></a-select>
						</td>
						<td>
                            <div class="flex flex-wrap gap-1">
							<template v-if="JSON.parse(props.item.diary).ws == true">
								<div class='workshop rounded px-2 text-xxs font-bold w-full bg-blue-400 text-black'>Workshop</div>
							</template>
							<template v-if="JSON.parse(props.item.diary).tun == true">
								<div class='tuning rounded px-2 text-xxs font-bold w-full bg-gray-500 text-white'>Tuning</div>
							</template>
							<template v-if="JSON.parse(props.item.diary).fab == true">
								<div class='fabrication rounded px-2 text-xxs font-bold w-full bg-gray-800 text-white'>Fabrication</div>
							</template>
							<template v-if="JSON.parse(props.item.diary).vvt == true">
								<div class='vvt rounded px-2 text-xxs font-bold w-full bg-yellow-400 text-black'>VVT</div>
							</template>
							<template v-if="JSON.parse(props.item.diary).bodyshop == true">
								<div class='bodyshop rounded px-2 text-xxs font-bold w-full bg-yellow-400 text-black'>Bodyshop</div>
							</template>
                            </div>
						</td>
						<td>
							<a-select class="custom-select mw-100 w-100" v-model="props.item.owner" :options="userList" @change="UpdateOwner($event, props.item.id)"></a-select>
						</td>
						<td>
							<a-select class="custom-select mw-100 w-100" v-model="props.item.default_tuning_owner" :options="userList" @change="UpdateTuner($event, props.item.id)"></a-select>
                        </td>
						<td>
							<template v-if="props.item.task"><i class='far fa-check-circle fa-2x'></i></template>
							<template v-else><i class='far fa-times-circle fa-2x' @click='CreateTask(props.item.id, props.item)' style="cursor:pointer"></i></template>
						</td>
						<td><template v-if="parseFloat(props.item.labour_total)">£{{props.item.labour_total.toFixed(2)}}</template><template v-else>£0.00</template></td>
						<td><template v-if="parseFloat(props.item.parts_total)">£{{props.item.parts_total.toFixed(2)}}</template><template v-else>£0.00</template></td>
						<td>{{GetProgress(props.item.task)}}</td>
						<td>{{props.item.progress}}</td>
					</tr>
				</template>
			</v-data-table>-->
        </div>
	</div>
</template>

<script>
	import moment from 'moment';
	import flatPickr from 'vue-flatpickr-component';
	import 'flatpickr/dist/flatpickr.css';
	import _ from 'lodash';
    import {useAuthStore} from "@/store/authStore";
	export default {
		name: 'Overview',
		props: ['startDSDTable'],
		data() {
			return {
				snackbar: false,
				toastText: "",
				token: Cookies.get('token') || "",
				showDSDTable: this.startDSDTable,
				checkboxes: {
					awaiting_payment: true,
					awaiting_vehicle: true,
					awaiting_technician: true,
					awaiting_road_test: true,
					bodyshop: true,
					vehicle_not_started: true,
					road_test: true,
					final_road_test_complete: true,
					started_workshop: true,
					started_bodyshop: true,
					started_tuning: true,
					started_fabrication: true,
					final_checks: true,
					complete: true,
					cancelled: false,
					customer_notified: true,
					awaiting_collection: true,
					awaiting_invoice: true,
					collected_paid: false,
					rebooking: false,
					awaiting_parts: true,
					awaiting_authority: true,
					approved: true,
				},
				statusList: [],
				internalStatusList: [],
				users: [],
				userList: [],
				jobsTable: null,
				dsdTable: null,
				usersNotOnJob: [],
				workingUsers: [],
				statusTypes: [
                    {
                        value: 'Approved',
                        label: 'Approved'
                    },
                    {
                        value: 'Awaiting Authority',
                        label: 'Awaiting Authority'
                    },
                    {
                        value: 'Awaiting Authority - Insurance',
                        label: 'Awaiting Authority - Insurance'
                    },
                    {
                        value: 'Awaiting Collection',
                        label: 'Awaiting Collection',
                    },
                    {
                        value: 'Awaiting Invoice',
                        label: 'Awaiting Invoice',
                    },
                    {
                        value: 'Awaiting Parts',
                        label: 'Awaiting Parts',
                    },
                    {
                        value: 'Awaiting Payment',
                        label: 'Awaiting Payment',
                    },
                    {
                        value: 'Awaiting Road Test',
                        label: 'Awaiting Road Test',
                    },
                    {
                        value: 'Awaiting Technician',
                        label: 'Awaiting Technician',
                    },
                    {
                        value: 'Awaiting Vehicle',
                        label: 'Awaiting Vehicle',
                    },
                    {
                        value: 'Cancelled',
                        label: 'Cancelled',
                    },
                    {
                        value: 'Collected / Paid',
                        label: 'Collected / Paid',
                    },
                    {
                        value: 'Complete',
                        label: 'Complete',
                    },
                    {
                        value: 'Customer Notified',
                        label: 'Customer Notified',
                    },
                    {
                        value: 'Final Checks',
                        label: 'Final Checks',
                    },
                    {
                        value: 'Final Road Test Complete',
                        label: 'Final Road Test Complete',
                    },
                    {
                        value: 'Rebooking',
                        label: 'Rebooking',
                    },
                    {
                        value: 'Road Test',
                        label: 'Road Test',
                    },
                    {
                        value: 'Started - Fabrication',
                        label: 'Started - Fabrication',
                    },
                    {
                        value: 'Started - Tuning',
                        label: 'Started - Tuning',
                    },
                    {
                        value: 'Started - Workshop',
                        label: 'Started - Workshop',
                    },
                    {
                        value: 'Started - Bodyshop',
                        label: 'Started - Bodyshop',
                    },
                    {
                        value: 'Vehicle Present Not Started',
                        label: 'Vehicle Present Not Started',
                    }
                ],
				activityTypes: [
                    {
                        value: 'Leaving - Collect same day',
                        label: 'Leaving - Collect same day',
                    },
                    {
                        value: 'Leaving - Collect when done',
                        label: 'Leaving - Collect when done',
                    },
                    {
                        value: 'Waiting in Reception',
                        label: 'Waiting in Reception'
                    },
                    {
                        value: 'Staying in Hotel',
                        label: 'Staying in Hotel',
                    }
                ],
				search: "",
				internalSearch: "",
				headers: [
					{ text: 'ID', value: 'id', align: 'center' },
					{ text: 'Name', value: 'name', align: 'center' },
					{ text: 'Vehicle Reg', value: 'reg', align: 'center' },
					{ text: 'Vehicle', value: 'VRModel', align: 'center' },
					{ text: 'Due', value: 'drop_off_time', align: 'center' },
					{ text: 'Customer Activity', value: 'customer_activity', align: 'center' },
					{ text: 'Collection', value: 'pick_up_time', align: 'center' },
					{ text: 'Order Status', value: 'order_status', align: 'center' },
					{ text: 'Status', value: 'status', align: 'center' },
					{ text: 'Diary', value: 'diary', align: 'center' },
					{ text: 'Owner', value: 'nicename', align: 'center' },
					{ text: 'Tuning Owner', value: 'default_tuning_owner', align: 'center' },
					{ text: 'Task', value: 'task', align: 'center' },
					{ text: 'Labour', value: 'id', align: 'center' },
					{ text: 'Parts', value: 'price', align: 'center' },
					{ text: 'In Progress', value: 'id', align: 'center' },
					{ text: 'Workshop Progress', value: 'workshop_progress', align: 'center' },
				],
				dsdHeaders: [
					{ text: 'ID', value: 'id', align: 'center' },
					{ text: 'Name', value: 'name', align: 'center' },
					{ text: 'Vehicle Reg', value: 'reg', align: 'center' },
					{ text: 'Vehicle', value: 'VRModel', align: 'center' },
					{ text: 'Due', value: 'drop_off_time', align: 'center' },
					{ text: 'Order Status', value: 'order_status', align: 'center' },
					{ text: 'Status', value: 'status', align: 'center' },
					{ text: 'Diary', value: 'diary', align: 'center' },
					{ text: 'Owner', value: 'nicename', align: 'center' },
					{ text: 'Tuning Owner', value: 'default_tuning_owner', align: 'center' },
					{ text: 'Task', value: 'task', align: 'center' },
					{ text: 'In Progress', value: 'id', align: 'center' },
					{ text: 'Workshop Progress', value: 'workshop_progress', align: 'center' },
				],
				jobData: [],
				internalJobData: [],
				overviewTableLoading: false,
				internalOverviewTableLoading: false,
				flatPickrConf: {
					position: 'auto',
					enableTime: true,
					time_24hr: true,
					altFormat: 'd-m-Y H:i:S',
					altInput: true,
					dateFormat: 'Y-m-d H:i:S',
				}
			}
		},
		components: {
			flatPickr
		},
		mounted() {
			if (this.user) {
				this.GetStaff();
			}
		},
		computed: {
			nonJobUsers() {
				var _users = this.users.filter(x => x.department == 1 || x.department == 2 || x.department == 4);
				var _names = []
				_users.forEach(element => {
					_names.push(element.nicename);
				});
				var notOnJob = _names.filter((el) => !this.workingUsers.includes(el));
				return notOnJob;
			},
			user() {
				if (useAuthStore().user) {
					return useAuthStore().user;
				} else {
					return null
				}
			},
			overviewSearch() {
				if (this.search.length > 3) {
					return this.search;
				}
			},
		},
		methods: {
			GetStaff() {
				if (this.users.length == 0) {
					try {
                        axios.get(api_url + '/v2/staff').then(resp => {
                            this.users = resp.data;
                            this.users.forEach(element => {
                                this.userList.push({label: element.nicename, value: element.id});
                            });
                            this.GetJobs();
                            this.GetInternalJobs();
                        }).catch((error) => {

                        });
                    } catch (error) {

                    }
				} else {
					this.GetJobs();
					this.GetInternalJobs();
				}
			},
			GetJobs() {
				this.overviewTableLoading = true;
				if (this.checkboxes.awaiting_payment == true) { this.statusList.push('Awaiting Payment') }
				if (this.checkboxes.awaiting_vehicle == true) { this.statusList.push('Awaiting Vehicle') }
				if (this.checkboxes.bodyshop == true) { this.statusList.push('Bodyshop') }
				if (this.checkboxes.awaiting_technician == true) { this.statusList.push('Awaiting Technician') }
				if (this.checkboxes.awaiting_road_test == true) { this.statusList.push('Awaiting Road Test') }
				if (this.checkboxes.vehicle_not_started == true) { this.statusList.push('Vehicle Present Not Started') }
				if (this.checkboxes.road_test == true) { this.statusList.push('Road Test') }
				if (this.checkboxes.started_workshop == true) { this.statusList.push('Started - Workshop') }
				if (this.checkboxes.started_bodyshop == true) { this.statusList.push('Started - Bodyshop') }
				if (this.checkboxes.started_tuning == true) { this.statusList.push('Started - Tuning') }
				if (this.checkboxes.started_fabrication == true) { this.statusList.push('Started - Fabrication') }
				if (this.checkboxes.awaiting_invoice == true) { this.statusList.push('Awaiting Invoice') }
				if (this.checkboxes.final_checks == true) { this.statusList.push('Final Checks') }
				if (this.checkboxes.customer_notified == true) { this.statusList.push('Customer Notified') }
				if (this.checkboxes.awaiting_collection == true) { this.statusList.push('Awaiting Collection') }
				if (this.checkboxes.final_road_test_complete == true) { this.statusList.push('Final Road Test Complete') }
				if (this.checkboxes.complete == true) { this.statusList.push('Complete') }
				if (this.checkboxes.cancelled == true) { this.statusList.push('Cancelled') }
				if (this.checkboxes.collected_paid == true) { this.statusList.push('Collected / Paid') }
				if (this.checkboxes.awaiting_parts == true) { this.statusList.push('Awaiting Parts') }
				if (this.checkboxes.awaiting_authority == true) { this.statusList.push('Awaiting Authority') }
				if (this.checkboxes.approved == true) { this.statusList.push('Approved') }

				axios.post(api_url + '/v2/jobs/overview/bodyshop', {
					status: this.statusList
				}).then(resp => {
					this.jobData = resp.data;
                    console.log(resp.data)
					this.overviewTableLoading = false;
					this.jobData.forEach((j, index) => {
						var oldDropoff = moment(j.drop_off_time).tz("Europe/London");
						var oldPickup = moment(j.pick_up_time).tz("Europe/London");
						var diff = oldPickup.diff(oldDropoff, 'days');
						this.jobData[index].daysDiff = diff;
					});
				});
			},
			GetInternalJobs() {
				this.internalOverviewTableLoading = true;
				if (this.checkboxes.awaiting_payment == true) { this.internalStatusList.push('Awaiting Payment') }
				if (this.checkboxes.awaiting_vehicle == true) { this.internalStatusList.push('Awaiting Vehicle') }
				if (this.checkboxes.bodyshop == true) { this.internalStatusList.push('Bodyshop') }
				if (this.checkboxes.awaiting_technician == true) { this.internalStatusList.push('Awaiting Technician') }
				if (this.checkboxes.awaiting_road_test == true) { this.internalStatusList.push('Awaiting Road Test') }
				if (this.checkboxes.vehicle_not_started == true) { this.internalStatusList.push('Vehicle Present Not Started') }
				if (this.checkboxes.road_test == true) { this.internalStatusList.push('Road Test') }
				if (this.checkboxes.started_workshop == true) { this.internalStatusList.push('Started - Workshop') }
				if (this.checkboxes.started_bodyshop == true) { this.internalStatusList.push('Started - Bodyshop') }
				if (this.checkboxes.started_tuning == true) { this.internalStatusList.push('Started - Tuning') }
				if (this.checkboxes.started_fabrication == true) { this.internalStatusList.push('Started - Fabrication') }
				if (this.checkboxes.awaiting_invoice == true) { this.internalStatusList.push('Awaiting Invoice') }
				if (this.checkboxes.final_checks == true) { this.internalStatusList.push('Final Checks') }
				if (this.checkboxes.customer_notified == true) { this.internalStatusList.push('Customer Notified') }
				if (this.checkboxes.awaiting_collection == true) { this.internalStatusList.push('Awaiting Collection') }
				if (this.checkboxes.final_road_test_complete == true) { this.internalStatusList.push('Final Road Test Complete') }
				if (this.checkboxes.complete == true) { this.internalStatusList.push('Complete') }
				if (this.checkboxes.cancelled == true) { this.internalStatusList.push('Cancelled') }
				if (this.checkboxes.collected_paid == true) { this.internalStatusList.push('Collected / Paid') }
				if (this.checkboxes.awaiting_parts == true) { this.internalStatusList.push('Awaiting Parts') }
				if (this.checkboxes.awaiting_authority == true) { this.internalStatusList.push('Awaiting Authority') }
                if (this.checkboxes.approved == true) { this.internalStatusList.push('Approved') }

				axios.post(api_url + '/v2/jobs/overview/internal', {
					status: this.internalStatusList
				}).then(resp => {
					this.internalJobData = resp.data;
					this.internalOverviewTableLoading = false;
				});
			},
			Reload() {
				this.$message.success("Reloading Table...");
				this.statusList = [];
				if (this.checkboxes.awaiting_payment == true) { this.statusList.push('Awaiting Payment') }
				if (this.checkboxes.awaiting_vehicle == true) { this.statusList.push('Awaiting Vehicle') }
				if (this.checkboxes.awaiting_technician == true) { this.statusList.push('Awaiting Technician') }
				if (this.checkboxes.awaiting_road_test == true) { this.statusList.push('Awaiting Road Test') }
				if (this.checkboxes.bodyshop == true) { this.statusList.push('Bodyshop') }
				if (this.checkboxes.vehicle_not_started == true) { this.statusList.push('Vehicle Present Not Started') }
				if (this.checkboxes.road_test == true) { this.statusList.push('Road Test') }
				if (this.checkboxes.started_workshop == true) { this.statusList.push('Started - Workshop') }
				if (this.checkboxes.started_bodyshop == true) { this.statusList.push('Started - Bodyshop') }
				if (this.checkboxes.started_tuning == true) { this.statusList.push('Started - Tuning') }
				if (this.checkboxes.started_fabrication == true) { this.statusList.push('Started - Fabrication') }
				if (this.checkboxes.awaiting_invoice == true) { this.statusList.push('Awaiting Invoice') }
				if (this.checkboxes.final_checks == true) { this.statusList.push('Final Checks') }
				if (this.checkboxes.customer_notified == true) { this.statusList.push('Customer Notified') }
				if (this.checkboxes.awaiting_collection == true) { this.statusList.push('Awaiting Collection') }
				if (this.checkboxes.final_road_test_complete == true) { this.statusList.push('Final Road Test Complete') }
				if (this.checkboxes.complete == true) { this.statusList.push('Complete') }
				if (this.checkboxes.cancelled == true) { this.statusList.push('Cancelled') }
				if (this.checkboxes.collected_paid == true) { this.statusList.push('Collected / Paid') }
				if (this.checkboxes.rebooking == true) { this.statusList.push('Rebooking') }
				if (this.checkboxes.awaiting_parts == true) { this.statusList.push('Awaiting Parts') }
				if (this.checkboxes.awaiting_authority == true) { this.statusList.push('Awaiting Authority') }
				if (this.statusList.length == 0) {
					this.statusList = ['empty'];
				}

				this.GetJobs();
				this.GetInternalJobs();
			},
			CreateTask(id, rowdata) {
				var title = rowdata.firstname + " " + rowdata.lastname + " - " + rowdata.email + " - " + rowdata.phonenumber + " - " + rowdata.make + " " + rowdata.model.trim() + " [" + rowdata.reg + "]";
				this.$message.success("Creating Task...");
				axios.post(api_url + '/v2/jobs/'+id+'/task/create', {
					title: title,
					id: id
				}).then((resp) => {
					if (resp.data.status == "Success") {
						this.$message.success("Task Created");
						location.reload();
					} else {
						this.$message.error(resp.status);
					}
				}).catch((err) => {
					this.$message.error(err);
				});
			},
			CheckAll() {
				this.checkboxes.awaiting_payment = true;
				this.checkboxes.awaiting_vehicle = true;
				this.checkboxes.awaiting_technician = true;
				this.checkboxes.awaiting_road_test = true;
				this.checkboxes.vehicle_not_started = true;
				this.checkboxes.road_test = true;
				this.checkboxes.started_workshop = true;
				this.checkboxes.started_bodyshop = true;
				this.checkboxes.started_tuning = true;
				this.checkboxes.started_fabrication = true;
				this.checkboxes.awaiting_invoice = true;
				this.checkboxes.final_checks = true;
				this.checkboxes.customer_notified = true;
				this.checkboxes.awaiting_collection = true;
				this.checkboxes.final_road_test_complete = true;
				this.checkboxes.complete = true;
				this.checkboxes.cancelled = true;
				this.checkboxes.collected_paid = true;
				this.checkboxes.awaiting_parts = true;
				this.checkboxes.awaiting_authority = true;
				this.checkboxes.approved = true;
				this.statusList = [];
				if (this.checkboxes.awaiting_payment == true) { this.statusList.push('Awaiting Payment') }
				if (this.checkboxes.awaiting_vehicle == true) { this.statusList.push('Awaiting Vehicle') }
				if (this.checkboxes.awaiting_technician == true) { this.statusList.push('Awaiting Technician') }
				if (this.checkboxes.awaiting_road_test == true) { this.statusList.push('Awaiting Road Test') }
				if (this.checkboxes.vehicle_not_started == true) { this.statusList.push('Vehicle Present Not Started') }
				if (this.checkboxes.road_test == true) { this.statusList.push('Road Test') }
				if (this.checkboxes.started_workshop == true) { this.statusList.push('Started - Workshop') }
				if (this.checkboxes.started_bodyshop == true) { this.statusList.push('Started - Bodyshop') }
				if (this.checkboxes.started_tuning == true) { this.statusList.push('Started - Tuning') }
				if (this.checkboxes.started_fabrication == true) { this.statusList.push('Started - Fabrication') }
				if (this.checkboxes.awaiting_invoice == true) { this.statusList.push('Awaiting Invoice') }
				if (this.checkboxes.final_checks == true) { this.statusList.push('Final Checks') }
				if (this.checkboxes.customer_notified == true) { this.statusList.push('Customer Notified') }
				if (this.checkboxes.awaiting_collection == true) { this.statusList.push('Awaiting Collection') }
				if (this.checkboxes.final_road_test_complete == true) { this.statusList.push('Final Road Test Complete') }
				if (this.checkboxes.collected_paid == true) { this.statusList.push('Collected / Paid') }
				if (this.checkboxes.rebooking == true) { this.statusList.push('Rebooking') }
				if (this.checkboxes.awaiting_parts == true) { this.statusList.push('Awaiting Parts') }
				if (this.checkboxes.awaiting_authority == true) { this.statusList.push('Awaiting Authority') }
                if (this.checkboxes.approved == true) { this.statusList.push('Approved') }
				this.GetJobs();
			},
			UncheckAll() {
				this.checkboxes.awaiting_payment = false;
				this.checkboxes.awaiting_vehicle = false;
				this.checkboxes.awaiting_technician = false;
				this.checkboxes.awaiting_road_test = false;
				this.checkboxes.vehicle_not_started = false;
				this.checkboxes.road_test = false;
				this.checkboxes.started_workshop = false;
				this.checkboxes.started_bodyshop = false;
				this.checkboxes.started_tuning = false;
				this.checkboxes.started_fabrication = false;
				this.checkboxes.awaiting_invoice = false;
				this.checkboxes.final_checks = false;
				this.checkboxes.customer_notified = false;
				this.checkboxes.awaiting_collection = false;
				this.checkboxes.final_road_test_complete = false;
				this.checkboxes.complete = false;
				this.checkboxes.cancelled = false;
				this.checkboxes.collected_paid = false;
				this.checkboxes.rebooking = false;
				this.checkboxes.awaiting_parts = false;
				this.checkboxes.awaiting_authority = false;
				this.checkboxes.approved = false;
				this.statusList = [];
			},
			IsJsonString(str) {
				try {
					JSON.parse(str);
				} catch (e) {
					return false;
				}
				return true;
			},
			RowBackgroundColour(cellData) {
				var color = "";
				var text = "";
				if (cellData == "Awaiting Vehicle") { color = "#00a909"; text = "#000" }
				if (cellData == "Awaiting Road Test") { color = "#a6ce55"; text = "#000" }
				if (cellData == "Awaiting Collection") { color = "#02ce93"; text = "#000" }
				if (cellData == "Awaiting Invoice") { color = "#02ce93"; text = "#000" }
				if (cellData == "Awaiting Payment") { color = "#00a909"; text = "#000" }
				if (cellData == "Vehicle Present Not Started") { color = "#076604"; text = "#fff" }
				if (cellData == "Road Test") { color = "#00919b"; text = "#fff" }
				if (cellData == "Final Checks") { color = "#6ece11"; text = "#000" }
				if (cellData == "Customer Notified") { color = "#988cff"; text = "" }
				if (cellData == "Complete") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Collected / Paid") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Rebooking") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Cancelled") { color = "#000"; text = "#fff" }
				if (cellData == "Started - Tuning") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Fabrication") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Workshop") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Bodyshop") { color = "#045f66"; text = "#fff" }
				if (cellData == 'Awaiting Technician') { color = "#045f66"; text = "#fff" }
				return color;
			},
			RowTextColour(cellData) {
				var color = "";
				var text = "";
				if (cellData == "Awaiting Vehicle") { color = "#00a909"; text = "#000" }
				if (cellData == "Awaiting Road Test") { color = "#a6ce55"; text = "#000" }
				if (cellData == "Awaiting Collection") { color = "#02ce93"; text = "#000" }
				if (cellData == "Awaiting Invoice") { color = "#02ce93"; text = "#000" }
				if (cellData == "Awaiting Payment") { color = "#00a909"; text = "#000" }
				if (cellData == "Vehicle Present Not Started") { color = "#076604"; text = "#fff" }
				if (cellData == "Road Test") { color = "#00919b"; text = "#fff" }
				if (cellData == "Final Checks") { color = "#6ece11"; text = "#000" }
				if (cellData == "Customer Notified") { color = "#988cff"; text = "" }
				if (cellData == "Complete") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Collected / Paid") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Rebooking") { color = "#8b47f6"; text = "#fff" }
				if (cellData == "Cancelled") { color = "#000"; text = "#fff" }
				if (cellData == "Started - Tuning") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Fabrication") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Workshop") { color = "#045f66"; text = "#fff" }
				if (cellData == "Started - Bodyshop") { color = "#045f66"; text = "#fff" }
				if (cellData == 'Awaiting Technician') { color = "#045f66"; text = "#fff" }
				return text;
			},
			StatusBackgroundColour(cellData) {
				var status = '';
				var colour;
				if (cellData === 2) { status = "Not Picked"; colour = "#ff6666"; }
				if (cellData === 0) { status = "Not Started"; colour = "#ff6666"; }
				if (cellData === 5) { status = "Picked"; colour = "#78ff66"; }
				if (cellData === 6) { status = "Picked & Processed"; colour = "#78ff66"; }
				if (cellData === 7) { status = "Picked & Deleted"; colour = "#78ff66"; }
				if (cellData === 3) { status = "Partially Picked/Ordered"; colour = "#ffb266"; }
				if (cellData === 4) { status = "Partially Picked"; colour = "#ffb266"; }
				if (cellData === 1) { status = "Partially Ordered"; colour = "#ffb266"; }
				return colour;
			},
			GetProgress(data) {
				var inProgress = ""
				if (data !== null && data !== undefined) {
					if (data.times) {
						data.times.forEach(element => {
							if (element.pause_time == null) {
								var username = "";
								this.users.forEach(user => {
									if (user.id === element.user) {
										username = user.nicename;
									}
								});

								var a = moment(element.start_time);
								var b = moment();
								var timespent = convertTimeHHMMSS(b.diff(a) / 1000);

								if (inProgress.length == 0) {
									inProgress = username + " - " + timespent;
								} else {
									inProgress = inProgress + "<br>" + username + " - " + timespent
								}
							}
						});
					} else {
						return "";
					}
				}
				return inProgress
			},
			DarkRow(data) {
				var dark = false;
				if (data == "Awaiting Vehicle") { dark = true; }
				if (data == "Awaiting Road Test") { dark = true; }
				if (data == "Awaiting Collection") { dark = true; }
				if (data == "Awaiting Invoice") { dark = true; }
				if (data == "Awaiting Payment") { dark = true; }
				if (data == "Vehicle Present Not Started") { dark = false; }
				if (data == "Road Test") { dark = false; }
				if (data == "Final Checks") { dark = true; }
				if (data == "Customer Notified") { dark = false; }
				if (data == "Complete") { dark = false; }
				if (data == "Collected / Paid") { dark = false; }
				if (data == "Rebooking") { dark = false; }
				if (data == "Cancelled") { dark = false; }
				if (data == "Started - Tuning") { dark = false; }
				if (data == "Started - Fabrication") { dark = false; }
				if (data == "Started - Workshop") { dark = false; }
				if (data == "Started - Bodyshop") { dark = false; }
				if (data == 'Awaiting Technician') { dark = false; }
				return dark;
			},
			UpdateCustomerActivity(e, id) {
                console.log(e)
				var value = e.target.value;
				axios.post(api_url + '/v2/jobs/'+id+'/update/customer_activity', {
					id: id,
					value: value
				}).then(resp => {
					this.$message.success('Customer Activity Updated.');
				});
			},
			UpdateStatus(e, item) {
				var value = e.target.value;
				axios.post(api_url + '/v2/jobs/'+id+'/update/status', {
					id: item.id,
					value: value,
					username: this.user.nicename
				}).then((response) => {
					if (response.data.status == "Job not processed on Linnworks or Website!") {
						alert('Job not processed on Linnworks or Website!')
						var j = this.jobData.find(el => el.id == item.id);
						j.status = response.data.value;
					} else {
						this.$message.success('Updated status.');
					}
				}).catch((error) => {

				});
			},
			UpdateOwner(e, id) {
				var value = e.target.value;
				axios.post(api_url + '/v2/jobs/'+id+'/update/owner', {
					id: id,
					value: value
				}).then((response) => {
					this.$message.success('Updated Owner.');
				}).catch((error) => {
				});
			},
			UpdateTuner(e, id) {
				var value = e.target.value;
				axios.post(api_url + '/v2/jobs/'+id+'/update/tuner', {
					id: id,
					value: value
				})
					.then((response) => {
						this.$message.success('Updated Tuner.');
					})
					.catch((error) => {
					});
			},
			OpenJobsheet(id) {
				let routeData = this.$router.resolve({ name: 'Jobsheet', params: { id: id } });
				window.open(routeData.href, '_blank');
			},
			UpdateDropOffTime(selectedDates, dateStr, instance) {
				var id = instance.input.attributes.rowid.value;
				var job = this.jobData.find(el => el.id == id);
				var puTime = moment(job.pick_up_time);
				var end_time = moment(job.pick_up_time).format('YYYY-MM-DD HH:mm:ss');
				var diff = job.daysDiff;
				if (diff < 0) { diff = 1 }
				if (diff > 0) {
					end_time = moment(dateStr).tz("Europe/London").add(diff, 'days');
					if (end_time.isoWeekday() == 7) { end_time = end_time.add(1, 'days') }
					var time = puTime.format("HH:mm:ss");
					end_time = end_time.format('YYYY-MM-DD ') + time;
					job.pick_up_time = end_time;
				}

				axios.post(api_url + '/v2/jobs/'+id+'/update/dropoff', {
					id: id,
					dropoff: dateStr,
					pickup: end_time
				}).then((response) => {
					if (response.data.status && response.data.status == "Success") {
						if (response.data.message !== "Nothing to update") {
							this.$message.success('Dropoff Date Updated.');
						}
					}
				}).catch((error) => {
				});
			},
			UpdatePickUpTime(selectedDates, dateStr, instance) {
				var id = instance.input.attributes.rowid.value;
				if (dateStr !== undefined) {
					axios.post(api_url + '/v2/jobs/'+id+'/update/pickup', {
						id: id,
						value: dateStr
					}).then((response) => {
						this.$message.success('Pickup Date Updated.');
					}).catch((error) => {
					});
				}
			},
		},
	}
	window.convertTimeHHMMSS = function convertTimeHHMMSS(val) {
		let hhmmss = new Date(val * 1000).toISOString().substr(11, 8);
		return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
	};
</script>

<style lang="scss" scoped>
	table#overview_table,
	table#darkside_table {
		font-size: 12px;
	}
	.tablerow {
		td {
			text-align: center;
			font-size: 12px !important;
		}
		.form-control.input {
			color: #fff !important;
		}
		&.dark {
			.form-control.input {
				color: #000 !important;
			}
		}
	}
	.mw-100 {
		min-width: 100px !important;
	}
	.flatpickr-input ~ .input {
		color: #fff;
	}
	.v-snack--top {
		top: 60px;
    }
    .justify-content-space-evenly {
        justify-content: space-evenly;
    }
</style>
