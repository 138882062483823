<template>
    <editor-content :editor="editor" :class="className" :id="idName" :ref="idName"/>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from '@tiptap/starter-kit'
import _ from 'underscore';
export default {
    props: ['modelValue', 'className', 'idName', 'readonly'],
    components: {
        EditorContent
    },
    mounted() {
        this.val = this.escapeHtml(this.modelValue);
        this.editor = new Editor({
            content: this.val,
            extensions: [
                StarterKit, 
            ],
            onUpdate: (e) => {
                console.log(this.editor)
                var html = this.editor.getHTML();
                if(html !== "" && html !== null) {
                    html = html.replace(/<a\s+href=/gi, '<a target="_blank" href=');
                }
                this.$emit('update:modelValue', html)
            },
            onBlur: (e) => {
                this.$emit('blur', e)
            },
            spellcheck: true
        });
        if(this.readonly) {
            this.editor.setOptions({editable: false});
        }
    },
    data(){
        return {
            editor: null,
            val: "",
            linkUrl: null,
            linkMenuIsActive: false
        }
    },
    methods: {
        showLinkMenu(attrs) {
            this.linkUrl = attrs.href
            this.linkMenuIsActive = true
            this.$nextTick(() => {
                this.$refs.linkInput.focus()
            })
        },
        hideLinkMenu() {
            this.linkUrl = null
            this.linkMenuIsActive = false
        },
        setLinkUrl(event, command, url) {
            event.stopPropagation();
            command(
                { 
                    href: url,
                    target: '_blank'
                }
            )
            this.hideLinkMenu();
            this.editor.focus();
        },
        unescape: function(val) {
            if(val) {
                var el = document.createElement('div');
                return val.replace(/\&[#0-9a-z]+;/gi, function (enc) {
                    el.innerHTML = enc;
                    return el.innerText
                });
            }
        },
        wrapURL(val, new_window) {
            if(val !== '') {
                var text = this.unescape(val);
                text = text.replace(/\<br\>/g, '<br/>');
                //text = text.replace(/\<p\>\<br\/\>/g, '<p>');
                text = text.replace(/\<br \/\>/g, '<br/>');
                text = text.replace(/&(?!#?[a-z0-9]+;)/g, '&amp;');
                text = "<div>"+text+"</div>"
                const parser = new DOMParser();
                const xmlString = text;
                const doc1 = parser.parseFromString(xmlString, "application/xml");
                return doc1.documentElement.innerHTML;
                return text;
            } else {
                return val;
            }
        },
        replace(s) {
            if(s !== "" && s !== null && s !== undefined) {
                s = s.replace(/Â\s/g,'');
                s = s.replace(/^\s*/g,'');
                return this.wrapURL(s);
            } else {
                return this.wrapURL(s);
            }
        },
        escapeHtml(text) {
            var textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            var newVal = this.replace(_.unescape(textArea.value));
            return newVal;
            //return text;
        },
    },
    beforeUnmount() {
        this.editor.destroy()
    },
    filters: {
        unescape: function(val) {
            return unescape(val)
        }
    },
    watch: {
        value (val) {
            /*if (this.editor && val !== this.value) {
                this.editor.setContent(val, true)
            }*/
        }
    }
}
</script>

<style>

</style>