<template>
    <div class="pt-4">
        <BookingStep1 v-if="step === 1"></BookingStep1>
        <BookingStep2 v-if="step === 2"></BookingStep2>
        <BookingStep3 v-if="step === 3"></BookingStep3>
        <BookingStep4 v-if="step === 4"></BookingStep4>
    </div>
</template>

<script>
import BookingStep1 from './step1.vue';
import BookingStep2 from './step2.vue';
import BookingStep3 from './step3.vue';
import BookingStep4 from './step4.vue';
export default {
    components: {
        BookingStep1,
        BookingStep2,
        BookingStep3,
        BookingStep4,
    },
    data(){
        return {
            step: 1
        }
    },
}
</script>

<style>

</style>
